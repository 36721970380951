import React, { useState } from 'react';

import AdvanceExportModal from '+containers/Dashboard/Shared/AdvanceExportModal';
import { getActionButton } from '+containers/Dashboard/Shared/data/Filter';
import { useSetUserAccess } from '+hooks';
import { ExportActionType, filterButton } from '+types';
import { isAllowed } from '+utils';

const ExportPermissions = ({
  canExport = true,
  options,
  exportAction
}: {
  canExport: boolean;
  options: Record<string, string>;
  exportAction: (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => void;
}) => {
  const [openExport, setOpenExport] = useState(false);
  const userAccess = useSetUserAccess();

  const actionButtons = getActionButton('permissions', 'Permissions', setOpenExport, false, Boolean(canExport), Boolean(canExport));

  return (
    <>
      <span className="element-actions-tab export-container">
        <ul className="export-container">
          {actionButtons.map((button: filterButton, index: number) => (
            <li key={button?.text}>
              {actionButtons.length > 1 && index !== 1 && button?.show && <span className="divider-sm" />}
              <button
                type="button"
                className="btn  userdetails-btn --edit"
                hidden={button?.permissionsArray ? !isAllowed(userAccess, button.permissionsArray || []) : !button?.show}
                onClick={button?.action}
                data-testid={button?.dataTestId}
              >
                {button?.image}
                &nbsp;
                <span>{button?.text}</span>
              </button>
            </li>
          ))}
        </ul>
      </span>
      <AdvanceExportModal
        openExport={openExport}
        heading="Export Permissions"
        description="Choose how you would like to export these permissions"
        type="permissions"
        exportAction={exportAction}
        close={() => setOpenExport(false)}
        options={options}
      />
    </>
  );
};

export default ExportPermissions;
