import React from 'react';
import { Link } from 'react-router-dom';

import {
  capitalize,
  capitalizeRemovedash,
  chargebackStatusTagConfig,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  getTwoDigitDate,
  switchCard,
  switchCurrency,
  switchStatus,
  truncateString
} from '+utils';
import Tooltip from '+containers/Dashboard/Shared/Tooltip';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import Copyable from '+shared/Copyable';
import {
  ActionButtonType,
  SummaryItemType,
  ChargebackActionsType,
  ISODateStringType,
  ChargebackStatusType,
  IChargebackTransactionDetails
} from '+types';
import { chargebackStatus } from './constants';

function isChargebackPending(status: ChargebackStatusType) {
  return [chargebackStatus.PENDING, chargebackStatus.PENDING_PRE_ARB].includes(status);
}

function isChargebackReescalated(status: ChargebackStatusType) {
  return ![
    chargebackStatus.PENDING,
    chargebackStatus.PROCESSING,
    chargebackStatus.DECLINED,
    chargebackStatus.PART_ACCEPTED,
    chargebackStatus.INVALID,
    chargebackStatus.ACCEPTED
  ].includes(status);
}

function canUpdateChargeback(status: ChargebackStatusType) {
  return [chargebackStatus.PROCESSING, chargebackStatus.PROCESSING_PRE_ARB].includes(status);
}

// @Dev: do not delete or you will be fired!
/* function canRefundChargeback(status: ChargebackStatusType) {
  return [
    chargebackStatus.ACCEPTED,
    chargebackStatus.PART_ACCEPTED,
    chargebackStatus.ACCEPTED_PRE_ARB,
    chargebackStatus.PART_ACCEPTED_PRE_ARB,
    chargebackStatus.ACCEPTED_ARB
  ].includes(status);
}
*/

export function getActionButtonsBy(status: ChargebackStatusType, onClick: (val: ChargebackActionsType) => void): Array<ActionButtonType> {
  if (isChargebackPending(status))
    return [
      {
        children: 'Declare Invalid',
        variant: 'light-blue',
        onClick: () => onClick('declareInvalid')
      },
      {
        children: 'Process Chargeback',
        onClick: () => onClick('processChargeback')
      }
    ];

  if (canUpdateChargeback(status))
    return [
      {
        children: 'Update Status',
        variant: 'light-blue',
        onClick: () => onClick('updateStatus')
      }
    ];

  // @Dev: do not delete or you will be fired!
  /* if (canRefundChargeback(status))
    return [
      {
        children: (
          <span className="d-flex align-items-center">
            Refund
            <i className="os-icon os-icon-corner-up-right ml-3" />
          </span>
        ),
        variant: 'light-blue',
        onClick: () => onClick('refundChargeback')
      }
    ]; */

  return [];
}

export function generateSummaryFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  return [
    {
      label: (
        <>
          Chargeback Amount
          <Tooltip type="net_amount" image={InfoIcon} message="This is the amount escalated" />
        </>
      ),
      value: `${formatAmount(data?.amount)} ${data?.currency}`
    },
    {
      label: 'Date / Time',
      value: `${getDate(data?.escalation_date as string)}, ${getTime(data?.escalation_date as string)}`
    },
    { label: 'Merchant Name', value: data?.merchant_name },
    {
      label: 'Transaction ID',
      value: (
        <>
          <div>
            <Copyable text={data?.transaction_reference?.toUpperCase?.()} textModifier={(text) => truncateString(text, 18)} />
          </div>
          <Link
            to={`/dashboard/pay-ins/virtual-card/${data?.transaction_reference}`}
            className="btn"
            data-redirectto="original-transaction"
          >
            See Original Transaction
            <i className="os-icon os-icon-arrow-up-right icon-after ml-2" />
          </Link>
        </>
      )
    }
  ];
}

export function generateMoreDetailFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  const chargebackIsAccepted = [
    chargebackStatus.ACCEPTED,
    chargebackStatus.PART_ACCEPTED,
    chargebackStatus.ACCEPTED_PRE_ARB,
    chargebackStatus.PART_ACCEPTED_PRE_ARB
  ].includes(data?.status);

  return [
    {
      label: (
        <>
          Chargeback Status
          <Tooltip type="net_amount" image={InfoIcon} message="This is the current status of the chargeback" />
        </>
      ),
      value: (
        <>
          <span className={`status-pill smaller ${switchStatus(data?.status)}`} style={{ margin: '0 7px 5px 0' }} />
          {chargebackStatusTagConfig[data?.status]?.name}
        </>
      )
    },
    {
      label: 'Refund Status',
      value: 'Not Available'
    },
    {
      label: 'Transaction Amount',
      value: `${formatAmount(data?.transaction_amount)} ${data?.currency}`
    },
    {
      label: 'Chargeback Amount',
      value: `${formatAmount(data?.amount)} ${data?.currency}`
    },
    {
      label: isChargebackReescalated(data?.status) ? 'Re-escalated Amount' : 'Escalated Amount',
      value: `${formatAmount(data?.re_escalated_amount ?? data?.amount)} ${data?.currency}`
    },
    {
      label: 'Accepted Amount',
      value: `${formatAmount(data?.accepted_amount)} ${data?.currency}`,
      hidden: chargebackIsAccepted
    },
    {
      label: 'Currency Charged',
      value: switchCurrency[data?.currency]
    },
    {
      label: 'Transaction Type',
      value: capitalizeRemovedash(data?.transaction_type)
    },
    {
      label: 'Purchasing Vendor',
      value: data?.card_acceptor_name ? capitalize(data?.card_acceptor_name) : 'Not Available'
    },
    {
      label: 'Date, Time of Transaction',
      value: getDateAndTime(data?.transaction_date as string)
    },
    {
      label: 'Date, Time of Escalation',
      value: getDateAndTime(data?.escalation_date as string)
    },
    {
      label: (
        <>
          Expected Resolution Date
          <Tooltip
            type="net_amount"
            image={InfoIcon}
            message="This is the date by or before when the chargeback request will be resolved"
          />
        </>
      ),
      value: data?.escalation_date ? getDateAndTime(data?.escalation_date as string) : 'Not Available'
    },
    {
      label: 'Date, Time of Resolution',
      value: data?.actual_resolution_date ? getDateAndTime(data?.actual_resolution_date as string) : 'Not Available'
    },
    {
      label: 'Kora Reference',
      value: <Copyable text={data?.reference} spanClassName="copyable-blue" />
    },
    {
      label: 'Location',
      value: data?.card_acceptor_country ?? 'Not Available'
    },
    { label: isChargebackReescalated(data?.status) ? 'Reason for Re-escalation' : 'Description', value: data?.description }
  ];
}

export function generateCardholderInfoFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  return [
    {
      label: "Carholder's name",
      value: data?.card_holder_name
    },
    {
      label: 'PAN',
      value: (
        <>
          <img alt={data?.card_brand} src={switchCard[data?.card_brand]} className="card-logo" />
          <Copyable text={`**** **** **** ${data?.last_four}`} spanClassName="copyable-bold" />
        </>
      )
    },
    {
      label: 'Card ID',
      value: <Copyable text={data?.card_reference} spanClassName="copyable-blue" />
    },
    {
      label: 'Expiry Date',
      value: getTwoDigitDate(data?.card_expiry_date)
    }
  ];
}

export function reduceDocumentsFrom(data: IChargebackTransactionDetails['status_history'] = []) {
  return data?.reduce<Array<{ url: string; date: ISODateStringType }>>((prev, next) => {
    if (next.evidence) {
      return [
        ...prev,
        {
          url: next.evidence,
          date: next.date
        }
      ];
    }
    return prev;
  }, []);
}
