/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import SettlementsConfig from '+shared/SettlementsConfig';
import { logError, storageDataKey } from '+utils';

import Conversions from './Conversions';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

export default function MerchantSettlements() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams() as { id: string };
  const [activeCurrency, setActiveCurrency] = useState('NGN');

  const { storeQueryData, getQueryData } = useQueryStorage();
  const data = getQueryData(storageDataKey.SINGLE_MERCHANT) as { settlements: unknown; risk_level: string };

  const { data: res = {}, isLoading } = useQuery(['SINGLE_MERCHANT', id], () => apiRequest.getMerchant(id), {
    enabled: !data,
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error getting this merchant's details`,
        type: 'danger'
      });
    }
  });

  const {
    data: getMerchantSettlements,
    refetch,
    isLoading: fetchingSettlements
  } = useQuery(['MERCHANT_SETTLEMENT'], () => apiRequest.getMerchantSettlements(id), {
    enabled: !data,
    onSuccess: response => storeQueryData(storageDataKey.SINGLE_MERCHANT, { ...(data || res.data), settlements: response.data }),
    onError: error => {
      logError(error);
      feedbackInit({
        message: 'There has been an error fetching merchant settlements, Please try again',
        type: 'danger'
      });
    }
  });

  const settlements = data?.settlements || getMerchantSettlements?.data;

  useEffect(() => {
    if (settlements && !Object.keys(settlements).includes(activeCurrency)) {
      setActiveCurrency(Object.keys(settlements)[0]);
    }
  }, [settlements, activeCurrency]);

  return (
    <div className="nav-content active">
      {fetchingSettlements || isLoading ? (
        <LoadingPlaceholder type="table" content={2} />
      ) : (
        <div className="element-box">
          <div className="element-box-heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="heading-box-mmd">
              <h4 className="form-header">Settlement Configuration</h4>
              <div className="form-desc no-underline mb-2">You can adjust the configuration of this merchant’s settlements here.</div>
            </div>
          </div>

          <CurrencyPicker
            options={Object.keys(settlements || {})}
            onChange={value => {
              setActiveCurrency(value);
            }}
            className="mt-2 mb-3"
            activeCurrency={activeCurrency}
            label={<strong>Showing configuration for:</strong>}
            id="merchant-settlements-settings__currency-switch"
          />

          <Conversions activeCurrency={activeCurrency} id={id} />

          {settlements?.[activeCurrency] && (
            <SettlementsConfig
              data={settlements}
              activeCurrency={activeCurrency}
              source="merchant"
              getConfig={refetch}
              merchantID={id}
              merchantRiskLevel={(data || res.data)?.risk_level || ''}
            />
          )}
        </div>
      )}
    </div>
  );
}
