/* eslint-disable no-param-reassign */
import { CurrencyType } from '+types';
import { formatAmount } from '+utils';

const sharedStatus = {
  PENDING: 'pending',
  PROCESSING: 'processing'
};

export const chargebackStatus = {
  ...sharedStatus,
  ACCEPTED: 'accepted',
  PART_ACCEPTED: 'partially_accepted',
  INVALID: 'invalid',
  DECLINED: 'declined',
  PENDING_PRE_ARB: 'pending_pre_arbitration',
  PROCESSING_PRE_ARB: 'processing_pre_arbitration',
  ACCEPTED_PRE_ARB: 'accepted_pre_arbitration',
  PART_ACCEPTED_PRE_ARB: 'partially_accepted_pre_arbitration',
  DELAYED_PRE_ARB: 'delayed_pre_arbitration',
  INVALID_PRE_ARB: 'invalid_pre_arbitration',
  DECLINED_PRE_ARB: 'declined_pre_arbitration',
  DECLINED_ARB: 'declined_arbitration',
  ACCEPTED_ARB: 'accepted_arbitration'
};

export const refundStatus = {
  ...sharedStatus,
  FULLY_PAID: 'fully_paid',
  PART_PAID: 'partially_paid'
};

export const initialValues = {
  reason: '',
  description: '',
  evidence: '',
  newStatus: '',
  amountAccepted: '',
  amountDeclined: '',
  refundAmount: ''
};

type ValuesType = typeof initialValues;

type ValidatePropsType = {
  formValues: Partial<ValuesType>;
  step: 'declareInvalid' | 'declareInvalid' | 'updateStatus' | 'refundChargeback';
  currency: CurrencyType;
  chargebackAmount: string;
  prevAcceptedAmount: string;
};

export const validate = ({ formValues, step, currency, chargebackAmount, prevAcceptedAmount }: ValidatePropsType) => {
  const errors: Partial<typeof formValues> = {};

  if (step === 'declareInvalid') validateDeclareInvalid(formValues, errors);

  if (step === 'updateStatus') validateUpdateStatus(errors, formValues, currency, chargebackAmount);

  if (step === 'refundChargeback') validateRefundChargeback(formValues, errors, prevAcceptedAmount);

  return errors;
};

export const updateEscalationStatusOptions = [
  { value: '', label: '- Select a status -' },
  { value: chargebackStatus.ACCEPTED, label: 'Chargeback Accepted' },
  { value: chargebackStatus.PART_ACCEPTED, label: 'Chargeback Accepted (Partially)' },
  { value: chargebackStatus.DECLINED, label: 'Chargeback Declined' }
];

export const updateReescalationStatusOptions = [
  { value: '', label: '- Select a status -' },
  { value: chargebackStatus.ACCEPTED_PRE_ARB, label: 'Pre-arbitration Accepted' },
  { value: chargebackStatus.PART_ACCEPTED_PRE_ARB, label: 'Pre-arbitration Accepted (Partially)' },
  { value: chargebackStatus.DECLINED_PRE_ARB, label: 'Pre-arbitration Declined' }
];

export const declareInvalidStatusOptions = [
  { value: chargebackStatus.INVALID, label: 'Chargeback Invalid' },
  { value: chargebackStatus.INVALID_PRE_ARB, label: 'Pre-arbitration Invalid' }
];

export const chargebackReasonOptions = [
  { value: '', label: '- Select a reason -' },
  { value: 'inconsistent', label: 'Inconsistent Information' },
  { value: 'false-claim', label: 'False Claim' },
  { value: 'other', label: 'Other' }
];

const validateDeclareInvalid = (values: Partial<ValuesType>, errors: Partial<ValuesType>) => {
  if (!values.reason) {
    errors.reason = 'Please select a reason';
  }
  if (!values.description) {
    errors.description = 'Please enter a description';
  }
};

const validateUpdateStatus = (errors: Partial<ValuesType>, values: Partial<ValuesType>, currency: CurrencyType, amount: string) => {
  const minAcceptedAmount = 0;
  const minDeclinedAmount = 0;

  const chargebackAmount = Number(amount);
  const amountAccepted = Number(values.amountAccepted);

  const isPartiallyAccepted = [chargebackStatus.PART_ACCEPTED, chargebackStatus.PART_ACCEPTED_PRE_ARB].includes(values.newStatus as string);

  const isDeclined = [chargebackStatus.DECLINED, chargebackStatus.DECLINED_PRE_ARB].includes(values.newStatus as string);

  if (!values.newStatus) errors.newStatus = 'Please select a status';

  if (isPartiallyAccepted) {
    if (!values.amountAccepted) errors.amountAccepted = 'Please enter the accepted amount';

    if (!values.evidence) errors.evidence = 'Please upload a file';

    if (values.amountAccepted && amountAccepted < minAcceptedAmount)
      errors.amountAccepted = `Amount accepted should be at least ${formatAmount(minAcceptedAmount)} ${currency}`;

    if (values.amountAccepted && amountAccepted > chargebackAmount)
      errors.amountAccepted = `Amount accepted cannot exceed ${formatAmount(chargebackAmount)} ${currency}`;

    if (!values.amountDeclined) errors.amountDeclined = 'Please enter the declined amount';

    if (values.amountDeclined && +values.amountDeclined < minDeclinedAmount)
      errors.amountDeclined = `Amount declined should be at least ${formatAmount(minDeclinedAmount)} ${currency}`;

    if (values.amountDeclined && +values.amountDeclined > chargebackAmount)
      errors.amountDeclined = `Amount declined cannot exceed ${formatAmount(chargebackAmount)} ${currency}`;
  }

  if (isDeclined) {
    if (!values.reason) errors.reason = 'Please give a reason';
    if (!values.evidence) errors.evidence = 'Please upload a file';
  }
};

const validateRefundChargeback = (values: Partial<ValuesType>, errors: Partial<ValuesType>, prevAcceptedAmount: string) => {
  if (!values.refundAmount) {
    errors.refundAmount = 'Please enter the refunded amount';
  }
  if (Number(values?.refundAmount) !== Number(prevAcceptedAmount)) {
    errors.refundAmount = 'refund amount should equal the accepted amount';
  }
};
