/* eslint-disable no-restricted-globals */
import React, { FC, useState } from 'react';
import { capitalize, capitalizeRemovedash } from '+utils';
import success from '+assets/img/dashboard/success-check-mark.svg';
import Modal from '+shared/Modal';
import { tiers, TEditCompletedModal, TEditLimitModal, TConfirmEditModal, limitsForm } from '../data';
import './fixed-virtual-accounts.scss';

export const EditLimitModal: FC<TEditLimitModal> = ({ newLimit, setModalType, formSubmit, newLimitCap }) => {
  const [type, setType] = useState<'single' | 'daily' | 'monthly'>('single');
  const form = limitsForm(newLimit, newLimitCap, formSubmit, type);

  return (
    <Modal
      description="Set the default limits around merchants use of this product. All merchants with Default Configuration for this product will inherit this configuration."
      heading="Default limit configuration"
      headerBottomBorder
      size="sm"
      formCenter
      close={() => setModalType(null)}
      secondButtonText="Save"
      secondButtonDisable={!form.isValid}
      secondButtonAction={() => form.isValid && form.handleSubmit()}
      content={
        <div className="fva-modal" data-testid="edit-limit-modal">
          <div className="__title">
            <span>Limits for {capitalize(newLimit.type)} Account</span>
            <span>Tier {tiers(newLimit.tier)}</span>
          </div>
          {(['single', 'daily', 'monthly'] as unknown as [keyof typeof form.values]).map((key) => (
            <div className="mt-3" key={key}>
              <label htmlFor={key} className="semibold">
                Max. Funding limit ({capitalize(key === 'single' ? 'Per Transaction' : key)})
              </label>
              <input
                type="text"
                name={key}
                inputMode="numeric"
                pattern="[0-9]+"
                id={key}
                style={{ borderColor: form.errors[key] ? 'red' : '' }}
                onFocus={() => setType(key)}
                onKeyDown={(e) => {
                  const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace'];
                  if (isNaN(+e.key) && !allowedKeys.includes(e.key)) e.preventDefault();
                }}
                data-testid={`${key}-limit`}
                value={form.values[key]}
                onChange={(e) => form.setFieldValue(key, e.target.value)}
                className="form-control"
                placeholder="Enter amount"
              />
              <span data-testid="error-text" className="input__errors">
                {form.errors[key]}
              </span>
            </div>
          ))}
          <div className="__info">Note: Modifications you make here will be applied immediately after you save them.</div>
        </div>
      }
    />
  );
};

export const ConfirmEditModal: FC<TConfirmEditModal> = ({ setModalType, updateLimits }) => (
  <Modal
    description="Kindly confirm that you want to apply the modification(s) to the configuration. They will take effect immediately"
    heading="Default limit configuration"
    headerBottomBorder
    size="sm"
    firstButtonText="Back"
    firstButtonAction={() => setModalType('edit')}
    secondButtonText="Yes, Confirm"
    secondButtonAction={updateLimits}
    content=""
    close={() => setModalType(null)}
  />
);

export const EditCompletedModal: FC<TEditCompletedModal> = ({ setModalType }) => (
  <Modal
    content={
      <div className="fva-modal__success" data-testid="edit-completed-modal">
        <img src={success} alt="success response icon" />
        <h6>Success</h6>
        <p>You have successfully made changes to this configuration</p>
        <button type="button" onClick={() => setModalType(null)} aria-label="close success modal">
          Dismiss
        </button>
      </div>
    }
    size="sm"
    showButtons={false}
    close={() => setModalType(null)}
  />
);
