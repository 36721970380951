import React, { useState, useEffect } from 'react';
import check from '+assets/img/dashboard/check-white.svg';

import './index.scss';


interface ICustomCheckboxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  text: React.ReactNode | string;
  onChange: () => void;
}

const CustomCheckbox = ({ className, checked, text, onChange, disabled } : ICustomCheckboxProps ) => {
  const [checkboxInput, setCheckboxInput] = useState(checked);

  useEffect(() => {
    if (checked !== checkboxInput) {
      onChange(checkboxInput);
    }
  }, [checkboxInput]);

  useEffect(() => {
    if (checked !== checkboxInput) {
      setCheckboxInput(checked);
    }
  }, [checked]);

  return (
    <label className={`checkbox__custom ${className} ${disabled ? 'disabled' : ''}`} style={{ '--check-image': `url("${check}")` }}>
      <input
        type="checkbox"
        className={`${checkboxInput ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        defaultChecked={checkboxInput}
        onClick={() => {
          if (disabled) return;
          setCheckboxInput(prevCheckboxInput => !prevCheckboxInput);
        }}
      />
      <button
        aria-label="check"
        onClick={() => {
          if (disabled) return;
          setCheckboxInput(prevCheckboxInput => !prevCheckboxInput);
        }}
        type="button"
      >
        {text}
      </button>
    </label>
  );
};

export default CustomCheckbox;
