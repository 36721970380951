import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { APIDownload, filteredOutObjectProperty, getDate, logError, queriesParams } from '+utils';

import HeroSection from '../Components/HeroSection';
import { VerificationEventTable } from '../Components/VerificationEventTable';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function Verification() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const dateFrom = searchQuery.value.dateFrom;
  const dateTo = searchQuery.value.dateTo;
  const status = searchQuery.value.status;
  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.status,
    queriesParams.dateFrom,
    queriesParams.dateTo
  ];

  const sortingParams = {
    ...(status ? { status: typeof status === 'string' ? [status] : status } : {}),
    ...(dateFrom ? { startDate: dateFrom } : {}),
    ...(dateTo ? { endDate: dateTo } : {}),
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };
  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(['VERIFICATIONS', searchQuery.value], () => api.getVerifications({ page, limit, sortingParams }), {
    keepPreviousData: true,
    onError: () => {
      let message = 'There has been an error getting verification events.';
      if (searchQuery.value?.sorterType)
        message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        },
        componentLevel: false
      });
    }
  });

  const exportVerifications = async (format: 'csv' | 'xlsx', close: () => void, fieldsToExport: Array<string>) => {
    try {
      const res = await api.getVerifications({ sortingParams, format, fieldsToExport });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Verifications at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your verfication list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };
  return (
    <div>
      <HeroSection
        title="Verification Events"
        description="When a merchant performs a check on their customer’s identity, it is recorded as a verification event. These are the verification events from various merchants using Identity."
      />
      <VerificationEventTable allMerchants resolvedData={resolvedData} isFetching={isFetching} exportVerifications={exportVerifications} />
    </div>
  );
}
