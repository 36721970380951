import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { IReversalsTransactionDetails } from '+types/reversal';
import { formatAmount, history, switchTrxnMessage } from '+utils';

import { generateMoreDetailFrom, generateReversalFrom, generateSummaryFrom } from '../ReversalsData';

const api = new APIRequest();

export default function PayinReversalDetails() {
  const { id } = useParams<Record<string, string>>();
  const { feedbackInit } = useFeedbackHandler();

  const { data, refetch, isLoading } = useQuery(`REVERSALS_${id}`, () => api.getReversal(id), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id?.toUpperCase()}.`,
        type: 'danger',
        action: { name: 'retry', action: () => refetch() }
      });
      history.goBack();
    }
  });

  const summaries = generateSummaryFrom(data as IReversalsTransactionDetails['data']);
  const reversalInfo = generateReversalFrom(data as IReversalsTransactionDetails['data']);
  const moreDetails = generateMoreDetailFrom(data as IReversalsTransactionDetails['data']);

  return (
    <div style={{ padding: '40px' }}>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount as number)}
          currency={data?.currency}
          status={switchTrxnMessage[data?.status as IReversalsTransactionDetails['status']]?.name.replace('Transaction', 'Reversal')}
          statusBg={switchTrxnMessage[data?.status as IReversalsTransactionDetails['status']]?.backgroundColor}
          statusColor={switchTrxnMessage[data?.status as IReversalsTransactionDetails['status']]?.color}
          isLoading={isLoading}
          summaries={summaries}
        />
        <TransactionDetails.Section isLoading={isLoading} heading="More Transaction Details" summaries={moreDetails} />
        <TransactionDetails.Section isLoading={isLoading} heading="Recepient's Information" summaries={reversalInfo} />
      </TransactionDetails>
    </div>
  );
}
