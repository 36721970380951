import React from 'react';
import Copyable from '../Copyable';
import Icon from '../Icons';
import './index.scss';


export default function RenderStyledJson({data}: {data: Record<string, string>}) {
  const [showMore, setShowMore] = React.useState(false);
  return (
    <div className="render-styled-json">
      <div className="render-styled-json__copy">
        <Copyable textToCopy={JSON.stringify(data)} text="" />
      </div>
      <div className={`code-block ${showMore ? 'show-more': ''}`}>{renderObject(data)}</div>
      {!showMore && (
        <button data-testid="show-btn" type="button" className="btn btn-link render-styled-json__btn" onClick={() => setShowMore((prev) => !prev)}>
          Show more <Icon name="caretDown" />
        </button>
      )}
    </div>
  );
}

const renderObject = (data: Record<string, string>) => {
  return (
    <pre>
      <span className="curly-brace">&#123;</span>
      {Object.entries(data).map(([key, value]) => {
        const tempValue = value !== null && value !== undefined ? value : 'null';
        return (
          <div key={key}>
            <span className="key">{JSON.stringify(key)}:</span>
            {typeof tempValue === 'object' ? (
              renderObject(tempValue)
            ) : (
              <span className={`value ${typeof value === 'number' && 'number'}`}>{JSON.stringify(tempValue)}</span>
            )}
          </div>
        );
      })}
      <span className="curly-brace">&#125;</span>
    </pre>
  );
};
