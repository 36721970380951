/* eslint-disable no-nested-ternary */
import React from 'react';

import useSetUserAccess from '+hooks/useSetUserAccess';
import CurrencyPicker from '+shared/CurrencyPicker';
import EmptyState from '+shared/EmptyState';
import Tooltip from '+shared/Tooltip';
import { capitalizeRemovedash, isAllowed, maskBetweenSetRange } from '+utils';

import CheckedIcon from '+assets/img/dashboard/check-green.svg';
import Check from '+assets/img/dashboard/check-greenround.svg';
import RejectedIcon from '+assets/img/dashboard/close-red.svg';
import Info from '+assets/img/dashboard/information-button-disable.svg';

interface IMerchantSettlementAccountProps {
  settlementAccounts?: Record<string, string> | null;
  kycStatus: string;
  setModal?: () => void;
  validation?: any; 
  bvn?: string;
}

export default function MerchantSettlementAccount({ 
  settlementAccounts = null, 
  setModal = () => null, 
  kycStatus, 
  validation, 
  bvn 
}: IMerchantSettlementAccountProps) {
  const [activeCurrency, setActiveCurrency] = React.useState(Object.keys(settlementAccounts)?.[0] || 'NGN');
  const userAccess = useSetUserAccess();
  const validationError = () => {
    if (validation?.bvn?.is_valid === false) return true;
    if (
      (validation?.bvn?.dob && !validation?.bvn?.dob?.match) ||
      (validation?.bvn?.phone && !validation?.bvn?.phone?.match) ||
      !validation?.bvn?.name?.match
    )
      return true;
  };

  const renderValidationMessage = bvnDetails => {
    const dobMatch = bvnDetails?.dob?.match;
    const nameMatch = bvnDetails?.name?.match;
    const phoneNumberMatch = bvnDetails?.phone?.match;

    if (bvnDetails.is_valid === false) {
      return <p>Invalid BVN</p>;
    }

    if (dobMatch === false && nameMatch === false && phoneNumberMatch === false) {
      return <p>Name, Date of Birth and Phone number does not match</p>;
    }
    return (
      <>
        {nameMatch === false && <p>Name does not match.</p>}
        {dobMatch === false && <p>Date of birth does not match.</p>}
        {phoneNumberMatch === false && <p>Phone number does not match.</p>}
      </>
    );
  };

  return (
    <>
      {Object.keys(settlementAccounts || {}).length > 0 && (
        <CurrencyPicker
          options={Object.keys(settlementAccounts)}
          onChange={value => {
            setActiveCurrency(value);
          }}
          className="merchants-fees__currency-toggle"
          activeCurrency={activeCurrency}
          label={<strong>Showing accounts for:</strong>}
          id="merchant-fees__currency-switch"
        />
      )}

      {settlementAccounts && Object.keys(settlementAccounts || {}).length === 0 && (
        <EmptyState message="No settlement account information available" />
      )}

      {settlementAccounts ? (
        <div className={`details-wrapper ${kycStatus?.toLowerCase() === 'rejected' ? 'is-disabled' : ''}`}>
          {settlementAccounts[activeCurrency]?.length === 0 && (
            <div className="entry entry-acc">
              <div className="entry-acc__left">
                <span className="bk-text">Bank account</span>
              </div>
              <div className="entry-acc__right">
                <span>
                  <em>-- Bank account details not provided yet --</em>
                </span>
              </div>
            </div>
          )}

          {settlementAccounts[activeCurrency]?.map(account => (
            <div className={`entry entry-acc ${account?.status}`} key={account?.bank?.name}>
              {activeCurrency === 'GHS' ? (
                <div className="entry-acc__left">
                  <span className={account?.status === 'active' ? 'gy-text' : 'bk-text'}>
                    {account?.account_details?.mobile_number || account?.account_details?.account_number}
                  </span>
                  <span className={account?.status === 'active' ? 'gy-text' : 'bk-text'}>
                    {account?.account_details?.mobile_money_operator_name || account?.bank?.name}
                  </span>
                </div>
              ) : (
                <div className="entry-acc__left">
                  <span className={account?.status === 'active' ? 'gy-text' : 'bk-text'}>{account?.account_details?.account_number}</span>
                  <span className={account?.status === 'active' ? 'gy-text' : 'bk-text'}>{account?.bank?.name}</span>
                  <span className={account?.status === 'active' ? 'gy-text' : 'bk-text'}>{account?.account_details?.account_name}</span>
                </div>
              )}

              <div className="entry-acc__right">
                {kycStatus === 'verified' && (
                  <>
                    {account?.status === 'active' && (
                      <>
                        <span className="gn-text">{capitalizeRemovedash(account?.status)}</span>
                        <img alt="" src={CheckedIcon} />
                      </>
                    )}
                    {account?.status === 'rejected' && (
                      <>
                        <span className="rd-text">{capitalizeRemovedash(account?.status)}</span>
                        <img alt="" src={RejectedIcon} />
                      </>
                    )}
                    {account?.status === 'under_review' && isAllowed(userAccess, ['merchant_settlement_account_status.update']) && (
                      <button
                        className="entry-doc__btn"
                        type="button"
                        onClick={() => {
                          setModal({ modalType: 'approve-account', modalInfo: { settlementAcctId: account.id } });
                        }}
                      >
                        Approve
                      </button>
                    )}
                    {account?.status !== 'rejected' && isAllowed(userAccess, ['merchant_settlement_account_status.update']) && (
                      <>
                        <span className="divider-sm" />
                        <button
                          className="entry-doc__btn"
                          type="button"
                          onClick={() => {
                            setModal({ modalType: 'reject-account', modalInfo: { settlementAcctId: account.id } });
                          }}
                        >
                          Reject
                        </button>
                      </>
                    )}
                  </>
                )}

                {kycStatus !== 'verified' && <em>-- Under review --</em>}
              </div>
            </div>
          ))}

          {validation && validation?.bvn && (
            <div className="entry entry-acc">
              <div className="entry-acc__left">
                <span className="bk-text">BVN</span>
                <span className="bk-text spacing">{maskBetweenSetRange(validation?.bvn?.number, 0, 5)}</span>
                <span className="bk-text spacing">
                  {(validation?.bvn?.is_valid && validation?.bvn?.name?.value) || '-- --'}
                  <Tooltip
                    image={!validationError() ? Check : Info}
                    message={!validationError() ? <p> BVN Verified</p> : renderValidationMessage(validation?.bvn)}
                  />
                </span>
              </div>

              <div className="entry-acc__right">
                {bvn?.status === 'submitted' && (
                  <>
                    <div className="button-div" type="button">
                      <button
                        className="entry-doc__btn"
                        type="button"
                        onClick={() => {
                          setModal({ modalType: 'accept-bvn' });
                        }}
                      >
                        Accept
                      </button>
                    </div>
                    <span className="divider-sm" />
                    <div className="button-div" type="button">
                      <button
                        className="entry-doc__btn"
                        type="button"
                        disabled={kycStatus?.toLowerCase() === 'rejected'}
                        onClick={() => {
                          setModal({ modalType: 'reject-bvn' });
                        }}
                      >
                        Decline
                      </button>
                    </div>
                    <span className="divider-sm" />
                  </>
                )}

                {bvn?.status === 'declined' && (
                  <>
                    <span className="bvn-declined" type="button">
                      <em>Declined</em>
                    </span>
                    <span className="divider-sm" />
                  </>
                )}

                {bvn?.status === 'approved' && (
                  <>
                    <span className="bvn-declined" type="button">
                      <em>Approved</em>
                    </span>
                    <span className="divider-sm" />
                  </>
                )}

                <button
                  className="entry-doc__btn"
                  type="button"
                  disabled={kycStatus?.toLowerCase() === 'rejected'}
                  onClick={() => {
                    setModal({ modalType: 'view-bvn', modalInfo: { bvnDetails: validation?.bvn } });
                  }}
                >
                  View Details
                </button>
              </div>
            </div>
          )}

          {!validation && bvn && (
            <div className="entry entry-acc">
              <div className="entry-acc__left">
                <span className="bk-text">BVN</span>
                <span className="bk-text spacing">{bvn?.number}</span>
              </div>

              <div className="entry-acc__right">
                {bvn?.status === 'submitted' && (
                  <>
                    <div className="button-div" type="button">
                      <button
                        className="entry-doc__btn"
                        type="button"
                        onClick={() => {
                          setModal({ modalType: 'accept-bvn' });
                        }}
                      >
                        Accept
                      </button>
                    </div>
                    <span className="divider-sm" />
                    <div className="button-div" type="button">
                      <button
                        className="entry-doc__btn"
                        type="button"
                        disabled={kycStatus?.toLowerCase() === 'rejected'}
                        onClick={() => {
                          setModal({ modalType: 'reject-bvn' });
                        }}
                      >
                        Decline
                      </button>
                    </div>
                  </>
                )}

                {bvn?.status === 'declined' && (
                  <span className="bvn-declined" type="button">
                    <em>Declined</em>
                  </span>
                )}

                {bvn?.status === 'approved' && (
                  <span className="bvn-declined" type="button">
                    <em>Approved</em>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyState message="No settlement account information available" />
      )}
    </>
  );
}