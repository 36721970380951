import React from 'react';
import { capitalize, capitalizeRemovedash, formatAmount, getDayandMonth, getTime, switchStatus } from '+utils';
import APIRequest from '+services/api-services';
import Icon from '+shared/Icons';
import './index.scss';

export const queryKeys = {
  VA_OVERVIEW: 'VA_OVERVIEW',
  FIXED_VA: 'FIXED_VA',
  FIXED_VA_ACC_NUMBERS: 'FIXED_VA_ACC_NUMBERS',
  UPGRADE_REQUESTS: 'UPGRADE_REQUESTS',
  ACCOUNT_HOLDERS: 'ACCOUNT_HOLDERS',
  TRANSACTIONS: 'TRANSACTIONS'
} as const;

type TSharedBankAccountDetails = {
  [key in
    | 'account_name'
    | 'account_number'
    | 'account_reference'
    | 'account_status'
    | 'unique_id'
    | 'bank_name'
    | 'bank_code'
    | 'created_at'
    | 'total_transacted_amount']: string;
} & { merchant: { id: number; name: string } } & { customer: { [key in 'name' | 'email']: string } } & { status_reason?: string };

type TForeignBankAccountDetails = TSharedBankAccountDetails & {
  [key in 'iban' | 'account_type' | 'email']: string;
} & {
  transaction_limit: {
    [key in 'individual' | 'corporate']?: Record<'tier_one' | 'tier_two', { daily: string; single: string; monthly: string }>;
  };
} & ({ tier: 1 | 2; account_holder: { reference: string } } & (
    | {
        currency: 'USD';
        payment_schemes: {
          swift: {
            [key in 'account_holder_name' | 'account_number' | 'memo' | 'bank_name' | 'swift_code']: string;
          } & {
            account_holder_address: {
              [key in 'city' | 'postal_code' | 'country_code' | 'address_line1']: string;
            } & {
              bank_address: {
                [key in 'city' | 'state' | 'postal_code' | 'country_code' | 'address_line1' | 'address_line2']: string;
              };
            };
          };
          ach: {
            [key in 'account_holder_name' | 'account_number' | 'memo' | 'bank_name' | 'routing_code']: string;
          } & {
            account_holder_address: {
              [key in 'city' | 'postal_code' | 'country_code' | 'address_line1']: string;
            } & {
              bank_address: {
                [key in 'city' | 'state' | 'postal_code' | 'country_code' | 'address_line1' | 'address_line2']: string;
              };
            };
          };
          fedwire: {
            [key in 'account_holder_name' | 'account_number' | 'memo' | 'bank_name' | 'routing_code']: string;
          } & {
            account_holder_address: {
              [key in 'city' | 'postal_code' | 'country_code' | 'address_line1']: string;
            } & {
              bank_address: {
                [key in 'city' | 'state' | 'postal_code' | 'country_code' | 'address_line1' | 'address_line2']: string;
              };
            };
          };
        };
      }
    | { currency: 'GBP' | 'EUR' }
  ));

type TLocalBankAccountDetails = TSharedBankAccountDetails & {
  currency: 'NGN';
};

export type TBankAccountDetails = TForeignBankAccountDetails | TLocalBankAccountDetails;

export type TUpgradeRequestDetails = {
  [key in 'reference' | 'merchant' | 'status' | 'requested_date' | 'date_upgraded' | 'upgrade_use_case' | 'decline_reason']: string;
} & { [key in 'current_tier' | 'requested_tier']: number } & {
  upgrade_use_case_document: { url: string };
  virtual_bank_account: { [key in 'reference' | 'account_name' | 'account_number' | 'bank']: string };
} & {
  account_holder: { [key in 'name' | 'reference' | 'status' | 'type' | 'email' | 'phone' | 'date_of_birth']: string } & {
    address: { [key in 'zip' | 'city' | 'country' | 'state' | 'address']: string };
  };
};

type queryKeyType = keyof typeof queryKeys;

type TAccountNumber = {
  [key in 'status' | 'account_number' | 'bank_name' | 'account_name' | 'account_reference' | 'created_at']: string;
} & { merchant: { name: string } };

type TUpgradeRequest = {
  [key in 'upgrade_from' | 'upgrade_to' | 'status' | 'account_reference' | 'created_at']: string;
} & { [key in 'account_holder' | 'virtual_bank_account']: Record<string, string> };

type TAccountHolder = {
  [key in 'status' | 'first_name' | 'last_name' | 'email' | 'account_type' | 'nationality' | 'created_at' | 'phone_number']: string;
} & { merchant: { name: string } };

type TTransactions = {
  [key in 'status' | 'reference' | 'completed_at' | 'currency']: string;
} & { payment: { account: { name: string; id: number }; first_name: string; last_name: string }; amount: number };

const api = new APIRequest();

type VirtualAccountsType = Array<{
  key: string;
  label: string;
  description?: string;
  query?: (...args: any[]) => Promise<void>;
  queryKey?: queryKeyType;
  subTabs?: Array<{
    key: string;
    label: string;
    query: <T>(...args: any[]) => Promise<T>;
    queryKey: queryKeyType;
  }>;
}>;

export const switchTabData = (activeTab: string, state?: any, paging?: { total_items?: number }) => {
  function getTableTitle(title: string, def = true) {
    const filtered: boolean = Object.keys(state?.sortingParams || {}).length > 0;
    return def
      ? `${filtered ? 'Filtered' : 'All'} ${title} (${formatAmount(paging?.total_items || 0, false)})`
      : `${formatAmount(paging?.total_items || 0, false)} ${title} ${filtered ? '(Filtered)' : ''}`;
  }

  const countryCode = {
    NG: 'Nigeria'
  };

  switch (activeTab) {
    case 'account_numbers':
      return {
        type: 'fixed-virtual-accounts',
        filterTitle: getTableTitle('Account Numbers', false),
        className: '--vba-fixed-virtual-accounts',
        rowURL: '/dashboard/virtual-accounts',
        rowKey: 'korapay_reference',
        emptyStateHeading: 'No fixed virtual account numbers found',
        emptyStateMessage: 'It seems there are no fixed account numbers yet.',
        annotations: 'account number(s)',
        fields: (iter: TAccountNumber) => ({
          style: { background: iter.status === 'suspended' ? '#FFFAF0' : '' },
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalize(iter?.status || '')}</span>
              </>
            ),
            'account_number/bank': (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{iter?.account_number}</span>{' '}
                {iter?.bank_name && <span style={{ color: '#A9AFBC' }}>&nbsp;{capitalizeRemovedash(iter?.bank_name)}</span>}
              </>
            ),
            account_name: iter.account_name,
            merchant: iter.merchant?.name,
            account_reference: <span style={{ color: '#94A7B7' }}>{iter?.account_reference}</span>,
            date_created: (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{getDayandMonth(iter.created_at)}</span>,
                <span style={{ color: '#A9AFBC' }}>&nbsp;{getTime(iter.created_at)}</span>
              </>
            )
          }
        })
      };
    case 'upgrade_requests':
      return {
        type: 'upgrade-requests',
        filterTitle: getTableTitle('Upgrade Requests'),
        className: '--vba-fixed-virtual-accounts',
        rowKey: 'payment_reference',
        emptyStateHeading: 'No upgrade requests found',
        emptyStateMessage: 'It seems there are no upgrade requests yet.',
        annotations: 'upgrade request(s)',
        fields: (iter: TUpgradeRequest) => ({
          data: {
            request_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status === 'approved' ? 'success' : iter.status)}`} />
                <span>{capitalize(iter?.status || '')}</span>
              </>
            ),
            'account_number/bank': (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{iter?.virtual_bank_account?.account_number}</span>{' '}
                {iter?.virtual_bank_account?.bank_name && (
                  <span style={{ color: '#A9AFBC' }}>&nbsp;{capitalizeRemovedash(iter?.virtual_bank_account?.bank_name)}</span>
                )}
              </>
            ),
            account_holder: `${iter?.account_holder?.first_name} ${iter?.account_holder?.last_name}`,
            upgrade_from: `Tier ${iter?.upgrade_from}`,
            upgrade_to: `Tier ${iter?.upgrade_to}`,
            date_requested: (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{getDayandMonth(iter.created_at)}</span>,
                <span style={{ color: '#A9AFBC' }}>&nbsp;{getTime(iter.created_at)}</span>
              </>
            )
          }
        })
      };

    case 'account_holders':
      return {
        type: 'account-holders',
        filterTitle: getTableTitle('Account Holders', false),
        className: '--vba-acc-holders',
        rowURL: '/dashboard/virtual-accounts/account-holders',
        rowKey: 'reference',
        emptyStateHeading: 'No virtual account numbers found',
        emptyStateMessage: 'It seems there are no account numbers yet.',
        annotations: 'account holder(s)',
        fields: (iter: TAccountHolder) => ({
          style: { background: iter.status === 'pending' ? '#FFFAF0' : '' },
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>
                  {capitalize(iter?.status || '')}
                  {iter?.status === 'pending' && <Icon name="warningTriangle" width={16} height={16} style={{ marginLeft: 5 }} />}
                </span>
              </>
            ),
            account_holder: (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>
                  {iter?.first_name} {iter?.last_name}
                </span>{' '}
                <span style={{ color: '#A9AFBC', marginLeft: 5 }}>{iter?.email}</span>
              </>
            ),
            type: capitalize(iter.account_type),
            country: countryCode[iter.nationality as keyof typeof countryCode] || iter.nationality,
            merchant: iter.merchant?.name,
            date_created: (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{getDayandMonth(iter.created_at)}</span>,
                <span style={{ color: '#A9AFBC' }}>&nbsp;{getTime(iter.created_at)}</span>
              </>
            )
          }
        })
      };
    case 'transactions':
      return {
        type: 'vba-transactions',
        filterTitle: getTableTitle('Transactions'),
        filterQueryIDPlaceholder: 'ID Transactions',
        className: '--vba-trx',
        rowURL: '/dashboard/pay-ins',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions yet.',
        annotations: 'transaction(s)',
        fields: (iter: TTransactions) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>
                  {capitalize(iter?.status || '')}{' '}
                  {iter?.status?.includes('flagged') && <Icon name="warningTriangle" width={16} height={16} style={{ marginLeft: 5 }} />}
                </span>
              </>
            ),
            transaction_id: <span style={{ fontWeight: 600, color: '#2376F3' }}>{iter?.reference}</span>,
            merchant: iter.payment?.account?.name,
            date_and_time: (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{getDayandMonth(iter.completed_at)}</span>,
                <span style={{ color: '#A9AFBC' }}>&nbsp;{getTime(iter.completed_at)}</span>
              </>
            ),
            amount: (
              <span style={{ fontWeight: 600, color: '#414F5F' }}>
                {formatAmount(iter?.amount)} {iter.currency}
              </span>
            )
          }
        })
      };
    default:
      return {};
  }
};

export const VirtualAccountsTabsList: VirtualAccountsType = [
  {
    label: 'Overview',
    key: 'overview',
    description:
      'Kora allows merchants to create and manage virtual bank accounts. Here’s an overview of the virtual account service for different merchants on Kora.',
    query: api.getVirtualBankAccountOverview,
    queryKey: queryKeys.VA_OVERVIEW
  },

  {
    label: 'Account Holders',
    key: 'account_holders',
    description: 'All account holders (customers that were issued fixed virtual accounts) appear here.',
    query: api.getAllAccountHolders,
    queryKey: queryKeys.ACCOUNT_HOLDERS
  },
  {
    label: 'Fixed Virtual Accounts',
    key: 'fixed_virtual_accounts',
    description: 'These are the fixed virtual accounts that merchants have issued to their customers.',
    subTabs: [
      {
        label: 'Account Numbers',
        key: 'account_numbers',
        query: api.getFixedVirtualBankAccounts,
        queryKey: queryKeys.FIXED_VA_ACC_NUMBERS
      },
      {
        label: 'Upgrade Requests',
        key: 'upgrade_requests',
        query: api.getFixedVirtualBankAccountUpgradeRequests,
        queryKey: queryKeys.UPGRADE_REQUESTS
      }
    ]
  },
  {
    label: 'Transactions',
    key: 'transactions',
    description: 'These are all the transactions performed on the fixed virtual accounts.',
    query: api.getAllVirtualBankAccountTransactions,
    queryKey: queryKeys.TRANSACTIONS
  }
];

export type TSummaryInfo = {
  [key in 'label' | 'description']: string;
} & {
  value: number;
  tooltip?: string;
  link: {
    linkText: string;
    linkAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  };
};

export type TSummaryData = {
  [key in 'fixed_virtual_bank_accounts_count' | 'account_holders_count' | 'fixed_virtual_bank_account_transactions_count']?: number;
};

export const summaryInfo = (
  data: TSummaryData | undefined,
  state: Partial<Record<string, string | null>>,
  setTab: React.Dispatch<string>
): TSummaryInfo[] =>
  [
    {
      label: 'Fixed Virtual Account Holders',
      value: data?.account_holders_count as number,
      description: 'Customers to whom accounts are assigned',
      link: {
        linkText: 'See Account Holders',
        linkAction: () => setTab('account_holders')
      }
    },
    {
      label: `Fixed Virtual Accounts (${state.currency || 'NGN'})`,
      value: data?.fixed_virtual_bank_accounts_count as number,
      description: 'Total accounts created by merchants',
      link: {
        linkText: 'See Accounts',
        linkAction: () => setTab('fixed_virtual_accounts')
      }
    },

    {
      label: `Transaction Count (${state.currency || 'NGN'})`,
      value: data?.fixed_virtual_bank_account_transactions_count as number,
      description: 'Count of transactions on fixed virtual accounts',
      link: {
        linkText: 'See Transactions',
        linkAction: () => setTab('transactions')
      }
    }
  ] as TSummaryInfo[];

export const accountStatusBanner = ({
  status,
  reason,
  onClick,
  type
}: {
  status: 'suspended' | 'deleted' | 'deactivated' | string;
  onClick: () => void;
  reason?: string;
  type: string;
}) => {
  const reasonObj = {
    suspended: {},
    deleted: {
      text: 'deleted and is no longer in use!'
    },
    rejected: {},
    deactivated: {}
  }[status];

  return reasonObj ? (
    <section className={`vba-banner ${status}`}>
      <span>
        This account {type} has been {reasonObj.text || `${status}!`}
      </span>
      {reason && (
        <button onClick={onClick} type="button">
          Learn Why
        </button>
      )}
    </section>
  ) : null;
};
