import React from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import ToolTip from '+shared/Tooltip';
import { TTransactionDetails, useTransactionStatusStateType } from '+types';
import { capitalize, isAllowed, specialisedDate, switchCard, switchStatus, truncateString } from '+utils';

// eslint-disable-next-line import/prefer-default-export
export const virtualCardDetails = ({
  data,
  updateTransactionStatusModalState,
  state,
  userAccess
}: {
  data: TTransactionDetails;
  transactionType: string;
  updateTransactionStatusModalState: (a: boolean, b: any) => void;
  state: useTransactionStatusStateType;
  userAccess: { [key: string]: boolean };
}) => {
  const {
    card,

    status,

    processor_reference: processorReference
  } = data || {};

  return {
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 5px 0' }}
        />
        {capitalize(status === 'requires_auth' ? 'Pending (Requires Authorization)' : status || 'Not Available')}
        {status === 'processing' &&
          ['mobile_money', 'bank_account'].includes(data?.payment_destination_type || data?.payment_source_type) &&
          !state.clickedTransactionIds?.includes(processorReference) &&
          isAllowed(userAccess, ['payouts_status.update']) && (
            <button
              aria-label="settings"
              onClick={e => {
                e.stopPropagation();
                updateTransactionStatusModalState(true, data);
              }}
              type="button"
              className="status-update-icon"
            >
              <Icon name="settings" />
            </button>
          )}
        {state.clickedTransactionIds?.includes(processorReference) && status === 'processing' && (
          <ToolTip
            type=""
            message={
              <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
            }
            centered
          >
            <span className="rotate">
              <Icon name="loading" />
            </span>
          </ToolTip>
        )}
      </>
    ),
    breakdown: {
      "cardholder's_name": `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      PAN: card?.last_four ? (
        <>
          <img
            src={switchCard[card?.brand as keyof typeof switchCard]}
            className="card-logo"
            alt="card-logo"
            style={{ width: '1.5rem', marginRight: '8px' }}
          />
          <Copyable text={card?.last_four} spanClassName="semibold" textModifier={text => `**** **** **** ${text}`} />
        </>
      ) : (
        'N/A'
      ),
      Card_ID: <Copyable text={card?.reference} textModifier={text => truncateString(text, 15)} />,
      expiry_date: card?.expiration_date ? specialisedDate(card?.expiration_date, 'MM/YY') : 'N/A'
    }
  };
};
