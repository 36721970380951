import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Tooltip from '+containers/Dashboard/Shared/Tooltip';
import Copyable from '+shared/Copyable';
import { CurrencyType, DisputeType, ICardTransactionDetails, RefundType, SummaryItemType } from '+types';
import { capitalizeRemovedash, formatAmount, getDate, getTime, specialisedDate, switchCard, switchCurrency, truncateString } from '+utils';

import { refundStatus } from '../Chargebacks/constants';
import UpdateRefundStatusModal from '../Chargebacks/UpdateRefundStatusModal';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

export const statusPropsMap = {
  success: { name: 'Successful', color: '#24B314', icon: '✓' },
  pending: { name: 'Pending', color: 'rgba(16,38,73,.4)' },
  processing: { name: 'Processing', color: 'rgba(16,38,73,.4)' },
  failed: { name: 'Failed', color: '#F32345', icon: '✗' },
  partially_paid: { name: 'Partially Paid', color: '#24B314' },
  fully_paid: { name: 'Paid', color: '#24B314' },
  accepted: { name: 'Accepted', color: '#24b314' },
  partially_accepted: { name: 'Accepted (Partially)', color: '#48ceb0' },
  invalid: { name: 'Invalid', color: '#94a7b7' },
  declined: { name: 'Declined', color: '#f32345' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#ffb447' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#fa9500' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24b314' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24b314' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#f32345' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94a7b7' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#f32345' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#f32345' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#f32345' }
};

type Status = keyof typeof statusPropsMap;

export function generateSummaryFrom(data: ICardTransactionDetails): Array<SummaryItemType> {
  const cardCategory = data?.card?.reserved ? 'reservedCards' : 'issuedCards';

  return [
    { label: 'Net Amount', value: `${formatAmount(data?.amount)} ${data?.currency}` },
    { label: 'Fee', value: `${formatAmount(data?.fee)} ${data?.currency}` },
    { label: 'Date / Time', value: `${getDate(data?.date as string)}, ${getTime(data?.date as string)}` },
    {
      label: 'Cardholder',
      value: `${data?.card?.card_holder?.first_name ?? 'Not Available'} ${data?.card?.card_holder?.last_name ?? 'Not Available'}`
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <div>
            <Copyable text={data?.reference?.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
          </div>
          <Link
            to={`/dashboard/card-issuance/${cardCategory}/${data?.card?.reference}`}
            className="btn"
            data-redirectto="original-transaction"
          >
            See Virtual Card
            <i className="os-icon os-icon-arrow-up-right icon-after ml-2" />
          </Link>
        </>
      )
    }
  ];
}

export function generateCardholderInfoFrom(data: ICardTransactionDetails['card']): Array<SummaryItemType> {
  return [
    {
      label: "Carholder's name",
      value: `${data?.card_holder?.first_name ?? 'Not Available'} ${data?.card_holder?.last_name ?? 'Not Available'}`
    },
    {
      label: 'PAN',
      value: data?.last_four ? (
        <>
          <img alt={data?.brand} src={switchCard[data?.brand]} className="card-logo" />
          <Copyable text={`**** **** **** ${data?.last_four}`} spanClassName="copyable-bold" />
        </>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Card ID',
      value: data?.reference ? (
        <Copyable text={data?.reference} textModifier={text => truncateString(text, 15)} spanClassName="copyable-blue" />
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Expiry Date',
      value: data?.expiration_date ? specialisedDate(data?.expiration_date, 'MM/YY') : 'N/A'
    }
  ];
}

export function generateMoreDetailFrom(data: ICardTransactionDetails): Array<SummaryItemType> {
  return [
    { label: 'Currency Charged', value: data?.currency ? switchCurrency[data?.currency] : 'Not Available' },
    { label: 'Korapay ID', value: 'Not Available' },
    { label: 'Channel', value: 'Not Available' },
    { label: 'Transaction Type', value: data?.type ? <strong>{capitalizeRemovedash(data?.type)}</strong> : 'Not Available' },
    {
      label: 'Business Name',
      value: data?.card_acceptor_name ? data?.card_acceptor_name : 'Not Available',
      hidden: data?.type !== 'card_transaction'
    },
    { label: 'Processor', value: data?.card?.provider ?? 'Not Available' },
    { label: "Processor's Transaction ID", value: 'Not Available' },
    { label: 'Description', value: data?.description, hidden: data?.type === 'card_creation' },
    {
      label: (
        <>
          Cross-currency Status
          <Tooltip
            type="net_amount"
            image={InfoIcon}
            message="This defines whether a transaction is performed in the issued virtual card currency or not"
          />
        </>
      ),
      value: data?.cross_currency ? 'Yes' : 'No'
    }
  ];
}

export function generateBusinessInfoFrom(): Array<SummaryItemType> {
  return [
    { label: 'Business Name', value: 'Not Available' },
    { label: 'Business Country', value: 'Not Available' },
    { label: 'Business Email', value: 'Not Available' }
  ];
}

export const summaryGenerators = {
  refunds: (item: any, currency: CurrencyType) => [
    { label: 'Refund ID', value: <Copyable text={item.reference} spanClassName="copyable-blue" /> },
    { label: 'Refund Amount', value: `${item.amount ?? 'Not Available'} ${currency ?? 'Not Available'}` },
    { label: 'Refund Destination', value: 'Not Available' },
    {
      label: 'Refund Status',
      value: (
        <span style={{ color: statusPropsMap[item.status as Status].color ?? '' }}>
          {statusPropsMap[item.status as Status].name ?? 'Not Available'}
        </span>
      )
    },
    {
      label: 'Date Created',
      value: item.created_at ? `${getDate(item.created_at)} | ${getTime(item.created_at)}` : '-- | --',
      hidden: !item.created_at
    },
    {
      label: 'Date Completed',
      value: item.date_completed ? `${getDate(item.date_completed)} | ${getTime(item.date_completed)}` : '-- | --'
    },
    { label: 'Reason for Refund', value: (item?.reversal_reason || item.description) ?? 'Not Available' }
  ],
  chargebacks: (item: any, currency: CurrencyType) => [
    { label: 'Chargeback ID', value: <Copyable text={item?.reference} spanClassName="copyable-blue" /> },
    {
      label: 'Chargeback Amount',
      value: (
        <strong>
          {item?.amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Escalated Amount',
      value: (
        <strong>
          {item?.amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Accepted Amount',
      value: (
        <strong>
          {item?.accepted_amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Chargeback Status',
      value: (
        <span style={{ color: statusPropsMap[item?.status as Status]?.color ?? '' }}>
          {statusPropsMap[item?.status as Status]?.name ?? 'Not Available'}
        </span>
      )
    },
    {
      label: 'Date Escalated',
      value: item?.escalation_date ? `${getDate(item?.escalation_date)} | ${getTime(item?.escalation_date)}` : '-- | --'
    },
    {
      label: 'Date Completed',
      value: item?.actual_resolution_date
        ? `${getDate(item?.actual_resolution_date)} | ${getTime(item?.actual_resolution_date)}`
        : '-- | --'
    }
  ]
};

export const childrenGenerators: Partial<Record<DisputeType, (arg: unknown) => React.ReactNode>> = {};

export const RefundChildren = ({
  refund,
  refetch,
  acceptedAmount,
  currency,
  chargebackAmount
}: {
  refund: RefundType;
  refetch: () => void;
  acceptedAmount: string | number;
  chargebackAmount: string;
  currency: CurrencyType;
}) => {
  const [openRefundUpdateModal, setOpenRefundUpdateModal] = useState(false);
  const disableStatusUpdate = refund.status !== refundStatus.PENDING;

  return (
    <>
      <button onClick={() => setOpenRefundUpdateModal(true)} className="btn btn-primary" type="button" disabled={disableStatusUpdate}>
        Update Status
      </button>

      {openRefundUpdateModal ? (
        <UpdateRefundStatusModal
          refundId={refund?.reference as string}
          onClose={() => setOpenRefundUpdateModal(false)}
          currency={currency as CurrencyType}
          currentStatus={refund?.status ? refund?.status : undefined}
          acceptedAmount={acceptedAmount}
          chargebackAmount={chargebackAmount}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};
