import React, { useRef } from 'react';

import Modal from './Modal';

import KpyLoader from '+assets/img/dashboard/kpy-loader.svg';

export default function FileViewer({
  showView,
  setShowView,
  url,
  viewInModal = true,
  size = 'lg'
}: {
  showView: boolean;
  setShowView: (e: boolean) => void;
  url: string;
  viewInModal?: boolean;
  size?: 'sm' | 'lg' | 'md';
}) {
  const loaderRef = useRef<HTMLImageElement>(null);
  const fileView = (
    <section className="iframe-container">
      <img src={KpyLoader} alt="loader" ref={loaderRef} width="40px" />
      <img
        alt="file-viewer"
        src={url}
        title="file-viewer"
        sandbox=""
        width="100%"
        height="100%"
        style={{ objectFit: 'contain' }}
        onLoad={() => {
          if (loaderRef.current) loaderRef.current.style.display = 'none';
        }}
      />
    </section>
  );

  return (
    showView &&
    (viewInModal ? (
      <Modal
        close={() => setShowView(false)}
        size={size}
        heading="File Viewer"
        content={fileView}
        showButtons={false}
        secondButtonText=""
        description=""
      />
    ) : (
      <section data-testid="no-modal-doc-view" className="no-modal-doc-view">
        <button data-testid="close-no-modal-doc-view" type="button" onClick={() => setShowView(false)}>
          <span className="os-icon os-icon-close" />
        </button>
        {fileView}
      </section>
    ))
  );
}
