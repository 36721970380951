import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import pendingCircles from '+assets/img/dashboard/pending-circles.gif';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import Modal from '+shared/Modal';
import { capitalize, capitalizeAllCharacters, capitalizeRemovedash, formatAmount, getDate, getTime } from '+utils';

import ReversalStates from '../ReversalStates';

import Checkmark from '+assets/img/dashboard/check-green.svg';
import completed from '+assets/img/dashboard/completed-tick.svg';
import Failed from '+assets/img/dashboard/icon_failed.svg';

import '../index.scss';

const api = new APIRequest();

const Reversals = ({ data, currency, isLoading, isError, refetch }) => {
  const { feedbackInit } = useFeedbackHandler();
  const queryCache = useQueryClient();
  const banks = queryCache.getQueryData('BANKS');
  const [attemptsInfo, setAttemptInfo] = useState({});
  const [attemptReference, setAttemptReference] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeReference, setActiveReference] = useState(null);

  const handleClick = item => {
    setOpen(true);
    setItem([item]);
    setActiveReference(item.reference);
  };

  useLayoutEffect(() => {
    if (data?.length > 0) {
      setItem([data[0]]);
      setActiveReference(data[0].reference);
    }
  }, [data]);

  const [bankDetails, setBankDetails] = useState({
    account: '',
    bank: ''
  });

  const [retryInfo, setRetryInfo] = useState({
    reference: '',
    account_number: '',
    bank_code: ''
  });

  const [state, setState] = useState({
    modalVisible: false,
    type: ''
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [reversalResponse, setReversalResponse] = useState({
    heading: '',
    description: ''
  });

  const items = [];
  const [item, setItem] = useState(items);
  const [open, setOpen] = useState(false);
  const count = data?.length;

  const getBankCode = bankname => {
    const findBank = banks.find(bank => bank.name === bankname);
    return findBank?.code;
  };

  const switchRefundStatus = status => {
    switch (true) {
      case ['success', 'manual'].includes(status):
        return { name: `${capitalize(status)}`, color: '#24B314', icon: '✓' };
      case ['processing', 'pending_approval', 'pending'].includes(status):
        return {
          name: `${status === 'pending_approval' ? 'Pending Approval' : 'Processing...'}`,
          color: 'rgba(16,38,73,.4)',
          icon: <img src={pendingCircles} alt="" id="pending-circles" />
        };
      case status === 'failed':
        return { name: 'Failed', color: '#F32345', icon: '✗' };
      default:
        return {};
    }
  };

  const switchReversalReason = {
    underpayment: 'Underpayment',
    overpayment: 'Overpayment',
    chargeback: 'Chargeback',
    refund: 'Refund'
  };

  const modalContent = attempt => (
    <ul className="modal-list" style={{ padding: 0 }}>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Status</h6>
          <div className="attempt-modal-wrap">
            <p
              style={{
                height: '6px',
                width: '6px',
                borderRadius: '50%',
                backgroundColor: switchRefundStatus(attempt.status)?.color
              }}
            />
            <p className="attempt-modal-text">{capitalize(attempt.status)}</p>
          </div>
        </div>
        <div className="attempt-modal">
          <h6>Attempted at</h6>
          <p>{`${getDate(attempt.created_at)}, ${getTime(attempt.created_at)}`}</p>
        </div>
      </li>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Bank</h6>
          <p>{attempt.bank_name || 'Not Available'}</p>
        </div>
        <div className="attempt-modal">
          <h6>Account number</h6>
          <p>{attempt.account_number || 'Not Available'}</p>
        </div>
      </li>
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Account Name</h6>
          <p>{attempt.account_name || 'Not Available'}</p>
        </div>
      </li>
      {attempt.status === 'failed' && (
        <li>
          <div className="attempt-modal modal-left-content">
            <h6>Reason for failure</h6>
            <p>{attempt.message || 'Not Available'}</p>
          </div>
        </li>
      )}
      <li>
        <div className="attempt-modal modal-left-content">
          <h6>Reversal for</h6>
          <p className="attempt-modal-blue">{capitalizeAllCharacters(attempt?.reference) || 'Not Available'}</p>
        </div>
      </li>
      {attempt.trace_id && (
        <li>
          <div className="attempt-modal modal-left-content">
            <h6>Session ID</h6>
            <p className="attempt-modal-blue">{capitalizeAllCharacters(attempt.trace_id)}</p>
          </div>
        </li>
      )}
    </ul>
  );

  const retryReversalMutation = useMutation(reversaldata => api.retryReversals(reversaldata), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setState({ modalVisible: true, type: 'reversalSuccess' });
      setReversalResponse({
        heading: 'Reversal initiated',
        description: 'Transaction reversal has been initiated'
      });
      feedbackInit({
        message: 'Transaction Reversal has been initiated',
        type: 'success'
      });
    }
  });

  const handleDisableReversalButton = retryDetail => {
    if (
      !retryDetail.account_number ||
      !retryDetail.bank_code ||
      !retryDetail.reference ||
      bankDetails.account.length !== 10 ||
      resolveBankInfomationlMutation.isLoading
    )
      return true;
    return false;
  };

  const handleRetry = async retryData => {
    try {
      await retryReversalMutation.mutateAsync(retryData);
      await refetch();
    } catch (error) {
      setErrorMessage('There has been an error initialising this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error initialising this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const approveReversalMutation = useMutation(approvalData => api.resolveReversal(approvalData), {
    onError: error => {
      throw error;
    }
  });

  const handleApproval = async approvalData => {
    try {
      await approveReversalMutation.mutateAsync(approvalData);
      setReversalResponse({
        heading: 'Reversal Approved',
        description: 'Transaction reversal has been approved'
      });
      setState({ modalVisible: true, type: 'reversalSuccess' });
      await refetch();
    } catch (error) {
      setErrorMessage('There has been an error approving this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error approving this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const handleDecline = async declineData => {
    try {
      await approveReversalMutation.mutateAsync(declineData);
      setState({ modalVisible: true, type: 'declined' });
      await refetch();
    } catch (error) {
      setErrorMessage('There has been an error declining this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error declining this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const manualUpdateMutation = useMutation(updateData => api.updateReversal(updateData), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setReversalResponse({
        heading: 'Reversal status updated',
        description: 'The status of the reversal for this transaction has been updated'
      });
      setState({ modalVisible: true, type: 'reversalSuccess' });
    }
  });

  const handleManualUpdate = async approvalData => {
    try {
      await manualUpdateMutation.mutateAsync(approvalData);
      await refetch();
    } catch (error) {
      setErrorMessage('There has been an error manually updating this reversal.');
      setState({ modalVisible: true, type: 'error' });
      feedbackInit({
        message: 'There has been an error manually updating this reversal.',
        type: 'danger'
      });
      throw error;
    }
  };

  const resolveBankInfomationlMutation = useMutation(approvalData => api.resolveBankInformation(approvalData), {
    onError: error => {
      setRetryInfo(prevState => {
        return {
          ...prevState,
          bank_code: '',
          account_number: ''
        };
      });
      throw error;
    },
    onSuccess: bank => {
      setRetryInfo(prevState => {
        return {
          ...prevState,
          bank_code: bank?.bank_code,
          account_number: bank?.account_number
        };
      });
    }
  });

  const getBankInformation = async bankDetailsInfo => {
    try {
      setLoading(true);
      await resolveBankInfomationlMutation.mutateAsync(bankDetailsInfo);
      setLoading(false);
    } catch (error) {
      feedbackInit({
        message: 'There has been an error resolving this account details.',
        type: 'danger',
        componentLevel: true
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bankDetails?.bank && bankDetails?.account?.length === 10) {
      getBankInformation(bankDetails);
    }
  }, [bankDetails]);

  const handleChange = e => {
    e.persist();
    setBankDetails(prev => {
      return { ...prev, [e.target.name]: e?.target?.value };
    });
  };

  const lastReversalAttempt = item[0]?.payment_reversal_payouts
    ? item[0]?.payment_reversal_payouts[item[0]?.payment_reversal_payouts.length - 1]
    : {};

  const switchReversalModal = type => {
    let content;
    switch (type) {
      case 'manual':
        content = {
          size: 'sm',
          heading: 'Update Reversal Status',
          secondButtonText: 'Update',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              Please confirm that this reversal has been resolved offline. This action cannot be undone.
            </span>
          ),
          secondButtonAction: () => handleManualUpdate({ reference: attemptReference }),
          secondButtonActionIsTerminal: false,
          completedHeading: 'Reversal status updated',
          completedDescription: 'The status of the reversal for this transaction has been updated'
        };
        break;
      case 'retryWithNewDetails':
        content = {
          size: 'md',
          heading: 'Reversal Payout',
          firstButtonText: 'Cancel',
          secondButtonText: 'Send',
          secondButtonAction: () => handleRetry(retryInfo),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: handleDisableReversalButton(retryInfo),
          content: (
            <>
              <p style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
                This reversal failed due to invalid account details, enter new account details below to proceed
              </p>
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <h6>Bank Account</h6>
                <select
                  name="bank"
                  aria-label="method"
                  className="form-control"
                  onChange={e => handleChange(e)}
                  value={bankDetails?.bank}
                  style={{ minWidth: '200px' }}
                >
                  <option value="">Select bank</option>
                  {banks &&
                    banks.map(paymentMethod => (
                      <option key={paymentMethod.code} value={paymentMethod.code}>
                        {paymentMethod.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <h6>Enter account number</h6>
                <input
                  name="account"
                  className="form-control"
                  maxLength={10}
                  value={bankDetails?.account}
                  onChange={e => handleChange(e)}
                  type="text"
                />
              </div>
              {resolveBankInfomationlMutation?.data ? (
                <p className="account-name">
                  {resolveBankInfomationlMutation?.data?.account_name}
                  <img height={14} width={14} src={Checkmark} alt="check" />
                </p>
              ) : null}
              {loading && (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              )}
            </>
          )
        };
        break;
      case 'approval':
        content = {
          size: 'md',
          heading: 'Approve Reversal',
          firstButtonText: 'No, Decline',
          firstButtonAction: () => {
            handleDecline({ reference: attemptReference, action: 'decline' });
          },
          secondButtonText: 'Yes, Approve',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              This payout for reversal needs to be approved. Do you want to approve it?
            </span>
          ),
          secondButtonActionIsTerminal: false,
          secondButtonAction: () =>
            handleApproval({
              reference: attemptReference,
              action: 'approve'
            }),
          content: (
            <ul className="approve-modal-content" style={{ padding: '1rem' }}>
              <li className="approve-modal-list">
                <p>Bank Account</p>
                <p>{lastReversalAttempt?.bank_name || 'Not available'}</p>
              </li>
              <li className="approve-modal-list">
                <p>Account Number</p>
                <p>{lastReversalAttempt?.account_number || 'not available'}</p>
              </li>
              <li className="approve-modal-list">
                <p>Account Name</p>
                <p>{lastReversalAttempt?.account_name || 'Not available'}</p>
              </li>
            </ul>
          )
        };
        break;
      case 'declined':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={Failed} alt="success" />
              <h5>Reversal Declined</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>Transaction reversal has been declined</p>
              <button type="button" onClick={() => setState({ modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'reversalSuccess':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={completed} alt="success" />
              <h5>{reversalResponse?.heading}</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>{reversalResponse?.description}</p>
              <button type="button" onClick={() => setState({ modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'error':
        content = {
          size: 'sm',
          description: (
            <div
              style={{
                marginTop: '0px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}
              className="refund-heading"
            >
              <img width={80} height={80} src={Failed} alt="success" />
              <h5>Error</h5>
              <p style={{ fontWeight: '400', textAlign: 'center', fontSize: '0.863rem' }}>{errorMessage}</p>
              <button type="button" onClick={() => setState({ modalVisible: false })}>
                Dismiss
              </button>
            </div>
          ),
          showButtons: false
        };
        break;
      case 'attemptsInfo':
        content = {
          size: 'md',
          heading: capitalizeAllCharacters(item[0]?.reference),
          content: modalContent(attemptsInfo),
          showButtons: false
        };
        break;
      case 'retry':
        content = {
          size: 'sm',
          showButtons: true,
          heading: 'Update Bank Information',
          firstButtonText: 'No',
          secondButtonText: 'Yes, I would',
          description: (
            <span style={{ fontStyle: 'normal', fontWeight: '400', fontSize: '0.863rem' }}>
              Would you like to retry this reversal with new bank information?
            </span>
          ),
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => setState({ modalVisible: true, type: 'retryWithNewDetails' }),
          firstButtonAction: () => handleRetry(retryInfo)
        };
        break;
      default:
        return <div />;
    }
    return {
      visible: state.modalVisible,
      close: () => {
        setState({ ...state, modalVisible: false });
      },
      completedHeading: 'Success',
      ...content
    };
  };

  return (
    <>
      <div>
        {isLoading && <ReversalStates reversalState="loading" reversalType="reversals" />}
        {isError && <ReversalStates reversalState="error" reversalType="reversals" refreshFn={() => refetch()} />}
        {data?.length > 0 && (
          <ul>
            <li id="payment-detail">
              <div className="reversal-count-container" style={{ width: '40%' }}>
                <p className="reversal-count">{`${count} ${count > 1 ? 'Reversals' : 'Reversal'} found`}</p>
                {data.map(item => {
                  if (item) {
                    items.push(item);
                  }
                  return (
                    <div
                      className={`--container ${activeReference === item.reference ? 'active-dispute' : ''}`}
                      key={item.reference}
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <p className="reversal-amount">
                        <label className="">Reversal of</label>
                        {`${formatAmount(item.amount || 0)} ${currency}`}
                      </p>
                      <span className={`tab-icon ${activeReference === item.reference && 'active'}`} />
                    </div>
                  );
                })}
              </div>
              {open && (
                <div className="customer-reversal-info" style={{ width: '60%' }}>
                  <Link className="reversal-pg-route" to={`/dashboard/payment-reversals/${item[0].reference}`}>
                    View Reversal Details
                  </Link>
                  <ul className="" style={{ borderBottom: 'none' }}>
                    <li>
                      <p>Reason for Reversal</p>
                      <p>{switchReversalReason[item[0].type]}</p>
                    </li>
                    <li>
                      <p>Reversal Destination</p>
                      <p>{item.destination === 'disbursement_wallet' ? `Balance` : 'Customer'}</p>
                    </li>
                    <li>
                      <p>Reversal Status</p>
                      <p
                        style={{
                          color: switchRefundStatus(item[0]?.status)?.color || ''
                        }}
                      >
                        {switchRefundStatus(item[0]?.status)?.name}
                      </p>
                    </li>
                    <li>
                      <p>Date Created</p>
                      <p>{item[0]?.created_at}</p>
                    </li>
                    {item[0]?.status === 'failed' && (
                      <li>
                        <p>Reason for Failure</p>
                        <p>{capitalizeRemovedash(item[0].payment_reversal_payout?.message || 'Not available')}</p>
                      </li>
                    )}
                    <li className="underline">
                      <p>Attempts </p>
                      <p style={{ color: switchRefundStatus(item[0]?.status)?.color || '' }}>{item[0]?.payment_reversal_payouts?.length}</p>
                    </li>
                    <br />
                    {item[0]?.payment_reversal_payouts?.map((attempts, index) => (
                      <li key={attempts.id}>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: '#a9afbc',
                            marginBottom: '1rem'
                          }}
                        >
                          <div style={{ display: 'flex', color: '#a9afbc', width: 'max-content' }}>Attempt {index + 1}</div>
                          <div className="dot" style={{ width: '0.375rem', height: '0.375rem', marginLeft: '0.5rem' }} />
                          <span className="date-span" style={{ minWidth: 'max-content', marginLeft: '8px' }}>{`${getDate(
                            attempts.created_at
                          )}, ${getTime(attempts.created_at)}`}</span>
                        </p>
                        <button
                          onClick={() => {
                            setAttemptInfo(attempts);
                            setState({
                              modalVisible: true,
                              type: 'attemptsInfo'
                            });
                          }}
                          className="attempt-id"
                          type="button"
                          style={{ marginTop: '-1rem' }}
                        >
                          <Copyable showOnHover text={capitalizeAllCharacters(attempts?.reference)} />
                        </button>
                      </li>
                    ))}
                    {!['success', 'manual', 'pending'].includes(item[0]?.status) && (
                      <div className="button-wrapper">
                        {item[0]?.status === 'failed' ? (
                          <button
                            onClick={() => {
                              if (item[0]?.payment_reversal_payouts.length === 0 || lastReversalAttempt.account_number === null) {
                                setRetryInfo(prev => {
                                  return {
                                    ...prev,
                                    reference: item[0]?.reference
                                  };
                                });
                                setState({
                                  modalVisible: true,
                                  type: 'retryWithNewDetails'
                                });
                              } else {
                                setRetryInfo(prev => {
                                  return {
                                    ...prev,
                                    reference: item[0]?.reference,
                                    account_number: lastReversalAttempt?.account_number,
                                    bank_code: getBankCode(lastReversalAttempt?.bank_name)
                                  };
                                });
                                setState({ modalVisible: true, type: 'retry' });
                              }
                            }}
                            className="retry-button"
                            type="button"
                          >
                            Retry Reversal
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setAttemptReference(item[0]?.reference);
                              setState({
                                modalVisible: true,
                                type: 'approval'
                              });
                            }}
                            className="retry-button"
                            style={{ borderRadius: '4px' }}
                            type="button"
                            disabled={item[0]?.status === 'processing'}
                          >
                            {item[0]?.status === 'processing' ? 'Processing Reversal' : 'Approve Reversal'}
                          </button>
                        )}
                        <button
                          onClick={() => {
                            setAttemptReference(item[0]?.reference);
                            setState({ modalVisible: true, type: 'manual' });
                          }}
                          disabled={item[0]?.status === 'processing'}
                          className="update-button"
                          style={{ borderRadius: '4px' }}
                          type="button"
                        >
                          ...
                        </button>
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </li>
          </ul>
        )}
        {data?.length === 0 && !isLoading && !isError && <ReversalStates reversalState="empty" reversalType="reversals" />}
      </div>
      {state.modalVisible && <Modal {...switchReversalModal(state.type)} />}
    </>
  );
};
export default Reversals;
