import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { switchCurrency } from '+utils';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import './index.scss';

const api = new APIRequest();

const CurrenciesMenu = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { data, refetch, isLoading } = useQuery('SYSTEM_CURRENCIES', () => api.getCurrencyMerchantCount(), {
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this currencies`,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        }
      });
    }
  });

  return (
    <div className="menu-container">
      <h1 className="title">Product Configuration</h1>
      <p className="subtitle">
        This is the main hub for managing products, currencies, and merchants with access to various currencies and products on Kora.
      </p>
      <div className="menu">
        <p className="subtitle">Available Currencies on Kora.</p>
        <div className="table">
          {isLoading ? (
            <LoadingPlaceholder type="table" content={1} background="#f5f6f6" />
          ) : (
            Object.entries(data || {}).map(([key, value]) => (
              <div className="product-config-row" key={key}>
                <div className="product-config-row__currency">
                  <p>{switchCurrency[key as keyof typeof switchCurrency]}</p>
                </div>
                <div className="product-config-row__merchants">
                  <p>{`${value} merchants have access to this currency`}</p>
                </div>
                <div className="product-config-row__merchant">
                  <Link to={`/dashboard/product-config/${key}`}>Manage Merchants</Link>
                </div>
                {/* <div className="product">
                  <Link to={`/dashboard/product-config/products/${key}`}>Manage Products</Link>
                </div> */}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrenciesMenu;
