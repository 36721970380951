/* eslint-disable react/forbid-prop-types */
import React from 'react';
import './index.scss';

const SecondaryDetails = ({
  title,
  data = [],
  hideLine = false,
  leftSpacing = '1rem',
  className
}: {
  title: string;
  data: any[];
  hideLine?: boolean;
  leftSpacing?: string;
  className?: string;
}): JSX.Element => {
  return (
    <div className={className}>
      <p className="sec-details-title" style={{ paddingLeft: leftSpacing }}>
        {title}
      </p>
      <section className="secondary-details__comp">
        {data.map((group: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <ul className="sec-details-container" style={{ marginLeft: leftSpacing }}>
              {Object.entries(group).map(([key, val]) => (
                <li className="sec-details-list fade-in" key={key}>
                  <span className="sec-details-key">{key}</span>{' '}
                  <span className="sec-details-val" style={val === 'Not Available' ? { fontStyle: 'italic', color: '#DDE2EC' } : {}}>
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </section>
      {!hideLine && <hr className="sec-details-hr" />}
    </div>
  );
};

export default SecondaryDetails;
