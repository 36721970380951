import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import ProductFeatureDropdown from '+containers/Dashboard/Shared/ProductFeatureDropdown';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

const api = new APIRequest();

export default function Limits() {
  const { id } = useParams<{ id: string }>();
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const setActiveCurrency = (currency: string) => searchQuery.setQuery({ currency });

  const { data: merchantLimit, isLoading } = useQuery(['MERCHANT_PRODUCT_LIMIT', id], () => api.getMerchantProductLimit(id), {
    onError: (error: AxiosError<{ message: string }>) => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error fetching the product limit for this merchant.`,
        type: 'danger'
      });
    }
  });

  return (
    <div className="limit-tab-wrapper">
      <div className="fees-heading-box">
        <div className="fees-heading-content">
          <h5 className="form-header">Limits & Product Access</h5>

          <div className="form-desc tier-form-desc">
            View and configure the transaction limits for this merchant here. To change the default transaction limits for all merchants, go
            to <Link to="/dashboard/settings/fees">Settings</Link>.
          </div>
        </div>
        <div>
          <CurrencyPicker
            onChange={value => {
              setActiveCurrency(value);
            }}
            className="merchants-fees__currency-toggle"
            activeCurrency={activeCurrency}
            label={<strong>Show configuration for:</strong>}
            id="merchant-fees__currency-switch"
            allowedOptions={['NGN']}
            showDropDown={false}
          />
        </div>
      </div>
      <div className="os-tabs-controls os-tabs-complex mx-0">
        <ul className="nav nav-tabs px-0 mb-2">
          <li className="nav-item">
            <button type="button" className="nav-link active" data-toggle="tab">
              <span className="tab-label">Overview</span>
            </button>
          </li>
        </ul>
      </div>
      {isLoading && <LoadingPlaceholder type="table" content={3} />}
      {!isLoading && !merchantLimit?.data && <EmptyStateComponent message="Product Limit Not Available" />}
      {!isLoading && merchantLimit?.data && <ProductFeatureDropdown details={merchantLimit?.data} currency={activeCurrency} />}
    </div>
  );
}
