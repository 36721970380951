import React, { Dispatch, FC } from 'react';

interface CategoriesProps {
  currentRoute: string;
  setRoute: Dispatch<Partial<{ route: string }>>;
}

const Categories: FC<CategoriesProps> = ({ currentRoute, setRoute }) => {
  const menuOptions = [
    'Marketing & Product Updates',
    'Payout Withdrawals',
    'Payouts API',
    'Pay-ins',
    'Support',
    'Refunds & Reversals',
    'Chargebacks',
    'Card Issuance'
  ];

  return (
    <div className="email-categories">
      <ul>
        {menuOptions?.map((menu, i) => {
          return (
            <li className={`${currentRoute === menu && 'active'}`} onClick={() => setRoute({ route: menu })} key={i}>
              {menu}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Categories;
