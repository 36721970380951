import { Storage } from '+services/storage-services';
import { decryptContent, encryptContent } from '+utils';
import { StateCreator } from 'zustand';
import { StateStorage, createJSONStorage, persist } from 'zustand/middleware';

export type AuthSlice = {
  clientToken: string;
  userToken: string;
  refreshToken: string;
  userTokenExpiration: string;
  isAuthenticated: boolean;
  isAuthorized: boolean;
  authorizeData: Record<string, unknown>;
  isLoading: boolean;
  error: boolean;
  profile: {
    email?: string;
    avatar?: string;
  };
  errors: Record<string, unknown>;
};

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    const item = await decryptContent(sessionStorage.getItem(name));
    return JSON.parse(item || null);
  },
  setItem: async (name: string, value: string): Promise<void> => {
    sessionStorage.setItem(name, await encryptContent(value));
  },
  removeItem: (name: string): void => {
    sessionStorage.removeItem(name);
  }
};

const createAuthSlice: StateCreator<AuthSlice, [], [['zustand/persist', AuthSlice]]> = persist<AuthSlice>(
  () => ({
    clientToken: Storage.clientToken(),
    userToken: Storage.checkAuthentication(),
    refreshToken: Storage.getRefreshToken(),
    userTokenExpiration: Storage.checkExpiration(),
    isAuthenticated: !!Storage.checkAuthentication(),
    isAuthorized: false,
    authorizeData: {},
    isLoading: false,
    error: false,
    profile: Storage.getItem('koraAdminUserProfile') || {
      email: '',
      avatar: ''
    },
    errors: {}
  }),
  {
    name: 'auth',
    storage: createJSONStorage(() => storage)
  }
);

export default createAuthSlice;
