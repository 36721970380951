/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { capitalize, getTime, getDate, capitalizeRemovedash, isAllowed, truncateString } from '+utils';
import Table from '+shared/Table';
import Modal from '+shared/Modal';
import Copyable from '+shared/Copyable';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import Padlock from '+assets/img/dashboard/padlock.svg';
import UnlockMerchantModal from './components/UnlockMerchantModal';
import './index.scss';

const api = new APIRequest();

export default function TeamsTab() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [modal, setModal] = useState();

  const { isLoading, data: teamMembers } = useQuery(`TEAM_MEMBERS_${id}`, () => api.getMerchantTeamMembers(id), {
    select: (data) => {
      const owners = data?.filter((member) => member.userRole.name === 'Owner') || [];
      const others = data?.filter((member) => member.userRole.name !== 'Owner') || [];
      return [...owners, ...others];
    },
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching the team members details for the merchant.',
        type: 'danger'
      });
    }
  });

  const Teams = {
    className: '--teams-table',
    emptyStateHeading: 'No Merchants yet',
    emptyStateMessage: 'It looks like there are no results yet.',
    fields: (each) => ({
      data: {
        team_member: each?.user?.first_name ? (
          <div className="member-name">
            <span>{`${capitalize(each?.user?.first_name)} ${capitalize(each?.user?.last_name)}`}</span>
            {each?.user?.status === 'locked' && <img src={Padlock} alt="padlock" className="lock" />}
          </div>
        ) : (
          '--'
        ),
        email: <Copyable text={each?.email} textModifier={text => truncateString(text, 13)} imageClassName="team-copy-img-xx" />,
        role: (
          <span>
            {capitalize(each?.userRole?.name || 'N/A')}{' '}
            {each?.userRole?.category === 'system' && <span className="role-default">(Default) </span>}{' '}
          </span>
        ),
        status: (
          <>
            {(each?.status === 'active' || each?.status === 'invite_accepted') && <span className="status-pill smaller green" />}
            <span
              className="status-text"
              style={{ fontStyle: each?.status !== 'active' && each?.status !== 'invite_accepted' ? 'italic' : '' }}
            >
              {each?.status === 'active' || each?.status === 'invite_accepted'
                ? 'Active'
                : each?.status !== 'active' || each?.status !== 'invite_accepted'
                ? capitalizeRemovedash(each?.status || '')
                : 'N/A'}
            </span>
          </>
        ),
        '2fa_enabled': <span className="status-text">{each?.user?.two_factor_enabled ? 'Yes' : 'No'}</span>,
        last_login: (
          <span className="last-login">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>{each?.user?.last_login ? getDate(each?.user?.last_login) : '--'}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {each?.user?.last_login ? getTime(each?.user?.last_login) : ''}
              </span>
            </span>

            <UnlockMerchantModal
              setModal={setModal}
              userId={each.user_id}
              role={each.userRole}
              isTwoFactor={each?.user?.two_factor_enabled === 1 && isAllowed(userAccess, ['disable_user_totp.update'])}
              isLocked={each?.user?.status === 'locked' && isAllowed(userAccess, ['merchant_unlock_account.update'])}
            />
          </span>
        )
      }
    })
  };
  const tableDataKeys = Object.keys(Teams.fields({}).data);

  return (
    <>
      <div className="element-box">
        <h5 className="form-header">Team</h5>
        <div className="form-desc">These team members have access to the merchants dashboard.</div>

        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <h6 className="element-header">Team Members ({teamMembers?.length})</h6>
              <div className="element-box-tp">
                <Table
                  className={Teams.className}
                  loading={isLoading}
                  data={teamMembers || []}
                  renderFields
                  hasPagination={0}
                  tableHeadings={tableDataKeys}
                  annotation={Teams.annotations}
                  emptyStateHeading={Teams.emptyStateHeading || ''}
                  tableWrapperClassName=""
                  emptyStateMessage={Teams.emptyStateMessage || ''}
                  persistState
                  type=""
                  totalItems={(teamMembers || []).length || 0}
                  filterHasAdvancedFilter={false}
                  hasFilter={false}
                >
                  {Teams.fields}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={!!modal} isScrollable {...modal} />
    </>
  );
}
