import React, { useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import Icon from '+containers/Dashboard/Shared/Icons';
import ProductFeatureDropdown from '+containers/Dashboard/Shared/ProductFeatureDropdown';
import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { IProductLimitData, IState } from '+types/productTiers';

import TierCategories from './TierCategories';

import './index.scss';

const api = new APIRequest();

const MerchantProductAccessLimits = () => {
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const setActiveCurrency = (currency: string) => searchQuery.setQuery({ currency });
  const { feedbackInit } = useFeedbackHandler();
  const [state, updateState] = useReducerState<IState>({
    route: 'Base Tier'
  });
  const { data: allTierLevels } = useQuery(['TIER_LEVELS'], () => api.getAllTierLevels(), {
    onSuccess: data => {
      updateState({ route: data[0].name });
    },
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching tier levels.',
        type: 'danger'
      });
    },
    select: data => {
      const responseData = data?.data as IProductLimitData[];

      return responseData?.filter?.(tier => tier.slug !== 'test_mode');
    }
  });
  const selectedTierDetail = allTierLevels?.find((tier: IProductLimitData) => tier.name === state.route);

  return (
    <div className="limit-tab-wrapper">
      <div className="fees-heading-box tier-heading-box">
        <div className="fees-heading-content">
          <h5 className="form-header">Product Access and Limits</h5>

          <div className="form-desc" style={{ border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
            Manage and edit product access for merchants grouped into tiers, according to already set product feature access and limits.{' '}
            {/* <p className="tier-learn-more">
              <span>
                <Icon name="lightBulb" />
              </span>
              <Link to="/dashboard/settings/fees">
                <strong>Learn more about Tiers and Limits</strong>
              </Link>
              .
            </p> */}
          </div>
        </div>
        <div>
          <CurrencyPicker
            onChange={value => {
              setActiveCurrency(value);
            }}
            className="merchants-fees__currency-toggle"
            activeCurrency={activeCurrency}
            id="merchant-fees__currency-switch"
            allowedOptions={['NGN']}
            showDropDown={false}
          />
        </div>
      </div>
      <div className="os-tabs-controls os-tabs-complex mx-0">
        <ul className="nav nav-tabs px-0 mb-2">
          <li className="nav-item">
            <button type="button" className="nav-link active" data-toggle="tab">
              <span className="tab-label">Tiering System</span>
            </button>
          </li>
        </ul>
      </div>
      <section>
        <div className="row mt-3 ">
          <div className="col-md-2 mr-5">
            <h6 className="tier-sidebar">MERCHANT TIERS</h6>
            <TierCategories allTiers={allTierLevels} currentRoute={state.route} setRoute={updateState} />
          </div>
          <div className="col-md-9">
            <h4>Merchant {selectedTierDetail?.name}</h4>
            <p className="tier-merchant-count">
              {' '}
              <span>
                <Icon name="briefCasePurple" className="tier-brief-case" />
              </span>
              {selectedTierDetail?.merchantsCount} Total Merchants{' '}
            </p>
            <p>{selectedTierDetail?.description}</p>
            <ProductFeatureDropdown details={selectedTierDetail?.productLimits} currency={activeCurrency} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MerchantProductAccessLimits;
