import React, { LegacyRef, Dispatch, SetStateAction, useEffect, useState, useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { capitalizeRemovedash, logError } from '+utils';
import useClickOutside from '+hooks/useClickOutside';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import InfoSvg from '+assets/img/dashboard/info-red.svg';
import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { TAccountHolderModal } from './TypesAccountHolders';
import ManageAccForm from '../../Shared/ManageAccForm';
import './index.scss';

const api = new APIRequest();

type TSetButtonStateFunction = (state: boolean) => void;

const reasons = [
  { label: '- Select an option -', value: '' },
  { label: 'Suspected Fraud', value: 'suspected_fraud' },
  { label: 'Data Breach', value: 'data_breach' },
  { label: 'Other', value: 'other' }
];

const reasonFeedbackContent = (description = '') => {
  const optionIsNotOther = ['suspected_fraud', 'data_breach'].includes(description);
  return (
    <div className="reason-modal">
      <div className="title"> {optionIsNotOther ? capitalizeRemovedash(description) : description}</div>
    </div>
  );
};

const ManageAccountHolderModal = ({
  setModal,
  setButtonState,
  accountReference,
  accountHolder,
  modalType
}: {
  setModal: Dispatch<SetStateAction<TAccountHolderModal>>;
  setButtonState: TSetButtonStateFunction;
  accountReference: string;
  modalType: string | null;
  accountHolder: {
    status: string;
    reason?: string;
  };
}) => {
  const { feedbackInit } = useFeedbackHandler();
  const [manageModal, setManageModal] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonText, setReasonText] = useState('');
  const [actionType, setActionType] = useState('');
  const suspended = accountHolder.status === 'suspended';
  const queryClient = useQueryClient();

  const wrapperRef = useClickOutside(() => {
    setManageModal(false);
  });

  const { mutateAsync } = useMutation(() => api.manageAccountHolder(accountReference, actionType, reasonText || reason), {
    onSuccess: () => {
      feedbackInit({
        message: `This account holder has been ${actionType + (actionType === 'suspend' ? 'ed' : 'd')}!`,
        type: (actionType === 'suspend' && 'warning') || (actionType === 'deactivate' && 'danger') || 'success',
        componentLevel: true
      });
    },
    onError: (error: { response: { message: string; data: { message?: string } } }) => {
      logError(error);
      feedbackInit({
        message:
          error?.response?.data?.message ||
          error?.response?.message ||
          `There has been an error ${actionType}ing this account Holder. Please try again`,
        type: 'danger'
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries('ACCOUNT_HOLDERS');
      queryClient.invalidateQueries('ACCOUNT_HOLDER_KYC');
    }
  });

  const modalDetails = useCallback(
    () =>
      ({
        suspend: {
          heading: 'Suspend Account Holder',
          description:
            'Suspending an account holder blocks the customer from making payments through all of the account numbers created under the account holder.',
          secondButtonText: 'Proceed',
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => setModalVisible('confirmSuspend'),
          content: (
            <ManageAccForm
              headerText="Why are you suspending this account holder?"
              options={reasons}
              setReason={setReason}
              setReasonText={setReasonText}
            />
          )
        },
        confirmSuspend: {
          heading: (
            <p>
              Are you sure you want to suspend
              <br />
              this account Holder?
            </p>
          ),
          description: 'Please note that this will suspend all account numbers generated under this account holder immediately',
          completedHeading: 'Done!',
          completedDescription: 'You have successfully suspended this account holder',
          secondButtonActionIsTerminal: true,
          secondButtonText: 'Yes, Suspend',
          secondButtonAction: mutateAsync,
          size: 'sm'
        },
        deactivate: {
          heading: 'Deactivate Account Holder',
          description: 'Please note that this account Holder would be deactivated immediately and will not be reactivated',
          secondButtonText: 'Deactivate',
          content: <ManageAccForm setReason={setReason} showDescription setReasonText={setReasonText} />,
          secondButtonColor: '#F32345',
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => setModalVisible('confirmDeactivate')
        },
        confirmDeactivate: {
          heading: (
            <p>
              Are you sure you want to deactivate this
              <br />
              account Holder?
            </p>
          ),
          description: (
            <div className="deactivate">
              <img src={InfoSvg} alt="info" />
              Please note that this action is irreversible. Deactivated accounts can not be reactivated, users won&apos;t be able to carry
              out transactions with this account Holder.
            </div>
          ),
          completedHeading: 'Done!',
          completedDescription: 'You have successfully deactivated this account Holder',
          secondButtonActionIsTerminal: true,
          secondButtonText: 'Yes, Deactivate',
          secondButtonAction: mutateAsync,
          secondButtonColor: '#F32345',
          size: 'sm'
        },
        reactivate: {
          heading: 'Reactivate Account Holder',
          description: 'Please note that all account holders generated for this account holder would be reactivated immediately.',
          secondButtonText: 'Reactivate',
          secondButtonActionIsTerminal: false,
          secondButtonAction: () => {
            setModalVisible('confirmReactivate');
          },
          content: <ManageAccForm setReason={setReason} showDescription setReasonText={setReasonText} />
        },
        confirmReactivate: {
          heading: (
            <p>
              Are you sure you want to reactivate this
              <br />
              account holder?
            </p>
          ),
          description: 'Please note that this action cannot be undone.',
          completedHeading: 'Done!',
          completedDescription: 'Your request to reactivate this account holder was successful.',
          secondButtonActionIsTerminal: true,
          secondButtonText: 'Yes, Reactivate',
          secondButtonAction: mutateAsync,
          size: 'md'
        },

        actionReason: {
          heading: `Reason for ${accountHolder.status === 'suspended' ? 'Suspension' : 'Deactivation'}`,
          content: reasonFeedbackContent(accountHolder?.reason),
          hideSecondButton: true,
          firstButtonText: 'Dismiss'
        }
      } as unknown as { [key: string]: IModalProps }),
    [accountHolder?.reason]
  );

  const setModalVisible = (type: string) => {
    const modal = modalDetails();
    setModal({
      modalType: type,
      modalInfo: {
        heading: modal[type].heading,
        description: modal[type].description,
        completedHeading: modal[type].completedHeading,
        completedDescription: modal[type].completedDescription,
        content: modal[type].content,
        secondButtonText: modal[type].secondButtonText,
        secondButtonAction: modal[type].secondButtonAction,
        secondButtonActionIsTerminal: modal[type].secondButtonActionIsTerminal,
        size: modal[type].size,
        secondButtonColor: modal[type]?.secondButtonColor,
        showButtons: modal[type]?.showButtons,
        hideSecondButton: modal[type]?.hideSecondButton,
        firstButtonText: modal[type]?.firstButtonText
      } as unknown as IModalProps
    });
  };

  useEffect(() => {
    if (reason === 'other' && !reasonText.trim()) setButtonState(false);
    else if (reasonText.trim() || reason.trim() || modalType?.includes('confirm')) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [reason, reasonText]);

  useEffect(() => {
    if (!modalType) {
      setReason('');
      setReasonText('');
    }
  }, [modalType]);

  useEffect(() => {
    if (modalType === 'actionReason') setModalVisible('actionReason');
  }, [modalType]);

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>}>
      {!['deactivated', 'pending', 'rejected'].includes(accountHolder.status) && (
        <div className="manage-account-modal">
          <button
            type="button"
            onClick={() => {
              setManageModal((value) => !value);
            }}
          >
            <i className="os-icon os-icon-settings" />
            &nbsp;
            <span>Manage Account Holder</span>
            &nbsp;
            <i className="os-icon os-icon-arrow-down" />
          </button>
        </div>
      )}

      {manageModal && (
        <ul className="element-box ellipsis__nav user-ellipsis">
          {!suspended && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModalVisible('suspend');
                setActionType('suspend');
              }}
            >
              <span>Suspend Account Holder</span>
            </li>
          )}

          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              const action = suspended ? 'reactivate' : 'deactivate';
              setModalVisible(action);
              setActionType(action);
            }}
          >
            <span style={{ color: suspended ? '#000' : '#F32345', fontWeight: 600 }}>
              {suspended ? 'Reactivate' : 'Deactivate'} Account Holder
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ManageAccountHolderModal;
