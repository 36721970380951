import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import CaretSvg from '+assets/img/dashboard/caret.svg';
import InfoSvg from '+assets/img/dashboard/info-brown.svg';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import ViewAndDownload from '+containers/Dashboard/Shared/ViewAndDownload';
import { capitalize, capitalizeFirst, getDate, getDateAndTime, getDayandMonth, getTime, logError, switchStatus } from '+utils';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import { TUpgradeRequestDetails } from '../../data';
import './index.scss';

const api = new APIRequest();

const requestHistoryColors = {
  declined: { bgColor: '#FFF7ED', textColor: '#FA9500' },
  approved: { bgColor: '#E4FFF1', textColor: '#24B314' },
  pending: { bgColor: '#FFF7ED', textColor: '#FA9500' }
};

const requestDetails = (data: TUpgradeRequestDetails) => {
  const objStructure = data
    ? {
        'Account Name': data?.virtual_bank_account?.account_name,
        'Account Number': (
          <>
            {data?.virtual_bank_account?.account_number}&nbsp;
            {data?.virtual_bank_account?.account_number && (
              <Copyable text={data?.virtual_bank_account?.account_number} showText={false} buttonClassName="copy-icon" />
            )}
          </>
        ),
        Bank: data?.virtual_bank_account?.bank,
        'Account Holder': data?.account_holder?.name,
        Merchant: data?.merchant,
        'Upgrading From (Current Tier)': <>Tier {data?.current_tier}</>,
        'Upgrading To (Requested Tier)': <>Tier {data?.requested_tier}</>,
        'Date Requested': getDateAndTime(data?.requested_date),
        ...(data?.decline_reason && { 'Previous Request Declines': data?.decline_reason }),
        'Reason for Upgrade': data?.upgrade_use_case
      }
    : {};

  return (
    <div data-testid="request-details-container" className="request-details-container">
      {Object.entries(objStructure).map(([key, content]) => (
        <div
          key={key}
          className="request-detail"
          style={key.startsWith('Reason') ? { flexDirection: 'column', alignItems: 'flex-start' } : {}}
        >
          <p className="request-detail__label">{key}</p>
          <p className="request-detail__value">{content}</p>
        </div>
      ))}
    </div>
  );
};

const accountHolderInformation = (data: TUpgradeRequestDetails) => {
  const holder = data?.account_holder;

  const objStructure = {
    'Account Holder Name': holder.name,
    'Account Holder Reference': (
      <>
        {holder.reference}&nbsp;
        <Copyable text={holder?.reference} showText={false} buttonClassName="copy-icon" />
      </>
    ),
    Status: (
      <>
        <span className={`status-pill smaller status-pill-smaller ${switchStatus(holder.status)}`} />{' '}
        {capitalize(holder.status || 'Not Available')}
      </>
    ),
    Type: holder.type,
    Email: holder.email,
    Phone: holder.phone,
    'Date of Birth': getDate(holder.date_of_birth),
    Address: holder.address?.address,
    City: holder.address?.city,
    Country: holder.address?.country
  };

  return (
    <div data-testid="account-holder-information-container" className="request-details-container">
      {Object.entries(objStructure).map(([key, value]) => (
        <div className="request-detail" key={key}>
          <p className="request-detail__label">{key}</p>
          <p className="request-detail__value">{value}</p>
        </div>
      ))}
    </div>
  );
};

const UpgradeRequestModalContent = ({ data }: { data: TUpgradeRequestDetails }) => {
  const { feedbackInit } = useFeedbackHandler();

  const [openRequestDetails, setOpenRequestDetails] = useState(false);
  const [openSupportingDocuments, setOpenSupportingDocuments] = useState(false);
  const [openAccountHolderInformation, setOpenAccountHolderInformation] = useState(false);
  const [openRequestHistory, setOpenRequestHistory] = useState(false);

  const { data: upgradeRequestDeclines, refetch } = useQuery(
    [data?.virtual_bank_account?.reference],
    () => api.getFixedVirtualBankAccountUpgradeRequestsDeclines(data?.virtual_bank_account?.reference, 'declined'),
    {
      enabled: !!data?.virtual_bank_account?.reference,
      onError: (error) => {
        logError(error);
        feedbackInit({
          message: `There has been an error fetching the upgrade request decline history`,
          type: 'danger',
          isActive: false
        });
      }
    }
  );

  const supportingDocuments = () => {
    const fileUrl: string = data?.upgrade_use_case_document?.url || '';
    return (
      <div data-testid="supporting-documents-container" className="request-details-container">
        <div className="request-detail">
          <p className="request-detail__label">File</p>
          <p className="request-detail__value" style={{ display: 'flex', marginBottom: 0 }}>
            <ViewAndDownload
              url={fileUrl}
              info="pdf"
              type={fileUrl?.split('.').pop() || ''}
              filename="upgrade-request"
              disableView={undefined}
              disableDownload={undefined}
              viewInModal={false}
              showDownloadBtn={false}
            />
          </p>
        </div>
      </div>
    );
  };

  const requestHistory = () => {
    return (
      <div data-testid="upgrade-req-history-container" className="request-history-container">
        {upgradeRequestDeclines?.data?.map((decline: Record<string, string>) => (
          <div
            key={decline.reference}
            className="request-decision"
            style={{ background: requestHistoryColors[decline.status as keyof typeof requestHistoryColors].bgColor }}
          >
            <p
              className="request-decision__title"
              style={{ color: requestHistoryColors[decline.status as keyof typeof requestHistoryColors].textColor }}
            >
              {capitalizeFirst(decline.status)}
            </p>
            <p className="request-decision__body">{decline.decline_reason}</p>
            <p className="request-decision__date">
              <span style={{ fontWeight: 600, color: '#414F5F' }}>{getDayandMonth(decline?.created_at)}</span>,
              <span style={{ color: '#A9AFBC' }}>&nbsp;{getTime(decline?.created_at)}</span>
            </p>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (data?.virtual_bank_account?.reference) {
      refetch();
    }
  }, [data?.virtual_bank_account?.reference]);

  return (
    <div className="upgrade-request-modal">
      <div className="upgrade-request-content">
        <div className="upgrade-request-content__header">
          <p>Request Details</p>
          <div
            className={`caret ${openRequestDetails ? 'flipped' : ''}`}
            onKeyUp={() => setOpenRequestDetails(!openRequestDetails)}
            onClick={() => setOpenRequestDetails(!openRequestDetails)}
            role="button"
            data-testid="open-request-details"
            tabIndex={0}
          >
            <img src={CaretSvg} alt="close" />
          </div>
        </div>
        <div className="upgrade-request-content__body">
          <p>This is a summary of the request below with some information submitted by requesting merchant.</p>
        </div>
        {openRequestDetails && requestDetails(data)}
      </div>
      <div className="upgrade-request-content">
        <div className="upgrade-request-content__header">
          <p>Supporting Documents</p>
          <div
            data-testid="open-supporting-documents"
            className={`caret ${openSupportingDocuments ? 'flipped' : ''}`}
            onKeyUp={() => setOpenSupportingDocuments(!openSupportingDocuments)}
            onClick={() => setOpenSupportingDocuments(!openSupportingDocuments)}
            role="button"
            tabIndex={0}
          >
            <img src={CaretSvg} alt="close" />
          </div>
        </div>
        <div className="upgrade-request-content__body">
          <p>These are the documents submitted for this upgrade. Please note that supported documents must match use case for upgrade.</p>
        </div>
        <div className="upgrade-request-content__body__note">
          <img src={InfoSvg} alt="info" />
          <span>
            Please note that supported documents must match <strong>use case for upgrade.</strong>
          </span>
        </div>
        {openSupportingDocuments && supportingDocuments()}
      </div>
      <div className="upgrade-request-content">
        <div className="upgrade-request-content__header">
          <p>Account Holder Information</p>
          <div
            data-testid="open-account-holder-information"
            className={`caret ${openAccountHolderInformation ? 'flipped' : ''}`}
            onKeyUp={() => setOpenAccountHolderInformation(!openAccountHolderInformation)}
            onClick={() => setOpenAccountHolderInformation(!openAccountHolderInformation)}
            role="button"
            tabIndex={0}
          >
            <img src={CaretSvg} alt="close" />
          </div>
        </div>
        <div className="upgrade-request-content__body">
          <p>Here, you find detailed information on the account holder of the account to be upgraded.</p>
        </div>
        {openAccountHolderInformation && accountHolderInformation(data)}
      </div>
      <div className="upgrade-request-content">
        <div className="upgrade-request-content__header">
          <p>Upgrade Request History</p>
          <div
            data-testid="open-upgrade-req-history"
            className={`caret ${openRequestHistory ? 'flipped' : ''}`}
            onKeyUp={() => setOpenRequestHistory(!openRequestHistory)}
            onClick={() => setOpenRequestHistory(!openRequestHistory)}
            role="button"
            tabIndex={0}
          >
            <img src={CaretSvg} alt="close" />
          </div>
        </div>
        <div className="upgrade-request-content__body">
          <p>Here’s a history of the decisions that have been made with regards to upgrading this account.</p>
        </div>
        {openRequestHistory && requestHistory()}
      </div>
    </div>
  );
};

export default UpgradeRequestModalContent;
