import React, { useState } from 'react';
import Modal from '+shared/Modal';

interface ICardOperationsModalProps {
  close: () => void;
  type: 'suspend' | 'activate' | 'terminate';
  operation: Function;
}

type modalStages = 'suspend' | 'activate' | 'terminate' | 'confirmSuspend' | 'confirmTerminate';

export default function CardOperationsModal({ close, type, operation }: ICardOperationsModalProps): JSX.Element {
  const reasons = [
    { label: '- Select an option -', value: '' },
    { label: 'Suspected Fraud', value: 'fraud' },
    { label: 'Card Compromise', value: 'compromise' },
    { label: "Cardholder's request", value: 'request' },
    { label: 'Other', value: 'other' }
  ];

  const [stage, setStage] = useState<modalStages>(type);
  const [reason, setReason] = useState<string>('');
  const [other, setOther] = useState<string>('');

  const getFormData = () => {
    return {
      ...(type !== 'terminate' && { action: type === 'suspend' ? 'suspend' : 'activate' }),
      // eslint-disable-next-line no-nested-ternary
      reason: type !== 'activate' ? (reason !== 'other' ? reason : other) : 'Admin action',
      initiator: `KORA-admin`
    };
  };

  function renderSuspendForm() {
    return (
      <>
        <div className="form-group">
          <label htmlFor="reason">
            <span className="dark">Why do you want to suspend this card?</span>
          </label>
          <select
            name="reason"
            className="form-control"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            data-testid="select-reason"
          >
            {reasons.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {reason === 'other' && (
          <div className="form-group">
            <label htmlFor="other-reason">
              <span className="dark">Reason</span>
            </label>
            <input
              name="other-reason"
              className="form-control"
              placeholder=""
              onChange={(e) => setOther(e.target.value)}
              data-testid="others"
            />
          </div>
        )}
      </>
    );
  }

  const modalPropOptions = {
    activate: {
      heading: 'Confirm reactivation',
      description: 'This card was suspended due to Fraudulent activities. Please confirm that you want to reactivate this card.',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Reactivate',
      secondButtonAction: () => operation(getFormData()),
      completedDescription: 'Your request to reactivate this card was successful.'
    },
    suspend: {
      heading: 'Suspend Card',
      description: 'Suspending a card makes its balance blocked thus transactions cannot be carried out on such card.',
      content: renderSuspendForm(),
      secondButtonDisable: !other && !reason,
      secondButtonActionIsTerminal: false,
      secondButtonText: 'Proceed',
      secondButtonAction: () => setStage('confirmSuspend')
    },
    confirmSuspend: {
      heading: 'Confirm suspension',
      description:
        'Please confirm that you want to suspend this virtual card. This action blocks the card balance thus transactions cannot be carried out.',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Suspend',
      secondButtonAction: () => operation(getFormData()),
      completedDescription: 'Your request to suspend this virtual card was successful.'
    },
    terminate: {
      heading: 'Terminate Card',
      description: 'Terminating a card makes its balance blocked thus transactions cannot be carried out on such card.',
      content: renderSuspendForm(),
      secondButtonDisable: !other && !reason,
      secondButtonActionIsTerminal: false,
      secondButtonText: 'Proceed',
      secondButtonAction: () => setStage('confirmTerminate')
    },
    confirmTerminate: {
      heading: 'Confirm termination',
      description: 'Please confirm that you want to terminate this virtual card. This action cannot be undone.',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Terminate',
      secondButtonAction: () => operation(getFormData()),
      completedDescription: 'Your request to terminate this card was successful.'
    }
  };

  const modalProps = {
    close,
    size: 'md',
    completedHeading: 'Done!',
    ...(modalPropOptions[stage] || {})
  };

  return <Modal {...modalProps} />;
}
