import React, { Dispatch, SetStateAction, FC } from 'react';
import { useFormik } from 'formik';
import Modal from '+shared/Modal';
import successCheckMarkIcon from '+assets/img/dashboard/success-check-mark.svg';
import { TDeclineKycModal, TFormField, TApproveKycModal, TKycActionFeedbackModal } from '../TypesAccountHolders';
import './kyc-information.scss';

const formik = (initialValues: { reason: string }) =>
  useFormik({
    initialValues,
    validateOnChange: true,
    validate: (values: { reason: string }) => {
      const errors = {} as Record<keyof TFormField, string>;
      if (/[<>=/?]/.test(values.reason)) {
        errors.reason = 'Some Invalid characters have been inputted';
      }
      if (!values.reason) {
        errors.reason = 'Please provide a reason';
      }
      return errors;
    },
    onSubmit: async () => null
  });

export const DeclineKycModal: FC<TDeclineKycModal> = ({ setModalType, formSubmit }) => {
  const form = formik({ reason: '' });

  return (
    <Modal
      description="Please confirm that you want to decline this KYC verification request. You will need to provide a reason for this action as it cannot be undone."
      heading="Decline KYC verification request?"
      headerBottomBorder
      size="md"
      firstButtonText="Back"
      close={() => setModalType(null)}
      secondButtonText="Yes, Decline"
      secondButtonDisable={!form.values.reason || !form.isValid}
      secondButtonAction={() => formSubmit(form.values.reason)}
      content={
        <div className="fva-modal" data-testid="edit-limit-modal">
          <div className="my-3">
            <label htmlFor="reason" className="semibold">
              Reason for decline
            </label>
            <input
              type="text"
              name="reason"
              id="reason"
              data-testid="decline-kyc-input"
              value={form.values.reason}
              onChange={(e) => form.setFieldValue('reason', e.target.value)}
              className="form-control mt-2"
              placeholder="Enter your reason for this action"
            />
            <span data-testid="error-text" className="input__errors">
              {form.errors.reason}
            </span>
          </div>
        </div>
      }
    />
  );
};

export const ApproveKycModal: FC<TApproveKycModal> = ({ setModalType, dispatch }) => (
  <Modal
    description="Please confirm that you want to verify this KYC information. Ensure that all documents are valid and up to date. Note that this action cannot be undone."
    heading="Verify KYC Information?"
    headerBottomBorder
    size="sm"
    firstButtonText="Back"
    firstButtonAction={() => setModalType(null)}
    secondButtonText="Yes, Verify"
    secondButtonAction={() => dispatch()}
    content=""
    close={() => setModalType(null)}
  />
);

export const KycActionFeedbackModal: FC<TKycActionFeedbackModal> = ({ setModalType, action }) => (
  <Modal
    content={
      <div className="kyc-modal__success" data-testid="edit-completed-modal">
        <img src={successCheckMarkIcon} alt="success feedback icon" />
        <h6>Success</h6>
        <p>You have successfully {action === 'approve' ? 'verified' : 'declined'} this KYC information.</p>
        <button type="button" onClick={() => setModalType(null)} aria-label="close success modal">
          Dismiss
        </button>
      </div>
    }
    size="sm"
    showButtons={false}
    close={() => setModalType(null)}
    heading=""
    description={undefined}
  />
);
