import React from 'react';

import ImgEmptyState from '+assets/img/dashboard/empty-state.png';
import SearchImgEmptyState from '+assets/img/dashboard/empty-state-search.svg';

interface IEmptyStateComponentProps {
  heading?: string | null;
  message?: React.ReactNode | string | null;
  searching?: boolean;
  screenHeight?: boolean;
}
export default function EmptyStateComponent({ heading, message, searching, screenHeight }: IEmptyStateComponentProps) {
  return (
    <div className="element-box-tp">
      <div className={`empty-state-box mt-4 ${screenHeight ? 'full-screen' : ''}`}>
        <div className="empty-state-content">
          <div className="empty-state-image">
            <img alt="Nothing" src={searching ? SearchImgEmptyState : ImgEmptyState} loading="lazy" />
          </div>
          {heading && <p className="heading">{heading}</p>}
          <p>{message || 'It looks like there are no results yet.'}</p>
        </div>
      </div>
    </div>
  );
}
