import React, { InputHTMLAttributes } from 'react';
import UploadIcon from '+assets/img/dashboard/round-upload.svg';
import CancelIcon from '+assets/img/dashboard/upload-cancel.svg';
import PdfIcon from '+assets/img/dashboard/pdf-file-icon.svg';
import './fileUploader.scss';

type Props = {
  text?: string;
  maxFileSizeMb?: number;
  infoText?: string;
  fileName?: string;
  uploaded?: boolean;
  uploading?: boolean;
  clearUploads?: () => unknown;
} & InputHTMLAttributes<HTMLInputElement>;

export default function FileUploader({
  text = 'Browse file to upload',
  maxFileSizeMb = 20,
  infoText = `Max file size ${maxFileSizeMb}MB`,
  uploaded = false,
  uploading = false,
  onChange = () => null,
  clearUploads = () => null,
  fileName = '',
  ...otherInputProps
}: Props) {
  return (
    <div className={`file-input-wrapper ${uploaded && 'uploaded'}`}>
      <label className="file-input">
        {uploaded ? (
          <>
            <img src={PdfIcon} className="icon" title="file" />
            <span className="file-name">{fileName}</span>
            <img
              src={CancelIcon}
              className="icon cancel-icon"
              title="cancel uploads"
              onClick={(e) => {
                e.preventDefault?.();
                clearUploads();
              }}
            />
          </>
        ) : (
          <>
            <img src={UploadIcon} className="icon" title="upload" />
            <span>{text}</span>
            <input type="file" style={{ display: 'none' }} onChange={onChange} {...otherInputProps} />
          </>
        )}
      </label>
      <div className={`bottom-info-row ${uploaded && !uploading && 'hidden'}`}>
        <p className="info-text">{infoText}</p>
        {uploading && <p className="info-text">Uploading...</p>}
      </div>
    </div>
  );
}
