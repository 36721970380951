import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import APIRequest from '+services/api-services';
import { useFeedbackHandler, useFileUpload } from '+hooks';
import { ChargebackActionsType } from '+types';
import { backwardAmountInput, chargebackStatusTagConfig, cleanInput, formatAmount } from '+utils';
import Modal, { IModalProps } from '+shared/Modal';
import FileUploader from '+shared/FileUploader';
import {
  ChargebackStatusType,
  IChargebackActionModal,
  NonConfirmActionType,
  CurrencyType,
  ChargebackUpdatePayloadType,
  ChargebackActionType
} from '+types';

import {
  chargebackReasonOptions,
  declareInvalidStatusOptions,
  initialValues,
  updateEscalationStatusOptions,
  updateReescalationStatusOptions,
  validate
} from './constants';
import { queryKeys } from '../data';

const api = new APIRequest();

const initialFileState = {
  evidence: '',
  fileName: '',
  isUploading: false
};

export default function ChargebackActionModal({ action, close, chargebackDetails, refetchTransaction }: IChargebackActionModal) {
  const [modalStep, setModalStep] = useState<ChargebackActionType>(action);
  const { uploadFiles } = useFileUpload();
  const { status: currentChargebackStatus, currency, chargebackAmount, reference: chargebackId, acceptedAmount } = chargebackDetails;

  const availableStatusOptions =
    currentChargebackStatus === 'processing_pre_arbitration' ? updateReescalationStatusOptions : updateEscalationStatusOptions;

  const invalidStatusOption =
    currentChargebackStatus === 'pending_pre_arbitration' ? declareInvalidStatusOptions[1] : declareInvalidStatusOptions[0];

  const [fileState, setFileState] = useState(initialFileState);

  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation((payload: ChargebackUpdatePayloadType) => api.updateIssuanceChargebackStatus(chargebackId, payload), {
    onSuccess: () => {
      resetForm({ values: initialValues });
      setFileState(initialFileState);
      queryClient.invalidateQueries([queryKeys.CARD_DISPUTES]);
    },
    onError: (error) => {
      feedbackInit({
        message: error.response?.data?.message || 'We are sorry, we could not process the chargeback right now. Please try again',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const goToPrevStageOrClose = () => {
    if (modalStep === 'confirmDeclareInvalid') {
      setModalStep('declareInvalid');
      return;
    }

    if (modalStep === 'confirmUpdateStatus') {
      setModalStep('updateStatus');
      return;
    }

    if (modalStep === 'confirmRefund') {
      setModalStep('refundChargeback');
      return;
    }

    close();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const [file] = e.target.files || [];
    const { size, type } = file;
    const isValidFileType = ['application/pdf', 'image/jpeg'].includes(type);
    let error = '';

    if (!isValidFileType) {
      error = 'Please upload a valid file type';
      handleUploadError(error);
      return;
    }

    error = checkFileSize(size) as string;

    if (error) {
      handleUploadError(error);
      return;
    }

    handleFileUpload(file);
  };

  const checkFileSize = (fileSize: number) => {
    const maxFileSize = +process.env.REACT_APP_CARD_ISSUANCE_MAX_FILE_SIZE_IN_MB!;
    const calculatedFileSize = Math.floor(fileSize / 1024 ** 2);
    if (calculatedFileSize > maxFileSize) return `Please upload a file less than ${maxFileSize}MB in size`;
    return;
  };

  const handleFileUpload = async (file: File) => {
    try {
      setFileState((prev) => ({
        ...prev,
        isUploading: true
      }));
      const response = await uploadFileToServer(file);
      setFileState((prev) => ({
        ...prev,
        evidence: response[0]?.path,
        fileName: response[0]?.original_name,
        isUploading: false
      }));
      setFieldValue('evidence', response[0]?.path);
    } catch (error) {
      setFileState((prev) => ({
        ...prev,
        evidence: '',
        fileName: '',
        isUploading: false
      }));
      handleUploadError(error as string);
    }
  };

  const uploadFileToServer = async (file: File) => {
    const newFormData = new FormData();
    newFormData.append('files', file);
    const { data: response } = await uploadFiles(newFormData);
    return response;
  };

  const handleUploadError = (error: string) => {
    if (error) {
      feedbackInit({
        message: typeof error === 'string' ? error : 'There has been an error uploading your file',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  };

  const { values, touched, errors, submitForm, getFieldProps, setFieldValue, handleBlur, resetForm, dirty, isValid, validateForm } =
    useFormik({
      initialValues,
      validate: (formValues) =>
        validate({
          formValues,
          step: modalStep as NonConfirmActionType,
          currency: currency as CurrencyType,
          chargebackAmount: chargebackAmount as string,
          prevAcceptedAmount: acceptedAmount
        }),
      onSubmit: () => {
        switch (modalStep) {
          case 'declareInvalid':
            return setModalStep('confirmDeclareInvalid');
          case 'confirmDeclareInvalid':
            return declareChargebackInvalid();
          case 'processChargeback':
            return processChargeback();
          case 'updateStatus':
            return setModalStep('confirmUpdateStatus');
          case 'confirmUpdateStatus':
            return updateChargebackStatus();
          case 'refundChargeback':
            return setModalStep('confirmRefund');
          default:
            return;
        }
      }
    });

  useEffect(() => {
    validateForm();
  }, [values.newStatus]);

  useEffect(() => {
    let newStatus;
    if (modalStep === 'processChargeback') {
      if (currentChargebackStatus === 'pending') newStatus = 'processing';
      if (currentChargebackStatus === 'pending_pre_arbitration') newStatus = 'processing_pre_arbitration';
      setFieldValue('newStatus', newStatus);
    }

    if (modalStep === 'declareInvalid') {
      setFieldValue('newStatus', invalidStatusOption.value);
    }
  }, [modalStep]);

  const newStatusIsPartiallyAccepted = ['partially_accepted', 'partially_accepted_pre_arbitration'].includes(values.newStatus);

  const newStatusIsDeclined = ['declined', 'declined_pre_arbitration'].includes(values.newStatus);

  const requiredInputsAreValid = dirty && isValid;

  const declareChargebackInvalid = async () => {
    const { reason, description } = values;
    await mutateAsync({ status: invalidStatusOption.value, reason, description });
  };

  const processChargeback = async () => {
    await mutateAsync({ status: values.newStatus });
  };

  const updateChargebackStatus = async () => {
    const sharedParams = {
      status: values.newStatus,
      evidence: fileState.evidence
    };

    if (newStatusIsDeclined) {
      await mutateAsync({
        ...sharedParams,
        reason: values.reason
      });
      return;
    }

    if (newStatusIsPartiallyAccepted) {
      await mutateAsync({
        ...sharedParams,
        accepted_amount: values.amountAccepted,
        declined_amount: values.amountDeclined
      });
      return;
    }

    await mutateAsync({ status: sharedParams.status });
  };

  const declareInvalidContent = (
    <div>
      <div className="mb-3">
        <label htmlFor="status" className="semibold">
          Chargeback status
        </label>
        <select className="form-control" id="status" {...getFieldProps('newStatus')} style={{ pointerEvents: 'none' }} tabIndex={-1}>
          <option value={invalidStatusOption?.value}>{invalidStatusOption?.label}</option>
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="reason" className="semibold">
          Reason for declaring chargeback invalid
        </label>
        <select className="form-control" id="reason" {...getFieldProps('reason')}>
          {chargebackReasonOptions.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>

        {touched.reason && errors.reason && (
          <div className="input__errors">
            <span>{errors.reason}</span>
          </div>
        )}
      </div>

      <div>
        <label htmlFor="description" className="semibold">
          Description
        </label>
        <textarea
          rows={2}
          maxLength={200}
          id="description"
          className="form-control"
          {...getFieldProps('description')}
          placeholder="Tell us why you want to declare this chargeback invalid"
        />
        {touched.description && errors.description && (
          <div className="input__errors">
            <span>{errors.description}</span>
          </div>
        )}
      </div>
    </div>
  );

  const updateStatusContent = (
    <div>
      <div className="mb-3">
        <label htmlFor="current-status" className="semibold">
          Current chargeback status
        </label>
        <select className="form-control" id="current-status" style={{ pointerEvents: 'none' }} tabIndex={-1}>
          <option value={currentChargebackStatus}>
            {chargebackStatusTagConfig[currentChargebackStatus as ChargebackStatusType]?.name}
          </option>
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="new-status" className="semibold">
          New chargeback status
        </label>
        <select
          className="form-control"
          id="new-status"
          name="newStatus"
          value={values.newStatus}
          onChange={(e) => {
            resetForm({ values: initialValues });
            setFileState(initialFileState);
            setFieldValue('newStatus', e.target.value);
          }}
          onBlur={handleBlur}
        >
          {availableStatusOptions?.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>

        {touched.newStatus && errors.newStatus && (
          <div className="input__errors">
            <span>{errors.newStatus}</span>
          </div>
        )}
      </div>

      {newStatusIsPartiallyAccepted && (
        <>
          <div className="mb-3 fade-in">
            <label htmlFor="amount-accepted" className="semibold">
              Amount Accepted ({currency})
            </label>
            <input
              type="text"
              name="amountAccepted"
              id="amount-accepted"
              value={values.amountAccepted}
              onChange={(e) => {
                setFieldValue('amountAccepted', backwardAmountInput(cleanInput(e.target.value)));
                setFieldValue(
                  'amountDeclined',
                  String(parseFloat(chargebackAmount as string) - +backwardAmountInput(cleanInput(e.target.value)))
                );
              }}
              className="form-control"
              placeholder="Enter amount"
            />
            {values.amountAccepted && errors.amountAccepted ? (
              <div className="input__errors">
                <span>{errors.amountAccepted}</span>
              </div>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="amount-declined" className="semibold">
              Amount Declined ({currency})
            </label>
            <input
              type="text"
              name="amountDeclined"
              id="amount-declined"
              value={values.amountDeclined}
              onChange={(e) => {
                setFieldValue('amountDeclined', backwardAmountInput(cleanInput(e.target.value)));
                setFieldValue(
                  'amountAccepted',
                  String(parseFloat(chargebackAmount as string) - +backwardAmountInput(cleanInput(e.target.value)))
                );
              }}
              className="form-control"
              placeholder="Enter amount"
            />
            {values.amountDeclined && errors.amountDeclined ? (
              <div className="input__errors">
                <span>{errors.amountDeclined}</span>
              </div>
            ) : null}
          </div>
        </>
      )}

      {newStatusIsDeclined && (
        <div className="mb-3 fade-in">
          <label htmlFor="reason" className="semibold">
            Reason for decline
          </label>
          <textarea
            rows={2}
            maxLength={200}
            className="form-control"
            {...getFieldProps('reason')}
            placeholder="The issuing partner found evidence that the transaction was successful."
          />
          {touched.reason && errors.reason && (
            <div className="input__errors">
              <span>{errors.reason}</span>
            </div>
          )}
        </div>
      )}

      {(newStatusIsPartiallyAccepted || newStatusIsDeclined) && (
        <div className="fade-in">
          <label htmlFor="evidence" className="semibold">
            {`Submit documentary evidence that the issuing partner ${newStatusIsDeclined ? 'declined' : 'accepted'} this chargeback.`}
          </label>

          <span className="info-text">
            Add all supporting documents in one pdf file. preferably in A4 size for best result. After submitting, you will not be able to
            make anymore changes.
          </span>

          <div className="mt-4">
            <FileUploader
              id="evidence"
              name="evidence"
              onChange={handleFileChange}
              onBlur={handleBlur}
              clearUploads={() => {
                setFileState(initialFileState);
                setFieldValue('evidence', '');
              }}
              uploaded={!!fileState.evidence}
              fileName={fileState.fileName}
              uploading={fileState.isUploading}
              accept=".pdf, .jpeg, .jpg"
            />
            {touched.evidence && errors.evidence && (
              <div className="input__errors">
                <span>{errors.evidence}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const createRefundContent = (
    <div className="stack-md">
      <div className="panel">Note that requested Refunds are typically processed within 5 - 7 business days.</div>
      <div className="mb-3 stack-sm">
        <label htmlFor="refund-amount" className="semibold">
          Refund Amount ({currency})
        </label>
        <input
          type="text"
          name="refundAmount"
          value={values.refundAmount}
          onChange={(e) => setFieldValue('refundAmount', backwardAmountInput(cleanInput(e.target.value)))}
          id="refund-amount"
          className="form-control"
          placeholder="Enter amount"
        />
        <div className="flex text-sm" data-justify="between">
          <span>
            Minimum Amount:{' '}
            <span className={`text-bold ${values.refundAmount && +values.refundAmount! < +acceptedAmount! && 'text-error'}`} id="min">
              {currency} {formatAmount(acceptedAmount)}
            </span>
          </span>
          <span>
            Maximum Amount:{' '}
            <span className={`text-bold ${values.refundAmount && +values.refundAmount! > +acceptedAmount! && 'text-error'}`}>
              {currency} {formatAmount(acceptedAmount)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );

  const modalPropsOptions: Record<ChargebackActionType, Partial<IModalProps>> = {
    declareInvalid: {
      heading: 'Declare Chargeback Invalid',
      description: 'Fill in the details below to complete the process of proclaiming this chargeback invalid.',
      content: declareInvalidContent,
      secondButtonText: 'Submit',
      secondButtonActionIsTerminal: false
    },
    confirmDeclareInvalid: {
      heading: 'Declare Chargeback Invalid',
      description: 'Are you sure you want to declare this chargeback invalid? This action cannot be undone.',
      secondButtonColor: '#F32345',
      secondButtonText: 'Yes, submit',
      completedHeading: 'Chargeback Declared Invalid',
      completedDescription: 'This chargeback has been declared invalid successfully.',
      secondaryCompletedModal: true,
      completedActionText: 'Dismiss'
    },
    processChargeback: {
      heading: 'Process Chargeback',
      description: 'Are you sure you want to process this chargeback? This action cannot be undone.',
      secondButtonText: 'Yes, submit',
      completedHeading: 'Chargeback Processed',
      completedDescription: 'This chargeback has been processed successfully.',
      size: 'sm',
      secondaryCompletedModal: true,
      completedActionText: 'Dismiss'
    },
    updateStatus: {
      heading: 'Update Chargeback Status',
      description: 'Fill in the details below to change the status of this chargeback.',
      content: updateStatusContent,
      secondButtonText: 'Update',
      secondButtonActionIsTerminal: false
    },
    confirmUpdateStatus: {
      heading: 'Update Chargeback Status',
      description: 'Are you sure you want to update the status of this chargeback? This action cannot be undone.',
      secondButtonText: 'Yes, update',
      completedHeading: 'Chargeback Status Updated',
      completedDescription: 'This status of this chargeback has been updated successfully.',
      secondaryCompletedModal: true,
      completedActionText: 'Dismiss'
    },
    refundChargeback: {
      heading: 'Create New Refund',
      description: 'Provide the details required below to make a refund for this chargeback',
      secondButtonText: 'Refund',
      content: createRefundContent,
      secondButtonActionIsTerminal: false
    },
    confirmRefund: {
      heading: 'Confirm Refund',
      description: (
        <p>
          Are you sure you want to refund{' '}
          <span className="text-bold">
            {acceptedAmount} {currency}
          </span>{' '}
          for the selected chargeback?
        </p>
      ),
      secondButtonText: 'Yes, refund',
      completedHeading: 'Refund Creation successful',
      completedDescription: 'Your refund has been created and is being processed.',
      secondaryCompletedModal: true,
      completedActionText: 'Dismiss'
    }
  };
  return (
    <Modal
      size="md"
      close={() => {
        refetchTransaction();
        close();
      }}
      firstButtonAction={goToPrevStageOrClose}
      secondButtonAction={submitForm}
      secondButtonDisable={!requiredInputsAreValid}
      {...modalPropsOptions[modalStep]}
    />
  );
}
