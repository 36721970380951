import React, { useState } from 'react';

import { useClickOutside, useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';

import DropdownIcon from '+assets/img/dashboard/down.svg';
import SettingsIcon from '+assets/img/dashboard/settings.svg';

export const ActionModalType = {
  updateMerchantDetails: 'update-merchant-details',
  changeRisk: 'change-risk',
  suspendMerchant: 'suspend-merchant',
  enableSentinal: 'enable-sentinal',
  disableSentinal: 'disable-sentinal',
  deactivateMerchant: 'deactivate-merchant',
  activateMerchant: 'activate-merchant',
  revokeMerchant: 'revoke-merchant',
  rejectMerchant: 'reject-merchant',
  updateMerchantDetailsConfirm: 'update-merchant-details-confirm',
  feedBackModel: 'feedback-modal',
  unSentModal: 'unsent-feedback',
  sendFeddback: 'send-feedback',
  verifyMerchant: 'verify-merchant',
  approveAccount: 'approve-account',
  rejectAccount: 'reject-account',
  confirmRiskChange: 'confirm-risk-change',
  confirmRejectMerchant: 'confirm-reject-merchant',
  viewBvn: 'view-bvn'
};
interface IActionModalProps {
  setModal?: () => void;
  details?: Record<string, any>;
}

const ActionModal = ({ setModal = () => null, details }: IActionModalProps) => {
  const [actionModal, setActionModal] = useState(false);
  const userAccess = useSetUserAccess();
  const wrapperRef = useClickOutside(() => {
    setActionModal(false);
  });

  const sentinalStatus = {
    undefined: 'choose-merchant-class',
    true: 'disable-sentinal',
    false: 'enable-sentinal'
  };

  return (
    <div ref={wrapperRef}>
      {isAllowed(userAccess, ['merchant_status.update']) ? (
        <button
          type="button"
          className="ellipsis__icon"
          data-testid="manage-merchant"
          onClick={() => {
            setActionModal(value => !value);
          }}
        >
          <div className="header-actions">
            <img alt="" src={SettingsIcon} />
            <span>Manage Merchant</span>
            <img alt="" src={DropdownIcon} />
          </div>
        </button>
      ) : (
        ''
      )}

      {actionModal && (
        <ul className="element-box ellipsis__nav merchant-ellipsis">
          {isAllowed(userAccess, ['merchants_information.update']) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.updateMerchantDetails });
                setActionModal(false);
              }}
            >
              <span>Edit Profile</span>
            </li>
          )}
          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModal({ modalType: ActionModalType.changeRisk });
              setActionModal(false);
            }}
          >
            <span>Change Risk Rating</span>
          </li>
          {details?.status === 'active' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.suspendMerchant });
                setActionModal(false);
              }}
            >
              <span>Suspend Merchant</span>
            </li>
          )}

          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModal({ modalType: sentinalStatus[details.sentinalEnabled] });
              setActionModal(false);
            }}
          >
            <span>{details.sentinalEnabled ? 'Deactivate' : 'Activate'} Sentinal</span>
          </li>

          {details?.status === 'active' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.deactivateMerchant });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#F32345', fontWeight: 600 }}>Deactivate Merchant</span>
            </li>
          )}

          {['deactivated', 'unsupported'].includes(details?.status) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.activateMerchant });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#2376F3', fontWeight: 600 }}>Activate Merchant</span>
            </li>
          )}
          {details?.status === 'suspended' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.revokeMerchant });
                setActionModal(false);
              }}
            >
              <span style={{ color: '#2376F3', fontWeight: 600 }}>Revoke Suspension</span>
            </li>
          )}
          {details?.status === 'active' && !['rejected', 'verified'].includes(details?.kyc_status) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: ActionModalType.rejectMerchant });
                setActionModal(false);
              }}
            >
              <span>Reject Merchant KYC</span>
            </li>
          )}
          {isAllowed(userAccess, ['merchant_kyc_status.update']) && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModal({ modalType: 'update-merchant-tier' });
                setActionModal(false);
              }}
            >
              <span>Update Merchant Tier</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ActionModal;
