import { Nullable } from '+types';

import { switchTrxnMessage } from './transaction';

export const cleanInput = (value: string): string => `${value}`.replace(/[<>%$]/gi, '');
export const stripNonNumeric = (value: string) => `${value}`.replace(/\D/g, '');
export const advanceCleanInput = (value: string): string => `${value}`.replace(/[!<>%$?!&^()"'{}[|`~#%*_=+@;\]\n\r\\]/gi, '');
export const capitalizeAllCharacters = (text: string): string => text?.toUpperCase();
export const camelCaseToWords = (text: string): string => {
  const result = text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
export const masklast4digits = (text: string): string => text.replace(/\d(?=\d{4})/g, '*');
export const maskBetweenSetRange = (text, startValue, endValue) =>
  `${text}`.slice(0, startValue) + `${text}`.slice(startValue, endValue).replace(/[A-Za-z0-9]/g, '*') + `${text}`.slice(endValue);

export const capitalizeFirst = (text: string): string => text.charAt(0)?.toUpperCase() + text.slice(1).toLowerCase();
export const truncateString = (string = '', maxLength = 50): string => {
  if (string === 'Not Available') return string;
  return string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;
};

export const backwardAmountInput = (value: Nullable<string>): string | boolean => {
  const formatted = `${value}`.replace(/[A-Z.]/gi, '');
  if (formatted === '') return '';
  const first = formatted.substring(0, formatted.length - 2);
  const second = formatted.substring(formatted.length - 2);
  if (!second) {
    const number = parseInt(first, 10);
    if (!Number.isNaN(formatted)) return number.toFixed(2);
    return false;
  }
  return `${first}.${second}`;
};

export const formatAmount = (value: string | number, isCurrency = true): string => {
  const formattedValue = isCurrency ? parseFloat(`${value}`).toFixed(2) : value;
  return `${formattedValue}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const capitalize = (text: string): string => {
  if (!text) return '';
  return text
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeRemovedash = (text: string): string => {
  if (!text) return '';
  return text
    .split('_')
    .flatMap((x: string) => x.split('-'))
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const lowercaseRemovedash = (text: string): string => {
  if (!text) return '';
  return text
    .toLowerCase()
    .split('_')
    .flatMap((x: string) => x.split('-'))
    .join(' ');
};

export const formatProductConfigStatus = (status: string): keyof typeof switchTrxnMessage => {
  const formattedStatus = status === 'true' ? 'enabled' : 'disabled';
  return formattedStatus;
};

export const convertCamelToTitleCase = (text = ''): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const renameObjectKeys = (params: Record<string, string> = {}, paramsToChange: Record<string, string> = {}): typeof params => {
  const newParams = { ...params };
  Object.keys(paramsToChange).forEach(key => {
    if (params[key]) {
      newParams[paramsToChange[key]] = params[key];
    }
    delete newParams[key];
  });
  return newParams;
};

export const formatAllTime = (value: string): string | undefined => {
  if (value === 'all_time') {
    return undefined;
  }
  return value;
};

export const getOrdinal = (num?: number) => {
  if (num === undefined) return '--';

  if (num % 100 >= 11 && num % 100 <= 13) {
    return `${num}th`;
  }

  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};
export const formatWithCommas = (values: string | number | undefined): string => {
  if (!values) return '';
  return values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
