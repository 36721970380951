import { useQueryClient } from 'react-query';

const useQueryStorage = () => {
  const queryClient = useQueryClient();
  function storeQueryData(key, data) {
    queryClient.setQueryData(key, data);
  }

  function clearQueryData(key) {
    queryClient.setQueryData(key, null);
    queryClient.removeQueries([key]);
  }

  function refetchQueryData(key) {
    queryClient.invalidateQueries(key);
  }

  function getQueryData(key) {
    return queryClient.getQueryData(key);
  }

  return { storeQueryData, clearQueryData, refetchQueryData, getQueryData };
};

export default useQueryStorage;
