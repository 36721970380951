// This is in principle a hook actually and so would live in the hooks container
/* eslint-disable prefer-destructuring */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

import { Storage } from '+services/storage-services';
import useBulkActionStore from '+store/zustand';

export default function usePermission(matchRoutesToComponents) {
  const location = useLocation();

  const userToken = Storage.checkAuthentication();
  const [permissions, setPermissions] = useState([]);
  const [permissionsAccess, setPermissionsAccess] = useState({});

  const bulkInfo = useBulkActionStore(state => state.bulkInfo);
  const hasBulkInProgress = bulkInfo?.data?.some(action => action.status === 'in_progress');
  const hasDelayedProcesses = sessionStorage.getItem('delayedProcesses') !== null || sessionStorage.getItem('isUtility');

  const previousStrings = [];
  const processPermissionString = value => {
    let permission;
    const actualPermission = permissionsAccess[value] ? value : '';
    if (actualPermission) {
      permission = value.split('.')[0];
      if (previousStrings.includes(permission)) return '';
      previousStrings.push(permission);
      return permission;
    }
    return null;
  };

  const handleMultiplePermission = item => {
    let perm;
    Object.entries(matchRoutesToComponents).forEach(components => {
      if (components[0].includes(',')) {
        const splittedKeys = components[0].split(',');
        const result = splittedKeys.find(splittedKey => splittedKey === item);

        if (result) {
          perm = components[1];
        }
      }
      if (item === components[0]) {
        perm = components[1];
      }
      return '';
    });
    return perm;
  };

  const displayPermissions = value => {
    let routeLabel;
    switch (value) {
      case 'welcome':
        routeLabel = 'Home';
        break;
      case 'dashboard':
        routeLabel = 'Dashboard';
        break;
      case 'merchant':
        routeLabel = 'Merchants';
        break;
      case 'virtual_accounts':
        routeLabel = 'Virtual Accounts';
        break;
      case 'pay_ins':
        routeLabel = 'Pay-ins';
        break;
      case 'payouts':
        routeLabel = 'Payouts';
        break;
      case 'payment_reversals':
        routeLabel = 'Reversals';
        break;
      case 'settlements':
        routeLabel = 'Settlements';
        break;
      case 'settlement_payouts':
        routeLabel = 'Settlement Payouts';
        break;
      case 'disputes':
        routeLabel = 'Disputes';
        break;
      case 'webhooks':
        routeLabel = 'Webhook';
        break;
      case 'audit_logs':
        routeLabel = 'Audit Logs';
        break;
      case 'settings':
        routeLabel = 'Global Settings';
        break;
      case 'admin_users':
        routeLabel = 'Users';
        break;
      case 'utilities':
        routeLabel = 'Utilities';
        break;
      case 'card_issuance':
        routeLabel = 'Card Issuance';
        break;
      case 'currency_exchange':
        routeLabel = 'Currency Exchange';
        break;
      case 'transaction_config_list':
        routeLabel = 'Product Config';
        break;
      case 'bulk_payouts':
        routeLabel = 'Bulk Payouts';
        break;
      case 'paused':
        routeLabel = 'Paused Payments';
        break;
      case 'partner_funding':
        routeLabel = 'Partner Balance';
        break;
      case 'third_party_reports':
        routeLabel = 'Reports';
        break;
      case 'identity':
        routeLabel = 'Identity';
        break;
      default:
        routeLabel = value;
        break;
    }
    return routeLabel;
  };

  const detailRoute = {
    merchant_compliance_details: 'kyc-approval',
    merchant_general_details: 'merchant',
    merchant_balances: 'merchant',
    merchant_rolling_reserve_config: 'merchant',
    merchant_settlement_account: 'merchant',
    merchant_settlement_config: 'merchant',
    merchant_fee_config: 'merchant',
    merchant_teams: 'merchant',
    merchant_audit_logs: 'merchant'
  };

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      setPermissionsAccess(jwt?.permissions || {});
      setPermissions(Object.keys(jwt?.permissions || {}));
      Storage.setItem('access', jwt?.permissions || {});
    }
  }, [userToken]);

  const createRoutes = () => {
    const utilityRoute = hasBulkInProgress || hasDelayedProcesses ? ['utilities'] : [];
    const defaultRoutes = [
      'welcome',
      'merchant',
      'settings',
      'disputes',
      'card_issuance',
      'currency_exchange',
      'bulk_payouts',
      'partners_balance',
      'paused',
      'identity',
      ...utilityRoute
    ];
    const computeDefaultRoute = defaultRoutes.map(route => (
      <li
        className={
          location.pathname.includes(`/dashboard/${handleMultiplePermission(route)?.[0]}`) ||
          location.pathname === `/dashboard/${handleMultiplePermission(route)?.[0]}`
            ? 'selected'
            : ''
        }
      >
        <Link
          to={{
            pathname: `/dashboard/${handleMultiplePermission(route)?.[0]}`
          }}
        >
          <div className="icon-w">
            <div className={`os-icon ${handleMultiplePermission(route)?.[1]}`} />
          </div>
          <span>{displayPermissions(route)}</span>
        </Link>
      </li>
    ));

    const computedDefaultRouter = defaultRoutes.map(route => (
      <Route
        path={`/dashboard/${handleMultiplePermission(route)?.[0]}`}
        key={`/dashboard/${handleMultiplePermission(route)?.[0]}`}
        component={handleMultiplePermission(route)?.[2]}
      />
    ));
    const routes = {
      base: [...computeDefaultRoute],
      merchants: []
    };

    const router = [...computedDefaultRouter];

    const checkIfNestedRoute = route => {
      const [first, ...rest] = route.split('/');

      if (rest.length) {
        return first;
      }
      return 'base';
    };

    permissions.forEach(permission => {
      const updatedPermission = processPermissionString(permission);
      let routeType = 'base';

      if (!handleMultiplePermission(updatedPermission)) {
        return;
      }
      routeType = checkIfNestedRoute(handleMultiplePermission(updatedPermission)?.[0]);

      if (permissionsAccess[permission]) {
        if (routeType === 'base') {
          routes[routeType].push(
            <li
              className={
                location.pathname.includes(`/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}`) ||
                location.pathname === `/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}/home` ||
                location.pathname === `/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}`
                  ? 'selected'
                  : ''
              }
            >
              <Link
                to={{
                  pathname: `/dashboard/${handleMultiplePermission(updatedPermission)?.[0] || ''}`,
                  state: permissionsAccess
                }}
              >
                <div className="icon-w">
                  <div className={`os-icon ${handleMultiplePermission(updatedPermission)?.[1]}`} />
                </div>
                <span>{displayPermissions(updatedPermission)}</span>
              </Link>
            </li>
          );
        } else {
          routes[routeType].push(
            <li key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}>
              <Link
                to={{
                  pathname: `/dashboard/${matchRoutesToComponents[permission]?.[0]}`,
                  state: permissionsAccess
                }}
              >
                {displayPermissions(updatedPermission)}
              </Link>
            </li>
          );
        }
        if (handleMultiplePermission(updatedPermission)?.[0]) {
          router.push(
            <Route
              path={`/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}`}
              key={`/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}`}
              component={handleMultiplePermission(updatedPermission)?.[2]}
              location={{ state: permissionsAccess }}
            />
          );
        } else {
          router.push(
            <Route
              path={`/dashboard/${detailRoute[updatedPermission]}/:id`}
              key={`/dashboard/${handleMultiplePermission(updatedPermission)?.[0]}`}
              component={handleMultiplePermission(updatedPermission)?.[2]}
              location={{ state: permissionsAccess }}
            />
          );
        }
      }
    });
    return [routes, router];
  };

  const result = useMemo(() => createRoutes(), [permissionsAccess, location.pathname]);

  return result;
}
