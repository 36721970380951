import { Dispatch, useReducer } from 'react';

/**
 * @description This hook is used to manage state containing large object
 * @param {object} data the object to be updated by state
 * @returns [state, setState] the state allows you to access the object while setState allows you update the object
 * @example const [state, setState] = useReducerState({name: 'John Doe', age: 20});
 *
 *  updating state: setState({...state, name: 'Jane Doe'});
 *  accessing state: console.log(state.name);
 */

const useReducerState = <T>(data: T): [state: T, setState: Dispatch<Partial<T>>] => {
  const [state, setState] = useReducer(
    (prev: T, next: Partial<T>): T & Partial<T> => {
      return { ...prev, ...next };
    },
    {
      ...data
    }
  );

  return [state, setState];
};

export default useReducerState;
