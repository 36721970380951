/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { IUpdateRefundStatusModal, RefundStatusType, UpdateRefundStatusPayloadType } from '+types';
import { backwardAmountInput, cleanInput, formatAmount } from '+utils';

import { refundStatus } from './constants';

const api = new APIRequest();

type StepType = 0 | 1;

const UpdateRefundStatusModal = ({
  currentStatus,
  onClose,
  currency,
  acceptedAmount,
  chargebackAmount,
  refetch,
  refundId
}: IUpdateRefundStatusModal) => {
  const { feedbackInit } = useFeedbackHandler();
  const [step, setStep] = useState<StepType>(0);
  const [refundIsConfirmed, setRefundIsConfirmed] = useState(false);
  const refundIsPartiallyPaid = currentStatus === 'partially_paid';
  const floatedAcceptedAmount = parseFloat(acceptedAmount as string);
  const floatedChargebackAmount = parseFloat(chargebackAmount as string);
  const refundIsComplete = floatedChargebackAmount - floatedAcceptedAmount === 0;

  const formik = useFormik({
    initialValues: {
      status: '',
      amount: refundIsPartiallyPaid ? acceptedAmount : ''
    },
    validate: formValues => {
      const errors: Partial<Record<keyof typeof formValues, string>> = {};
      if (!formValues.status) errors.status = 'A new status is required';
      if (!formValues.amount) errors.amount = 'A refund amount is required';
      if (formValues.amount && parseFloat(formValues.amount as string) < floatedAcceptedAmount)
        errors.amount = `Refunded amount cannot be less than ${acceptedAmount} ${currency}`;
      if (formValues.amount && parseFloat(formValues.amount as string) > floatedAcceptedAmount)
        errors.amount = `Refunded amount cannot exceed ${acceptedAmount} ${currency}`;

      return errors;
    },
    onSubmit: async () => {
      switch (step) {
        case 0:
          setStep(1);
          break;
        case 1:
          await handleRefundStatusUpdate();
          break;
        default:
          break;
      }
    }
  });

  const getError = (key: keyof typeof formik.values) => (formik.touched[key] && formik.errors[key] ? formik.errors[key] : '');

  const goBackOrClose = () => {
    switch (step) {
      case 1:
        setStep(0);
        break;
      case 0:
      default:
        onClose();
    }
  };

  const mutate = useMutation((payload: UpdateRefundStatusPayloadType) => api.updateChargebackRefundStatus(refundId, payload), {
    onSuccess: () => {
      refetch();
    },
    onError: error => {
      feedbackInit({
        message: error.response?.data?.message || 'Refund Update failed',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const handleRefundStatusUpdate = async () => {
    await mutate.mutateAsync({ status: formik.values.status as RefundStatusType, amount: parseFloat(formik.values.amount as string) });
  };

  const StatusUpdationForm = (
    <div>
      <div>
        <label htmlFor="current_refund_status" className="font-weight-bold">
          Current refund status
        </label>
        <select className="form-control" id="current_refund_status" value={currentStatus as string} disabled>
          <option value={refundStatus.PENDING}>Pending </option>
          <option value={refundStatus.FULLY_PAID}>Paid</option>
          <option value={refundStatus.PART_PAID}>Paid (partially)</option>
        </select>
      </div>

      <div className="mt-4">
        <label htmlFor="new_refund_status" className="font-weight-bold">
          New refund status
        </label>
        <select
          {...formik.getFieldProps('status')}
          className="form-control"
          data-control-state={formik.touched.status && formik.errors.status ? 'error' : ''}
          id="new_refund_status"
          aria-describedby="refund-status-error"
        >
          <option>-- select status --</option>
          <option value={refundStatus.FULLY_PAID} hidden={currentStatus === refundStatus.FULLY_PAID || refundIsComplete}>
            Paid
          </option>
          <option
            value={refundStatus.PART_PAID}
            hidden={[refundStatus.FULLY_PAID, refundStatus.PART_PAID].includes(currentStatus as string) || !refundIsComplete}
          >
            Paid (partially)
          </option>
        </select>
        <span id="refund-status-error" aria-live="polite" className="mt-2 text-sm" data-text-state="error">
          {getError('status')}
        </span>
      </div>

      <div className="mt-4">
        <label htmlFor="amount_refunded" className="font-weight-bold">
          Amount refunded ({currency})
        </label>
        <input
          aria-describedby="refund-amount-error"
          name="amount"
          onBlur={formik.handleBlur}
          value={formik.values.amount}
          onChange={e => formik.setFieldValue('amount', backwardAmountInput(cleanInput(e.target.value)))}
          className="form-control"
          data-control-state={formik.touched.amount && formik.errors.amount ? 'error' : ''}
          id="amount_refunded"
          disabled={refundIsPartiallyPaid}
        />

        <span id="refund-amount-error" aria-live="polite" className="mt-2 text-sm" data-text-state="error">
          {getError('amount')}
        </span>
      </div>
    </div>
  );

  const StatusUpdateConfirmation = (
    <div style={{ gap: '0.5rem' }} className="d-flex align-items-baseline">
      <input id="confirm_refund" type="checkbox" checked={refundIsConfirmed} onChange={e => setRefundIsConfirmed(e.target.checked)} />
      <label htmlFor="confirm_refund" className="font-weight-bold">
        Yes, I understand the implications of this action
      </label>
    </div>
  );

  const modalPropsByStep: Record<StepType | 'shared', Partial<IModalProps>> = {
    shared: {
      close: onClose,
      size: 'md',
      secondButtonAction: formik.submitForm,
      firstButtonAction: goBackOrClose
    },
    '0': {
      heading: 'Update Status',
      description: 'Fill in the details below to change the status of this refund.',
      content: StatusUpdationForm,
      secondButtonDisable: !(formik.isValid && formik.dirty),
      secondButtonText: 'Next',
      secondButtonActionIsTerminal: false
    },
    '1': {
      size: 'sm',
      heading: 'Confirm refund status',
      description: 'Once you confirm, the refund status will be updated and cannot be undone.',
      content: StatusUpdateConfirmation,
      firstButtonText: 'Back',
      secondButtonDisable: !refundIsConfirmed,
      secondButtonActionIsTerminal: true,
      secondaryCompletedModal: true,
      completedDescription: 'The status of this refund has been updated successfully',
      completedActionText: 'Dismiss'
    }
  };

  const mergedModalProps = {
    ...modalPropsByStep.shared,
    ...modalPropsByStep[step]
  };

  return <Modal {...mergedModalProps} />;
};

export default UpdateRefundStatusModal;
