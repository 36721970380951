/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import useBulkActionStore from '+store/zustand';
import { CurrencyType, FileFormatType } from '+types';
import { APIDownload, capitalize, durationMonth, filteredOutObjectProperty, getDate, isAllowed, logError, queriesParams } from '+utils';
import useStore from '+zustandStore';

import { summaryInfo, switchTabData } from './data';

import './index.scss';

const api = new APIRequest();

type TabType = 'pending' | 'ready' | 'approved' | 'settled';
function SettlementHistory() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const bulkInfo = useBulkActionStore(state => state.bulkInfo);
  const completedAction = useBulkActionStore(state => state.completedAction);
  const setCompletedAction = useBulkActionStore(state => state.setCompletedAction);
  const { profile } = useStore(state => state);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const searchQuery = useSearchQuery();
  const queryClient = useQueryClient();
  const activeTab = (searchQuery.value.tab ?? 'pending') as TabType;
  const limit = searchQuery.value.limit ?? '10';
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const dateFrom = searchQuery.value.dateFrom ?? durationMonth(3)[0];
  const dateTo = searchQuery.value.dateTo ?? durationMonth(3)[1];

  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.status,
    queriesParams.dateFrom,
    queriesParams.dateTo,
    queriesParams.totalItems
  ];
  const sortingParams = {
    dateFrom,
    dateTo,
    status: [activeTab],
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const [controls, setControls] = useReducer((prev, next) => ({ ...prev, ...next }), {
    tableData: {},
    showLargeExportModal: false
  });

  const mapTypesToActions = {
    pending: 'settlement_approval',
    ready: 'settlement_approval',
    approved: 'settlement_processing'
  };

  const tabs = ['pending', 'ready', 'approved', 'settled'] as const;
  const buffer = [];
  const anyLoading = {} as { [key in TabType]: boolean };
  const getTabDetails = (tab: TabType) => buffer.find(data => data.type === tab);

  const { getDownload } = useLargeExportDownloader('Settlements');

  const { data: summaryData } = useQuery(
    ['SETTLEMENT_SUMMARY', activeCurrency],
    () => api.getSettlementSummary(activeCurrency as CurrencyType),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting merchant settlement summary',
          type: 'danger'
        });
      },
      retry: false
    }
  );

  useEffect(() => {
    getDownload();
  }, []);

  useEffect(() => {
    setControls({
      tableData: switchTabData(activeTab, activeCurrency)
    });
  }, [activeTab, activeCurrency]);

  const { data, isFetching } = useQuery(
    [`${activeTab.toUpperCase()}_SETTLEMENTS`, limit, sortingParams, activeCurrency],
    () => api.getSettlements(limit, [activeTab], sortingParams, activeCurrency),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the ${activeTab} settlements.`,
          type: 'danger'
        });
      }
    }
  );
  anyLoading[activeTab] = isFetching;
  buffer.push({ type: activeTab, ...data, data: data?.data?.data, paging: data?.data?.paging });

  const exportSettlements = async (
    format: FileFormatType,
    close: () => void,
    fieldsToExport: string | string[],
    settlementReferences: string[] | undefined
  ) => {
    try {
      const res = await api.exportSettlementsByTab(sortingParams, format, activeCurrency, activeTab, fieldsToExport, settlementReferences);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Settlements ${getTabDetails(activeTab).type} at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported{' '}
              <strong>{settlementReferences?.length ?? getTabDetails(activeTab)?.paging?.total_items} transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your settlements`,
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  useEffect(() => {
    if (completedAction && Object.values(mapTypesToActions).includes(completedAction)) {
      queryClient.invalidateQueries({
        queryKey: [
          `${(Object?.keys(mapTypesToActions) ?? [])
            .find(key => mapTypesToActions[key as keyof typeof mapTypesToActions] === completedAction)
            ?.toUpperCase()}_SETTLEMENTS`
        ]
      });
      setCompletedAction('');
    }
  }, [completedAction]);

  const summary = summaryData?.data || {};
  const tableDataKeys = Object.keys(controls.tableData?.fields?.({}).data || {});

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />
      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex settlement-tab">
          <ul className="nav nav-tabs settlement-nav">
            {tabs.map(tab => (
              <li className="nav-item" key={tab}>
                <button
                  onClick={() => {
                    searchQuery.setQuery(
                      { tab, currency: activeCurrency, page: '1', startingAfter: '', endingBefore: '', totalItems: '' },
                      true
                    );
                  }}
                  type="button"
                  tabIndex={0}
                  className={`nav-link ${activeTab === tab && 'active'}`}
                >
                  {capitalize(tab)}
                </button>
              </li>
            ))}
          </ul>
          <CurrencyPicker
            onChange={value => {
              searchQuery.setQuery(
                { currency: value, tab: activeTab, page: '1', startingAfter: '', endingBefore: '', totalItems: '' },
                true
              );
            }}
            className="settlement-history__currency-switch selectpicker"
            activeCurrency={activeCurrency}
            label={<strong>Currency:</strong>}
            bordered
            id="settlement-history__currency-switch"
          />
        </div>
      </section>

      <section className="history_summary_details settlement-summary">
        <section className="history_summary_types" style={{ justifyContent: 'unset', gap: '2rem' }}>
          <div style={{ minWidth: '30%' }}>
            <p>{summaryInfo(activeTab, activeCurrency, summary)?.label}</p>
            <p>{summaryInfo(activeTab, activeCurrency, summary)?.value}</p>
            <p>{summaryInfo(activeTab, activeCurrency, summary)?.description}</p>
          </div>
        </section>
      </section>

      <section className="element-box-tp mt-5">
        <Table
          hasBulkAction
          bulkAction={mapTypesToActions[activeTab as keyof typeof mapTypesToActions]}
          bulkState={bulkInfo?.data?.find(
            action => action.type === mapTypesToActions[activeTab as keyof typeof mapTypesToActions] && action.status === 'in_progress'
          )}
          header={null}
          className={controls.tableData?.className || ''}
          tableHeadings={tableDataKeys}
          loading={anyLoading[activeTab]}
          data={getTabDetails(activeTab)?.data}
          renderFields
          hasPagination
          annotation={controls.tableData?.annotations}
          rowKey={controls.tableData?.rowKey}
          rowURL={controls.tableData?.rowURL}
          checkBoxKey={controls.tableData?.rowKey}
          emptyStateHeading={controls.tableData?.emptyStateHeading || ''}
          tableWrapperClassName="table-responsive"
          emptyStateMessage={controls.tableData?.emptyStateMessage || ''}
          type={controls.tableData?.type}
          subType={`${activeTab}_settlements`}
          storedState={{
            activeCurrency
          }}
          filterExportAction={exportSettlements}
          filterActiveCurrency={activeCurrency}
          filterDefaultStatus={activeTab}
          filterKeywordPlaceholder="Search by Settlement IDs or Merchant Name ..."
          filterShowExport={isAllowed(userAccess, ['settlements.export']) as boolean}
          cursors={getTabDetails(activeTab)?.paging}
        >
          {controls.tableData?.fields}
        </Table>
      </section>
    </>
  );
}

export default SettlementHistory;
