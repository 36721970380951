import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { formatAmount, capitalize } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+zustandStore';
import AnalyticsGraph from './components/AnalyticsGraph';
import retry from '+assets/img/dashboard/retry.svg';
import './index.scss';

const api = new APIRequest();

export default function Home() {
  const {
    profile: { email }
  } = useStore(state => state);
  const { feedbackInit } = useFeedbackHandler();
  const [processor, setProcessor] = useState('monnify');
  const errorDispatch = message =>
    feedbackInit({
      message,
      type: 'danger'
    });

  const feeBuffer = [];
  ['gross', 'net'].forEach(tab => {
    const { data: feeData } = useQuery(`${tab.toUpperCase()} FEE_ANALYTICS`, () => api.getFeeAnalytics(tab), {
      onError: () => errorDispatch(`There has been an error fetching fee analytics`)
    });
    feeBuffer.push(feeData);
  });

  const { data: balanceData, refetch: balanceRefetch } = useQuery(
    [`${processor.toUpperCase()}_BALANCE`, processor],
    () => api.getProcessorBalanceAnalytics(processor),
    {
      keepPreviousData: true,
      onError: () => {
        errorDispatch(`There has been an error fetching ${processor}'s balance.`);
        setProcessor('monnify');
      }
    }
  );

  const { data: signupData } = useQuery(`SIGNUP_ANALYTICS`, () => api.getSignupAnalytics(), {
    onError: () => errorDispatch(`There has been an error fetching sign up analytics.`)
  });

  return (
    <div className="content-i dashboard__home">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="pb-4" data-testid="greet-user">
              Holla, {capitalize(email?.slice(0, -12) || '')}.{' '}
              <span role="img" aria-label="Hi">
                &#128075;
              </span>
            </h3>
          </div>
        </div>
        <AnalyticsGraph />
        <div className="disbursements_balance">
          <div className="row">
            <div className="col-sm-12">
              <div className="element-box os-tabs-w mb-3">
                <div className="os-tabs-controls">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <span className="nav-link active disbursements_span">Disbursements Balance</span>
                    </li>
                  </ul>
                  <ul className="nav nav-pills smaller d-none d-sm-flex">
                    <div className="element-actions">
                      <form className="form-inline justify-content-sm-end">
                        <select
                          aria-label="Select Processor"
                          className="form-control form-control-sm rounded"
                          style={{ marginBottom: '8px', border: 'none' }}
                          onChange={e => setProcessor(e.target.value)}
                          value={processor}
                        >
                          <option value="monnify">Monnify</option>
                        </select>
                      </form>
                    </div>
                  </ul>
                </div>
                <div className="tab-c~ontent">
                  <div className="tab-pane active">
                    <section className="el-tablo --disbursment">
                      <div>
                        <div className="value">{formatAmount(balanceData?.availableBalance || 0)}</div>
                        <div className="label">Available (NGN)</div>
                      </div>
                      <button aria-label="Retry Button" type="button" onClick={() => balanceRefetch()}>
                        <img src={retry} alt="" />
                      </button>
                    </section>
                    <p className="ledger_balance">LEDGER BALANCE - {formatAmount(balanceData?.ledgerBalance || 0)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row vat_row_wrapper">
            <div className="col-sm-4 col-xxxl-3 vat_row">
              <section className="element-box el-tablo">
                <div className="value">{formatAmount(feeBuffer[0] || 0)}</div>
                <div className="label">GROSS FEES (NGN) (Excl. VAT)</div>
              </section>
            </div>
            <div className="col-sm-4 col-xxxl-3 vat_row">
              <section className="element-box el-tablo">
                <div className="value">{formatAmount(feeBuffer[1] || 0)}</div>
                <div className="label">NET FEES (NGN) (Excl. VAT)</div>
              </section>
            </div>
            <div className="col-sm-4 col-xxxl-3 vat_row">
              <section className="element-box el-tablo">
                <div className="value">{signupData || 0}</div>
                <div className="label">SIGNUPS (Count)</div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
