import React from 'react';
import { history, getDate, getTime } from '+utils';
import Modal from '+shared/Modal';


interface IWebhooksDetailsModalProps {
  close: () => void;
  webhook: Record<string, any>;
  savedState: Record<string, any>;
  navigateToPayInOrPayoutDetailPage: (type: string, reference: string) => void;
}
export default function WebhooksDetailsModal({
  close,
  webhook,
  savedState,
  navigateToPayInOrPayoutDetailPage
}: IWebhooksDetailsModalProps) {
  const switchStatus = status => {
    switch (status) {
      case 'delivered':
        return 'green';
      case 'failed':
        return 'red';
      case 'processing':
        return 'yellow';
      default:
        return 'light-grey';
    }
  };

  const WebhooksContent = () => (
    <div className="webhooks__modal">
      <ul className="webhooks__details-grid">
        <li>
          <p className="details--header">Status</p>
          <p className="details--content --flex">
            <span className={`status-pill smaller ${switchStatus(webhook.status)}`} />
            <span className="status--text">{webhook.status}</span>
          </p>
        </li>
        <li>
          <p className="details--header">Response code</p>
          <p className="details--content --flex">
            {webhook?.response_code ? (
              <span className={`status--code ${switchStatus(webhook.status)}`}>{webhook.response_code}</span>
            ) : (
              <span className="--italic">awaiting...</span>
            )}
          </p>
        </li>
        <li>
          <p className="details--header">Merchant</p>
          <p className="details--content">
            <span>{webhook?.merchant?.name || 'Not Available'}</span>
          </p>
        </li>
        <li>
          <p className="details--header">Type</p>
          <p className="details--content">
            <span>{webhook.type === 'payin' ? 'Pay-in' : 'Payout'}</span>
          </p>
        </li>
        <li>
          <p className="details--header">Triggered at</p>
          <p className="details--content">
            <span>
              {getDate(webhook.webhook_date)}
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(webhook.webhook_date)}
              </span>
            </span>
          </p>
        </li>
        <li>
          <p className="details--header">Last attempt at</p>
          <p className="details--content">
            <span>
              {getDate(webhook.last_attempt)}
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(webhook.last_attempt)}
              </span>
            </span>
          </p>
        </li>
        <li className="full-list-item">
          <p className="details--header">Number of attempts</p>
          <p className="details--content">
            <span>{webhook?.attempts || 'Not Available'}</span>
          </p>
        </li>
        <li className="full-list-item">
          <p className="details--header">URL</p>
          <p className="details--content">
            <span>{webhook?.webhook_url || 'Not Available'}</span>
          </p>
        </li>
        <li className="full-list-item">
          <p className="details--header">Transaction ID</p>
          <p className="details--content">
            <button
              onClick={() => navigateToPayInOrPayoutDetailPage(webhook.type, webhook.transaction_reference)}
    
              type="button"
              className="trxn-id"
            >
              {webhook?.transaction_reference || 'Not Available'}
            </button>
          </p>
        </li>
      </ul>
    </div>
  );

  return (
    <Modal
      close={close}
      size="md"
      heading={webhook?.transaction_reference?.toUpperCase()}
      showButtons={false}
      content={<WebhooksContent />}
    />
  );
}

