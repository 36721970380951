import deleted from '+assets/img/dashboard/deleted.svg';
import { useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useBulkActionStore from '+store/zustand';
import { formatAmount } from '+utils';
import React from 'react';
import { useQuery } from 'react-query';
import LoadingPlaceholder from '../LoadingPlaceHolder';
import './index.scss';

const api = new APIRequest();

type Stage = 'process_single' | 'process_multiple' | 'cancel_single' | 'cancel_multiple';

type TPausedPaymentsModalContent = {
  stage: Stage;
  refs?: string[];
};
type TData = { unique_reference: string; amount: string };
type TSummary = { currency: string; totalAmount: number; totalCount: number };

const messages = (activeCurrency: string) => ({
  process_single: `Are you sure that you want to process this paused ${activeCurrency} payment? Please confirm that there is enough liquidity before you do this.`,
  process_multiple: `Are you sure that you want to process all paused ${activeCurrency}  payments? Please confirm that there is enough liquidity before you do this.`,
  cancel_single: `Are you sure that you want to cancel this paused ${activeCurrency}  payment? This action cannot be undone.`,
  cancel_multiple: `Are you sure that you want to cancel all paused ${activeCurrency}  payments? This action cannot be undone.`
});

export const mapContentToAction = {
  process_single: {
    heading: 'Process This Paused Payment?',
    secondButtonText: 'Yes, Process',
    secondButtonAction: (arg: () => void) => arg,
    completedDescription: 'You have successfully processed this paused payment.'
  },
  cancel_single: {
    heading: 'Cancel This Paused Payment?',
    secondButtonText: 'Yes, Cancel',
    secondButtonColor: 'red',
    secondButtonAction: (arg: () => void) => arg,
    completedDescription: 'You have successfully cancelled this paused payment.',
    completedImage: deleted,
    completedHeading: 'Cancelled'
  },
  process_multiple: {
    heading: 'Process These Paused Payments?',
    secondButtonText: 'Yes, Process',
    completedDescription: 'You have successfully processed these paused payments.',
    secondButtonAction: (arg: () => void) => arg
  },
  cancel_multiple: {
    heading: 'Cancel These Paused Payments?',
    secondButtonText: 'Yes, Cancel',
    secondButtonColor: 'red',
    completedDescription: 'You have successfully cancelled these paused payments.',
    completedImage: deleted,
    completedHeading: 'Cancelled',
    secondButtonAction: (arg: () => void) => arg
  }
};

export const PausedPaymentsModalContent: React.FC<TPausedPaymentsModalContent> = ({ stage, refs }) => {
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'USD';
  const bulkInfo = (useBulkActionStore((state: any): TData[] => state.bulkInfo?.data) || []).filter((payment) =>
    refs?.includes(payment.unique_reference)
  );

  const { data, isLoading } = useQuery([], () => api.getPausedSummary(), {
    enabled: !refs?.length
  });

  const summary = !refs?.length ? data?.data?.find((datum: TSummary) => datum.currency === activeCurrency) : {};

  const amount = summary?.totalAmount || bulkInfo.reduce((prev, next) => prev + +next.amount, 0);

  if (isLoading) {
    return (
      <div style={{ overflow: 'hidden' }}>
        <LoadingPlaceholder type="text" />
      </div>
    );
  }

  return (
    <>
      <p>{messages(activeCurrency)[stage]}</p>
      <section className="form-summary">
        <p>Some important details of your transaction:</p>
        <div className="form-summary-item">
          <span>Total Amount:</span>
          <span>
            {formatAmount(amount || 0)} {activeCurrency}
          </span>
        </div>
        <div className="form-summary-item">
          <span>Transaction Count:</span>
          <span>{summary?.totalCount || bulkInfo.length}</span>
        </div>
        <div className="form-summary-item">
          <span>Currency:</span>
          <span>{activeCurrency}</span>
        </div>
      </section>
    </>
  );
};
