import { create } from 'zustand';
import { combine, createJSONStorage, devtools, persist } from 'zustand/middleware';

import { IPartnerBalance } from '+types';
import { StoreStorage } from '+utils';

interface IPartnerBalanceState {
  partnerBalances: Record<number, IPartnerBalance>;
}

interface IPartnerBalanceAction {
  setPartnerBalances: (data: Record<number, IPartnerBalance>) => void;
}
const initialState = {
  partnerBalances: {}
} as IPartnerBalanceState;

const usePartnerBalanceStore = create(
  devtools(
    persist(
      combine<IPartnerBalanceState, IPartnerBalanceAction>(initialState, set => ({
        partnerBalances: {},
        setPartnerBalances: (data: Record<number, IPartnerBalance>) => {
          set(state => ({
            ...state,
            partnerBalances: {
              ...state.partnerBalances,
              ...data
            }
          }));
        }
      })),
      {
        name: 'partnerBalance',
        storage: createJSONStorage(() => StoreStorage)
      }
    )
  )
);

export default usePartnerBalanceStore;
