/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Table from '+shared/Table';
import { IApiResponse, IEvent, IRouteParams, Nullable } from '+types';
import { capitalizeRemovedash, durationMonth, durationToday, durationWeek, getDate, logError, specialisedDate } from '+utils';

import './index.scss';

type ITabsT = 'today' | 'this_week' | 'this_month' | 'all_time';

const api = new APIRequest();

export default function AuditLogs() {
  const { id } = useParams<IRouteParams>();
  const { feedbackInit } = useFeedbackHandler();

  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const activeTab = searchQuery.value.activeTab || 'all_time';

  const tabs: Record<ITabsT, Nullable<string>[]> = {
    today: durationToday(),
    this_week: durationWeek(),
    this_month: durationMonth(),
    all_time: [null, null]
  };

  const { data, isLoading, isFetching, refetch } = useQuery<IApiResponse>(
    [`MERCHANT_AUDIT_LOGS_${id}`, page, limit, ...tabs[activeTab as ITabsT]],
    () => api.getMerchantLogs(id, Number(page), Number(limit), ...(tabs[activeTab as ITabsT] as [string, string])),
    {
      keepPreviousData: true,
      onError: (error: unknown) => {
        logError(error);
        feedbackInit({
          message: `There has been an error getting activity for the specified time period`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const paging = data?.paging;
  const paginate = (currentPage: string | number) => {
    searchQuery.setQuery({ page: currentPage.toString() });
  };
  const renderEvents = (event: IEvent) => ({
    data: {
      timestamp: `${getDate(event.created_at)}, ${specialisedDate(event.created_at, 'hh:mm A')}`,
      event: event.description,
      eventId: event.event_id
    }
  });

  return (
    <div className="element-box">
      <div className="os-tabs-w mb-3">
        <div className="os-tabs-controls">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button type="button" data-toggle="tab" className="nav-link active" style={{ paddingBottom: '15px' }}>
                <span className="tab-label">Events</span>
              </button>
            </li>
          </ul>
          <ul className="nav nav-pills smaller d-none d-md-flex" style={{ paddingBottom: '10px' }}>
            {Object.keys(tabs).map(tab => (
              <li className="nav-item" key={tab}>
                <button
                  data-toggle="tab"
                  type="button"
                  onClick={() => searchQuery.setQuery({ activeTab: tab as ITabsT })}
                  className={`nav-link ${activeTab === tab && 'active'}`}
                >
                  {capitalizeRemovedash(tab)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_activity">
            <div className="table-responsive">
              {isLoading || isFetching ? (
                <LoadingPlaceholder type="table" background="#f5f6f6" content={0} />
              ) : (
                <Table
                  className="--audit-log-table"
                  hasPagination
                  header={false}
                  tableHeadings={['Timestamp', 'Event', 'Event ID']}
                  data={data?.data || []}
                  totalItems={paging?.total_items || 0}
                  pageSize={paging?.page_size || 0}
                  current={parseInt(page, 10)}
                  loading={isFetching}
                  limitAction={currentLimit => searchQuery.setQuery({ limit: currentLimit.toString() })}
                  renderFields
                  actionFn={paginate}
                  tableWrapperClassName="table-responsive"
                  annotation="actions"
                  emptyStateHeading="There are no logs yet"
                  hasFilter={false}
                  emptyStateMessage="You do not have any audit logs at the moment."
                >
                  {renderEvents}
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
