/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { useSearchQuery, useFeedbackHandler, useQueryStorage } from '+hooks';
import { logError } from '+utils';
import queryKeys from '+store/constants/queryKeys';
import CurrencyPicker from '+shared/CurrencyPicker';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import { TAccountLimits, TLimitsApiError } from './data';
import FixedVirtualAccounts from './FixedVirtualAccounts';
import './index.scss';

const api = new APIRequest();

function TransactionLimits() {
  const searchQuery = useSearchQuery();
  const currency = searchQuery.value.currency ?? 'GBP';

  const { feedbackInit } = useFeedbackHandler();
  const { getQueryData } = useQueryStorage();
  const currencies = getQueryData(queryKeys.currencies) as [{ code: string }];

  const { data, isLoading } = useQuery(['TRANSACTION_LIMITS', currency], () => api.getVirtualBankAccountLimits(currency), {
    onError: (error: TLimitsApiError) => {
      logError(error);
      feedbackInit({
        type: 'danger',
        message: error?.response?.data?.message || 'An error occurred while trying to fetch transaction limits'
      });
    }
  });

  return (
    <div className="element-box">
      <div className="element-box-heading settlements-settings__heading">
        <div className="heading-box-mmd">
          <h5 className="form-header">Transaction Limits Configuration</h5>
          <div className="form-desc no-underline mb-2">
            This is where you configure the transaction limits for all merchants. If you need to change the transaction limits for a
            specific merchant, simply go to the &apos;Transaction Limits&apos; tab in the details of that merchant.
          </div>
        </div>
      </div>
      <CurrencyPicker
        options={currencies?.map((currency: { code: string }) => currency.code)}
        onChange={(value) => {
          searchQuery.setQuery({ currency: value }, true);
        }}
        className="mt-2 mb-3"
        activeCurrency={currency}
        label={<strong>Set configuration for:</strong>}
        id="settlements-settings__currency-switch"
      />

      {isLoading && (
        <div className="mt-5">
          <LoadingPlaceholder type="text" />
        </div>
      )}
      {!isLoading && !data && <EmptyStateComponent heading="Transaction Limits" message="Unable to fetch Transaction Limits" />}
      {!isLoading && data && <FixedVirtualAccounts currentLimit={data.data as TAccountLimits} currency={currency} />}
    </div>
  );
}

export default TransactionLimits;
