import React from 'react';
import { ICheckboxInput } from "+types/identity";

export default function CheckboxInput({ label, value, id, data, onChange, borderTop, setChecked, disabled = false }: ICheckboxInput) {
    return (
      <div className={`iam-modal__form-group ${borderTop ? 'top-border' : ''}`}>
        <label htmlFor={id}>{label}</label>
        {data?.map((each) => (
          <div key={each.value} className="iam-modal__form-check">
            <input
              data-testid="checkbox"
              type="checkbox"
              id={each.value}
              name={each.value}
              onChange={(e) => {
                if (e.target.checked) {
                  setChecked([...(value as string[]), each.value]);
                } else {
                  setChecked((value as string[]).filter((value) => value !== each.value));
                }
              }}
              disabled={disabled}
            />
            <label className={disabled ? 'disabled' : ''} htmlFor={each.value}>
              {each.label}
            </label>
          </div>
        ))}
      </div>
    );
  }
  