import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { cleanInput } from '+utils';
import { PlanFormValuesType } from '../data';

type SingleDebitFormProps = {
  setButtonState: (state: boolean) => void;
  formValue: { [key: string]: string | string[] };
  setFormValues: Dispatch<SetStateAction<PlanFormValuesType>>;
};

const SingleDebitForm = ({ setButtonState, formValue, setFormValues }: SingleDebitFormProps) => {
  const [val, setVal] = useState(formValue?.uniqueId || '');
  useEffect(() => {
    setFormValues((prev) => ({ ...prev, uniqueId: val }));
    setButtonState(val.length > 15);
  }, [val]);
  return (
    <div className="form-group modal-content">
      <div className="form-group">
        <label htmlFor="uniqueId">
          <span className="dark">Unique ID (Transaction ID, Card ID)</span>
        </label>
        <input
          type="text"
          id="uniqueId"
          className="form-control"
          value={val}
          name="uniqueId"
          onChange={(e) => {
            const { value } = e.target;
            const cleanValue = cleanInput(value);
            setVal(cleanValue);
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="cardType">
          <span className="dark">Card Type</span>
        </label>
        <input type="text" id="cardType" className="form-control" value="Virtual Card" disabled />
      </div>
    </div>
  );
};

export default SingleDebitForm;
