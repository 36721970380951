import React from 'react';
import KpyLogo from '+assets/theme/img/kpy-logo.png';
import { history } from '+utils';
import './index.scss';


interface IErrorPageProps {
  clearError: () => void;
}

const ErrorPage = ({ clearError }: IErrorPageProps) => {
  return (
    <div className="error__wrapper">
      <div className="logo-container">
        <img src={KpyLogo} alt="Korapay-Logo" loading="lazy" />
      </div>
      <div className="error-content">
        <h1>Well this is unexpected</h1>
        <p className="description">
          An error has occurred and we’re working to fix the problem! We’ll be up and running shortly. Please
          <a href="/contact">Contact us</a>
          for immediate help
        </p>
      </div>
      <button
        className="btn-kpy --full-green"
        type="button"
        onClick={() => {
          if (clearError) clearError();
          history.goBack();
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default ErrorPage;
