/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import {
  capitalize,
  capitalizeRemovedash,
  getDate,
  getTime,
  formatAmount,
  APIDownload,
  logError,
  isAllowed,
  filteredOutObjectProperty,
  queriesParams
} from '+utils';
import { useFeedbackHandler, useSetUserAccess, useSearchQuery } from '+hooks';
import useBulkActionStore from '+store/zustand';
import Table from '+shared/Table';


const apiRequest = new APIRequest();
const payOutReversalStatus = {
  approved: 'approved',
  pending_approval: 'pending_approval',
  declined: 'declined',
  completeApprove: 'payout_reversal_approve',
  completeDecline: 'payout_reversal_decline'
};
const queryKeys = {
  PAYOUT_REVERSALS: 'PAYOUT_REVERSALS'
};
const Reversals = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const queryClient = useQueryClient();
  const completedAction = useBulkActionStore(state => state.completedAction);
  const setCompletedAction = useBulkActionStore(state => state.setCompletedAction);
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [queriesParams.limit, queriesParams.status, queriesParams.page])
  };

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery([queryKeys.PAYOUT_REVERSALS, page, limit, sortingParams], () => apiRequest.getPayoutReversals(page, limit, sortingParams), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Reversals',
        message: 'There has been an error getting payment reversals',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const exportPayouts = async (format, close, fieldsToExport) => {
    try {
      const res = await apiRequest.exportPayoutReversals(format, fieldsToExport, sortingParams);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Reversals Report for ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{paging?.total_items} transactions.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your reversals file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const switchStatus = {
    approved: {
      color: 'green',
      text: 'Reversed'
    },
    pending_approval: {
      color: 'yellow',
      text: 'Pending'
    },
    declined: {
      color: 'red',
      text: 'Declined'
    }
  };

  const reversals = {
    className: '--payout-reversal-table',
    rowURL: isAllowed(userAccess, ['payout_details.view']) ? '/dashboard/payouts' : '/dashboard/access-denied',
    rowKey: isAllowed(userAccess, ['payout_details.view']) ? 'transaction_reference' : '',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no payout reversals yet.',
    annotations: 'payout reversal(s)',
    fields: (iter: any) => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus[iter.status]?.color}`} style={{ flex: 'none' }} />
            <span>{capitalizeRemovedash(switchStatus[iter.status]?.text || iter.status || 'N/A')}</span>
          </>
        ),
        payout_id: <Copyable showOnHover text={iter?.reference?.toUpperCase()} />,
        'Initial Payout Reference': <span>{iter?.transaction_reference?.toUpperCase() || 'Not available'}</span>,
        merchant: <span className="merch-name">{capitalize(iter?.merchant || 'Not available')}</span>,
        'Date / Time': (
          <>
            <span>{getDate(iter?.reversal_date)}</span>
            <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
              {getTime(iter?.reversal_date)}
            </span>
          </>
        ),
        amount: (
          <span style={{ fontWeight: '500' }}>
            {formatAmount(iter?.refund_amount)}
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {iter.currency}
            </span>
          </span>
        )
      }
    })
  };

  const tableDataKeys: string[] = Object.keys(reversals.fields({}).data);

  const getTitle = (): string => {
    const filtered: boolean = Object.keys(sortingParams || {}).length > 0;
    return `${filtered ? 'Filtered' : 'All'} Payout Reversals`;
  };

  useEffect(() => {
    if (completedAction && Object.values(payOutReversalStatus).includes(completedAction)) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.PAYOUT_REVERSALS
      });
      setCompletedAction('');
    }
  }, [completedAction]);

  return (
    <div className="row">
      <div className="col-sm-12 reversals__page">
        <h6 className="element-header">{getTitle()}</h6>
        <Table
          showCheckbox
          checkboxStatusesToShow={[payOutReversalStatus.pending_approval]}
          checkBoxStatusesKey="status"
          checkBoxKey="reference"
          bulkAction={payOutReversalStatus.completeApprove}
          className={reversals.className || ''}
          loading={isFetching}
          data={data}
          renderFields
          hasPagination
          tableHeadings={tableDataKeys}
          annotation={reversals.annotations}
          current={parseInt(page, 10)}
          rowKey={reversals.rowKey}
          rowURL={reversals.rowURL}
          pageSize={paging?.page_size || 0}
          totalItems={paging?.total_items || 0}
          limitAction={(currentLimit) => searchQuery.setQuery({ limit: String(currentLimit) })}
          actionFn={(currentPage) => searchQuery.setQuery({ page: String(currentPage) })}
          emptyStateHeading={reversals.emptyStateHeading || ''}
          tableWrapperClassName="--reversal-table"
          emptyStateMessage={<p style={{ maxWidth: '200px', marginTop: '-20px' }}>There are no transactions for this account.</p>}
          type="payout_reversals"
          filterHasAdvancedFilter={false}
          filterQueryIDPlaceholder="Payout ID"
          filterExportAction={exportPayouts}
          filterTotalCount={paging?.total_items}
          filterShowExport={isAllowed(userAccess, ['payment_reversals.export'])}
          hideTable={data.length === 0}
        >
          {reversals.fields}
        </Table>
      </div>
    </div>
  );
};

export default Reversals;
