import React, { ChangeEvent } from 'react';

interface IInputProps {
  label: string;
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
}

const UpdateMerchantDetailsInput: React.FC<IInputProps> = ({
  label,
  name,
  placeholder = '',
  value,
  onChange,
  disabled = false,
  error = false,
  errorMessage
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="withdraw-label">
        <span className="dark">{label}</span>
      </label>
      <input
        type="text"
        className={`form-control ${error ? 'is-invalid' : ''}`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {error ? <span className="form-error">{errorMessage}</span> : null}
    </div>
  );
};

export default UpdateMerchantDetailsInput;
