import React from 'react';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import { useSearchQuery } from '+hooks';
import { CurrencyType } from '+types';

import CardIssuanceBilling from './CardIssuance';
import BillingCyclePanel from './CardIssuance/components/BillingCyclePanel';

import './index.scss';

const currencies = ['USD'];

export default function Billing() {
  const { setQuery, value } = useSearchQuery();
  const activeCurrency = (value?.currency ?? currencies[0]) as CurrencyType;

  const handleCurrencyChange = (arg: (typeof currencies)[number]) => {
    setQuery({ currency: arg });
  };

  return (
    <section className="billing-config-comp">
      <div className="stack-xl">
        <div className="stack-m">
          <div className="stack-m">
            <h5 className="main-title">Billing Configuration</h5>
            <p className="text-dark-100 main-subheading">
              The default billing configuration for all merchants can be adjusted here. These changes will apply to all merchants with
              access to the specified product.
            </p>
          </div>

          <div className="d-flex align-items-center">
            <span className="font-weight-bold">Set configuration for:</span>
            <CurrencyPicker options={currencies} onChange={handleCurrencyChange} activeCurrency={activeCurrency} />
          </div>
        </div>

        <div>
          <div className="mb-2 os-tabs-controls os-tabs-complex">
            <ul className="os-tabs-controls os-tabs-complex nav nav-tabs">
              <li className="nav-item">
                <button type="button" className="nav-link active tab-label">
                  Card Issuance
                </button>
              </li>
            </ul>
          </div>

          <div className="stack-xl">
            <BillingCyclePanel currency={activeCurrency} />

            <CardIssuanceBilling />
          </div>
        </div>
      </div>
    </section>
  );
}
