import React, { useEffect, useState, Dispatch, SetStateAction, LegacyRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { history, logError } from '+utils';
import useClickOutside from '+hooks/useClickOutside';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import InfoSvg from '+assets/img/dashboard/info-red.svg';
import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { TManageAccountModal } from '../AccountHoldersDetails/TypesAccountHolders';
import ManageAccForm from '../../Shared/ManageAccForm';
import './index.scss';

const apiRequest = new APIRequest();

type SetButtonStateFunction = (state: boolean) => void;

const reasons = [
  { label: '- Select an option -', value: '' },
  { label: 'Suspected Fraud', value: 'suspected_fraud' },
  { label: 'Data Breach', value: 'data_breach' },
  { label: 'Other', value: 'other' }
];

const ManageAccountModal = ({
  setModal,
  setButtonState,
  accountReference,
  accountStatus,
  accountHolderReference,
  trxId,
  modalType
}: {
  setModal: Dispatch<SetStateAction<TManageAccountModal>>;
  setButtonState: SetButtonStateFunction;
  accountReference: string;
  accountStatus: string;
  accountHolderReference: string;
  trxId: string;
  modalType: string | null;
}) => {
  if (modalType === 'reasonFeedback' || accountStatus === 'deactivated') return null;

  const { feedbackInit } = useFeedbackHandler();
  const [manageModal, setManageModal] = useState(false);
  const [reason, setReason] = useState<string>('');
  const [reasonText, setReasonText] = useState<string>('');
  const [actionType, setActionType] = useState<string>('');
  const queryClient = useQueryClient();

  const wrapperRef = useClickOutside(() => {
    setManageModal(false);
  });

  const { mutateAsync } = useMutation(() => apiRequest.manageVirtualBankAccount(accountReference, actionType, reasonText || reason), {
    onError: (error: { response: { data: { message: string } } }) => {
      logError(error);
      feedbackInit({
        message:
          error?.response?.data?.message ||
          `There has been an error ${
            actionType.endsWith('e') ? actionType.slice(0, -1) : actionType
          }ing this account number. Please try again later`,
        type: 'danger'
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`${trxId.toUpperCase()}_DETAILS`);
      queryClient.invalidateQueries(`${trxId?.toUpperCase()}_TRANSACTIONS`);
    }
  });

  const modalDetails = {
    suspend: {
      heading: 'Suspend Account Number',
      description:
        'Suspending an account number stops the customer from making payments into the account number. Transactions will be flagged and will not be settled',
      secondButtonText: 'Proceed',
      content: (
        <ManageAccForm
          headerText="Why are you suspending this account number?"
          options={reasons}
          setReason={setReason}
          setReasonText={setReasonText}
        />
      ),
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => setModalVisible('confirmSuspend')
    },
    confirmSuspend: {
      heading: (
        <p>
          Are you sure you want to suspend
          <br />
          this account number?
        </p>
      ),
      description:
        'Please confirm that you want to suspend this account number. This action blocks the account number thus transactions will not be settled',
      completedHeading: 'Done!',
      completedDescription: 'You have successfully suspended this account number',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Suspend',
      secondButtonAction: mutateAsync,
      size: 'sm'
    },
    deactivate: {
      heading: 'Deactivate Account Number',
      description: 'Please note that this account number would be deactivated immediately and will not be reactivated',
      secondButtonText: 'Deactivate',
      content: <ManageAccForm setReason={setReason} showDescription setReasonText={setReasonText} />,
      secondButtonActionIsTerminal: false,
      secondButtonColor: '#F32345',
      secondButtonAction: () => {
        setModalVisible('confirmDeactivate');
      }
    },
    confirmDeactivate: {
      heading: (
        <p>
          Are you sure you want to deactivate this
          <br />
          account number?
        </p>
      ),
      description: (
        <div className="deactivate">
          <img src={InfoSvg} alt="info" />
          Please note that this action is irreversible. Deactivated accounts can not be reactivated, users won&apos;t be able to carry out
          transactions with this account number.
        </div>
      ),
      completedHeading: 'Done!',
      completedDescription: 'You have successfully deactivated this account number',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Deactivate',
      secondButtonAction: mutateAsync,
      secondButtonColor: '#F32345',
      size: 'sm'
    },
    reactivate: {
      heading: 'Reactivate Account Number',
      description: 'Please note that all account numbers generated for this account number would be reactivated immediately.',
      secondButtonText: 'Reactivate',
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => {
        setModalVisible('confirmReactivate');
      },
      content: <ManageAccForm setReason={setReason} showDescription setReasonText={setReasonText} />
    },
    confirmReactivate: {
      heading: (
        <p>
          Are you sure you want to reactivate this
          <br />
          account number?
        </p>
      ),
      description: 'Please note that this action cannot be undone.',
      completedHeading: 'Done!',
      completedDescription: 'Your request to reactivate this account number was successful.',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Reactivate',
      secondButtonAction: mutateAsync,
      size: 'md'
    }
  } as unknown as Record<string, IModalProps>;

  const setModalVisible = (type: string) => {
    setModal({
      modalType: type,
      modalInfo: {
        heading: modalDetails[type]?.heading,
        description: modalDetails[type]?.description,
        completedHeading: modalDetails[type]?.completedHeading,
        completedDescription: modalDetails[type]?.completedDescription,
        content: modalDetails[type]?.content,
        secondButtonText: modalDetails[type]?.secondButtonText,
        secondButtonAction: modalDetails[type]?.secondButtonAction,
        secondButtonActionIsTerminal: modalDetails[type]?.secondButtonActionIsTerminal,
        size: modalDetails[type]?.size,
        secondButtonColor: modalDetails[type]?.secondButtonColor
      } as unknown as IModalProps
    });
  };

  useEffect(() => {
    if (reason === 'other' && !reasonText.trim()) setButtonState(false);
    else if (reasonText.trim() || reason.trim() || modalType?.includes('confirm')) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [reason, reasonText]);

  useEffect(() => {
    if (!modalType) {
      setReason('');
      setReasonText('');
    }
  }, [modalType]);

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>}>
      <div className="manage-account-modal">
        <button type="button" onClick={() => history.push(`/dashboard/virtual-accounts/account-holders/${accountHolderReference}`)}>
          <p>View Account Holder</p>
        </button>
        <button
          type="button"
          onClick={() => {
            setManageModal((value) => !value);
          }}
        >
          <i className="os-icon os-icon-settings" />
          &nbsp;
          <span>Manage Account Number</span>
          &nbsp;
          <i className="os-icon os-icon-arrow-down" />
        </button>
      </div>

      {manageModal && accountStatus !== 'suspended' && (
        <ul className="element-box ellipsis__nav user-ellipsis">
          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModalVisible('suspend');
              setActionType('suspend');
            }}
          >
            <span>Suspend Account Number</span>
          </li>

          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModalVisible('deactivate');
              setActionType('deactivate');
            }}
          >
            <span style={{ color: '#F32345', fontWeight: 600 }}>Deactivate Account Number</span>
          </li>
        </ul>
      )}

      {manageModal && accountStatus === 'suspended' && (
        <ul className="element-box ellipsis__nav user-ellipsis">
          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModalVisible('reactivate');
              setActionType('reactivate');
            }}
          >
            <span>Reactivate Account Number</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ManageAccountModal;
