import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IAccordionContext {
  onToggle: (arg: number) => void;
  isDescriptionVisible: (index: number, isOpen?: boolean) => boolean;
}

interface IAccordionSummary extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  index: number;
  isOpen?: boolean;
}

interface IAccordionDescription extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  index: number;
  isOpen?: boolean;
}

const FormAccordionContext = createContext<IAccordionContext | undefined>(undefined);

function useFormAccordionContext() {
  const context = useContext(FormAccordionContext);
  if (context === undefined) throw new Error(`FieldAccordion* must be used within FieldAccordion root`);
  return context;
}

const FormAccordion = ({ children, onToggleChange }: { children: React.ReactNode; onToggleChange?: (arg: number) => void }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const onToggle = useCallback(
    (index: number) => {
      if (onToggleChange) onToggleChange(index);
      setOpenIndex(openIndex === index ? null : index);
    },
    [openIndex]
  );
  const isDescriptionVisible = useCallback((value: number, isOpen?: boolean) => isOpen || value === openIndex, [openIndex]);
  const memoizedContext = useMemo(() => ({ onToggle, isDescriptionVisible }), [onToggle, isDescriptionVisible]);

  return <FormAccordionContext.Provider value={memoizedContext}>{children}</FormAccordionContext.Provider>;
};

const FormAccordionSummary = ({ children, className, index, isOpen, ...props }: IAccordionSummary) => {
  const { onToggle, isDescriptionVisible } = useFormAccordionContext();
  const isVisible = isDescriptionVisible(index, isOpen);

  return (
    <button
      type="button"
      onClick={() => onToggle(index)}
      className={`form-accordion-primitive-summary ${className}`}
      {...props}
      data-open={String(isVisible)}
    >
      {children}
    </button>
  );
};

const FormAccordionDescription = ({ children, className, index, isOpen, ...props }: IAccordionDescription) => {
  const { isDescriptionVisible } = useFormAccordionContext();
  const isVisible = isDescriptionVisible(index, isOpen);

  return isVisible ? (
    <div className={`form-accordion-primitive-description fade-in ${className}`} {...props}>
      {children}
    </div>
  ) : null;
};

const Root = FormAccordion;
const Summary = FormAccordionSummary;
const Description = FormAccordionDescription;

export { Root, Summary, Description };
