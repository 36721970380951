import React from 'react';
import useStore from '+zustandStore';
import { capitalize } from '+utils';
import bloomLogin from '+assets/img/dashboard/bloom-login.svg';
import './index.scss';

const Welcome = () => {
  const {
    profile: { email }
  } = useStore(state => state);

  return (
    <section className="container">
      <div className="content-wrapper">
        <img className="welcome-logo" alt="Bloom login" src={bloomLogin} />
        <p className="welcome-title" data-testid="welcome-title">
          Welcome to the internal dashboard, <span>{`${capitalize(email?.split('@')[0] ?? 'Admin')}!`}</span> 🌟
        </p>
        <p className="welcome-title--sub">The secure back office for managing transactions, merchants and all users.</p>
      </div>
    </section>
  );
};

export default Welcome;
