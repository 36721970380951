import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  ActionButtonType,
  BackButton,
  InfoList,
  SectionHeader,
  SummaryInfo,
  SummaryInfoDataType,
  TxPageHeader,
  TxPageHeaderProps
} from '+containers/Dashboard/TransactionDetails/components/LayoutElements';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import {
  capitalizeFirst,
  capitalizeRemovedash,
  createParamsObject,
  formatAmount,
  getDate,
  getTime,
  history,
  switchStatus,
  truncateString
} from '+utils';

import BalanceFundingActionModal from './BalanceFundingActionModal';

const api = new APIRequest();

type PageParamsType = {
  id: string;
};

const BalanceFundingDetails = () => {
  const [fundingAction, setFundingAction] = useState<'reject' | 'approve' | ''>('');
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams<PageParamsType>();

  const params = createParamsObject({ id });
  const { data, refetch, isLoading } = useQuery(`FUNDING_ID_${id}`, () => api.fetchBalanceFunding(...params), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger',
        action: refetch
      });
      history.goBack();
    }
  });

  const {
    reference,
    amount,
    status,
    description,
    completed_at,
    currency,
    fee,
    source,
    merchant,
    merchant_email,
    transaction_type: trxntype,
    transaction_date: trxdate
  } = data?.data[0] || {};

  const getActionButtons = (currentStatus: string): Array<ActionButtonType> => {
    return currentStatus === 'pending'
      ? [
          {
            label: 'Decline',
            variant: 'light-blue',
            onClick: () => setFundingAction('reject')
          },
          {
            label: 'Approve funding',
            onClick: () => setFundingAction('approve')
          }
        ]
      : [];
  };

  const headerData: TxPageHeaderProps = {
    heading: amount,
    currency,
    tag: status,
    actionButtons: getActionButtons(status)
  };

  const summaryInfo: SummaryInfoDataType = [
    {
      label: 'Net Amount',
      value: `${formatAmount(amount)} ${currency}`
    },
    {
      label: 'Fee',
      value: `${formatAmount(fee)} ${currency}`,
      labelTooltipContent: 'This is the amount escalated'
    },
    {
      label: 'Date / Time',
      value: `${getDate(trxdate)}, ${getTime(trxdate)}`
    },
    {
      label: 'Transaction ID',
      value: <Copyable text={reference} textModifier={text => truncateString(text, 18)} />
    }
  ];

  const moreDetails = [
    {
      label: 'Status',
      value: capitalizeFirst(status ?? ''),
      statusPill: <span className={`status-pill smaller ${switchStatus(status)}`} style={{ margin: '0 7px 5px 0' }} />
    },
    {
      label: 'Amount Paid',
      value: `${formatAmount(fee)} ${currency}`
    },
    {
      label: 'Transaction Type',
      value: trxntype
    },
    {
      label: 'Source',
      value: `${capitalizeRemovedash(source)}`
    },
    {
      label: 'Date, Time',
      value: `${getDate(trxdate)}, ${getTime(trxdate)}`
    },
    {
      label: 'Reference',
      value: <Copyable text={reference} textModifier={text => truncateString(text, 18)} />
    },
    { label: 'Description', value: description },
    { label: 'Completed at', value: completed_at ? `${getDate(completed_at)}, ${getTime(completed_at)}` : 'Not Available' }
  ];
  const businessInfo = [
    {
      label: 'Business Name',
      value: merchant
    },
    {
      label: 'Business Email',
      value: merchant_email
    }
  ];

  return (
    <div className="card-issuance-chargeback">
      <BackButton />
      {!isLoading && data ? (
        <>
          <TxPageHeader
            heading={headerData.heading}
            currency={headerData.currency}
            tag={headerData.tag}
            tagType="balance_funding"
            actionButtons={headerData.actionButtons}
          />
          <SummaryInfo data={summaryInfo} />

          <SectionHeader heading="More Transaction Details" />
          <InfoList data={moreDetails} />

          <SectionHeader heading="Business Information" />
          <InfoList data={businessInfo} />

          <SectionHeader heading="Disputes" />
          <div style={{ color: 'hsla(221, 12%, 70%, 1)', fontStyle: 'italic', textAlign: 'center' }}>No disputes for this transaction</div>
        </>
      ) : (
        <LoadingPlaceholder type="table" content={5} />
      )}
      {fundingAction !== '' && <BalanceFundingActionModal action={fundingAction} close={() => setFundingAction('')} reference={id} />}
    </div>
  );
};

export default BalanceFundingDetails;
