import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import usePagination from '+hooks/usePagination';
import { BulkDataT } from '+types';
import { formatAmount } from '+utils';
import Table from '+shared/Table';

const BulkPayoutDraftTable = (data: BulkDataT) => {
  const [pagination, handlePagination, handleLimit] = usePagination(1, 10); 

  const [paging, setPaging] = useState({
    page_size: 0,
    total_items: 0
  });

  const [tempData, setTempData] = useState<Array<BulkDataT>>(data?.data?.payouts || []);
  const [displayedData, setDisplayedData] = useState<Array<BulkDataT>>([]);

  useEffect(() => {
    setTempData(data?.data?.payouts || []);
  }, [data?.data?.payouts]);

  useEffect(() => {
    if (tempData) {
      const itemsPerPage = pagination?.limit;
      const totalItems = tempData?.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      setPaging({
        total_items: totalItems,
        page_size: totalPages
      });
    }
  }, [tempData, pagination?.limit]);

  useEffect(() => {
    const { page, limit } = pagination;
    const start = (page - 1) * limit;
    const end = start + limit;
    let newData: Array<BulkDataT> = [];

    newData = tempData;

    setDisplayedData(newData?.slice(start, end));
  }, [pagination, tempData]);

  const queryCache = useQueryClient();
  const banks = queryCache.getQueryData('BANKS');

  const getBankName = (bankName?: string) => {
    const findBank = (banks as Array<{ name: string; code: string | number }>)?.find((bank) => bank.name === bankName);
    return findBank?.name;
  };

  const tableData = {
    type: 'payouts',
    className: '--bulkpayouts-draft-table',
    rowKey: 'reference',
    rowURL: '/dashboard/bulk-payouts',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no bulk payouts yet.',
    annotations: 'transaction(s)',
    fields: (each: BulkDataT) => ({
      data: {
        Reference: <span className="reference-col-text text-ellipsis">{each?.reference}</span>,
        Bank: <span>{getBankName(each?.bank_account?.bank_name || (each?.bank_account?.bank_code as string)) || 'N/A'}</span>,
        'Account No.': <span>{each?.bank_account?.account_number || 'N/A'}</span>,
        'Account Name': <span className="text-ellipsis">{each?.bank_account?.account_name || 'N/A'}</span>,
        Description: <span className="text-ellipsis">{each?.narration || 'N/A'}</span>,
        'Amount (NGN)': (
          <>
            <span>{formatAmount(each?.amount) || 'N/A'}</span>
            {each?.currency || ' NGN'}
          </>
        )
      }
    })
  };
  const tableDataKeys = Object.keys(tableData.fields({} as BulkDataT).data);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <Table
            data={displayedData}
            hasFilter={false}
            className={`${tableData.className || ''}`}
            tableWrapperClassName="table-responsive"
            hasPagination
            renderFields
            tableHeadings={tableDataKeys}
            annotation={tableData.annotations}
            current={pagination.page}
            rowKey={tableData.rowKey}
            rowURL={tableData.rowURL}
            pageSize={pagination?.limit || 0}
            totalItems={paging?.total_items || 0}
            actionFn={(page: number | string) => handlePagination(page, pagination.limit)}
            limitAction={(limit: number | string) => handleLimit(limit)}
            emptyStateHeading={tableData.emptyStateHeading || ''}
            emptyStateMessage={tableData.emptyStateMessage || ''}
            filterHasAdvancedFilter={false}
            filterShowExport={false}
            hideTable={data?.length === 0}
            isRowClickable={false}
          >
            {tableData.fields}
          </Table>
        </div>
      </div>
    </>
  );
};
export default BulkPayoutDraftTable;
