/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  getPresentDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';

const apiRequest = new APIRequest();

const Payouts = () => {
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const current = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || 10;

  const dateFrom = searchQuery.value.dateFrom || getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo || getPresentDate().dateTo;
  const status = searchQuery.value.status || [];
  const sortingParams = {
    dateFrom,
    dateTo,
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.page,
      queriesParams.limit,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status
    ])
  };

  const {
    data: resolvedData,
    isLoading,
    refetch
  } = useQuery(['ALL_PAYOUTS', current, sortingParams, limit], () => apiRequest.getAllPayouts(sortingParams, current, limit), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Payouts',
        message: 'There has been an error getting payouts',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data?.data || [];
  const paging = resolvedData?.data?.paging || {};

  const exportPayouts = async (format: string, close: () => void, fieldsToExport: string[]): Promise<void> => {
    try {
      const res: Awaited<Blob> = await apiRequest.exportPayouts(format, fieldsToExport, sortingParams);
      const type = format === 'csv' ? 'csv' : 'excel';
      APIDownload(res, `Settlement Payouts Report at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{paging?.total_items} transactions.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your settlement payout file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const settlementPayouts = {
    className: '--settlement-payout-table',
    rowURL: isAllowed(userAccess, ['settlement_details.view']) ? '/dashboard/settlements' : '/dashboard/access-denied',
    rowKey: isAllowed(userAccess, ['settlement_details.view']) ? 'reference' : '',
    emptyStateHeading: 'No payouts yet',
    emptyStateMessage: 'There are no settlement payouts yet.',
    annotations: 'transaction(s)',
    fields: (iter: any) => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
            <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
          </>
        ),
        payout_id: (
          <span className="trxn-id">
            <Copyable showOnHover text={iter?.reference?.toUpperCase()} />
          </span>
        ),
        merchant: <span className="merch-name">{capitalize(iter.settlement?.account?.name || 'Merchant')}</span>,
        paid_at: (
          <>
            <span>{getDate(iter.updatedAt)}</span>
            <span className="annotation">{getTime(iter.updatedAt)}</span>
          </>
        ),
        payout_fee: (
          <>
            <span>{formatAmount(iter.fee)}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        ),
        amount: (
          <>
            <span>{formatAmount(iter.amount)}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys: string[] = Object.keys(settlementPayouts.fields({}).data);

  const getTitle = (): string => {
    const filtered: boolean = Object.keys(sortingParams || {}).length > 0;
    return `${filtered ? 'Filtered' : 'All'} Settlement Payouts`;
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <h6 className="element-header">{getTitle()}</h6>
              <Table
                className={settlementPayouts.className || ''}
                loading={isLoading}
                data={data}
                renderFields
                hasPagination
                tableHeadings={tableDataKeys}
                annotation={settlementPayouts.annotations}
                current={parseInt(current, 10)}
                rowKey={settlementPayouts.rowKey}
                rowURL={settlementPayouts.rowURL}
                pageSize={paging?.page_size || 0}
                totalItems={paging?.total_items || 0}
                limitAction={(currentLimit: number) => searchQuery.setQuery({ limit: String(currentLimit) })}
                actionFn={(currentPage: number) => searchQuery.setQuery({ page: String(currentPage) })}
                emptyStateHeading={settlementPayouts.emptyStateHeading || ''}
                tableWrapperClassName="table-responsive"
                emptyStateMessage={settlementPayouts.emptyStateMessage || ''}
                filterQueryIDPlaceholder="Payout ID"
                filterExportAction={exportPayouts}
                filterTotalCount={paging?.total_items}
                filterShowExport={isAllowed(userAccess, ['settlement_payouts.export'])}
                hideTable={data.length === 0}
                type="settlements-payouts"
                filterActiveCurrency="NGN"
              >
                {settlementPayouts.fields}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
