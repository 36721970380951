import React from 'react';

import Copyable from '+shared/Copyable';
import { IPartnerBalanceDetails } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getTime,
  switchCurrencyWithoutSymbol,
  switchStatus,
  truncateString
} from '+utils';

export const generateHeaderSummary = (data: IPartnerBalanceDetails) => {
  return [
    {
      label: 'Session ID',
      value: data?.session_id ? (
        <Copyable text={data.session_id} spanClassName="copyable-blue" textModifier={text => truncateString(text, 18)} />
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Date / Time',
      value: `${getDate(data?.createdAt)}, ${getTime(data?.createdAt)}`
    },
    { label: 'Initiated By', value: data?.initiated_by },
    {
      label: 'Transaction ID',
      value: <Copyable text={data?.reference?.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
    }
  ];
};

export const generateAdminDetailsSummary = (data: IPartnerBalanceDetails) => {
  return [
    {
      label: 'Status',
      value: (
        <>
          <span className={`status-pill smaller ${switchStatus(data?.status)}`} style={{ margin: '0 7px 5px 0' }} />
          {capitalize(data?.status)}
        </>
      )
    },
    {
      label: 'Amount',
      value: `${formatAmount(data?.amount)} ${data?.currency}`
    },
    {
      label: 'Source Account Name',
      value: `${capitalize(data?.source_partner_account?.destination_name)}`
    },
    {
      label: 'Source Account',
      value: `${data?.source_partner_account?.destination_number}`
    },
    {
      label: 'Source Bank',
      value: `${capitalize(data?.source_partner_account?.institution_name)}`
    },
    {
      label: 'Recipient Account Name',
      value: `${capitalize(data?.destination_partner_account?.destination_name)}`
    },
    {
      label: 'Recipient Account',
      value: `${capitalize(data?.destination_partner_account?.destination_number)}`
    },
    {
      label: 'Recipient Bank',
      value: `${capitalize(data?.destination_partner_account?.institution_name)}`
    },
    {
      label: 'Payment Method',
      value: `${capitalizeRemovedash(String(data?.destination_partner_account?.destination_type))}`
    },
    { label: 'Initiated By', value: capitalize(data?.initiated_by) },
    { label: 'Email', value: capitalize(data?.initiator_email) }
  ];
};

export const generateMoreDetailsSummary = (data: IPartnerBalanceDetails) => {
  return [
    {
      label: 'Currency Charged',
      value: (
        <>
          {switchCurrencyWithoutSymbol[data?.currency]} ({data?.currency})
        </>
      )
    },
    {
      label: 'Processor Reference',
      value: `${data?.processor_reference}`
    },
    {
      label: 'Session ID Reference',
      value: data.session_id ? <Copyable text={data.session_id} spanClassName="copyable-blue" /> : 'Not Available'
    },
    {
      label: 'Transaction ID',
      value: `${data?.reference}`
    },
    {
      label: 'Date Created',
      value: `${getDate(data?.createdAt)}, ${getTime(data?.createdAt)}`
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data?.completed_at)}, ${getTime(data?.completed_at)}` : 'Not Available'
    },
    {
      label: 'Description',
      value: 'Not Available'
    }
  ];
};
