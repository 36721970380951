import React, { act, LegacyRef, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import CSVFileReader, { CSVParsedDataT } from '+containers/Dashboard/Shared/CSVFileReader';
import Icon from '+containers/Dashboard/Shared/Icons';
import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useClickOutside, useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { capitalize, isAllowed, logError } from '+utils';

import { CustomFormType, FormattedPlanType, ManualDebitDataType, ManualDebitFormType, PlanFormValuesType, PlansType } from '../data';
import ChangeSubscriptionCustomForm from './ChangeSubscriptionCustomForm';
import ManualDebitForm from './ManualDebitForm';

import './index.scss';

import SingleDebitForm from './SingleDebitForm';

type ManageMerchantModalPropsType = {
  plans: PlansType[];
  merchantPlan: PlansType;
  setModal: (modal: { modalType: string | null; modalInfo: IModalProps }) => void;
  setButtonState: (state: boolean) => void;
  merchantDetails: { kora_id: string; plan: { name: string }; reference: string; currency: string };
  refetchMerchant: () => void;
};

const ManageMerchantModal = ({
  plans,
  merchantPlan,
  setModal,
  setButtonState,
  merchantDetails,
  refetchMerchant
}: ManageMerchantModalPropsType) => {
  const [manageModal, setManageModal] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [manualDebitData, setManualDebitData] = useState({} as ManualDebitDataType);
  const queryClient = useQueryClient();

  const userAccess = useSetUserAccess();
  const canDebitManually = isAllowed(userAccess, ['card_issuance_manual_billing.process']);
  const canUpdateSubscription = isAllowed(userAccess, ['card_issuance_plan_subscription.update']);

  const [formValues, setFormValues] = useState<PlanFormValuesType>({
    debitType: '',
    cardCategory: '',
    feeType: '',
    plan: '',
    uniqueId: ''
  });
  const [customizedPlan, setCustomizedPlan] = useState({} as FormattedPlanType);

  const formValuesRef = useRef(formValues);

  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  const filteredPlans = plans?.filter(obj => obj.name !== merchantDetails?.plan.name);

  useEffect(() => {
    if (filteredPlans) {
      setFormValues(prev => ({ ...prev, plan: filteredPlans[0].reference }));
    }
  }, [plans]);

  const wrapperRef = useClickOutside(() => {
    setManageModal(false);
  });

  const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setButtonState(e.target.checked === true);
    setChecked(e.target.checked);
  };

  const apiRequest = new APIRequest();
  const { feedbackInit } = useFeedbackHandler();

  const reloadMerchant = () => {
    queryClient.invalidateQueries('MERCHANT_DETAILS');
    refetchMerchant();
    setFormValues({ uniqueId: '', debitType: '', cardCategory: '', feeType: '', plan: '' } as unknown as PlanFormValuesType);
  };

  const { mutateAsync: subscribeToPlan } = useMutation(
    () => apiRequest.subscribeMerchantToPlan(formValuesRef.current.plan, merchantDetails.reference),
    {
      onSettled: reloadMerchant,
      onError: (error: { response: { data: { message: string } } }) => {
        setModal({ modalType: null, modalInfo: {} as IModalProps });
        logError(error);
        feedbackInit({
          message: error?.response?.data?.message || 'An error occurred while changing the subscription plan. Please try again later.',
          type: 'danger'
        });
      }
    }
  );

  const { mutateAsync: customizePlan } = useMutation(() => apiRequest.customizePlan(merchantDetails.reference, customizedPlan), {
    onSettled: reloadMerchant,
    onError: (error: { response: { data: { message: string } } }) => {
      setModal({ modalType: null, modalInfo: {} as IModalProps });
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || 'An error occurred while changing the subscription plan. Please try again later.',
        type: 'danger'
      });
    }
  });

  const { mutateAsync: manualDebit } = useMutation(() => apiRequest.createManualDebit(manualDebitData), {
    onSettled: reloadMerchant,
    onError: (error: { response: { data: { message: string } } }) => {
      setModal({ modalType: null, modalInfo: {} as IModalProps });
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || 'An error occurred while charging the merchant. Please try again later.',
        type: 'danger'
      });
    }
  });

  function changePlanForm() {
    return (
      <div className="form-group modal-content">
        <div className="form-group">
          <label htmlFor="current-plan">
            <span className="dark">Current plan</span>
          </label>
          <input type="text" id="current-plan" className="form-control" value={capitalize(merchantDetails?.plan?.name)} disabled />
        </div>
        <div className="form-group">
          <label htmlFor="plan">
            <span className="dark">Change plan to</span>
          </label>
          <div className="dropdown-container">
            <select
              name="plan"
              data-testid="select-plan"
              className="form-control"
              onChange={e => {
                const { value } = e.target;
                setFormValues(prev => ({ ...prev, plan: value }));
              }}
            >
              {filteredPlans?.map(option => (
                <option key={option.name} value={option.reference}>
                  {capitalize(option.name)}
                </option>
              ))}
              <option value="custom">Custom</option>
            </select>
            <div className="dropdown-icon">
              <Icon name="arrowDown" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const formatData = (data: CSVParsedDataT) => {
    const result = data.map((item: string[]) => item[0]);
    if (result.length < 1) {
      feedbackInit({
        message: 'A minimum of 1 entry is required in a single upload',
        type: 'danger'
      });
      return [];
    }
    setFormValues(prev => ({ ...prev, uniqueId: result }));
    setButtonState(true);
    return result;
  };

  function renderMultipleDebitForm() {
    return (
      <div className="form-group modal-content">
        <p>
          <strong>Submit documents to validate this manual debit</strong>
        </p>
        <p> Add all details in one csv file for best result. After submitting, you will not be able to make anymore changes .</p>
        <CSVFileReader onChange={(value: CSVParsedDataT) => formatData(value)} onClose={() => setButtonState(false)} />
        <p>Max file size 20MB</p>
      </div>
    );
  }

  const handleFormSubmit = (values: ManualDebitFormType) => {
    setFormValues(prev => ({ ...prev, ...values }));
  };

  const customFormSubmit = (values: CustomFormType) => {
    const data = values;
    const formmattedData = {
      monthly_card_limit: data.customerNumberOfCardsIssuable,
      reserved_card_limit: data.reservedNumberOfCardsIssuable,
      fee: {
        customer: {
          funding: {
            amount: data.customerFundingFee
          },
          issuance: {
            amount: data.customerIssuanceFee
          },
          chargeback: {
            amount: data.customerChargebackFee
          },
          withdrawal: {
            amount: data.customerWithdrawalFee
          },
          subscription: {
            amount: data.customerSubscriptionFee
          },
          cross_currency: {
            amount: data.customerCrossCurrencyFee
          },
          security_reserve: {
            amount: data.customerSecurityReserveFee
          }
        },
        reserved: {
          funding: {
            amount: data.reservedFundingFee
          },
          issuance: {
            amount: data.reservedIssuanceFee
          },
          chargeback: {
            amount: data.reservedChargebackFee
          },
          withdrawal: {
            amount: data.reservedWithdrawalFee
          },
          subscription: {
            amount: data.reservedSubscriptionFee
          },
          cross_currency: {
            amount: data.reservedCrossCurrencyFee
          },
          security_reserve: {
            amount: data.reservedSecurityReserveFee
          }
        }
      }
    };
    setCustomizedPlan(formmattedData);
  };

  const modalDetails = {
    changePlan: {
      heading: 'Change subscription plan',
      description: 'Provide the details below to change subscription plan.',
      firstButtonText: 'Cancel',
      secondButtonText: 'Continue',
      secondButtonActionIsTerminal: false,
      firstButtonAction: () => {
        setModal({ modalType: null, modalInfo: {} as IModalProps });
      },
      secondButtonAction: () => {
        if (formValuesRef.current.plan === 'custom') {
          setModalVisible('issuedCustomForm');
        } else {
          setModalVisible('confirmPlanChange');
        }
        setButtonState(false);
      },
      headerBottomBorder: true,
      content: changePlanForm()
    },
    issuedCustomForm: {
      heading: 'Change subscription plan',
      description: 'Provide the details below to change subscription plan.',
      secondButtonText: 'Continue',
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => {
        setButtonState(true);
        setModalVisible('reservedCustomForm');
      },
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('changePlan');
      },
      headerBottomBorder: true,
      content: (
        <ChangeSubscriptionCustomForm
          defaultPlan={merchantPlan as PlansType}
          validateForm={setButtonState}
          handleFormData={customFormSubmit}
          activeTab="issued_cards"
        />
      )
    },
    reservedCustomForm: {
      heading: 'Change subscription plan',
      description: 'Provide the details below to change subscription plan.',
      secondButtonText: 'Continue',
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => {
        setButtonState(false);
        setModalVisible('confirmCustomPlanChange');
      },
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('issuedCustomForm');
      },
      headerBottomBorder: true,
      content: (
        <ChangeSubscriptionCustomForm
          defaultPlan={merchantPlan as PlansType}
          validateForm={setButtonState}
          handleFormData={customFormSubmit}
          activeTab="reserved_cards"
        />
      )
    },
    confirmCustomPlanChange: {
      heading: 'Confirm new plan',
      description: 'Once you confirm, the merchant will be assigned to and charged fees under the new subscription plan.',
      secondButtonText: 'Yes, Confirm',
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('reservedCustomForm');
      },
      size: 'sm',
      secondButtonAction: customizePlan,
      secondButtonActionIsTerminal: true,
      headerBottomBorder: false,
      completedHeading: 'Success',
      completedDescription: 'You have successfully changed this merchant’s subscription plan.',
      content: (
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      )
    },
    confirmPlanChange: {
      heading: 'Confirm new plan',
      description: 'Once you confirm, the merchant will be assigned to and charged fees under the new subscription plan.',
      secondButtonText: 'Yes, Confirm',
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('changePlan');
      },
      size: 'sm',
      secondButtonAction: subscribeToPlan,
      secondButtonActionIsTerminal: true,
      headerBottomBorder: false,
      secondButtonDisable: !isChecked,
      completedHeading: 'Success',
      completedDescription: 'You have successfully changed this merchant’s subscription plan.',
      content: (
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      )
    },
    manualDebit: {
      heading: 'Manual Debit',
      description: 'Provide the details below to charge a merchant manually.',
      secondButtonText: 'Next',
      secondButtonActionIsTerminal: false,
      headerBottomBorder: true,
      content: <ManualDebitForm validateForm={setButtonState} handleFormData={handleFormSubmit} formData={formValuesRef} />,
      firstButtonText: 'Cancel',
      firstButtonAction: () => {
        setModal({ modalType: null, modalInfo: {} as IModalProps });
      },
      secondButtonAction: () => {
        if (formValuesRef.current?.debitType === 'single') {
          setModalVisible('singleDebit');
        } else {
          setModalVisible('multipleDebit');
        }
        setButtonState(false);
      }
    },
    singleDebit: {
      heading: 'Manual Debit',
      description: 'Provide the details below to charge a merchant manually.',
      secondButtonText: 'Continue',
      secondButtonActionIsTerminal: false,
      headerBottomBorder: true,
      content: <SingleDebitForm setButtonState={setButtonState} formValue={formValues} setFormValues={setFormValues} />,
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('manualDebit');
      },
      secondButtonAction: () => {
        setButtonState(false);
        const data = {
          currency: merchantPlan.currency,
          cardCategory: formValuesRef.current.cardCategory,
          billingType: 'cross_currency_fee',
          debitType: formValuesRef.current.debitType,
          reference: formValuesRef.current.debitType === 'single' ? formValuesRef.current.uniqueId : [formValuesRef.current.uniqueId],
          cardType: 'virtual'
        };
        setManualDebitData(data);
        setModalVisible('confirmDebit');
      }
    },
    multipleDebit: {
      heading: 'Manual Debit',
      description: 'Provide the details below to charge a merchant manually.',
      secondButtonText: 'Continue',
      secondButtonActionIsTerminal: false,
      headerBottomBorder: true,
      content: renderMultipleDebitForm(),
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        setModalVisible('manualDebit');
      },
      secondButtonAction: () => {
        setButtonState(false);
        const data = {
          currency: merchantPlan.currency,
          cardCategory: formValuesRef.current.cardCategory,
          billingType: 'cross_currency_fee',
          debitType: formValues.debitType,
          reference: formValuesRef.current.uniqueId,
          cardType: 'virtual'
        };
        setManualDebitData(data);
        setModalVisible('confirmDebit');
      }
    },
    confirmDebit: {
      heading: 'Confirm Debit',
      description: 'Once you confirm, the merchant will be charged from their issuing balance. ',
      firstButtonText: 'Back',
      firstButtonAction: () => {
        setButtonState(true);
        if (formValuesRef.current?.debitType === 'single') {
          setModalVisible('singleDebit');
        } else {
          setModalVisible('multipleDebit');
        }
      },
      secondButtonText: 'Yes, Confirm',
      secondButtonAction: manualDebit,
      size: 'sm',
      secondButtonActionIsTerminal: true,
      headerBottomBorder: false,
      completedHeading: 'Success',
      completedDescription: 'You have successfully charged this merchant.',
      content: (
        <div className="checkbox-container">
          <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
          <label htmlFor="approve">Yes, I understand the implications of this action</label>
        </div>
      )
    }
  } as unknown as { [key: string]: IModalProps };

  const setModalVisible = (type: string) => {
    setModal({
      modalType: type,
      modalInfo: modalDetails[type]
    });
  };

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>}>
      <div className="manage-billing-modal">
        <button type="button" onClick={() => setManageModal(value => !value)}>
          <i className="os-icon os-icon-settings" />
          &nbsp;
          <span>Manage Merchant</span>
          &nbsp;
          <i className="os-icon os-icon-arrow-down" />
        </button>
      </div>
      {manageModal && (
        <ul className="element-box ellipsis__nav user-ellipsis" style={{ padding: '10px' }}>
          <li
            role="presentation"
            className={`ellipsis__item ${canUpdateSubscription ? '' : 'disabled'}`}
            onClick={() => {
              if (canUpdateSubscription) {
                setButtonState(true);
                setModalVisible('changePlan');
              }
            }}
          >
            <span>Change Subscription Plan</span>
          </li>

          <li
            role="presentation"
            className={`ellipsis__item ${canDebitManually ? '' : 'disabled'}`}
            onClick={() => {
              if (canDebitManually) setModalVisible('manualDebit');
            }}
          >
            <span>Manual Debit</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ManageMerchantModal;
