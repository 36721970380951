import Icon from '+shared/Icons';
import { IPlanEditor } from '+types';
import { formatAmount } from '+utils';
import React, { useState } from 'react';

const ExistingTPVList = ({ tpvList }: { tpvList: IPlanEditor['tpvList'] }) => {
  const [showExistingTPVs, setShowExistingTPVs] = useState(false);

  return (
    <div className="existing-tpvs">
      <p className="existing-tpvs__copy font-italic">
        <span>Ensure this TPV does not overlap with any existing plans.</span>
        <button onClick={() => setShowExistingTPVs((prev) => !prev)} className="btn btn-link existing-tpvs__cta" type="button">
          <span>{showExistingTPVs ? 'Hide' : 'See existing'} plans</span>{' '}
          <Icon width={8} height={8} className="disclosure-chevron" name="caretRight" />
        </button>
      </p>

      <div className={`existing-tpvs__list-container ${showExistingTPVs ? 'content-visible' : ''}`}>
        <ul className="existing-tpvs__list">
          {tpvList.map((tpv) => (
            <li className="existing-tpvs__list-item" key={tpv.plan}>
              <span className="existing-tpvs__plan-name">{tpv.plan}</span>
              <span className="existing-tpvs__range">
                TPV: <strong>${formatAmount(tpv.tpv[0])}</strong>-<strong>${formatAmount(tpv.tpv[1])}</strong>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExistingTPVList;
