/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import { cleanInput, history, isAllowed, logError } from '+utils';

import UsersPermissionsTable from '../components/UsersPermissionsTable';

import infoMark from '+assets/img/dashboard/info-i.svg';

import './index.scss';

const apiRequest = new APIRequest();

type CreateNewRoleModalType = 'learn-more' | 'default' | 'content';

const CreateNewRole = () => {
  const queryClient = useQueryClient();
  const userAccess = useSetUserAccess();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<CreateNewRoleModalType>('default');
  const [roleName, setRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const createRoleMutation = useMutation(
    () => apiRequest.addRole({ name: `Custom-${roleName}`, permissions: [...new Set(selectedPermissions)] }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ROLES', 1, 10, {}]);
        history.push('/dashboard/users/roles', null);
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error creating this role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const saveRoleToggle = () => {
    setModalType('content');
    setModalVisible(true);
  };

  const getSelectedPermissions = (permissions: string[]) => {
    setSelectedPermissions(permissions);
  };

  const switchModal = (type: CreateNewRoleModalType) => {
    const learnMore = {
      heading: `You have the permission to create system roles`,
      content: (
        <p className="info-modal-cont">
          <b>You are about to create a:</b> <br /> <span className="info-modal-span"> Custom role</span> <br />
          <br /> We have two kinds of roles;
          <b>
            <i> System roles </i>
          </b>
          and
          <b>
            <i> custom roles</i>
          </b>
          <br />
          <br />
          System roles can be used by anyone that has the permission to send user invitations while custom roles can only be used by you for
          future invites.
        </p>
      ),
      size: 'md',
      showButtons: false
    };
    const content = {
      heading: `Create a new role?`,
      content: <p>{`Please confirm that you want to create a new custom role with name ${roleName}`}</p>,
      size: 'md',
      firstButtonText: 'Cancel',
      secondButtonText: 'Yes, Create',
      secondButtonAction: () => {
        closeFeedback();
        return createRoleMutation.mutateAsync();
      },
      equalFooterBtn: true,
      headerBottomBorder: false,
      completedModalSize: 'base',
      completedHeading: 'Created!',
      completedDescription: `You have successfully created a new custom role - ${roleName}`,
      secondaryCompletedModal: true
    };
    const modalMapper = {
      content,
      'learn-more': learnMore
    };

    return modalMapper[type as keyof typeof modalMapper];
  };

  return (
    <div className="content-i user-new-invite">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Roles</span>
            </button>
          </div>
        </div>
        <div className="element-box">
          <div className="element-box-heading">
            <div className="heading-box-mmd users-heading-box-mmd">
              <h5 className="form-header">Create new role</h5>
              <div className="form-desc no-underline">Create a new role to be assigned to the users of the internal dashboard.</div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="element-input-box">
                <div className="input-info">
                  <label htmlFor="roleName">Role Name</label>
                  <div className="basic-filter">
                    <input
                      type="text"
                      aria-label="New role name"
                      name="roleName"
                      id="roleName"
                      data-testid="roleName"
                      placeholder="Enter the role name"
                      onChange={e => setRoleName(cleanInput(e?.target?.value))}
                      value={roleName}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="element-link-exclament">
                <div className="element-exclament btn">
                  <div className="img-exclament-mark">
                    <img src={infoMark} alt="close" aria-hidden />
                  </div>
                  <p className="danger-txt danger-txt-more">
                    Everyone can see and use this role.
                    <b
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setModalType('learn-more');
                        setModalVisible(true);
                      }}
                      onKeyDown={() => {
                        setModalType('learn-more');
                        setModalVisible(true);
                      }}
                    >
                      Learn More{' '}
                    </b>
                  </p>
                </div>
              </div>

              <div className="legend-dash" />

              <div className="element-permission-modify">
                <div className="element-permission-box">
                  <h5 className="permission-modify-box">Role Permissions </h5>
                  <UsersPermissionsTable
                    getSelectedPermission={(value: (string | number)[]) => getSelectedPermissions(value as string[])}
                    type="role"
                  />
                </div>
              </div>

              {isAllowed(userAccess, ['custom_roles.create']) && (
                <button type="button" className="btn btn-save-role" onClick={saveRoleToggle} disabled={!roleName}>
                  Save Role
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal visible={modalVisible} {...(switchModal(modalType) as IModalProps)} close={() => setModalVisible(false)} />
    </div>
  );
};

export default CreateNewRole;
