import React from 'react';
import Modal from '+shared/Modal';
import './index.scss';


interface IReversalsInfoModalProps {
  visible: boolean;
  close: () => void;
}

const ReversalsInfoModal = ({ visible, close }: IReversalsInfoModalProps ) => {
  const InformationContent = () => (
    <section className="reversals-info-modal__comp">
      <h3>Reversals</h3>
      <p>
        Reversals occur automatically when customers pay less or more than the amount they are expected to pay. If the customer pays less
        than the expected amount (underpayment), the total amount they paid will be returned. If they pay more than the amount expected
        (overpayment), the excess will be returned to the customer, while the correct amount will be credited to the merchant. In the case
        of overpayments, such transactions will be treated as successful.
      </p>

      <h3>Refunds</h3>
      <p>Refunds can be initiated by the merchant. If the customer pays less than the expected amount (underpayment).</p>

      <h3>Chargebacks</h3>
      <p>Chargeback can be initiated by the customer. If the customer pays less than the expected amount (underpayment).</p>
    </section>
  );

  return (
    <Modal
      close={close}
      size="md"
      heading="Refunds, Reversals and Chargebacks"
      visible={visible}
      showButtons={false}
      content={<InformationContent />}
    />
  );
};

export default ReversalsInfoModal;
