import PermissionColumnType from '+types/teamTypes';

export default (permission?: string): string[] => {
  const { view, export: exportPermission, manage } = PermissionColumnType;
  switch (permission) {
    case PermissionColumnType.export:
      return [view, exportPermission];

    case PermissionColumnType.manage:
      return [view, exportPermission, manage];

    case PermissionColumnType.view:
      return [view];

    default:
      return [];
  }
};
