/* eslint-disable camelcase */
import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';

import FilterComponent from '+containers/Dashboard/Shared/Filter';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { IUser, moduleType } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  getTime,
  history,
  isAllowed,
  queriesParams,
  switchStatus
} from '+utils';

import Table from '../../Shared/Table';

const apiRequest = new APIRequest();

type TabType = { name: 'Users' | 'Invited Users'; key: 'users' | 'invited_users' };

const Users = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const [tabs, setTabs] = useState<TabType[]>([]);

  const activeTab = (searchQuery?.value?.tab ?? 'users') as TabType['key'];
  const page = searchQuery?.value?.page ?? '1';
  const limit = searchQuery?.value?.limit ?? '10';

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.activeTab];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  useLayoutEffect(() => {
    if (
      userAccess &&
      isAllowed(userAccess, ['admin_users.view', 'admin_user_details.view', 'admin_users.update', 'admin_user_audit_logs.view']) &&
      tabs.findIndex(tab => tab.key === 'users') === -1
    ) {
      setTabs(prev => [...prev, { name: 'Users', key: 'users' }]);
    }
    if (userAccess && isAllowed(userAccess, ['admin_user_invitations.view']) && tabs.findIndex(tab => tab.key === 'invited_users') === -1) {
      setTabs(prev => [...prev, { name: 'Invited Users', key: 'invited_users' }]);
    }
  }, [userAccess]);

  useLayoutEffect(() => {
    if (tabs.length > 0) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab ?? tabs[0].key });
      if (activeTab === 'invited_users') {
        searchQuery.setQuery({ status: 'pending' });
      }
    }
  }, [tabs.length]);

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(
    [`USERS_${activeTab?.toUpperCase()}`, page, limit, sortingParams],
    () =>
      activeTab === 'users' ? apiRequest.getAllUsers(page, limit, sortingParams) : apiRequest.getAllInvitedUser(page, limit, sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          title: 'Users',
          message: 'There has been an error getting users',
          type: 'danger',
          action: {
            action: () => {
              refetch();
            },
            name: 'Try again'
          }
        });
      },
      enabled: !!activeTab
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const navigateToUser = (id: string | number) => {
    if (
      (activeTab === 'users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_users.update', 'admin_user_audit_logs.view'])) ||
      (activeTab === 'invited_users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_user_invitation.delete']))
    ) {
      history.push(`/dashboard/users/${id}`, { from: activeTab });
    }
  };

  const handleTabChange = (selectedTab: TabType['key']) => {
    searchQuery.setQuery({ tab: selectedTab, page: '1', status: selectedTab === 'invited_users' ? 'pending' : undefined }, true);
  };

  const isUserTab = (currentActiveTab: TabType['key']) => currentActiveTab === 'users';

  const formatUserName = (name: string) => {
    if (!name) return '';
    return name;
  };

  const renderUsers = {
    rowFn: (user: IUser) => navigateToUser(isUserTab(activeTab) ? user?.user?.id : user?.code),
    className: '--users-table --row div-table-setup',
    isRowClickable:
      (activeTab === 'users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_users.update', 'admin_user_audit_logs.view'])) ||
      (activeTab === 'invited_users' && isAllowed(userAccess, ['admin_user_details.view', 'admin_user_invitation.delete'])),
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no users yet.',
    annotations: 'user(s)',
    fields: (user: IUser) => ({
      data: {
        status: (
          <span>
            <span className={`status-pill smaller ${switchStatus(isUserTab(activeTab) ? user?.user_status : user?.status)}`} />
            <span>{capitalizeRemovedash(isUserTab(activeTab) ? user?.user_status : user?.status || 'N/A')}</span>
          </span>
        ),
        name: (
          <span className="smaller no-ellipsis" style={{ color: 'rgb(35, 118, 243)' }}>
            {(
              <span>
                {`${isUserTab(activeTab) ? formatUserName(user?.user?.first_name) : ''} ${
                  isUserTab(activeTab) ? formatUserName(user?.user?.last_name) : ''
                }`}{' '}
                <span className="user-email-list" style={{ color: '#979797' }}>
                  {isUserTab(activeTab) ? `(${user?.user?.email})` : user?.email}
                </span>
              </span>
            ) || 'Not available'}
          </span>
        ),
        role: (
          <span className="smaller no-ellipsis">
            {capitalize(isUserTab(activeTab) ? user?.user?.adminRoles[0]?.name : user?.user_role?.name)}
          </span>
        ),
        last_login: (
          <>
            <span>{user?.user?.last_login ? getDate(user?.user?.last_login) : 'N/A'}</span>
            {user?.user?.last_login && <span className="annotation">{getTime(user?.user?.last_login)}</span>}
          </>
        )
      }
    })
  };

  const usersTable = () => {
    return (
      <div className="nav-content active">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {tabs.map(tab => (
                <li
                  className="nav-item"
                  key={tab.key}
                  style={{ position: 'relative' }}
                  onClick={() => handleTabChange(tab.key)}
                  onKeyUp={() => handleTabChange(tab.key)}
                  role="tab"
                  tabIndex={0}
                >
                  <button type="button" className={`nav-link ${activeTab === tab.key && 'active'}`}>
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <Table
          className="--users-table div-table-setup"
          hasPagination
          header={false}
          tableHeadings={['Status', 'Name/Email', 'Role', 'Last Active']}
          totalItems={paging?.total_items || 0}
          pageSize={paging?.page_size || 0}
          loading={isFetching}
          current={parseInt(page, 10)}
          actionFn={num => searchQuery.setQuery({ page: String(num) })}
          limitAction={no => searchQuery.setQuery({ limit: String(no) })}
          annotation="users"
          tableWrapperClassName="table-responsive"
          renderFields
          data={data}
          emptyStateHeading={renderUsers.emptyStateHeading}
          emptyStateMessage={renderUsers.emptyStateMessage}
          rowFn={renderUsers.rowFn}
          hasFilter={false}
        >
          {renderUsers.fields}
        </Table>
      </div>
    );
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12 users__page">
            <div className="element-wrapper">
              <h6 className="element-header">Dashboard Users</h6>
              {activeTab && (
                <FilterComponent
                  type={activeTab}
                  hasAdvancedFilter={false}
                  totalCount={paging?.total_items}
                  activeCurrency=""
                  hasBasicFilter
                />
              )}
              <div>{usersTable()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
