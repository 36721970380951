import mcLogo from '+assets/img/logos/mastercard.svg';
import visaLogo from '+assets/img/logos/visa.svg';

export const cardColorOptions = [
  { label: 'orange', value: '234.22deg, hsl(40, 100%, 64%) 2.32%, hsl(331, 87%, 31%) 138.6%' },
  { label: 'dark green', value: '222.14deg, hsl(187, 60%, 34%) -26.33%, hsl(217, 100%, 5%) 101.8%' },
  { label: 'purple', value: '220.68deg, hsl(253, 60%, 34%) 1.85%, hsl(217, 100%, 5%) 103.06%' }
];

export const getCardColorProp = (color: string) => {
  const targetObj = cardColorOptions.find(({ label }) => label === color) || cardColorOptions[2];
  return targetObj;
};

export type CardTypes = 'mastercard' | 'visa';

export const schemeLogoOptions: Record<CardTypes, string> = {
  mastercard: mcLogo,
  visa: visaLogo
};
