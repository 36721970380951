import InfoIcon from '+assets/img/dashboard/information-button.svg';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import APIRequest from '+services/api-services';
import Icon from '+shared/Icons';
import ToolTip from '+shared/Tooltip';
import { capitalize, capitalizeRemovedash, getDate, getTime, switchStatus } from '+utils';
import React from 'react';
import { TAccountHolderTransaction, TData, TEvent, TVirtualBankAccount } from './TypesAccountHolders';

const api = new APIRequest();
export const kycDetails = (data: TData) => [
  {
    'First Name': data?.first_name,
    'Last Name': data?.last_name,
    Email: data?.email,
    'Account Holder Reference': data?.reference,
    'Account holder Status': (
      <>
        <span className={`status-pill smaller ${switchStatus(data?.status)}`} />
        <span>{capitalize(data?.status || '')}</span>
      </>
    ),
    'Employment Status': capitalize(data?.employment?.status),
    Employer: capitalize(data?.employment?.employer)
  },
  {
    'Use Case': capitalize(data?.account_summary?.use_case),
    'Date Created': getDate(data?.date_created),
    'Date Verified': data?.account_summary?.date_verified ? getDate(data?.account_summary?.date_verified) : 'Not Verified',
    'Account Holder Type': capitalize(data?.account_type),
    'Postal Code': data?.address.zip,
    'Country Code': data.country
  }
];

export const kycNavHeaders = {
  'KYC Details': {
    header: "Account Holder's KYC Information",
    description: (
      <>
        These are the KYC details submitted for this account holder. Please ensure that the information provided are <b>valid</b> and{' '}
        <b>up to date.</b>
      </>
    )
  },
  'KYC Related Documents': {
    header: 'KYC Documents',
    description: (
      <>
        These are the documents submitted for this verification. Please ensure that these documents are <b>valid</b> and <b>up to date.</b>
      </>
    )
  },
  'Previous Approval/Rejections': {
    header: 'History of Previous Approvals/Rejections',
    description: 'Here’s a history of previous actions taken on this account holder’s KYC verification:'
  }
};

export const tableData = (subTab: string) => {
  switch (subTab) {
    case 'Account Numbers':
      return {
        className: '--vba-acc-nums',
        rowURL: '/dashboard/virtual-accounts',
        rowKey: 'korapay_reference',
        emptyStateHeading: 'No Account Numbers',
        emptyStateMessage: 'There are no account numbers yet.',
        annotations: 'account number(s)',
        fields: (iter: TVirtualBankAccount) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalize(iter?.status || '')}</span>
              </>
            ),

            'Account Name/Number': (
              <>
                <span style={{ fontWeight: 600, color: '#414F5F' }}>{iter?.account_name}</span>
                <span style={{ marginLeft: 5 }}>{iter?.account_number}</span>
              </>
            ),
            bank: <span>{iter.bank_name}</span>,
            currency: <span>{iter.currency}</span>,
            tier: <span>Tier {iter.tier}</span>,

            date_created: (
              <>
                <span>{getDate(iter.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(iter.created_at)}
                </span>
              </>
            )
          }
        })
      };

    case 'Transactions':
      return {
        className: '--vba-acc-trx',
        rowURL: '/dashboard/pay-ins',
        rowKey: 'reference',
        emptyStateHeading: 'No Transactions',
        emptyStateMessage: 'There are no transactions yet',
        annotations: 'transaction(s)',
        fields: (iter: TAccountHolderTransaction) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>
                  {capitalize(iter?.status || '')}
                  {iter?.status?.includes('flagged') && <Icon name="warningTriangle" width={16} height={16} style={{ marginLeft: 5 }} />}
                </span>
              </>
            ),

            transaction_id: <span style={{ fontWeight: 600, color: '#2376F3' }}>{iter?.reference}</span>,
            transaction_date: (
              <>
                <span>{getDate(iter.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(iter.created_at)}
                </span>
              </>
            ),
            amount: (
              <span>
                {iter.amount} {iter.currency}
              </span>
            )
          }
        })
      };
    case 'Events':
      return {
        type: 'events',
        className: '--vba-acc-holder-events',
        rowKey: 'reference',
        emptyStateHeading: 'No Account Events',
        emptyStateMessage: 'There are no account holder events yet.',
        annotations: 'account holder event(s)',
        fields: (iter: TEvent) => ({
          data: {
            timestamp: (
              <>
                <span className="sec-details-key">{getDate(iter.created_at)}</span>
                <span style={{ color: '#A9AFBC', marginLeft: 8 }}>{getTime(iter.created_at)}</span>
              </>
            ),
            event: (
              <>
                <span style={{ marginRight: 6 }}>{capitalize(iter.event_type)}: </span>
                <span style={{ color: '#A9AFBC', marginLeft: 4, maxWidth: 'unset' }}>{capitalize(iter.event_action)} </span>
                <span>
                  <ToolTip disabled image={InfoIcon} type="event-id" message="event details is very importnat to the gesture" />
                </span>
              </>
            ),
            event_ID: <span style={{ color: '#2376F3', maxWidth: 'unset' }}>{iter.reference}</span>
          }
        })
      };
    default:
      return {};
  }
};

export const kycFullDetails = (data: TData) => {
  const countryCode = {
    NG: 'Nigeria',
    US: 'United States'
  };
  return [
    {
      Occupation: data.occupation || 'Not provided',
      'Phone Number': data.phone_number || 'Not provided',
      'ID Document Type': capitalize(data.account_summary?.verified_identity.type) || 'Not provided',
      'ID Document No.': data.account_summary?.verified_identity?.number || 'Not provided',
      'Date of Birth': getDate(data.date_of_birth) || 'Not provided',
      'Address Line 1': data.address?.address || 'Not provided',
      'Address Line 2': 'Not provided',
      City: data.address?.city || 'Not provided',
      Country: countryCode[data.address?.country as keyof typeof countryCode] || data.address?.country || 'Not provided',
      'Country Code': data.address?.country || 'Not provided',
      'ZIP Code': data.address?.zip || 'Not provided',
      'Employment Description': data?.employment?.description
    }
  ];
};

export const routeQuery = {
  'Account Numbers': {
    query: api.getAllVirtualBankAccounts,
    errorMsg: "There has been an error getting this account's holder virtual accounts"
  },
  Transactions: {
    query: api.getAllAccountHolderTransactions,
    errorMsg: "There has been an error getting this account's holder virtual accounts"
  },
  KYC: {
    query: api.getAccountHolderKycInfo,
    errorMsg: "There has been an error getting this account's holder transactions"
  },
  Events: {
    query: api.getAccountHolderEvent,
    errorMsg: "There has been an error getting this account's holder virtual accounts"
  }
};
