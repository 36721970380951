import React from 'react';

export const modalContent = (modalState: string) =>
  ({
    overpayment: {
      heading: 'Overpayments',
      description: 'Over-payment occurs when a customer is expected to pay a fixed amount but pays more than the expected amount.',
      content: (
        <>
          <article data-testid="overpayment-info-modal">
            <h6>Accept All</h6>
            <p>This means you would receive any paid below the expected amount.</p>
          </article>
          <article className="mt-4">
            <h6>Return All</h6>
            <p>We would return the amount paid by the customer instantly.</p>
          </article>
          <article className="mt-4">
            <h6>Return Excess</h6>
            <p>We would receive the amount expected but return the excess amount</p>
          </article>
        </>
      )
    },
    underpayment: {
      heading: 'Underpayments',
      description: 'Underpayment occurs when a customer is expected to pay a fixed amount but pays less than the expected amount.',
      content: (
        <>
          <article data-testid="underpayment-info-modal">
            <h6>Accept All</h6>
            <p>This means you would receive any paid below the expected amount.</p>
          </article>
          <article className="mt-4">
            <h6>Return All</h6>
            <p>We would return the amount paid by the customer instantly.</p>
          </article>
        </>
      )
    }
  }[modalState]);

export const test = {};
