import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import './index.scss';

interface FeedbackHandlerProps {
  component: boolean;
}
/* This component handles feedback for this app in the dashboard, It can be called from an action or a component.
 * It in initialized by dispatching an action in the store 'initFeedback' in '+store/actions/feedbackHandler',
 * It is also closed from the same file by dispatching 'closeFeedback'
 * This component can receive a func and call it when passed through the prop 'action'
 * This component isn't timed for UX purposes, it's closed by the user's action to close the component or by changing the route in which the feedback was initialized
 * This component can be show either a success or danger feedback
 */

export default function FeedbackHandler({ component = false }: FeedbackHandlerProps) {
  const location = useLocation();
  const { closeFeedback, data } = useFeedbackHandler();
  const { message, type, isActive, title, isClosable, action, statusCode, componentLevel, callback } = data;

  useLayoutEffect(() => {
    if (callback) callback();
    return () => {
      if (callback) callback();
    };
  }, []);

  useLayoutEffect(() => {
    closeFeedback();
  }, [location]);

  const handleAction = () => {
    // INCASE YOU NEED TO PASS A FUNCTION TO THE FEEDBACK HANDLER E.G RELOAD PAGE
    action?.action();
    closeFeedback();
  };

  const getStatusText = (code: number) => {
    switch (code) {
      case 404:
        return 'Not Found:';
      case 500:
        return 'Server Error:';
      default:
        return null;
    }
  };

  const onClose = () => {
    $('.feedback').addClass('close-feedback');
    setTimeout(() => {
      closeFeedback();
    }, 300);
  };

  return isActive ? (
    <div
      className={`feedback ${type} ${componentLevel ? 'full-width component-level' : `full-width ${component && 'd-none'}`}`}
      role="alert"
    >
      {title ? <strong>{title} </strong> : null}
      {statusCode ? `${getStatusText(statusCode)} :` : null}
      <span className="feedback-message">{message}</span>
      {action !== null ? (
        <button className="btn-action" onClick={handleAction} type="button">
          {action?.name}
        </button>
      ) : null}
      {isClosable ? (
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClose}>
          <span aria-hidden="true"> &times;</span>
        </button>
      ) : null}
    </div>
  ) : null;
}
