/* eslint-disable import/prefer-default-export */
import React from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import { TTransactionDetails } from '+types';
import { capitalizeFirst, formatAmount, getDate, getTime, truncateString } from '+utils';

export const trxBreakdownOptions = ({
  data,
  merchantName,
  history,
  source_currency,
  conversionFee,
  source_amount,
  converted_amount,
  swapTransactionDate,
  destination_currency,
  merchantAccount,
  swapReference
}: {
  data: TTransactionDetails;
  merchantName: string;
  history: { push: (path: string, state?: unknown, info?: null) => void };
  source_currency: string;
  source_amount: string;
  conversionFee: string;
  converted_amount: string;
  swapTransactionDate: string;
  destination_currency: string;
  merchantAccount: { name: string };
  swapReference: string;
}) => {
  const {
    reference,
    amount,
    amount_paid: netAmount,
    channel,
    completed_at: completedAt,
    merchant_id: merchantID,
    currency,
    fee,
    vat,
    payment,
    status,
    account,
    payment_source,
    payment_source_reference: korapayReference,
    narration,
    created_at,
    approved_amount,
    merchant,
    card,
    date
  } = data || {};

  return {
    common: {
      ...(status !== 'pre_authorized' && {
        net: `${
          payment?.sentinal_transaction
            ? formatAmount(+netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee))
            : formatAmount(netAmount)
        } ${currency}`
      }),
      fee: `${formatAmount(
        parseFloat(fee || '0') +
          parseFloat(vat || '0') +
          // eslint-disable-next-line no-unsafe-optional-chaining
          (+(payment?.sentinal_transaction?.vat || 0) + +(payment?.sentinal_transaction?.processing_fee || 0))
      )} ${currency}`,
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
      merchant: merchantName ? (
        <button type="button" className="merchant-name" onClick={() => history.push(`/dashboard/merchant/${merchantID}`)}>
          {merchantName}
        </button>
      ) : (
        'Not Available'
      ),
      description: `${channel === 'web' ? 'Balance Top Up' : narration || 'Not Available'}`
    },
    'virtual-card': {
      net_amount: `${formatAmount(amount)} ${currency}`,
      fee: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,
      'date_/_time': `${getDate(date)} ${getTime(date)}`,
      cardholder: `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      'Transaction ID': (
        <>
          <Copyable text={reference?.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
          <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => {
              const cardType = card?.reserved ? 'reservedCards' : 'issuedCards';
              history.push(`/dashboard/card-issuance/${cardType}/${card?.reference}`);
            }}
          >
            <span>See Virtual Card</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    refunds: {
      refund_amount: `${formatAmount(amount) || 'Not available'}`,
      date_created: created_at ? `${getDate(created_at)}, ${getTime(created_at)}` : 'Not Available',
      merchant: <span>{capitalizeFirst(account?.name || 'Not Available')}</span>,
      amount_paid: `${formatAmount(payment_source?.amount) || 'Not available'}`,
      transaction_id: (
        <>
          <Copyable text={payment?.reference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => history.push(`/dashboard/pay-ins/${payment_source?.reference}`)}
          >
            <span>See original transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    chargebacks: {
      dispute_amount: `${formatAmount(amount)} ${currency}`,
      accepted_amount: `${formatAmount(approved_amount)} ${currency}`,
      rejected_amount: `${formatAmount(status === 'pending' ? 0 : +amount - +approved_amount)} ${currency}`,
      date_contested: `${getDate(created_at)}, ${getTime(created_at)}`,
      merchant: `${merchant}`,
      transaction_id: (
        <>
          <Copyable text={korapayReference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
          <button type="button" className="btn btn-original" onClick={() => history.push(`/dashboard/pay-ins/${korapayReference}`)}>
            <span>View Initial Transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    'currency-exchange': {
      amount_converted: `${formatAmount(source_amount)} ${source_currency}`,
      converted_to: `${formatAmount(converted_amount)} ${destination_currency}`,
      revenue: `${formatAmount(parseFloat(conversionFee || '0') + parseFloat(vat || '0'))} USD`,
      date_and_time: `${getDate(swapTransactionDate)}, ${getTime(swapTransactionDate)}`,
      merchant: <span style={{ color: 'rgb(35, 118, 243)' }}>{merchantAccount?.name}</span>,
      transaction_id: (
        <>
          <Copyable text={swapReference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
        </>
      )
    }
  };
};
