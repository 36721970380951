import React from 'react';

import './TierIcon.scss';

const TierIcon = ({
  id = 0,
  roundedBorderColor,
  variant
}: {
  id?: number;
  roundedBorderColor?: string;
  variant?: 'blue' | 'yellow' | 'default';
}) => {
  return (
    <div className={`tier_icon_wrapper ${variant || ''}`}>
      <div className="tier_icon_step" />
      <div className="tier_icon_step" />
      <div className="tier_icon_step" />
      <div className="tier_icon_circle" style={{ borderColor: roundedBorderColor || '#fff', color: roundedBorderColor || '#fff' }}>
        {id > 0 && id}
      </div>
    </div>
  );
};

export default TierIcon;
