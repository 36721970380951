import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Balances from './Balances';
import PartnerBalanceHistoryDetails from './PartnerBalanceHistoryDetails';
import PartnersBalanceHistory from './PartnersBalanceHistory';

const PartnersBalance = () => {
  return (
    <Switch>
      <Route path="/dashboard/partners-balance" exact component={Balances} />
      <Route path="/dashboard/partners-balance/:accountId" exact component={PartnersBalanceHistory} />
      <Route path="/dashboard/partners-balance/transaction/:reference" exact component={PartnerBalanceHistoryDetails} />
    </Switch>
  );
};

export default PartnersBalance;
