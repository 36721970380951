/* eslint-disable camelcase */
import { create } from 'zustand';
import { combine, createJSONStorage, devtools, persist } from 'zustand/middleware';

import { StoreStorage } from '+utils';

export type chargebackContentT = {
  id: string;
  processor_reference: string | null;
  amount: number | null;
  reason: string | null;
};
export interface IChargebackData {
  processor: string;
  currency: string;
  deadline: string;
  chargebacks: chargebackContentT[];
}

interface IChargebackState {
  chargebackData: IChargebackData;
  paginatedData: {
    data: chargebackContentT[];
    paging: {
      total_items: number;
      page_size: number;
      current: number;
      count: number;
      next: number;
    };
  };
}

interface IChargebackAction {
  setChargebackData: (data: IChargebackData) => void;
  setPaginatedData: ({ page_size, current }: Extract<IChargebackState['paginatedData']['paging'], 'page_size' | 'current'>) => void;
  clearChargebackData: () => void;
  deleteChargeback: (id: string) => void;
  addChargeback: (data: chargebackContentT) => void;
  editChargeback: (data: chargebackContentT) => void;
}

const initialState = {
  chargebackData: {
    processor: '',
    currency: '',
    deadline: '',
    chargebacks: []
  } as IChargebackData,
  paginatedData: {
    data: [
      {
        id: '',
        processor_reference: '',
        amount: 0,
        reason: ''
      }
    ],
    paging: {
      total_items: 0,
      page_size: 10,
      current: 1,
      count: 10,
      next: 2
    }
  }
};

const useChargebackStore = create(
  devtools(
    persist(
      combine<IChargebackState, IChargebackAction>(initialState, set => ({
        setChargebackData: (data: IChargebackData) =>
          set(state => ({
            ...state,
            chargebackData: data,
            paginatedData: {
              data: data.chargebacks.slice(0, state.paginatedData.paging.page_size),
              paging: {
                ...state.paginatedData.paging,
                total_items: data.chargebacks.length
              }
            }
          })),
        setPaginatedData: ({ page_size, current }: Extract<IChargebackState['paginatedData']['paging'], 'page_size' | 'current'>) =>
          set(state => ({
            ...state,
            paginatedData: {
              data: state.chargebackData.chargebacks.slice(page_size * (current - 1), page_size * current),
              paging: {
                ...state.paginatedData.paging,
                page_size,
                current,
                next: current + 1
              }
            }
          })),
        deleteChargeback: (id: string) =>
          set(state => {
            const newData = state.chargebackData.chargebacks.filter(items => items.id !== id);
            const { current, page_size } = state.paginatedData.paging;
            const newCurrent = newData.length < page_size * (current - 1) + 1 ? current - 1 : current;
            return {
              ...state,
              chargebackData: { ...state.chargebackData, chargebacks: newData },
              paginatedData: {
                ...state.paginatedData,
                data: newData.slice(page_size * (newCurrent - 1), page_size * newCurrent),
                paging: {
                  ...state.paginatedData.paging,
                  total_items: newData.length,
                  current: newCurrent
                }
              }
            };
          }),
        addChargeback: (data: chargebackContentT) =>
          set(state => {
            const newData = [...state.chargebackData.chargebacks, data];
            if (state.paginatedData.data.length === state.paginatedData.paging.page_size) {
              state.paginatedData.data.pop();
            }
            return {
              ...state,
              chargebackData: { ...state.chargebackData, chargebacks: newData },
              paginatedData: {
                ...state.paginatedData,
                data: [data, ...state.paginatedData.data],
                paging: {
                  ...state.paginatedData.paging,
                  total_items: newData.length
                }
              }
            };
          }),
        editChargeback: (data: chargebackContentT) =>
          set(state => {
            const { current, page_size } = state.paginatedData.paging;
            const newData = state.chargebackData.chargebacks.map(items => {
              if (items.id === data.id) {
                return data;
              }
              return items;
            });
            return {
              ...state,
              chargebackData: { ...state.chargebackData, chargebacks: newData },
              paginatedData: {
                ...state.paginatedData,
                data: newData.slice(page_size * (current - 1), page_size * current)
              }
            };
          }),
        clearChargebackData: () => {
          set(initialState);
        }
      })),
      {
        name: 'chargeback',
        storage: createJSONStorage(() => StoreStorage)
      }
    ),
    {
      enabled: process.env.NODE_ENV !== 'production'
    }
  )
);

export default useChargebackStore;
