import React, { useRef, useState, useEffect } from 'react';
import ellipsis from '+assets/img/dashboard/ellipsis-blue.svg';
import './HoverMenu.scss';

interface IHoverMenuProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}
function HoverMenu({ title, children, className }: IHoverMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const hoverButtonElement = useRef(null);
  const hoverListElement = useRef(null);
  const hoverWrapperElement = useRef(null);

  useEffect(() => {
    const listElement = hoverListElement.current;
    const hoverButton = hoverButtonElement.current;
    if (listElement) {
      if (isOpen) {
        const firstListFocusableItem = listElement.childNodes?.[0].childNodes?.[0];
        if (!firstListFocusableItem) return;
        firstListFocusableItem.focus();
        return;
      }
      hoverButton.focus();
    }
  }, [isOpen]);

  const handleKeyboard = useRef(e => {
    // Escape button closes the menu
    if (e.keyCode === 27) {
      setIsOpen(false);
      return;
    }
    // Spacebar on close button closes the menu
    if (
      e.keyCode === 32 &&
      hoverWrapperElement.current &&
      e.target === hoverWrapperElement.current.childNodes[hoverWrapperElement.current.childNodes.length - 1]
    ) {
      setIsOpen(false);
      return;
    }
    // Tabbing forwards on the Close button inside modal returns to the first menu item
    if (
      e.keyCode === 9 &&
      hoverWrapperElement.current &&
      document.activeElement === hoverWrapperElement.current.childNodes[hoverWrapperElement.current.childNodes.length - 1]
    ) {
      hoverListElement.current.childNodes[0].childNodes[0].focus();
    }
  });

  const toggleKeyboard = useRef(e => {
    e.preventDefault();
    const path = e?.path || e?.composedPath?.() || e.target;
    // Spacebar opens the tooltip
    if (e.keyCode === 32 && path.includes(hoverButtonElement.current)) {
      setIsOpen(prevIsOpen => !prevIsOpen);
      return;
    }
    // Keyboard Actions in Dropdown List
    if (path.includes(hoverListElement?.current)) {
      // Spacebar on button selects the item and closes dropdown
      if (e.keyCode === 32) {
        e.target.click();
        setIsOpen(prevIsOpen => !prevIsOpen);
      }
    }
  });

  const closeOuter = useRef(e => {
    e.preventDefault();
    const path = e?.path || e?.composedPath?.() || e.target;
    // Close menu when clicking outside
    if (e.target !== hoverButtonElement?.current && !path.includes(hoverWrapperElement.current?.parentNode || null)) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    const closeOuterFunc = closeOuter.current;
    const handleKeyboardFunc = handleKeyboard.current;
    const toggleKeyboardFunc = toggleKeyboard.current;

    // Clicking outside closes the tooltip
    window.addEventListener('click', closeOuterFunc);
    window.addEventListener('keydown', handleKeyboardFunc);
    window.addEventListener('keyup', toggleKeyboardFunc);

    return () => {
      window.removeEventListener('click', closeOuterFunc);
      window.removeEventListener('keydown', handleKeyboardFunc);
    };
  }, []);

  return (
    <div className={`hover-menu ${className}`}>
      <button
        className="hover-menu__button"
        tabIndex="0"
        type="button"
        aria-label={`${title} settings`}
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
        aria-expanded={isOpen}
        ref={hoverButtonElement}
      >
        <img src={ellipsis} alt="menu trigger icon" className="hover-menu__image" aria-hidden />
      </button>

      {isOpen && (
        <div className="hover-menu__wrapper" ref={hoverWrapperElement}>
          <ul className="hover-menu__list" ref={hoverListElement}>
            {children}
          </ul>
          <button className="close sr-only" type="button" aria-label="close tooltip" onClick={() => setIsOpen(false)}>
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default HoverMenu;
