import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { TPaymentPreferenceRequest } from '+types';
import { capitalize, capitalizeRemovedash, getDate, getTime } from '+utils';

import BusinessAvatar from '+assets/theme/img/biz-avatar.png';

const icon = {
  pending: { name: 'warningTriangle', size: 14, stroke: '' },
  rejected: { name: 'invalid', size: 12, stroke: 'red' },
  approved: {
    name: 'check',
    size: 10,
    stroke: 'green'
  }
} as const;

// eslint-disable-next-line import/prefer-default-export
export const allPreferencesTableContent = ({ data }: { data: TPaymentPreferenceRequest[] }) => ({
  className: '--payment-preferences-table',
  emptyStateHeading: 'No Preference Request',
  data,
  emptyStateMessage: `Merchant’s Preference requests for the “Bank Transfer” payment method will appear here.`,
  rowURL: '/dashboard/merchant',
  rowKey: 'merchant_id',
  rowUrlQuery: '?tab=Payment Preferences',
  fields: (each: TPaymentPreferenceRequest) => ({
    ...(each.status === 'pending' && { style: { background: '#FFF7ED' } }),
    data: {
      Merchant: (
        <>
          <img alt="business avatar" src={BusinessAvatar} />
          <span id="merchant-name">{capitalize(each.merchant.name)}</span>
        </>
      ),
      Status: (
        <>
          <Icon
            name={icon[each?.status as keyof typeof icon]?.name}
            stroke={icon[each?.status as keyof typeof icon]?.stroke || undefined}
            width={icon[each?.status as keyof typeof icon]?.size}
            height={icon[each?.status as keyof typeof icon]?.size}
            strokeWidth={4}
            style={{ marginRight: 8 }}
          />
          <span>{capitalizeRemovedash(each.status || 'N/A')}</span>
        </>
      ),
      merchant_id: each.merchant_id,
      Contact: <span className="lighter">{each.merchant.email}</span>,
      Date_Created: (
        <>
          <span>{getDate(each.createdAt)}</span>
          <span className="annotation merchant-table-date-created-container">{getTime(each.createdAt)}</span>
        </>
      )
    }
  })
});
