import { useState } from 'react';
import { PaginationT } from '+types';

const usePagination = (
  initialPage: number,
  initialLimit: number
): [PaginationT, (page: number | string, limit: number) => void, (limit: number | string) => void] => {
  const [pagination, setPagination] = useState<PaginationT>({ page: initialPage, limit: initialLimit });

  const handlePagination = (page: number | string, limit: number) => {
    setPagination((prev) => ({
      ...prev,
      page: Number(page),
      limit: limit
    }));
  };

  const handleLimit = (limit: number | string) => {
    setPagination((prev) => ({
      ...prev,
      limit: Number(limit),
      page: 1
    }));
  };

  return [pagination, handlePagination, handleLimit];
};

export default usePagination;
