import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import { logError, history } from '+utils';
import SpinnerIcon from '+assets/img/dashboard/spinner.svg';
import logo from '+assets/theme/img/kpy-logo-kora.svg';
import './index.scss';

const apiRequest = new APIRequest();

const UserInviteVerification = () => {
  const { code } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const userAccecptInviteMutation = useMutation(({ code }) => apiRequest.acceptAdminUserInvite(code), {
    onError: error => {
      logError(error);

      feedbackInit({
        message: `There has been an error verifying your invitation`,
        type: 'danger'
      });
      setTimeout(() => history.push('/invite-denial'), 5000);
    },
    onSuccess: () => {
      history.push('/');
    }
  });

  useEffect(() => {
    if (code) {
      userAccecptInviteMutation.mutate({ code });
    }
  }, [code]);

  return (
    <div className="cont-verify">
      <div className="logo-wh">
        <a href="/">
          <img className="verify-logo" alt="Korapay" src={logo} />
        </a>
      </div>
      <div className="invite-spinner">
        <p style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
          <img alt="SpinnerIcon" src={SpinnerIcon} className="rotate" role="status" aria-hidden="true" />
        </p>
      </div>
    </div>
  );
};

export default UserInviteVerification;
