import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import { logError } from '+utils';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import UpgradeRequestModalContent from './components/UpgradeRequestModalContent';
import { TUpgradeRequestDetails } from '../data';

const api = new APIRequest();

type TUpgradeRequestModal = Omit<IModalProps, 'close'> & { buttonState?: boolean; firstButtonState?: boolean };

const UpgradeRequestDetails = ({ data, updateModalState }: { data: TUpgradeRequestDetails; updateModalState: () => void }) => {
  const { feedbackInit } = useFeedbackHandler();
  const [status, setStatus] = useState<string>('');
  const [reasonText, setReasonText] = useState<string>('');
  const queryClient = useQueryClient();

  const [modal, setModal] = useState({
    modalType: 'upgrade',
    modalInfo: { content: <LoadingPlaceholder type="text" />, showButtons: false } as TUpgradeRequestModal
  });

  useEffect(() => {
    if (data) setModalVisible('upgrade');
  }, [data]);

  const { mutateAsync } = useMutation(() => api.upgradeVirtualBankAccount(data?.reference, status, status === 'declined' && reasonText), {
    onSuccess: () => {
      queryClient.invalidateQueries('UPGRADE_REQUESTS');
      queryClient.invalidateQueries(data.reference);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error upgrading the account`,
        type: 'danger'
      });
    }
  });

  function renderDeclineForm() {
    return (
      <div className="form-group">
        <label htmlFor="reason-text">
          <span className="dark">Reason for decline</span>
        </label>
        <textarea
          rows={3}
          maxLength={1500}
          name="reason-text"
          className="form-control"
          placeholder=""
          onChange={(e) => setReasonText(e.target.value)}
          data-testid="reason-text"
        />
      </div>
    );
  }

  const modalDetails = {
    upgrade: {
      heading: 'Account Upgrade Request',
      description:
        'This is a request to upgrade an account number. See a summary of the request and the relevant information provided for the upgrade.',
      firstButtonText: 'Decline Request',
      secondButtonText: 'Approve Request',
      content: <UpgradeRequestModalContent data={data} />,
      secondButtonActionIsTerminal: false,
      showButtons: data?.status === 'pending',
      secondButtonAction: () => {
        setStatus('approved');
        setModalVisible('confirmUpgrade');
      },
      firstButtonAction: () => {
        setStatus('declined');
        setModalVisible('declineUpgrade');
      }
    },
    confirmUpgrade: {
      heading: 'Approve Upgrade Request?',
      description: 'Please confirm that you want to approve this upgrade request. Note that this action cannot be undone.',
      completedHeading: 'Done!',
      completedDescription: 'You have successfully approved this upgrade request',
      firstButtonAction: () => {
        updateModalState();
        setModal({ modalType: '', modalInfo: {} as IModalProps });
      },
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Approve',
      buttonState: false,
      secondButtonAction: mutateAsync,
      size: 'sm'
    },
    declineUpgrade: {
      heading: 'Decline Upgrade Request?',
      description: 'Please confirm that you want to decline this upgrade request with a reason. Note that this action cannot be undone.',
      completedHeading: 'Done!',
      completedDescription: 'You have successfully declined this upgrade request',
      firstButtonAction: () => {
        updateModalState();
        setModal({ modalType: '', modalInfo: {} as IModalProps });
      },
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Decline',
      content: renderDeclineForm(),
      buttonState: !!reasonText,
      secondButtonAction: mutateAsync,
      size: 'sm'
    }
  } as unknown as Record<string, Omit<IModalProps & { buttonState?: boolean; firstButtonState?: boolean }, 'close'>>;

  const setModalVisible = (type: string) => {
    setModal({
      modalType: type,
      modalInfo: {
        heading: modalDetails[type].heading,
        description: modalDetails[type].description,
        completedHeading: modalDetails[type].completedHeading,
        completedDescription: modalDetails[type].completedDescription,
        content: modalDetails[type].content,
        firstButtonText: modalDetails[type].firstButtonText,
        secondButtonText: modalDetails[type].secondButtonText,
        secondButtonAction: modalDetails[type].secondButtonAction,
        firstButtonAction: modalDetails[type].firstButtonAction,
        secondButtonActionIsTerminal: modalDetails[type].secondButtonActionIsTerminal,
        size: modalDetails[type].size,
        buttonState: modalDetails[type].buttonState,
        secondButtonColor: modalDetails[type]?.secondButtonColor,
        firstButtonState: modalDetails[type]?.firstButtonState,
        showButtons: modalDetails[type]?.showButtons
      }
    });
  };

  return (
    <div>
      <Modal
        visible={modal.modalType !== null}
        close={() => {
          updateModalState();
          setModal({ modalType: '', modalInfo: {} as IModalProps });
        }}
        heading={modal.modalInfo?.heading || ''}
        description={modal.modalInfo?.description}
        content={modal.modalInfo?.content}
        size={modal.modalInfo?.size || 'md'}
        firstButtonText={modal.modalInfo?.firstButtonText}
        secondButtonText={modal.modalInfo?.secondButtonText}
        firstButtonAction={() => modal.modalInfo?.firstButtonAction?.()}
        secondButtonAction={() => modal.modalInfo?.secondButtonAction?.()}
        secondButtonDisable={modal.modalType === 'declineUpgrade' && !reasonText.trim()}
        firstButtonDisable={modal.modalInfo?.firstButtonState}
        secondButtonActionIsTerminal={modal.modalInfo?.secondButtonActionIsTerminal}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        completedModalSize={modal.modalInfo?.completedModalSize}
        showButtons={modal.modalInfo?.showButtons}
        secondaryCompletedModal
        firstButtonColor="#F32345"
        firstButtonTextColor="#FFFFFF"
        secondButtonColor="#24B314"
        headerBottomBorder
      />
    </div>
  );
};

export default UpgradeRequestDetails;
