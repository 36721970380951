import React from 'react';
import { useQuery } from 'react-query';

import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import { CurrencyType, modalState, productCategoriesType } from '+types';
import { capitalize, formatProductConfigStatus, productMapping, switchTrxnMessage } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import ProductCards from '../components/ProductCards';

import './index.scss';

const api = new APIRequest();
const Categories = ({ product, currency }: { product: productCategoriesType; currency: CurrencyType }) => {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });

  const params = {
    payment_type: productMapping[product]
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCTS`, currency, product], () => api.getProductConfiguration(currency, params), {
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    }
  });

  const productStatus = configData?.data?.setting?.enabled;
  return (
    <>
      {isLoading ? (
        <LoadingPlaceholder type="text" background="#f5f6f6" />
      ) : (
        <div className="payins-container">
          <div className="payins-container__first">
            <div>
              <span>
                <h4>{capitalize(product)}</h4>
                <span
                  className="status"
                  style={{
                    color: switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.color,
                    backgroundColor: switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.backgroundColor
                  }}
                >
                  {switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.name}
                </span>
              </span>
              <p>{`Here you can find the ${capitalize(
                product
              )?.toLowerCase()} products for this currency. You can modify the limits and payment channels configuration.`}</p>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-secondary --enable-btn"
                onClick={() =>
                  setState({
                    activeModal: productStatus ? 'disable-product-all' : 'enable-product-all'
                  })
                }
                title="more"
              >
                {`${productStatus ? 'Disable' : 'Enable'} ${product} for all merchant`}
              </button>
            </div>
          </div>
          {configData && Object.entries(configData?.data?.setting).length > 0 && !isLoading && (
            <ProductCards currency={currency} config={configData?.data?.setting} product={product} />
          )}
        </div>
      )}
      {state.activeModal && (
        <MerchantCurrencyModal
          activeModal={state.activeModal}
          setActiveModal={() => setState({ activeModal: '' })}
          productType={productMapping[product]}
          currency={currency}
          hideButton
        />
      )}
    </>
  );
};

export default Categories;
