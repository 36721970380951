import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { TCurrentPaymnentPreferenceSettings, TPaymentPreferenceRequest } from '+types';
import { capitalize, capitalizeFirst, capitalizeRemovedash, getDate, getTime, isAllowed } from '+utils';

import PreferenceDropdown from '../paymentPreferenceTabsWidgets/PreferenceDropdown';
import PreferenceStatus from '../paymentPreferenceTabsWidgets/PreferenceStatus';
import { modalContent } from './preferenceRequestData';

import './preference-request.scss';

const api = new APIRequest();

export default function PreferenceRequest({
  pendingRequest,
  currentPref
}: {
  pendingRequest: TPaymentPreferenceRequest;
  currentPref: TCurrentPaymnentPreferenceSettings;
}) {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState('');
  const [declineReason, setDeclineReason] = useState('');
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();

  const { mutateAsync: approveOrRejectAction } = useMutation(
    (arg: { status: string; comments?: string }) => api.approveOrRejectPaymentPreferenceRequest({ id: pendingRequest.id, ...arg }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`MERCHANTS_PREFERENCE_REQUESTS`);
        queryClient.invalidateQueries(`${id}_MERCHANT_PAYMENT_PREFERENCE`);
        queryClient.invalidateQueries(`${id}_CURRENT_PAYMENT_PREFERENCE`);

        setModalState('success');
        feedbackInit({
          message: 'Payment Preference has been updated',
          type: 'success',
          componentLevel: true
        });
      },
      onError: (e: { response: { data: { data: { status: { customErrorMessage: string } } } } }) => {
        feedbackInit({
          message: capitalizeFirst(e?.response?.data?.data?.status?.customErrorMessage || 'Unable to perform payment preference action'),
          type: 'danger',
          componentLevel: true,
          action: {
            action: () => {
              setTimeout(() => closeFeedback(), 3000);
            },
            name: 'Close'
          }
        });
      }
    }
  );

  const modal = modalContent({ declineReason, setDeclineReason, setModalState, modalState, approveOrRejectAction });

  const canModify = isAllowed(userAccess, ['bank_transfer_settings_requests.process']);

  return (
    <section data-testid="preference-request" className="preference-request">
      <div className="preference-request__headers">
        <div>
          <h5 data-testid="preference-request-title">Preference Request</h5>
          <p data-testid="preference-request-subtitle" className="subtitle">
            Here is the merchant&apos;s request to update their payment preference. Ensure the{' '}
            <b>Letter of Attestation is signed by an authorized signatory.</b>
          </p>
        </div>

        <div className="btn-group">
          {canModify && pendingRequest.status && (
            <>
              <button data-testid="approve" onClick={() => setModalState('approve')} type="button">
                Approve
              </button>
              <button data-testid="decline" onClick={() => setModalState('decline')} type="button">
                Decline
              </button>
            </>
          )}
        </div>
      </div>
      {pendingRequest.status ? (
        <div className="preference-dropdown-wrapper">
          <PreferenceDropdown header="Requested Preference">
            <>
              <div className="preference-dropdown__item">
                <p data-testid="preference-dropdown-item-title">Overpayment</p>
                <PreferenceStatus
                  from={capitalizeRemovedash(currentPref.overpayment.action)}
                  to={capitalizeRemovedash(pendingRequest.payment_events.overpayment)}
                />
              </div>
              <div className="preference-dropdown__item">
                <p data-testid="preference-dropdown-item-title">Underpayment</p>
                <PreferenceStatus
                  from={capitalizeRemovedash(currentPref.underpayment.action)}
                  to={capitalizeRemovedash(pendingRequest.payment_events.underpayment)}
                />
              </div>
            </>
          </PreferenceDropdown>

          <PreferenceDropdown header="Request Information">
            <>
              <div className="preference-dropdown__item">
                <p data-testid="preference-dropdown-item-title">Request Status</p>
                <div data-testid="preference-request-status">
                  <Icon name="warningTriangle" width={18} height={18} /> {capitalize(pendingRequest.status)}
                </div>
              </div>
              <div className="preference-dropdown__item">
                <p data-testid="preference-dropdown-item-title">Date Requested</p>
                <div data-testid="preference-request-time">
                  {getDate(pendingRequest.createdAt)} <span className="grey-text">{getTime(pendingRequest.createdAt)}</span>
                </div>
              </div>
            </>
          </PreferenceDropdown>

          <PreferenceDropdown header="Letter of Attestation">
            <div className="attestation">
              <div>
                <Icon name="file" width={18} height={18} fill="#414F5F" style={{ marginRight: 8 }} /> Signed Attestation Letter
              </div>
              <a
                href={pendingRequest.document_reference}
                download="letter_of_attestation.pdf"
                aria-label="download attestation doecument link"
              >
                Download <Icon name="download" width={18} height={18} style={{ marginLeft: 8 }} />
              </a>
            </div>
          </PreferenceDropdown>

          <Modal
            visible={!!modalState}
            size={modal?.size}
            close={() => {
              setModalState('');
              setDeclineReason('');
            }}
            heading={modal?.heading}
            description={modal?.description}
            content={modal?.content?.()}
            firstButtonText="Cancel"
            secondButtonText={modal?.secondButtonText}
            secondButtonColor={modal?.secondButtonColor}
            secondButtonAction={modal?.secondButtonAction}
            secondButtonDisable={modal?.secondButtonDisable}
            secondButtonActionIsTerminal={modal?.secondButtonActionIsTerminal}
            showButtons={modal?.showButtons}
          />
        </div>
      ) : (
        <EmptyStateComponent heading="No Preference request" message="Preference request from this merchant will appear here" />
      )}
    </section>
  );
}
