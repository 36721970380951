import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

import './payOutReversal.scss';

export interface IPayoutReversal {
  selectedRows: any[];
  actionMode: string;
}
export const maxNumOfCheckBoxToBeSelected = 10;

export const PayOutReversalBulkModalContents: React.FC<IPayoutReversal> = ({ selectedRows, actionMode }) => {
  return (
    <div className="pay-out-reversal-modal-wrapper">
      <p data-testid="action-mode-tag">{`You are about to ${actionMode === 'approve' ? 'approve' : 'decline'} selected payout reversal(s).`}</p>
      <p className="space-text" data-testid="question-tag">
        {' '}
        Please confirm that this reversal(s) has been resolved offline. Do you want to proceed ?
      </p>
      {selectedRows && selectedRows.length > maxNumOfCheckBoxToBeSelected ? (
        <div className="banner-component">
          <div className="icon-div-pay-out">
            {' '}
            <Icon name="warningThreeStroke" />
          </div>
          <div className="banner-text">
            <p data-testid="warning-tag">{`Please select a maximum of 10 pending transactions to ${actionMode === 'approve' ? 'Approve' : 'Decline'}.`}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};
