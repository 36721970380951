import { useQuery, UseQueryResult } from 'react-query';

import APIRequest from '+services/api-services';
import { Storage as StorageService } from '+services/storage-services';
import queryKeys from '+store/constants/queryKeys';
import { ICurrency, IStorage } from '+types';
import { storageDataKey } from '+utils';

import useQueryStorage from './useQueryStorage';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

export default function useGetCurrencies(storage: IStorage): UseQueryResult<ICurrency[], unknown> {
  const { refetchQueryData, storeQueryData } = useQueryStorage();
  return useQuery(queryKeys.currencies, () => apiRequest.getCurrencies(), {
    cacheTime: 30 * 60 * 1000,
    onSuccess: data => {
      if (!data) {
        refetchQueryData(queryKeys.currencies);
      } else {
        const availableCurrencies: string[] = [];
        data.forEach(currency => {
          if (currency.enabled) {
            availableCurrencies.push(currency.code);
          }
        });
        StorageService.setItem(storageDataKey.AVAILABLE_CURRENCIES, JSON.stringify(availableCurrencies));
        storeQueryData(storageDataKey.AVAILABLE_CURRENCIES, availableCurrencies);
      }
    },
    enabled: !!storage.checkAuthentication()
  });
}
