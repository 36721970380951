import React, { Dispatch, SetStateAction } from 'react';
import { MutationFunction } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';

type TPreferenceRequestModal = { [key in 'setDeclineReason' | 'setModalState']: Dispatch<SetStateAction<string>> } & {
  declineReason: string;
  modalState: string;
  approveOrRejectAction: MutationFunction<unknown, { status: string; comments?: string }>;
};

export const modalContent = (arg: TPreferenceRequestModal) =>
  ({
    decline: {
      heading: 'Decline Preference Request?',
      description:
        'Kindly confirm that you want to decline this preference request. You will need to provide a reason for this action as it cannot be undone.',
      content: () => {
        const inputLimit = arg?.declineReason.length === 30;
        const inputLimitWarning = arg?.declineReason.length > 25;

        return (
          <div data-testid="decline-preference-request-modal" className="preference-request__decline">
            <input
              data-testid="preference-decline-input-reason"
              value={arg?.declineReason}
              onChange={e => arg?.setDeclineReason(e.target.value)}
              onKeyDown={e => {
                const allowedKeysAfterLimit = ['Backspace', 'ArrowRight', 'ArrowLeft'];
                if (inputLimit && !allowedKeysAfterLimit.includes(e.key)) e.preventDefault();
              }}
              placeholder="Enter your reason for this action..."
            />
            <span className={inputLimitWarning ? 'limit' : ''}>{arg?.declineReason.length || 0}/30</span>
          </div>
        );
      },
      secondButtonDisable: arg.declineReason?.length < 10,
      secondButtonText: 'Yes, Decline',
      secondButtonColor: '#F32345',
      secondButtonAction: async () => arg.approveOrRejectAction({ status: 'rejected', comments: arg.declineReason }),
      secondButtonActionIsTerminal: false,
      size: 'md' as const,
      showButtons: true
    },
    approve: {
      heading: 'Approve Preference Request?',
      description: 'Add subtitle that further explains information surrounding the modal title.',
      content: () => (
        <div data-testid="approve-preference-request-modal" className="preference-request__approve">
          <Icon name="warningTriangle" height={30} width={30} />
          <span>
            <strong>Ensure all requirements have been met.</strong> Note that this action cannot be undone.
          </span>
        </div>
      ),
      secondButtonText: 'Yes, Approve',
      secondButtonColor: '#24B314',
      secondButtonAction: async () => arg.approveOrRejectAction({ status: 'approved' }),
      secondButtonActionIsTerminal: false,
      size: 'md' as const,
      showButtons: true
    },
    success: {
      size: 'sm',
      heading: '',

      content: () => {
        const action = arg.declineReason ? 'declined' : 'approved';

        const close = () => {
          arg.setDeclineReason('');
          arg.setModalState('');
        };

        return (
          <div className="preference-request__success">
            <Icon name="success" width={100} height={100} />
            <p data-testid="success-modal">You have successfully {action} this merchant’s update request.</p>
            <button data-testid="dismiss-btn" className="success-btn" type="button" onClick={close} aria-label="close success modal">
              Dismiss
            </button>
          </div>
        );
      },
      showButtons: false
    }
  }[arg.modalState as 'decline']);

export const test = {};
