import React from 'react';
import ImgEmptyState from '+assets/img/dashboard/empty-state.png';

export default function NoDisputesFound({ description }: { description: string }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-wrap no-disputes-found">
      <div className="image">
        <img alt="" src={ImgEmptyState} />
      </div>
      <p className="description">{description}</p>
    </div>
  );
}
