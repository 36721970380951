import { useQuery, UseQueryResult } from 'react-query';
import useQueryStorage from './useQueryStorage';
import APIRequest from '+services/api-services';
import queryKeys from '+store/constants/queryKeys';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

interface Storage extends WindowSessionStorage {
  checkAuthentication: () => boolean;
}

export default function useGetCurrencies(storage: Storage): UseQueryResult<any, unknown> {
  const { refetchQueryData } = useQueryStorage();
  return useQuery(queryKeys.countries, () => apiRequest.getCountries(), {
    onSuccess: data => {
      if (!data) {
        refetchQueryData(queryKeys.countries);
      }
    },
    enabled: !!storage.checkAuthentication()
  });
}
