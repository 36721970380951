const notificationTypesData = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Merchant Success',
    value: 'merchant_success'
  },
  {
    label: 'Engineering',
    value: 'engineering'
  },
  {
    label: 'Compliance',
    value: 'compliance'
  },
  {
    label: 'Sales/Business',
    value: 'sales_business'
  },
  {
    label: 'Operations (General)',
    value: 'operations_general'
  },
  {
    label: 'Operations (Chargebacks)',
    value: 'operations_chargeback'
  },
  {
    label: 'Security',
    value: 'security'
  }
];

export default notificationTypesData;
