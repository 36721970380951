import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Modal from '+shared/Modal';
import ReactSelectDropdown from '+shared/ReactSelectDropdown';
import APIRequest from '+services/api-services';
import { getFullDate, getTime, logError, switchCurrencyPair } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import './index.scss';

const api = new APIRequest();

interface ModalProps {
  close: () => void;
}

type actionType = 'updateSettings' | 'saveSettings';

export default function ManageExchangeRate({ close }: ModalProps): JSX.Element {
  const { feedbackInit } = useFeedbackHandler();
  const [stage, setStage] = useState<actionType>('updateSettings');
  const [formValue, setFormValue] = useState({
    currency: 'USD'
  });
  const [state, setState] = useState({
    loading: false,
    newMarkUp: {
      value: '',
      isValid: false
    }
  });

  const feedbackInitValues = {
    title: '',
    message: '',
    type: 'danger',
    action: {
      action: () => refetch(),
      name: 'Try again'
    },
    callback: undefined,
    statusCode: undefined,
    isActive: false,
    isClosable: true,
    componentLevel: true
  };

  const currencyLists = Object.entries(switchCurrencyPair).map(([currencyCode, currencyName]) => ({
    label: currencyName,
    value: currencyCode
  }));

  const [fromCurrency, toCurrency] = switchCurrencyPair[formValue.currency]?.split('-');

  const updateMarkUpData = {
    from_currency: fromCurrency,
    to_currency: toCurrency,
    base_markup: state.newMarkUp.value
  };

  const { data: lastSetMarkup, refetch } = useQuery(['MARK_UP', fromCurrency, toCurrency], () => api.getMarkUp(fromCurrency, toCurrency), {
    keepPreviousData: true,
    onError: (error) => {
      if (error) {
        logError(error);
        feedbackInit({
          ...feedbackInitValues,
          message: `There has been an error getting the last set markup`
        });
      }
    }
  });

  const updateMarkUp = useMutation(() => api.updateMarkUp(updateMarkUpData), {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      logError(error);
      feedbackInit({
        ...feedbackInitValues,
        message: `There has been an error adding Markup`
      });
    }
  });

  const { last_updated_at: dateSet, value } = lastSetMarkup?.data || [];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\s/g, '');
    const regex = /^\d+$/;

    setState((prevState) => ({
      ...prevState,
      newMarkUp: {
        value: inputValue,
        isValid: regex.test(inputValue.trim())
      }
    }));
  };

  const showEditRate = () => {
    return (
      <>
        <div className="form-group">
          <ReactSelectDropdown
            label="Select Currency Pair"
            placeholder="-- Select currency --"
            options={currencyLists}
            value={formValue.currency}
            onChange={(value) => setFormValue({ currency: value as string })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Payment Channel">
            <span>Payment Channel</span>
          </label>
          <input type="text" name="" placeholder="Exchange" className="form-control" disabled />
        </div>
        <div className="rate-container">
          {state.loading ? (
            <LoadingPlaceholder type="text" />
          ) : (
            lastSetMarkup && (
              <div className="saved-bank-container p-3">
                <div className="last_rate">
                  <ul>
                    <li>
                      <div>
                        <span className="">Last set rate:</span>
                      </div>
                      <div>
                        <span className="last_rate_value">{`${value}%` || 'N/A'}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Date Set:</span>
                      </div>
                      <div>
                        <span className="last_set_date">{`${getFullDate(dateSet)} ${getTime(dateSet)}` || 'N/A'}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )
          )}
        </div>
        <div className="form-group">
          <label htmlFor="New markup">
            <span>New Markup on Base Rate </span>
          </label>
          <input
            type="number"
            name="markup"
            min={1}
            placeholder="Enter new mark up"
            className="form-control"
            value={state.newMarkUp.value}
            onChange={handleInputChange}
          />
        </div>
      </>
    );
  };

  const saveRate = () => {
    return (
      <>
        <div className="rate-container">
          <div className="saved-bank-container p-3">
            <div className="last_rate">
              <ul>
                <li>
                  <span>Currency Pair:</span>
                  <span>
                    {fromCurrency} - {toCurrency}
                  </span>{' '}
                </li>
                <li>
                  <span>New Rate Mark Up:</span>
                  <span>{`${state.newMarkUp.value}%`}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const modalStages = {
    updateSettings: {
      heading: 'Edit Kora Exchange Rate for USD',
      description:
        'Modifying this rate rule will apply the updated fees to future transactions that fall within the specified range for all merchants.',
      content: showEditRate(),
      secondButtonActionIsTerminal: false,
      secondButtonText: 'Continue',
      secondButtonDisable: !state.newMarkUp.isValid,
      secondButtonAction: () => {
        setStage('saveSettings');
      }
    },
    saveSettings: {
      heading: 'Save Changes?',
      description: 'Please confirm that you want to save the changes made.',
      content: saveRate(),
      firstButtonText: 'Go back',
      firstButtonAction: () => setStage('updateSettings'),
      secondButtonText: 'Yes, Save',
      secondButtonActionIsTerminal: true,
      secondButtonAction: async () => {
        await updateMarkUp.mutateAsync();
        setStage('saveSettings');
      },
      completedHeading: 'Saved',
      completedDescription: 'The rates configuration has been saved.',
      completedActionText: 'Dismiss',
      secondaryCompletedModal: true
    }
  };

  const props = {
    close,
    size: 'md',
    ...(modalStages[stage] || {})
  };

  return <Modal {...props} />;
}
