import React, { FC, useState } from 'react';
import CancelIcon from '+assets/img/dashboard/delete.svg';
import LinkIcon from '+assets/img/dashboard/warning-orange.svg';
import './banner.scss';

type TBanner = {
  actionBtn?: () => void;
  actionText?: string;
  text: string;
  renderCloseBtn?: boolean;
};

const Banner: FC<TBanner> = ({ actionBtn, actionText, text, renderCloseBtn }) => {
  const [showBanner, setShowBanner] = useState(true);

  return showBanner ? (
    <div className="banner" data-testid="banner">
      <div>
        <img className="banner-sparkle" src={LinkIcon} alt="" />
        <p>{text}</p>
      </div>
      <div>
        {actionBtn && (
          <button type="button" className="banner-btn" onClick={() => actionBtn()}>
            {actionText}
          </button>
        )}
        {renderCloseBtn && (
          <button type="button" className="banner-btn close" onClick={() => setShowBanner(false)}>
            <img className="banner-btn-icon" src={CancelIcon} alt="cancel" />
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export default Banner;
