import React, { FC } from 'react';

import CustomCheckbox from '+shared/CustomCheckbox';

import SubscribeConfiguration from './SubscribeConfiguration';

interface IEmailCategoriesProps {
  route: string;
  defaultMerchant: Record<string, any>;
  configuration: {
    payin: any;
    payout: any;
    dispute: any;
    card_issuance: any;
    product_and_marketing: any;
  };
}

const EmailConfigurationForm: FC<IEmailCategoriesProps> = ({ route, configuration, defaultMerchant }) => {
  const { payin, payout, dispute, card_issuance, product_and_marketing } = configuration;
  const EmailCategories = {
    marketing: {
      category: 'Marketing & Product Updates',
      title: 'Marketing & Product Updates',
      description: 'News about product and feature updates',
      text: 'Feature releases, tips, announcements and beta programs',
      additionalContent: <SubscribeConfiguration />,
      options: null,
      forms: [
        {
          formHeading: 'Where should Kora send marketing emails?',
          formValue: (product_and_marketing?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    payouts_withdrawals: {
      category: 'Payout Withdrawals',
      title: 'Payout Withdrawal Notifications',
      description: 'Notifications for payout withdrawals ',
      text: 'Payouts and Withdrawals via dashboard',
      options: null,

      forms: [
        {
          formHeading: 'Where should Kora send payout withdrawal emails?',
          formValue: (payout?.dashboard_single_payout_completion?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    payout_api: {
      category: 'Payouts API',
      title: 'Payouts API Notifications',
      description: 'Notifications around payouts and bulk payouts via API',
      text: null,
      options: [
        { checked: payout?.api_bulk_payout_initiation?.enabled ?? false, option: 'Bulk payout initiation' },
        { checked: payout?.api_bulk_payout_completion?.enabled ?? false, option: 'Bulk payout completion' },
        { checked: payout?.api_single_payout_completion?.enabled ?? false, option: 'Single payout Completion' }
      ],
      forms: [
        {
          formHeading: 'Where should Kora send emails about payouts via API?',
          formValue: (payout?.dashboard_single_payout_completion?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    pay_ins: {
      category: 'Pay-ins',
      title: 'Pay-in Notifications',
      description: 'Notifications for pay-in transactions on the following:',
      text: 'Balance top-up and funding receipts',
      options: [{ checked: payin?.all_payins?.enabled ?? false, option: 'Inbound payments from customers' }],
      forms: [
        {
          formHeading: 'Where should Kora send balance top-up and funding emails?',
          formValue: (payin?.all_payins?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        },
        {
          formHeading: 'Where should Kora send other pay-in emails?',
          formValue: (payin?.other_payin_emails?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    support: {
      category: 'Support',
      title: 'Support Email',
      description: 'Preferred support email address to be included in receipts sent to customers',
      text: null,
      options: null,
      forms: [
        {
          formHeading: 'Pay-in support email address',
          formValue: (payin?.support_emails || [])?.join(', ') || defaultMerchant?.email
        },
        {
          formHeading: 'Payout support email address',
          formValue: (payout?.support_emails || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    refunds_reversals: {
      category: 'Refunds & Reversals',
      title: 'Refund & Reversal Notifications',
      description: 'Notifications for dispute notification around refunds and reversals',
      text: null,
      options: [{ checked: dispute?.refund?.enabled ?? false, option: 'Requested refund and updates ' }],

      forms: [
        {
          formHeading: 'Where should Kora send refunds and reversal emails?',
          formValue: (dispute?.refund?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    chargebacks: {
      category: 'Chargebacks',
      title: 'Chargeback Notifications',
      description: 'Notifications around reconciliation and chargebacks for transactions on this account',
      text: 'Chargeback escalation and updates',
      options: null,
      forms: [
        {
          formHeading: 'Where should Kora send chargeback emails?',
          formValue: (dispute?.chargeback?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    },
    card_issuance: {
      category: 'Card Issuance',
      title: 'Card Issuance',
      description: 'Notifications for card issuance on the following:',
      text: null,
      options: [
        { checked: card_issuance?.issuing_balance_funding?.enabled ?? false, option: 'Notification on auto-funding of issuing wallet' },
        { checked: card_issuance?.issuing_balance_funding?.enabled ?? false, option: 'Issuing balance funding' },
        { checked: card_issuance?.transaction_limits?.enabled ?? false, option: 'Notification on transaction limits' },
        { checked: card_issuance?.virtual_card_termination?.enabled ?? false, option: 'Virtual card termination' }
      ],

      forms: [
        {
          formHeading: 'Where should Kora send card issuance emails?',
          formValue: (card_issuance?.transaction_limits?.email_recipients || [])?.join(', ') || defaultMerchant?.email
        }
      ]
    }
  };

  const switchConfiguration: Record<string, any> = {
    'Marketing & Product Updates': EmailCategories.marketing,
    'Payout Withdrawals': EmailCategories.payouts_withdrawals,
    'Payouts API': EmailCategories.payout_api,
    'Pay-ins': EmailCategories.pay_ins,
    Support: EmailCategories.support,
    'Refunds & Reversals': EmailCategories.refunds_reversals,
    Chargebacks: EmailCategories.chargebacks,
    'Card Issuance': EmailCategories.card_issuance
  };

  const configData = switchConfiguration[route];

  return (
    <div className="configuration-form">
      <h3>{configData?.title}</h3>
      <p className="description">{configData?.description}</p>
      {configData?.text && <p className="sub-text">{configData?.text}</p>}
      {configData?.options &&
        configData?.options?.map((option: { checked: boolean; option: string }, i: number) => {
          return (
            <div className="form-check mb-3" key={i}>
              <label className="form-check-label checkbox-text">
                <CustomCheckbox className="form-check-input" checked={option?.checked} disabled />
                {option?.option}
              </label>
            </div>
          );
        })}
      <div className="configuration-form-heading">
        {configData?.forms?.map((form: { formHeading: string; formValue: string }, i: number) => {
          return (
            <div className="form-group mt-4 mb-4" key={i}>
              <label htmlFor="refund-amount" className="withdraw-label">
                <span className="dark" style={{ color: '#3E4B5B' }}>
                  {form?.formHeading}
                </span>
              </label>
              <input value={form?.formValue} className="form-control email-input" disabled data-testid="config-email-input" />
            </div>
          );
        })}
        {configData?.additionalContent}
      </div>
    </div>
  );
};

export default EmailConfigurationForm;
