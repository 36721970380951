import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

export default function PreferenceStatus({ from, to }: { from?: string; to: string }) {
  return (
    <div data-testid="preference-dropdown-item-content" className="preference-status">
      {from && (
        <>
          <span data-testid="from">{from}</span>
          <Icon name="arrowRight" />
        </>
      )}
      <span data-testid="to">{to}</span>
    </div>
  );
}
