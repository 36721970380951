import React from 'react';
import { history } from '+utils';
import circleExclamentionLogo from '+assets/img/dashboard/circle_exclamention.svg';
import './index.scss';

const AccessDenial = ({ imgSrc, title, description, actionBtn, actionText, hasContactSupport = false }) => {
  return (
    <div className="invite-denial">
      <div className="content-i dashboard__accessdenial">
        <div className="content-box">
          <img src={imgSrc || circleExclamentionLogo} alt="Circle Exclamention Mark" />
          <h2 className="txt-access_denial"> {title || 'Access Denied!'}</h2>
          <p className="txt-access_denial-msg">
            {description ||
              'Sorry, you do not have the permission to perform this action or view this page. Please contact your administrator.'}{' '}
          </p>
          {hasContactSupport && (
            <a href="mailto:support@korapay.com" className="btn btn-sm btn-link access_denial-button" target="_blank" rel="noreferrer">
              Contact Support
            </a>
          )}
          {!hasContactSupport && (
            <button type="button" className="btn btn-sm btn-link access_denial-button" onClick={actionBtn || history.goBack}>
              {actionText || 'Go back'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessDenial;
