import React, { useEffect, useState } from 'react';

import ToolTip from './Tooltip';

import './index.scss';

/*
 * This component renders the pagination elements according to the view available
 * The pagination control will look like the following:
 * (1) < {4} [5] {6} > (10)
 * pagingTotalItems is the "total_items" object in the paging response from the api
 * pageSize is the "page_size" object in the paging response from the api.
 * the action receives the current page and is responsible for handling the dispatch and updating on page change
 */
interface PaginationComponentProps {
  currentPage: number;
  pagingTotalItems: number;
  pageSize: number;
  action: (arg: number) => void;
  limitAction?: (arg: number) => void;
  showPaginationDescription?: boolean;
  showLimit?: boolean;
  annotation?: string;
  scrollToTop?: () => void;
  disabled?: boolean;
}

export default function PaginationComponent({
  currentPage = 1,
  pagingTotalItems,
  pageSize,
  action,
  showPaginationDescription = true,
  showLimit = true,
  limitAction,
  annotation = 'transactions',
  scrollToTop,
  disabled
}: PaginationComponentProps) {
  const [point, setPoint] = useState<number>(1);
  useEffect(() => {
    if (Number(currentPage)) {
      setPoint(Number(currentPage));
    }
  }, [currentPage]);
  const totalPages = Math.ceil(pagingTotalItems / pageSize) || 1;
  const paginate = (go: string | number) => {
    let current = currentPage;
    if (currentPage === go) return null;
    if (typeof go === 'string') {
      if (go === 'next') {
        current += 1;
        action(current);
      } else if (go === 'prev') {
        current -= 1;
        action(current);
      }
    } else {
      action(go);
    }
    return null;
  };

  const paginationLogic = () => {
    let pages: number[];
    const startPage = Math.max(2, currentPage - 1);
    const endPage = currentPage < totalPages - 2;
    const twoEnd = totalPages - 2;
    const preEnd = totalPages - 1;

    switch (startPage) {
      case 2:
        if (startPage === totalPages) {
          pages = [];
        } else if (startPage + 1 >= totalPages) {
          pages = [2];
        } else if (startPage + 2 >= totalPages) {
          pages = [2, 3];
        } else {
          pages = [2, 3, 4];
        }
        break;
      case twoEnd:
        pages = [startPage, currentPage];
        break;
      case preEnd:
        pages = [startPage - 1, startPage];
        break;
      default:
        pages = [startPage, currentPage, currentPage + 1];
        break;
    }
    return { startPage, endPage, pages };
  };
  const onChangeGoTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPoint(Math.abs(parseInt(e.target.value, 10)));
  };
  const renderPagination = () => {
    const { startPage, endPage, pages } = paginationLogic();
    if (totalPages === 1) return null;
    return (
      <div className="paging-row">
        <button
          disabled={disabled}
          onClick={() => paginate(1)}
          type="button"
          className={`pagination-button --first ${currentPage === 1 ? 'active' : ''}`}
        >
          1
        </button>
        {startPage !== 2 && (
          <button disabled={disabled} onClick={() => paginate('prev')} type="button" className="pagination-button">
            &laquo;
          </button>
        )}
        {pages.map(page => {
          return (
            <button
              disabled={disabled}
              key={page}
              type="button"
              onClick={() => paginate(page)}
              className={`pagination-button ${currentPage === page ? 'active' : null}`}
            >
              {page}
            </button>
          );
        })}
        {endPage && (
          <button disabled={disabled} onClick={() => paginate('next')} type="button" className="pagination-button">
            &raquo;
          </button>
        )}
        <button
          disabled={disabled}
          onClick={() => paginate(totalPages)}
          type="button"
          className={`pagination-button --last ${currentPage === totalPages ? 'active' : ''}`}
        >
          {totalPages}
        </button>

        <div className="goto-container" style={{ margin: showPaginationDescription ? '1rem 0 0 0.6rem' : '' }}>
          <span>Go to </span>
          <input disabled={disabled} value={point} type="number" min="1" placeholder="1" onChange={e => onChangeGoTo(e)} />

          <button
            disabled={disabled}
            onClick={() => {
              if (+point > +totalPages) return false;
              return paginate(point);
            }}
            type="button"
          >
            Go
          </button>
        </div>
      </div>
    );
  };

  const getBeginning = () => {
    if (currentPage === 1) return 1;
    const i = currentPage - 1;
    return pageSize * i + 1;
  };

  const scrollToTopSection = () => {
    if (pagingTotalItems < 10 || (pagingTotalItems <= 5 && document.body.clientWidth < 1024)) return null;
    const defaultScroll = () => window.scroll(0, 0);
    return (
      <div>
        <button disabled={disabled} className="btn btn-sm pagination-back-to-top" type="button" onClick={scrollToTop || defaultScroll}>
          <span>Back to top</span>
          <i className="os-icon os-icon-arrow-up6" />
        </button>
      </div>
    );
  };

  return (
    <ToolTip
      hasFullWidth
      centered
      disabled={!disabled || pagingTotalItems <= pageSize}
      type="bulk_actions_pagination"
      message={<p>This action is disabled because you are carrying out a bulk action on this page.</p>}
    >
      <section
        className={`pagination-container ${disabled ? 'disabled' : ''} `}
        hidden={pagingTotalItems === 0}
        data-testid="pagination-component"
      >
        {pagingTotalItems <= pageSize ? (
          <>
            <div className="dataTables_length">
              <span className="pagination-pages">
                <span className="annotation">
                  {pagingTotalItems} {annotation}
                </span>
              </span>
            </div>
            {scrollToTopSection()}
          </>
        ) : (
          <>
            <div className="dataTables_length" id="dataTable1_length">
              {showLimit && (
                <>
                  <label>
                    Show
                    <select
                      name="dataTable1_length"
                      onChange={e => {
                        if (limitAction) limitAction(Number(e.target.value));
                      }}
                      value={pageSize || '25'}
                      aria-controls="dataTable1_length"
                      className={`form-control form-control-sm ${annotation === 'users' ? 'default-menu' : ''}`}
                      disabled={disabled}
                      data-testid="pagination_limit"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    {annotation}
                  </label>
                  <span className="divider-sm" />
                </>
              )}
              {showPaginationDescription && (
                <span className="pagination-pages">
                  Showing <strong>{getBeginning()}</strong> to <strong>{Math.min(pageSize * currentPage, pagingTotalItems)}</strong> of{' '}
                  <strong>{pagingTotalItems}</strong> {annotation}
                </span>
              )}
            </div>
            {scrollToTopSection()}
            {renderPagination()}
          </>
        )}
      </section>
    </ToolTip>
  );
}
