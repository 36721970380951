import React from 'react';
import './index.scss';
import CustomCheckbox from './CustomCheckbox';

interface ICheckboxAccordionProps {
  className?: string;
  visible: boolean;
  showContent: () => void;
  title: React.ReactNode | string;
  children: React.ReactNode;
}

const CheckboxAccordion = ({ className, title, children, visible, showContent } : ICheckboxAccordionProps ) => {
  return (
    <div className={`accordion__comp--checkbox border-bottom mb-3 ${className}`}>
      <div className="accordion__comp--checkbox-title mb-2">
        <CustomCheckbox text={title} checked={visible} onChange={() => showContent(!visible)} />
      </div>
      <section className={visible ? 'content-visible' : ''}>{children}</section>
    </div>
  );
};


export default CheckboxAccordion;
