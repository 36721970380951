/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { Storage as StorageService } from '+services/storage-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import { TCurrentPaymnentPreferenceSettings } from '+types';
import { getDate, logError, storageDataKey } from '+utils';

import PaymentPreferenceTabs from './PaymentPreferenceTabs';

import './index.scss';

const api = new APIRequest();
export default function PaymentPreference() {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const availableCurrencies = (StorageService.getItem(storageDataKey.AVAILABLE_CURRENCIES) || []) as string[];

  const errorDispatch = (message: string) => {
    feedbackInit({
      message,
      type: 'danger'
    });
  };

  const { data: currentPref, isLoading: fetchingCurrentPref } = useQuery(
    [`${id}_CURRENT_PAYMENT_PREFERENCE`, activeCurrency],
    () => api.getMerchantPaymentPreference(id, activeCurrency),
    {
      onError: () => errorDispatch(`There has been an error fetching merchant payment preference.`)
    }
  );

  const { data, isLoading } = useQuery(
    `${id}_MERCHANT_PAYMENT_PREFERENCE`,
    () => api.getMerchantsPaymentPreferencesRequest({ merchantId: id }),
    {
      onError: e => {
        logError(e);
        errorDispatch(`There has been an error fetching merchant payment preference.`);
      }
    }
  );

  const settings = currentPref?.settings?.payment_events as TCurrentPaymnentPreferenceSettings;

  const lastUpdated = data?.data?.[0]?.status === 'pending' ? data?.data?.[1]?.updatedAt : data?.data?.[0]?.updatedAt;

  return (
    <section className="payment-preferences">
      <div className="element-box">
        <h4 className="form-header" data-testid="page-title">
          Payment Preferences
        </h4>
        <div className="form-desc no-underline" data-testid="page-subtitle">
          You can view merchant&apos;s payment preferences here.{' '}
        </div>
        <div data-testid="currency-picker" className="form-desc">
          <CurrencyPicker
            options={availableCurrencies}
            onChange={value => setActiveCurrency(value)}
            allowedOptions={['NGN']}
            activeCurrency={activeCurrency}
            label={<span className="payment-preferences__config">Show configurations for:</span>}
            id="payment-preference__currency-switch"
            showDropDown={false}
          />
        </div>

        <div className="payment-preferences__heading-sub">
          <div>
            <h5 className="form-header pt-0">Payment Preferences by Payment Methods</h5>
            <div className="form-desc no-underline mb-1">This is the preference applied to this merchant for each payment method</div>
          </div>
          {lastUpdated && (
            <div className="payment-preferences__last-updated">
              <p>Last updated: </p>
              <p>{getDate(lastUpdated)}</p>
            </div>
          )}
        </div>

        <div className="os-tabs-controls os-tabs-complex mx-0">
          <ul className="nav nav-tabs px-0 mb-2">
            <li className="nav-item">
              <button type="button" className="nav-link active" data-toggle="tab">
                <span className="tab-label">Bank Transfer</span>
              </button>
            </li>
          </ul>
        </div>

        <PaymentPreferenceTabs data={data?.data} currentPref={settings} loading={isLoading || fetchingCurrentPref} />
      </div>
    </section>
  );
}
