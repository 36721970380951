import React, { useState, useLayoutEffect } from 'react';
import { useMutation } from 'react-query';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, isAllowed } from '+utils';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';

type ReversalActionType = 'approve' | 'reject' | '';

const api = new APIRequest();

export function ReversePayoutModal({ transaction = {}, close, onReversalInitiated }) {
  const { feedbackInit } = useFeedbackHandler();
  const [reversalReason, setReversalReason] = useState('');

  const { unique_reference: transactionRef, payment, currency, amount } = transaction;
  const merchantName = payment?.account?.name;

  const canSubmit = reversalReason?.length > 3;

  const { mutateAsync: initiateReversal } = useMutation(
    () => api.initiatePayoutReversal({ payout_reference: transactionRef, reason: reversalReason }),
    {
      onError: (error) => {
        feedbackInit({
          message: error?.response?.data?.message || 'Unable to initiate reversal',
          type: 'danger',
          componentLevel: true
        });
      },
      onSuccess: () => {
        onReversalInitiated?.();
      }
    }
  );

  const contentForm = () => {
    return (
      <div>
        <label className="" htmlFor="reversal-reason">
          Reason for reversal
        </label>
        <input name="reversal-reason" className="form-control" value={reversalReason} onChange={(e) => setReversalReason(e.target.value)} />
      </div>
    );
  };

  return (
    <Modal
      size="md"
      close={close}
      heading="Initiate Reversal"
      description={`You are about to initiate a payout reversal of ${currency} ${formatAmount(amount)}${
        merchantName ? ` for ${merchantName}` : ''
      }. Do you want to proceed?`}
      secondButtonAction={initiateReversal}
      firstButtonAction={close}
      firstButtonText="No, Cancel"
      secondButtonText="Yes, Initiate"
      completedHeading="Reversal Initiated"
      completedDescription="Transaction reversal has been initiated"
      content={contentForm()}
      secondButtonDisable={!canSubmit}
    />
  );
}

export default function PayoutReversalsTab({ reversals = [], refetchTransaction, transaction = {} }) {
  const [selectedReversal, setSelectedReversal] = useState(null);
  const [reversalAction, setReversalAction] = useState<ReversalActionType>('');
  const count = reversals.length;

  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();

  useLayoutEffect(() => {
    if (reversals.length) {
      setSelectedReversal(reversals[0]);
    }
  }, [reversals]);

  const { reason: reversalReason, status, reference, amount, currency, completed_at: completedAt } = selectedReversal || {};
  const { payment } = transaction;
  const merchantName = payment?.account?.name;

  const statusOptions = {
    approved: <p style={{ color: '#24B314' }}>Reversed</p>,
    declined: <p style={{ color: '#F32345' }}>Declined</p>
  };

  const listedInfo = [
    {
      label: 'Reason for Reversal',
      value: capitalize(reversalReason)
    },
    {
      label: 'Reversal Status',
      value: statusOptions[status] || capitalizeRemovedash(status)
    },
    ...(status === 'approved'
      ? [
          {
            label: 'Date Approved',
            value: `${getDate(completedAt)}, ${getTime(completedAt)}` || 'Not Available'
          }
        ]
      : status === 'declined'
      ? [
          {
            label: 'Date Declined',
            value: `${getDate(completedAt)}, ${getTime(completedAt)}` || 'Not Available'
          }
        ]
      : [])
  ];

  const { mutateAsync: processReversal } = useMutation((action) => api.processPayoutReversal({ reference, action }), {
    onError: (error) => {
      feedbackInit({
        message: error?.response?.data?.message || 'Unable to process reversal',
        type: 'danger',
        componentLevel: true
      });
    },
    onSuccess: () => {
      refetchTransaction?.();
    }
  });

  const modalOptions: Record<ReversalActionType, Partial<IModalProps>> = {
    approve: {
      heading: 'Approve Reversal',
      content: (
        <>
          You are about to approve a payout reversal of{' '}
          <b>
            {currency} {formatAmount(amount)}
          </b>
          {merchantName && (
            <>
              {' '}
              for <b>{merchantName}</b>
            </>
          )}
          .
        </>
      ),
      firstButtonText: 'Cancel',
      secondButtonAction: () => processReversal('approved'),
      secondButtonText: 'Yes, Approve',
      completedHeading: 'Reversal Approved',
      completedDescription: 'Transaction reversal has been approved'
    },
    reject: {
      heading: 'Decline Reversal',
      content: (
        <>
          You are about to decline a payout reversal of{' '}
          <b>
            {currency} {formatAmount(amount)}
          </b>
          {merchantName && (
            <>
              {' '}
              for <b>{merchantName}</b>
            </>
          )}
          .
        </>
      ),
      firstButtonText: 'Cancel',
      secondButtonAction: () => processReversal('declined'),
      secondButtonText: 'Yes, Decline',
      completedHeading: 'Reversal Declined',
      completedDescription: 'Transaction reversal has been declined',
      secondButtonColor: '#F32345'
    }
  };

  const hasCreateAccess = isAllowed(userAccess, ['payout_reversals.create']);
  const hasProcessAccess = isAllowed(userAccess, ['payout_reversals.process']);

  return (
    <>
      <div>
        {reversals?.length > 0 ? (
          <ul>
            <li id="payment-detail">
              <div className="reversal-count-container" style={{ width: '40%' }}>
                <p className="reversal-count">{`${count} ${count > 1 ? 'Reversals' : 'Reversal'} found`}</p>
                {reversals.map((item) => {
                  return (
                    <div className="--container" key={item.reference} onClick={() => setSelectedReversal(item)}>
                      <p className="reversal-amount">
                        <label className="">Refund of</label>
                        {`${formatAmount(item.amount || 0)} ${item.currency}`}
                      </p>
                    </div>
                  );
                })}
                {!['reversed', 'approved', 'declined'].includes(status) && (
                  <>
                    {hasCreateAccess && !hasProcessAccess && (
                      <button type="button" disabled className="btn btn-primary full-width">
                        Pending (Awaiting approval)
                      </button>
                    )}

                    {hasProcessAccess && (
                      <>
                        <button type="button" onClick={() => setReversalAction('approve')} className="btn btn-primary full-width mb-2">
                          Approve Reversal
                        </button>
                        <button type="button" onClick={() => setReversalAction('reject')} className="btn btn-danger-light full-width">
                          Decline Reversal
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="customer-reversal-info" style={{ width: '60%' }}>
                <ul className="">
                  {listedInfo.map((item) => (
                    <li key={item.label}>
                      <p>{item.label}</p>
                      <p>{item.value}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        ) : (
          <p className="no-refund">
            <i>No reversal for this transaction</i>
          </p>
        )}

        {reversalAction ? <Modal size="md" {...modalOptions[reversalAction]} close={() => setReversalAction('')} /> : null}
      </div>
    </>
  );
}
