import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useFeedbackHandler } from '+hooks';
import Modal from '+containers/Dashboard/Shared/Modal';
import APIRequest from '+services/api-services';
import SearchInput from '+containers/Dashboard/Shared/SearchInput';
import CheckboxInput from './CheckInput';
import { AccessDataT, CheckboxT, FilterMerchantT, IAddMerchantsModal } from '+types/identity';
import './index.scss';


const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function AddMerchantsModal({ close, refetchMerchant }: IAddMerchantsModal) {
  const checkboxes: CheckboxT[] = [
    { label: 'Nigeria (NG)', value: 'ng' },
    { label: 'Ghana (GH)', value: 'gh' },
    { label: 'Kenya (KE)', value: 'ke' },
    { label: 'South Africa (ZA)', value: 'za' }
  ];

  const { feedbackInit } = useFeedbackHandler();

  const [selectedMerchant, setSelectedMerchant] = useState<number>();
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [shouldRunQuery, setShouldRunQuery] = useState(false);
  const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
  const grantAccessMutation = useMutation((data: AccessDataT) => api.addIdentityMerchant(data), {
    onError: (error: AxiosError) => {
      feedbackInit({ message: error.response?.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: (data) => {
      refetchMerchant();
      feedbackInit({ message: 'Merchant granted access successfully', componentLevel: true, type: 'success' });
    }
  });
  const {
    isFetching: isFetchingMerchants,
    refetch: refetchMerchants,
    data
  } = useQuery(
    ['MERCHANTS', debouncedSearchInput],
    () =>
      api.getMerchants(
        'verified',
        undefined,
        {
          merchantName: debouncedSearchInput
        },
        undefined
      ),
    {
      enabled: debouncedSearchInput?.length >= 3 && shouldRunQuery,
      select: (data) => data?.data?.data?.map((each: FilterMerchantT) => ({ name: each.name, kora_id: each.kora_id })),
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting all merchants',
          type: 'danger',
          action: {
            action: () => refetchMerchants(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const handleSubmit = async () => {
    if (!selectedMerchant || !selectedRegions.length) {
      feedbackInit({ message: 'Please select a merchant and at least one region', componentLevel: true, type: 'danger' });
      throw new Error('Please select a merchant and at least one region');
    }

    const countryObjects = selectedRegions.reduce((obj, country) => {
      return {
        ...obj,
        [country]: {
          enable: true
        }
      };
    }, {});

    await grantAccessMutation.mutateAsync({ kora_id: selectedMerchant, ...countryObjects });
  };
  const renderContent = () => {
    return (
      <div className="iam-modal">
        <SearchInput
          label="Search merchant"
          placeholder="Start typing"
          id="merchant"
          value={selectedMerchant}
          onChange={(e) => setSelectedMerchant(e as number)}
          dropdownData={data}
          setDebouncedSearchInput={setDebouncedSearchInput}
          setShouldRunQuery={setShouldRunQuery}
          isFetching={isFetchingMerchants}
        />
        <CheckboxInput
          label="In what regions/countries should this merchant be able to verify customers?"
          data={checkboxes}
          id="regions"
          value={selectedRegions}
          onChange={(e) => setSelectedRegions(e as string[])}
          setChecked={setSelectedRegions}
          borderTop
          disabled={selectedMerchant === undefined || selectedMerchant === null}
        />
      </div>
    );
  };
  return (
    <Modal
      size="md"
      heading="Add merchant to Identity service"
      description="You can find a merchant and add them to the Identity service. Once added, you can configure their fees and which regions they can perform verification checks."
      content={renderContent()}
      secondaryCompletedModal
      completedDescription="You have successfully added this merchant to the Identity service."
      completedHeading="Merchant Added!"
      completedActionText=""
      close={close}
      secondButtonText="Add Merchant"
      secondButtonAction={handleSubmit}
    />
  );
}
