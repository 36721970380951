import APIRequest from '+services/api-services';

const api = new APIRequest();

export type TabKeyType =
  | 'issuing_history'
  | 'merchant_card_transactions'
  | 'merchant_customer_cards'
  | 'merchant_reserved_cards'
  | 'billing'
  | 'disputes';

type SubTabKeyType = 'merchant_issuance_chargebacks';

type queryKeyType = keyof typeof queryKeys;

interface SubTabType {
  key: SubTabKeyType;
  label: string;
  query: <T>(...args: any[]) => Promise<T>;
  queryKey: queryKeyType;
}

type TabListType = Array<{
  key: TabKeyType;
  label: string;
  query?: <T>(...args: any[]) => Promise<T>;
  queryKey?: queryKeyType;
  subTabs?: Array<SubTabType>;
}>;

const queryKeys = {
  MERCHANT_ISSUING_HIST: 'MERCHANT_ISSUING_HIST',
  MERCHANT_CUSTOMER_CARDS: 'MERCHANT_CUSTOMER_CARDS',
  MERCHANT_RESERVED_CARDS: 'MERCHANT_RESERVED_CARDS',
  MERCHANT_CARD_TXS: 'MERCHANT_CARD_TXS',
  MERCHANT_DISPUTES: 'MERCHANT_DISPUTES',
  MERCHANT_BILLING: 'MERCHANT_BILLING'
} as const;

export const issuingMerchantTabList: TabListType = [
  {
    label: 'Issuing History',
    key: 'issuing_history',
    query: api.fetchMerchantWalletHistory,
    queryKey: queryKeys.MERCHANT_ISSUING_HIST
  },
  {
    label: 'Issued Cards',
    key: 'merchant_customer_cards',
    query: api.fetchMerchantCards,
    queryKey: queryKeys.MERCHANT_CUSTOMER_CARDS
  },
  {
    label: 'Reserved Cards',
    key: 'merchant_reserved_cards',
    query: api.fetchMerchantCards,
    queryKey: queryKeys.MERCHANT_RESERVED_CARDS
  },
  {
    label: 'Card Transactions',
    key: 'merchant_card_transactions',
    query: api.fetchMerchantTrxns,
    queryKey: queryKeys.MERCHANT_CARD_TXS
  },
  {
    label: 'Disputes',
    key: 'disputes',
    subTabs: [
      {
        label: 'Chargebacks',
        key: 'merchant_issuance_chargebacks',
        query: api.fetchMerchantChargebacks,
        queryKey: queryKeys.MERCHANT_DISPUTES
      }
    ]
  }
];

export type PlanFormValuesType = {
  [key in 'debitType' | 'cardCategory' | 'feeType' | 'plan']: string;
} & { uniqueId: string | string[] };

export type PaymentDataType = {
  status: string;
  amount: number;
  created_at: string;
  reference: string;
  debit_type: string;
};

type FeeDetailType = {
  amount: number;
};

type FeeType = {
  funding: FeeDetailType;
  issuance: FeeDetailType;
  chargeback: FeeDetailType;
  withdrawal: FeeDetailType;
  subscription: FeeDetailType;
  cross_currency: FeeDetailType;
  security_reserve: FeeDetailType;
};

export type FormattedPlanType = {
  monthly_card_limit: number;
  reserved_card_limit: number;
  fee: {
    customer: FeeType;
    reserved: FeeType;
  };
};

export type ManualDebitDataType = {
  currency: string;
  cardCategory: string;
  billingType: string;
  debitType: string;
  reference: string | (string | string[])[];
  cardType: string;
};

export interface ManualDebitFormType {
  cardCategory: string;
  feeType: string;
  debitType: string;
  uniqueId?: string | Array<string>;
}

export interface CustomFormType {
  customerNumberOfCardsIssuable: number;
  customerFundingFee: number;
  customerIssuanceFee: number;
  customerWithdrawalFee: number;
  customerSubscriptionFee: number;
  customerSecurityReserveFee: number;
  customerCrossCurrencyFee: number;
  customerChargebackFee: number;

  reservedNumberOfCardsIssuable: number;
  reservedFundingFee: number;
  reservedIssuanceFee: number;
  reservedWithdrawalFee: number;
  reservedSubscriptionFee: number;
  reservedSecurityReserveFee: number;
  reservedCrossCurrencyFee: number;
  reservedChargebackFee: number;
}

export interface PlansType {
  monthly_card_limit: number;
  reserved_card_limit: number;
  name: string;
  reference: string;
  currency: string;
  fee: {
    customer: {
      funding: {
        amount: number;
      };
      subscription: {
        amount: number;
      };
      issuance: {
        amount: number;
      };
      withdrawal: {
        amount: number;
      };
      security_reserve: {
        amount: number;
      };
      cross_currency: {
        amount: number;
      };
      chargeback: {
        amount: number;
      };
    };
    reserved: {
      funding: {
        amount: number;
      };
      issuance: {
        amount: number;
      };
      chargeback: {
        amount: number;
      };
      subscription: {
        amount: number;
      };
      cross_currency: {
        amount: number;
      };
      withdrawal: {
        amount: number;
      };
      security_reserve: {
        amount: number;
      };
    };
  };
}

export const switchDateFilterParam = (tabType: TabKeyType | SubTabKeyType) => {
  switch (tabType) {
    case 'merchant_customer_cards':
    case 'merchant_reserved_cards':
    case 'merchant_card_transactions':
    case 'merchant_issuance_chargebacks':
      return { dateFrom: 'dateCreatedFrom', dateTo: 'dateCreatedTo' };
    case 'issuing_history':
      return { dateFrom: 'date_from', dateTo: 'date_to' };
    default:
      return {};
  }
};

export const pciDssLevels = {
  level_1: 1,
  level_2: 2,
  level_3: 3,
  level_4: 4
} as const;
