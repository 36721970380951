import React from 'react';

import { StepType } from '+types';

type CardFeesStageType = Exclude<StepType, 'init' | 'confirmPlanUpdate'>;

interface IStepper {
  steps: Array<CardFeesStageType>;
  step: StepType;
}

const stageTitles: Record<CardFeesStageType, string> = {
  setIssuedCardFees: 'Issued',
  setReservedCardFees: 'Reserved'
};

export default function Stepper({ steps, step }: IStepper) {
  return (
    <div className="d-flex align-items-center stepper">
      {steps.map(stage => (
        <div className="d-flex align-items-center step" key={stage}>
          <span className="dashed-line" />
          <span className={`step-indicator ${stage === step ? 'active' : ''}`} />
          <span className="font-weight-bold">{stageTitles[stage]}</span>
        </div>
      ))}
    </div>
  );
}
