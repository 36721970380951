/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { SummaryItemType, CurrencyType } from '+types';
import { formatAmount } from '+utils';

import NoDisputesFound from './NoDisputesFound';
import './index.scss';

const RefundsTab = <T extends { reference: string; amount: string }>({
  data,
  currency,
  summaryGenerator,
  childrenGenerator
}: {
  data: Array<T>;
  currency: CurrencyType;
  summaryGenerator: (...args: Array<any>) => Array<SummaryItemType>;
  childrenGenerator: (...args: Array<any>) => React.ReactNode;
}) => {
  if (!data || data.length === 0) return <NoDisputesFound description="No refunds found for this transaction" />;

  const [activeIndex, setActiveIndex] = useState(0);
  const refundsCount = data?.length;
  const summaries = summaryGenerator(data[activeIndex], currency);
  const children = childrenGenerator?.(data[activeIndex]);

  const refunds = (
    <ul>
      {data.map((refund, index) => (
        <li key={refund.reference}>
          <button
            onClick={() => setActiveIndex(index)}
            type="button"
            className={`dispute-amount ${activeIndex === index ? 'active' : ''}`}
            aria-selected={activeIndex === index}
            id={`${index}-controller`}
            role="tab"
            aria-controls={`${index}-panel`}
          >
            Refund of <strong>{`${formatAmount(refund.amount)} ${currency}`} </strong>
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="with-sidebar">
      <div className="sidebar dispute-sidebar">
        <p className="counter">{`${refundsCount} ${refundsCount > 1 ? 'Refunds' : 'Refund'} found`}</p> {refunds}
      </div>

      <div
        className="not-sidebar dispute-not-sidebar"
        aria-labelledby={`${activeIndex}-controller`}
        id={`${activeIndex}-panel`}
        role="tabpanel"
      >
        <ul className="dispute-summaries">
          {summaries.map((summary, index) => {
            return summary.hidden ? null : (
              <li className="dispute-summary-item cluster nowrap" key={summary.label as string}>
                <div className="dispute-summary-label" id={`${index}`}>
                  {summary.label}
                </div>
                <div className="dispute-summary-value" aria-labelledby={`${index}`}>
                  {summary.value}
                </div>
              </li>
            );
          })}
        </ul>

        <div className="dispute-children-container">{children}</div>
      </div>
    </section>
  );
};

export default RefundsTab;
