/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import LargeExportModal from '+shared/LargeExportModal';
import PaginationComponent from '+shared/Pagination';
import Table from '+shared/Table';
import { CurrencyType } from '+types';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getTime,
  isAllowed,
  logError,
  swapArrayPositions,
  switchCurrency
} from '+utils';
import useStore from '+zustandStore';

const api = new APIRequest();

const tabType = {
  balance: 'Balance',
  reserve: 'Reserve'
} as const;
type TabType = (typeof tabType)[keyof typeof tabType];

export default function balancesTab() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams();
  const [currency, setCurrency] = useState<CurrencyType>('NGN' as CurrencyType);
  const [activeTab, setActiveTab] = useState<TabType>(tabType.balance);
  const [balances, setBalances] = useState({});
  const [buffer, setBuffer] = useState([]);
  const [loadingList, setLoadingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [state, setState] = useState({ sortingParams: {} });
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const { profile } = useStore(state => state);
  const userAccess = useSetUserAccess();
  const getCurrencyHistory = c => buffer.find(data => data.type === c);
  const computePage = c => (c === currency ? currentPage : 1);

  const tabs = ['Balance', 'Reserve'];

  useLayoutEffect(() => {
    if (isAllowed(userAccess, ['merchant_balance_history.view'])) {
      tabs.push('Balance');
    }
    if (isAllowed(userAccess, ['merchant_rolling_reserve_balance_history.view'])) {
      tabs.push('Reserve');
    }
  }, [userAccess]);

  const { data: balancesData, refetch: refetchBalance } = useQuery(['MERCHANT_BALANCES', id], () => api.getBalances(id), {
    refetchOnMount: 'always',
    onSuccess: data => setBalances(data?.data || {}),
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's balances `,
        type: 'danger',
        action: {
          action: () => refetchBalance(),
          name: 'Try again'
        }
      });
    }
  });

  const {
    data: reserveData,
    refetch: refetchReserve,
    isFetching: isFetchingReserveData
  } = useQuery(
    ['ROLLING_RESERVE', currency, computePage(currency), limit, id],
    () => api.getRollingReserveHistory(id, currency, computePage(currency), limit),
    {
      enabled: balances[currency]?.hasOwnProperty('reserve_balance') || false,
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's ${currency} rolling reserve `,
          type: 'danger',
          action: {
            action: () => refetchReserve(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const {
    data: merchantBalanceData,
    refetch: refetchMerchantBalanceHistory,
    isFetching: merchantBalanceHistoryLoading
  } = useQuery(
    ['MERCHANT_BALANCES_HISTORY', currency, computePage(currency), limit, id, state.sortingParams],
    () => api.getBalanceHistory(id, currency, computePage(currency), limit, state.sortingParams),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onSuccess: data => {
        setLoadingList(prevLoadingList => prevLoadingList.filter(item => item !== currency));
        updateHistory({ type: currency, ...data?.data });
      },
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the balance history for ${currency}.`,
          type: 'danger',
          action: {
            action: () => refetchMerchantBalanceHistory(),
            name: 'Try again'
          }
        });
        setLoadingList(prevLoadingList => prevLoadingList.filter(item => item !== currency));
      }
    }
  );
  const reserveBalance = reserveData?.data || [];

  const merchantBalance = merchantBalanceData?.data || [];

  const updateHistory = newData => {
    if (!buffer.find(x => x.type === newData.type)) {
      setBuffer(prevBuffer => [...prevBuffer, newData]);
    } else {
      setBuffer(prevBuffer => prevBuffer.map(x => (newData.type === x.type ? newData : x)));
    }
  };

  useEffect(() => {
    setActiveTab(tabType.balance);
  }, [currency]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const exportMerchantHistory = async (format, close, fieldsToExport, from, to) => {
    const typeInfo = activeTab === tabType.balance ? 'balance history' : 'reserve balance history';
    const handleTimeDescription = () => {
      if (!from && !to) return 'of all time';
      if (!from && to) return `up to ${to}`;
      if (from && !to) return `from ${from}`;
      return `from: ${from} to: ${to}`;
    };
    try {
      const res = await api.exportMerchantHistory(activeTab?.toLowerCase(), id, currency, format, fieldsToExport, from, to);
      const type = format === 'csv' ? 'csv' : 'excel';
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        APIDownload(res, `${currency} ${typeInfo} ${handleTimeDescription()}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>balance history.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      close();
      feedbackInit({
        message: `There has been an error exporting your ${switchCurrency[currency]} ${typeInfo}`,
        type: 'danger'
      });
      throw error;
    }
  };

  const filterHistory = (type, description, reference, direction) => {
    const notAvailable = [undefined, null, '', ' '];
    if (description?.startsWith('Settlement')) {
      return (
        <>
          Settlement for{' '}
          <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
            {reference?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (description?.startsWith('Chargeback') || description?.startsWith('Refund')) {
      const rcDescription = description.split('processed for ');
      return (
        <>
          {rcDescription[0]}
          <span style={{ fontWeight: 600 }}>({reference?.toUpperCase()})</span> processed for -{' '}
          <Link to={`/dashboard/pay-ins/${rcDescription[1]}`} style={{ fontWeight: 600 }}>
            {rcDescription[1]?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (description?.startsWith('overpayment') || description?.startsWith('underpayment')) {
      const ovDescription = description.split('for ');
      return (
        <>
          Reversal for <span style={{ textTransform: 'capitalize' }}>{ovDescription[0]}</span> processed for -{' '}
          <Link to={`/dashboard/pay-ins/${ovDescription[1]}`} style={{ fontWeight: 600 }}>
            {ovDescription[1]?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (description?.startsWith('Rolling')) {
      // eslint-disable-next-line prefer-regex-literals
      const rcDescription = description.split(new RegExp(' for ', 'i'));
      return (
        <>
          {capitalize(rcDescription[0].replace('Rolling', ''))}
          {' for '}
          <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
            {reference?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (notAvailable.includes(description)) return 'Not Available';
    return (
      <>
        {description}
        {!notAvailable.includes(reference) && (
          <>
            {' - '}
            {type === 'settlement' ? (
              <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
                {reference?.toUpperCase()}
              </Link>
            ) : (
              <Link to={`/dashboard/${direction === 'credit' ? 'pay-ins' : 'payouts'}/${reference}`} style={{ fontWeight: 600 }}>
                {reference?.toUpperCase()}
              </Link>
            )}
          </>
        )}
      </>
    );
  };

  const switchBalanceHistory = activetab => {
    switch (activetab) {
      case tabType.balance:
        return {
          className: '--balance-history',
          emptyStateHeading: 'No entries yet',
          emptyStateMessage: 'There are no results yet.',
          fields: each => ({
            data: {
              'Date/Time': (
                <>
                  <span>{getDate(each.history_date)}</span>
                  <span className="annotation" style={{ marginLeft: '5px' }}>
                    {getTime(each.history_date)}
                  </span>
                </>
              ),
              Details: <span>{filterHistory(each.source_type, each.description, each.source_reference, each.direction)}</span>,
              Amount: (
                <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${
                  each.direction === 'debit' ? '-' : '+'
                }${formatAmount(each.amount)}`}</span>
              ),
              balance_after: <span className="grey-text">{formatAmount(each.balance_after)}</span>
            }
          })
        };
      case tabType.reserve:
        return {
          className: '--balance-history',
          emptyStateHeading: 'No entries yet',
          emptyStateMessage: 'There are no results yet.',
          fields: each => ({
            data: {
              'Date/Time': (
                <>
                  <span>{getDate(each.history_date)}</span>
                  <span className="annotation" style={{ marginLeft: '5px' }}>
                    {getTime(each.history_date)}
                  </span>
                </>
              ),
              Details: (
                <span style={{ maxWidth: '500px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {filterHistory(each.source_type, each.description, each.transaction_reference, each.direction)}
                </span>
              ),
              Amount: (
                <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${
                  each.direction === 'debit' ? '-' : '+'
                }${formatAmount(each.amount)}`}</span>
              ),
              balance_after: <span className="grey-text">{formatAmount(each.balance_after)}</span>
            }
          })
        };
      default:
        return <div />;
    }
  };

  const tableDataKeys = Object.keys(switchBalanceHistory(activeTab).fields({}).data);
  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <div className="nav-content active" id="merchantWalletTab">
        <div className="element-box">
          <section>
            <article>
              <h5 className="form-header">Balances</h5>
              <div className="form-desc no-underline mb-2">
                See the
                {` merchant's `}
                balances as well as debit and credit entries for each currency.
              </div>
            </article>
          </section>

          <section className="element-wrapper pb-0 pt-3">
            <div className="element-header justify-content-between align-items-center d-flex pb-0">
              <h6 className="mb-0">{currency} Balance</h6>
              <div>
                <CurrencyPicker
                  options={Object.keys(balances || {})}
                  activeCurrency={currency}
                  onChange={value => {
                    setCurrency(value);
                  }}
                  className="mt-1 mb-3"
                  id="merchant-balances__currency-switch"
                />
              </div>
            </div>
          </section>

          <div className="wallet-box mt-4">
            <div className="wallet-details">
              {swapArrayPositions(Object.keys(balances[currency] || {}), 0, 1).map(balance => {
                if (balance === 'ledger_balance') return null;
                return (
                  <div className="wallet-data" key={balance}>
                    <p className="wd-label">
                      {capitalizeRemovedash(balance)} ({currency})
                    </p>
                    {balance === 'is_default' ? (
                      <p className="wd-value">{balances[currency][balance] === true ? 'YES' : 'NO'}</p>
                    ) : (
                      <p className="wd-value">{balances[currency][balance] ? formatAmount(balances[currency][balance]) : '0.00'}</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex balances__history-tabs">
            <ul className="nav nav-tabs">
              {tabs.map(tab => {
                if (!balancesData?.data[currency]?.hasOwnProperty('reserve_balance') && tab === tabType.reserve) return null;
                return (
                  <li
                    className="nav-item"
                    key={tab}
                    onClick={() => {
                      setActiveTab(tab);
                      setCurrentPage(1);
                    }}
                    onKeyUp={() => setActiveTab(tab)}
                    role="tab"
                    tabIndex={0}
                  >
                    <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                      {tab}
                      {' History '}
                      {tab === tabType.balance && `(${getCurrencyHistory(currency)?.paging?.total_items || 0})`}
                      {tab === tabType.reserve && `(${reserveBalance?.paging?.total_items || 0})`}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="element-wrapper pt-0">
          <Table
            className={switchBalanceHistory(activeTab).className}
            data={activeTab === tabType.balance ? merchantBalance?.data ?? [] : reserveBalance?.data ?? []}
            renderFields
            hasPagination={0}
            tableHeadings={tableDataKeys}
            emptyStateHeading={switchBalanceHistory(activeTab)?.emptyStateHeading || ''}
            tableWrapperClassName=""
            emptyStateMessage={switchBalanceHistory(activeTab)?.emptyStateMessage || ''}
            type="merchant-balance-history"
            current={currentPage}
            totalItems={getCurrencyHistory(currency)?.paging?.total_items || 0}
            pageSize={getCurrencyHistory(currency)?.paging?.page_size || 0}
            hideTable={switchBalanceHistory(activeTab)?.datas?.length === 0}
            filterHasAdvancedFilter={false}
            filterExportAction={exportMerchantHistory}
            filterExportModalHeading={activeTab === tabType.balance ? 'Export Balance History' : `Export ${activeTab} History`}
            filterExportModalDescription={`Choose how you would like to export this ${activeTab.toLowerCase()} history.`}
            filterExportName={`Export ${activeTab} History`}
            filterShowExport={isAllowed(userAccess, ['merchant_balance_history.export']) && activeTab === tabType.balance}
            hasFilter={isAllowed(userAccess, ['merchant_balance_history.export']) && activeTab === tabType.balance}
            filterHandleFilterQuery={param => {
              setState({ ...state, sortingParams: param });
            }}
            exportModalHeaderBottomBorder
            showExportModalDateRange
            exportModalScrollable
            loading={activeTab === tabType.balance ? merchantBalanceHistoryLoading : isFetchingReserveData}
          >
            {switchBalanceHistory(activeTab)?.fields}
          </Table>
          {!loadingList.includes(currency) && (
            <>
              {activeTab === tabType.balance && (
                <PaginationComponent
                  currentPage={currentPage}
                  pagingTotalItems={getCurrencyHistory(currency)?.paging?.total_items || 0}
                  pageSize={getCurrencyHistory(currency)?.paging?.page_size || 0}
                  action={current => setCurrentPage(current)}
                  limitAction={newLimit => setLimit(newLimit)}
                />
              )}
              {activeTab === tabType.reserve && (
                <PaginationComponent
                  currentPage={currentPage}
                  pagingTotalItems={reserveBalance?.paging?.total_items || 0}
                  pageSize={reserveBalance?.paging?.page_size || 0}
                  action={current => setCurrentPage(current)}
                  limitAction={newLimit => setLimit(newLimit)}
                />
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
}
