import React from 'react';
import { useQuery } from 'react-query';
import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import { formatAmount } from '+utils';

const api = new APIRequest();

const AnalyticsChart = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { data, isLoading } = useQuery(`ANALYTICS_CHART`, () => api.getTransactionsOverview(), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the transactions status.`,
        type: 'danger'
      });
    }
  });
  const overview = data || {};
  const legend = [
    { name: 'Total transactions', value: overview.totalCount || 0 },
    { name: 'Successful Transactions', value: overview.successCount || 0, color: '#047bf8' },
    { name: 'Failed transactions', value: overview.failedCount || 0, color: '#e65252' }
  ];
  const toolTipOptions = {
    backgroundColor: '#07002C',
    titleFontColor: 'white',
    bodyFontColor: 'white',
    borderColor: '#07002C',
    titleFontFamily: 'Averta PE',
    bodyFontFamily: 'Averta PE',
    borderWidth: 0,
    caretSize: 2,
    displayColors: false
  };

  const donutChart = () => {
    const newLegend = [
      { name: 'Successful Transactions', value: overview.successCount || 0, color: '#047bf8' },
      { name: 'Processing Transactions', value: overview.processingCount || 0, color: '#f8bc34' },
      { name: 'Pending Transactions', value: overview.pendingCount || 0, color: '#fadb92' },
      { name: 'Expired Transactions', value: overview.expiredCount || 0, color: '#E5E5E5' },
      { name: 'Failed Transactions', value: overview.failedCount || 0, color: '#e65252' }
    ];
    const donutData = {
      labels: newLegend.map(each => each.name),
      datasets: [
        {
          labels: newLegend.map(each => each.name),
          data: newLegend.map(each => each.value),
          backgroundColor: newLegend.map(each => each.color),
          borderWidth: 0.2
        }
      ]
    };
    const options = {
      legend: {
        display: false
      },
      plugins: {
        legend: {
          display: false
        }
      },
      tooltips: toolTipOptions,
      cutout: '60%',
      animation: {
        animateRotate: true
      }
    };
    return <Doughnut data={donutData} options={options} height={270} />;
  };

  return (
    <section className="col-sm-4 analytics-chart__comp">
      <div className="element-box">
        <div className="os-tabs-w mb-3">
          <div className="os-tabs-controls">
            <ul className="nav nav-tabs">
              <li className="nav-item nav-link active pb-2">Overall Performance</li>
            </ul>
          </div>
          {isLoading ? (
            <LoadingPlaceholder type="table" content={1} background="#f5f6f6" />
          ) : (
            <div className="tab-content">
              <div className="tab-pane active" id="tab_rates">
                <div className="el-chart-w">
                  {donutChart()}
                  <div className="inside-donut-chart-label">
                    <strong data-testid="success-rate">{overview.successRate ? parseFloat(overview.successRate).toFixed(2) : 0}%</strong>
                    <span data-testid="success-rate-text">Success Rate</span>
                  </div>
                </div>
                <div className="el-legend">
                  {legend.map(count => (
                    <div className="legend-value-w" key={count.name}>
                      {count.color && <div className="legend-pin" style={{ backgroundColor: count.color }} />}
                      <div className="legend-value">
                        {count.name}: <strong>{formatAmount(count.value, false)}</strong>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AnalyticsChart;
