/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import { ExportActionType, IRoleDetails } from '+types';
import { APIDownload, capitalizeFirst, isAllowed, logError } from '+utils';

import ExportPermissions from '../../components/ExportPermissions';
import UsersPermissionsTable from '../../components/UsersPermissionsTable';
import { filterPermissionOption } from '../../data';

import '../index.scss';

interface IRolePermissionProps {
  roleDetails: IRoleDetails;
}

const apiRequest = new APIRequest();
const RolePermission = ({ roleDetails }: IRolePermissionProps) => {
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const roleName = roleDetails?.name;
  const roleId = roleDetails?.id;
  const [modalVisible, setModalVisible] = useState(false);
  const [updatedPermissions, setUpdatedPermissions] = useState<string[]>();

  const isSystemRole = roleDetails?.category === 'system';

  const updateRoleMutation = useMutation(
    () =>
      apiRequest.updateRole(String(roleId), {
        name: roleName,
        permissions: updatedPermissions
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${roleId}_ROLE_DETAILS`]);
        setUpdatedPermissions([]);
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const switchModal = () => {
    const content = {
      heading: `Update role?`,
      content: (
        <p>{`Please confirm that you want to update ${isSystemRole ? 'system' : 'custom'} role with name ${capitalizeFirst(
          roleName ?? ''
        )}`}</p>
      ),
      size: 'md',
      firstButtonText: 'Cancel',
      secondButtonText: 'Yes, Update',
      secondButtonAction: () => {
        closeFeedback();
        return updateRoleMutation.mutateAsync();
      },
      equalFooterBtn: true,
      headerBottomBorder: false,
      completedModalSize: 'base',
      completedHeading: 'Updated!',
      completedDescription: (
        <p>{`You have successfully updated ${isSystemRole ? 'system' : 'custom'} role - ${capitalizeFirst(roleName ?? '')}`}</p>
      ),
      secondaryCompletedModal: true
    };

    return content;
  };

  const onUpdateRole = () => {
    setModalVisible(true);
  };

  const exportFile = async (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => {
    try {
      const newFormat = format === 'csv' ? 'csv' : 'excel';
      const res = await apiRequest.exportRole(String(roleId), newFormat, { entity: fieldsToExport });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Permissions for role with id:${roleId}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>user`s permissions.</strong>
          </>
        ),
        type: 'success'
      });

      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your permissions`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <div className="user-new-invite">
      <div className="col-sm-12">
        <div className="role-permission-actions">
          <ExportPermissions
            options={filterPermissionOption}
            exportAction={exportFile}
            canExport={isAllowed(userAccess, ['admin_user_permissions.export']) as boolean}
          />
        </div>
        <UsersPermissionsTable
          userDetails={roleDetails}
          getSelectedPermission={(value: (string | number)[]) => setUpdatedPermissions(value as string[])}
          readOnly={isSystemRole}
          type="role"
        />
        {isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update']) && !isSystemRole && (
          <button type="button" className="btn btn-save-role" onClick={onUpdateRole} disabled={!updatedPermissions}>
            Update Role
          </button>
        )}
      </div>
      <Modal visible={modalVisible} {...(switchModal() as unknown as IModalProps)} close={() => setModalVisible(false)} />
    </div>
  );
};

export default RolePermission;
