/* eslint-disable no-unused-vars */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { capitalize, logError } from '+utils';

import '../index.scss';

const authBaseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const api = new APIRequest(authBaseURL);

const SubscribeConfiguration = () => {
  const { feedbackInit } = useFeedbackHandler();

  const { id } = useParams() as { id: string };

  const { data } = useQuery(['SUBSCRIPTION_NOTIFY', id], () => api.getSubscribeEmailConfig(id), {
    select: data => data?.data?.configuration?.account_report?.transaction_report,
    onError: (error: AxiosError) => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error fetching the subscription settings.`,
        type: 'danger'
      });
    }
  });
  if (!data) return null;

  return (
    <>
      <div className="sub-desc">
        <p className="sub-desc-text col-9 pl-0">Automated product update reports</p>
        <p className="sub-interval">Sent {capitalize(data?.interval)}</p>
      </div>
      <div className="configuration-form-heading">
        <div className="form-group mb-4">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark" style={{ color: '#3E4B5B' }}>
              Where should Kora send automated product update emails?
            </span>
          </label>
          <div className="subscribe-box mt-0">
            <div className="subscribe-box-input ">
              <input
                value={data?.email_recipients?.join?.(', ')}
                className="form-control email-input"
                disabled
                data-testid="config-email-input"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribeConfiguration;
