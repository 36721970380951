import React, { FC } from 'react';

import TierIcon from '+shared/TierIcon';
import { CategoriesProps } from '+types/productTiers';

import './index.scss';

const TierCategories: FC<CategoriesProps> = ({ currentRoute, setRoute, allTiers }) => {
  return (
    <div className="email-categories">
      <ul>
        {allTiers?.map((menu, key) => {
          return (
            <li
              className={`${currentRoute === menu.name && 'active'} tier-categories`}
              onClick={() => setRoute({ route: menu.name })}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              key={menu.name}
              tabIndex={0}
              onKeyDown={() => setRoute({ route: menu.name })}
            >
              <TierIcon
                id={key}
                roundedBorderColor={currentRoute === menu.name ? '#f1f6fa' : '#fff'}
                variant={currentRoute === menu.name ? 'blue' : 'default'}
              />
              {menu.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TierCategories;
