import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import { useSearchQuery } from '+hooks';

import ToolTip from './Tooltip';

import './index.scss';

interface ICursorPagination {
  showPaginationDescription?: boolean;
  showLimit?: boolean;
  annotation?: string;
  scrollToTop?: () => void;
  disabled?: boolean;
  cursors: { next_cursor?: number; previous_cursor?: number; total_items: number | null; page_size: number };
  totalCount: number;
}

let previousTab: string;
export default function CursorPagination({
  showPaginationDescription = true,
  showLimit = true,
  annotation = 'transactions',
  scrollToTop,
  disabled,
  cursors,
  totalCount
}: ICursorPagination) {
  const searchQuery = useSearchQuery<{
    startingAfter: string;
    endingBefore: string;
    totalItems: string;
    page: string;
    limit: string;
    tab: string;
    currency: string;
    activeTab: string;
  }>();
  const totalItems = Number(searchQuery.value.totalItems ?? 0);
  const page = Number(searchQuery.value.page ?? 1);
  const pageSize = Number(cursors.page_size ?? 10);
  const currentPageSize = Number(searchQuery.value.limit ?? pageSize);
  const tab = searchQuery.value.tab ?? searchQuery.value.activeTab ?? searchQuery.value.currency ?? '';
  const pagingTotalItems = Number(cursors?.total_items ?? 0);
  const previousCursor = cursors?.previous_cursor;
  const nextCursor = cursors?.next_cursor;
  const noNextCursor = !nextCursor;

  useLayoutEffect(() => {
    if (pagingTotalItems && pagingTotalItems > 0 && pagingTotalItems !== totalItems) {
      searchQuery.setQuery({ totalItems: String(pagingTotalItems) });
    }
  }, [pagingTotalItems, totalItems]);

  useEffect(() => {
    if (previousTab && tab !== previousTab) {
      searchQuery.setQuery({ page: '1', startingAfter: '', endingBefore: '', totalItems: '' });
    }
    previousTab = tab;
  }, [tab]);

  const totalPages = Math.ceil(totalItems / currentPageSize) || 1;

  useLayoutEffect(() => {
    // handles when user enters a page greater than total number of pages in the URL
    // it returns the user back to the first page
    if (page > totalPages) {
      searchQuery.setQuery({ page: '1', startingAfter: '', endingBefore: '' });
    }
  }, [page]);

  const paginate = (direction: 'prev' | 'next') => {
    if (direction === 'next' && nextCursor) {
      searchQuery.setQuery({ startingAfter: nextCursor.toString(), endingBefore: '', page: String(page + 1) });
    } else if (direction === 'prev' && previousCursor && noNextCursor) {
      searchQuery.setQuery({ endingBefore: previousCursor.toString(), startingAfter: '', page: (totalPages - 1).toString() });
    } else if (direction === 'prev' && previousCursor && page > 1) {
      searchQuery.setQuery({ endingBefore: previousCursor.toString(), startingAfter: '', page: String(page - 1) });
    }
  };

  const getBeginning = () => {
    if (previousCursor && nextCursor) {
      return currentPageSize * (page - 1) + 1;
    }
    if (noNextCursor) {
      return currentPageSize * (totalPages - 1) + 1;
    }
    return 1;
  };

  const getEnding = () => {
    if (noNextCursor) {
      return totalItems;
    }
    if (nextCursor && previousCursor) {
      const currentEnd = currentPageSize * page;
      return currentEnd > totalItems ? totalItems : currentEnd;
    }
    return currentPageSize;
  };

  const ending = useMemo(() => getEnding(), [cursors, page, currentPageSize]);
  const beginning = useMemo(() => getBeginning(), [cursors, currentPageSize, page]);
  const gotoFirst = () => searchQuery.setQuery({ startingAfter: '', endingBefore: '', page: '1' });

  const renderPagination = () => {
    if (totalPages <= 1) return null;
    return (
      <div className="paging-row">
        {showPaginationDescription && (
          <span className="pagination-pages">
            <strong>{beginning}</strong> - <strong>{ending}</strong> of <strong>{totalItems}</strong>
          </span>
        )}
        <button
          disabled={disabled || page === 1}
          onClick={() => gotoFirst()}
          type="button"
          className={`pagination-button ${disabled || page === 1 ? 'disabled' : ''}`}
          aria-label="First Page"
        >
          &laquo;
        </button>

        <button
          disabled={disabled || page === 1}
          onClick={() => paginate('prev')}
          type="button"
          className={`pagination-button ${disabled || page === 1 ? 'disabled' : ''}`}
          aria-label="Previous Page"
        >
          &lsaquo;
        </button>

        <button
          disabled={disabled || noNextCursor || page === totalPages}
          onClick={() => paginate('next')}
          type="button"
          className={`pagination-button ${disabled || noNextCursor || page === totalPages ? 'disabled' : ''}`}
          aria-label="Next Page"
        >
          &rsaquo;
        </button>
      </div>
    );
  };

  const scrollToTopSection = () => {
    if (totalCount < 10 || (totalCount <= 5 && document.body.clientWidth < 1024)) return null;
    const defaultScroll = () => window.scroll(0, 0);
    return (
      <div>
        <button disabled={disabled} className="btn btn-sm pagination-back-to-top" type="button" onClick={scrollToTop || defaultScroll}>
          <span>Back to top</span>
          <i className="os-icon os-icon-arrow-up6" />
        </button>
      </div>
    );
  };

  const displayLimit = () => {
    return (
      showLimit && (
        <label>
          Show
          <select
            name="dataTable1_length"
            onChange={e => {
              searchQuery.setQuery({ limit: e.target.value, endingBefore: '' });
            }}
            value={pageSize || '25'}
            aria-controls="dataTable1_length"
            className={`form-control form-control-sm ${annotation === 'users' ? 'default-menu' : ''} ${
              disabled || page !== 1 ? 'disabled' : ''
            }`}
            disabled={disabled || page !== 1}
            data-testid="pagination_limit"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
          {annotation} per page
        </label>
      )
    );
  };

  return (
    <ToolTip
      hasFullWidth
      centered
      disabled={!disabled || totalItems <= pageSize}
      type="bulk_actions_pagination"
      message={<p>This action is disabled because you are carrying out a bulk action on this page.</p>}
    >
      <section
        className={`pagination-container ${disabled ? 'disabled' : ''} `}
        hidden={totalItems === 0}
        data-testid="pagination-component"
      >
        {totalItems <= pageSize ? (
          <>
            <div className="dataTables_length">
              <span className="pagination-pages">
                <strong>{totalItems}</strong> {totalItems === 1 ? annotation.substr(0, annotation.length - 1) : annotation}
              </span>
            </div>
            {scrollToTopSection()}
          </>
        ) : (
          <>
            <div className="dataTables_length" id="dataTable1_length">
              {page === 1 ? (
                displayLimit()
              ) : (
                <ToolTip type="limit" hasFullWidth message={<p>You can only choose the number of items to display from the first page.</p>}>
                  {displayLimit()}
                </ToolTip>
              )}
            </div>

            {scrollToTopSection()}

            {renderPagination()}
          </>
        )}
      </section>
    </ToolTip>
  );
}
