/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PausedPayments from './components/PausedPayments';
import TransactionDetails from '../../Dashboard/TransactionDetails';
import './index.scss';

export default function Paused() {
  return (
    <div className="content-i">
      <Switch>
        <Route exact path="/dashboard/paused/payouts" component={PausedPayments} />
        <Redirect path="/dashboard/paused/" to="/dashboard/paused/payouts" />
        <Route path="/dashboard/paused/payouts/:id">
          <TransactionDetails />
        </Route>
      </Switch>
    </div>
  );
}
