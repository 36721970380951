import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import {
  EntityType,
  ExplicitPermissionType,
  IPermission,
  IRoleDetails,
  IUserDetails,
  PermissionColumnType,
  PermissionModalType,
  PermissionTableType,
  SelectedPermissionType
} from '+types';
import { isAllowed } from '+utils';

import { permissions } from '../../data';
import ExplicitPermissionLabel from '../ExplicitPermissionLabel';
import ManagePermissionModal from '../ManagePermissionModal';

import './index.scss';

const apiRequest = new APIRequest();
interface IUserPermissionTableProps {
  userDetails?: IUserDetails | IRoleDetails | null;
  usePermissionId?: boolean;
  getSelectedPermission: (permission: (string | number)[]) => void;
  reset?: boolean;
  readOnly?: boolean;
  prevSelectedPermissions?: (string | number)[];
  acceptEmptyPermission?: boolean;
  usersPermissions?: IPermission[];
  userExplicitPermissions?: ExplicitPermissionType[];
  type?: string;
}

const UserPermissionTable = ({
  userDetails = null,
  usePermissionId = false,
  getSelectedPermission,
  reset = false,
  readOnly = false,
  prevSelectedPermissions = [],
  acceptEmptyPermission = false,
  usersPermissions = [],
  userExplicitPermissions = [],
  type = 'user'
}: IUserPermissionTableProps) => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [selectedPermissionsCache, setSelectedPermissionsCache] = useState<(string | number)[]>(prevSelectedPermissions);
  const [selectedPermission, setSelectedPermission] = useState<SelectedPermissionType | null>(null);
  const [modalType, setModalType] = useState<PermissionModalType | null>(null);
  const [isEdited, setIsEdited] = useState<Record<string, number[]>[]>([]);
  useEffect(() => {
    if (selectedPermissionsCache.length || acceptEmptyPermission) {
      getSelectedPermission(selectedPermissionsCache);
    }
    return () => setModalType(null);
  }, [selectedPermissionsCache]);

  useEffect(() => {
    if (reset) {
      setSelectedPermissionsCache([]);
      setIsEdited([]);
    }
  }, [reset]);

  useEffect(() => {
    if (!selectedPermission) return;
    const selectedPermissionDetails = selectedPermission.children.map(child => [...Object.values(child)[0]][0]?.id);

    if (selectedPermissionDetails.length) {
      setIsEdited(prev => [...prev, { [selectedPermission.key]: selectedPermissionDetails }]);
    }
  }, [selectedPermissionsCache.length]);

  const { data: resolvedData, refetch } = useQuery(['PERMISSIONS'], () => apiRequest.getAllPermissions(), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Users',
        message: 'There has been an error getting permissions',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const getExplicitPermissions = (permissionKey: string) => {
    const explicitPermissions = userExplicitPermissions.filter(permission => permission.entity === permissionKey && permission.allowed);
    return { explicitPermissions, explicitPermissionsLength: explicitPermissions.length };
  };

  const processPermission = (currentPermission: PermissionTableType) => {
    let newPermission: { [x: string]: PermissionColumnType[] }[] = [];
    const cache: string[] = [];
    const keys = new Set();
    if (resolvedData?.length) {
      const filteredPermissions = resolvedData.filter((data: IPermission) => {
        const result = data?.entity?.includes(currentPermission.key);
        if (result) {
          keys.add(data.slug.split('.')[0]);
        }
        return result;
      });
      if (filteredPermissions.length) {
        filteredPermissions.map((permission: IPermission) => {
          const value = permission.slug.split('.')[0];
          const isExplicit = !!getExplicitPermissions(currentPermission.key)?.explicitPermissions?.filter(val => val.id === permission.id)
            ?.length;
          if (keys.has(value)) {
            if (!cache.includes(value)) {
              cache.push(value);
              newPermission = [...newPermission, { [value]: [{ ...permission, isExplicit }] }];
            } else {
              newPermission = newPermission.map(item => {
                if (Object.keys(item)[0] === value) {
                  return {
                    [value]: [
                      ...item[value],
                      {
                        ...permission,
                        isExplicit
                      }
                    ]
                  };
                }
                return item;
              });
            }
          }
          return null;
        });
      }
      return { ...currentPermission, children: newPermission };
    }
    return null;
  };

  const handleSelectedPermission = (permission: PermissionTableType) => {
    setModalType('updatePermission');
    setSelectedPermission(processPermission(permission) as SelectedPermissionType);
  };
  const handleCloseModal = () => {
    setModalType(null);
    setSelectedPermission(null);
  };

  const handleConfirmChange = (updatedPermission?: (string | number)[]) => {
    if (!updatedPermission) return;
    setSelectedPermissionsCache([...updatedPermission]);
  };

  const handleRowClick = (selectedPermissions: PermissionTableType) => {
    if (
      !isAllowed(userAccess, [
        'admin_users.update',
        'custom_roles.create',
        'system_roles.update',
        'admin_user_permissions.update',
        'my_custom_roles.update',
        'custom_roles.update',
        'admin_user_permissions.view'
      ])
    )
      return;
    handleSelectedPermission(selectedPermissions);
  };

  const showIsEdited = useCallback(
    (key: EntityType) => {
      if (isEdited?.length) {
        const result = isEdited.filter(edited => edited[key]?.some(id => selectedPermissionsCache.includes(id)))[0];
        return result;
      }
      return false;
    },
    [selectedPermissionsCache.length, isEdited.length]
  );
  return (
    <>
      <div className="element-permission-modify">
        <div className="element-permission-box">
          <div className="permission-modify-cont">
            {permissions.map(permission => (
              <Fragment key={permission.key}>
                <div
                  className="permission-modify-roles"
                  role="presentation"
                  onClick={() => handleRowClick(permission)}
                  onKeyDown={() => handleRowClick(permission)}
                >
                  <div className="rolename-container">
                    <p className="permission-name">{permission.name}</p>
                    {getExplicitPermissions(permission.key)?.explicitPermissionsLength > 0 && (
                      <ExplicitPermissionLabel number={getExplicitPermissions(permission.key)?.explicitPermissionsLength} />
                    )}
                  </div>
                  <p />
                  {showIsEdited(permission.key) && <p className="modified-text">This permission has been modified</p>}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {modalType && (
        <ManagePermissionModal
          close={handleCloseModal}
          selectedPermission={selectedPermission}
          modalType={modalType}
          action={handleConfirmChange}
          userDetails={
            // eslint-disable-next-line no-nested-ternary
            !selectedPermissionsCache.length
              ? usersPermissions.length
                ? { id: userDetails?.id as number, adminPermissions: usersPermissions }
                : userDetails
              : { permissions: selectedPermissionsCache }
          }
          usePermissionId={usePermissionId}
          readOnly={readOnly}
          type={type}
        />
      )}
    </>
  );
};

export default UserPermissionTable;
