import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import usePartnerBalanceStore from '+store/partnerBalanceStore';
import { AddFundsModalType, CurrencyType, FileFormatType, IPartnerBalanceHistory, SortingParamsType } from '+types';
import {
  APIDownload,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatAllTime,
  formatAmount,
  getDate,
  getPresentDate,
  getTime,
  history,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';

import LoadingPlaceholder from '../Shared/LoadingPlaceHolder';
import AddFundsModal from './components/AddFundsModal';
import BalanceSummaryCard from './components/BalanceSummaryCard';

import './index.scss';

const TabInfo = {
  history: 'Transaction History'
};

const api = new APIRequest();

const PartnersBalanceHistory = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery<{
    tab: keyof typeof TabInfo;
    page: string;
    limit: string;
    dateFrom: string;
    dateTo: string;
    status: string;
    currency: CurrencyType;
  }>();
  const [activeModal, setActiveModal] = useState<AddFundsModalType>(null);
  const setPartnerBalance = usePartnerBalanceStore(state => state.setPartnerBalances);

  const activeTab = searchQuery.value.tab ?? 'history';
  const page = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? 10;
  const status = searchQuery.value.status ?? undefined;
  const currency = searchQuery.value.currency ?? 'NGN';
  const dateFrom = searchQuery.value.dateFrom ?? getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo ?? getPresentDate().dateTo;

  const sortingParams = {
    status,
    date_from: formatAllTime(dateFrom),
    date_to: formatAllTime(dateTo),
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      'currency'
    ])
  } satisfies SortingParamsType;

  const { data, refetch, isFetching } = useQuery(
    [`${accountId}_BALANCE_HISTORY`, limit, page, sortingParams],
    () => api.getPartnerBalanceHistory(accountId, page, Number(limit ?? 1), sortingParams),
    {
      refetchOnMount: 'always',
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error in getting this partner's balance history`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: !!accountId
    }
  );

  const {
    data: partnerBalanceData,
    refetch: refetchPartnerBalance,
    isFetching: partnerBalanceIsFetching
  } = useQuery([`${accountId}_BALANCE`], () => api.getPartnerBalance(accountId), {
    refetchOnMount: 'always',
    onSuccess: result => {
      setPartnerBalance({ [`${accountId}`]: result?.data });
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this partner's balance`,
        type: 'danger',
        action: {
          action: () => refetchPartnerBalance(),
          name: 'Try again'
        }
      });
    },
    enabled: !!accountId
  });

  const handleBalanceRefetch = () => {
    refetchPartnerBalance();
  };
  const isCredit = (sourceId: string) => sourceId !== accountId;
  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportPartnersBalance(sortingParams, format, fieldsToExport);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Partner Balance History at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>balance history.</strong>
          </>
        ),
        type: 'success'
      });

      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your balance history`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const partnerBalanceHistory = {
    className: '--partner-balance-history-table',
    rowURL: '/dashboard/partners-balance/transaction',
    rowKey: 'reference',
    emptyStateHeading: 'No History yet',
    data: data?.data?.data || [],
    emptyStateMessage: 'Balance History Not Available',
    fields: (each: IPartnerBalanceHistory) => ({
      data: {
        Currency_Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(each.status)}`} />
            <span>{capitalizeRemovedash(`${each?.status}`)}</span>
          </>
        ),
        transaction_id: <span className=" --blue-text">{each.reference}</span>,
        session_id: <span className="--blue-text">{each.session_id ?? 'N/A'}</span>,
        Date_Added: (
          <>
            <span>{getDate(each.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each.created_at)}
            </span>
          </>
        ),
        amount: (
          <span className={`${isCredit(String(each?.source_processor_account_id)) ? 'credit' : 'debit'}`} data-testid="amount">
            {isCredit(String(each?.source_processor_account_id)) ? '+' : '-'}
            {formatAmount(each.amount)}
          </span>
        )
      }
    })
  };

  return (
    <>
      <div className="content-i partners-balance-history">
        <div className="content-box">
          <div className="row">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Go Back</span>
            </button>
          </div>
          <div className="content">
            <div className="first-section">
              {partnerBalanceIsFetching ? (
                <div className="title-wrapper --loading">
                  <LoadingPlaceholder type="text" content={2} />
                </div>
              ) : (
                <div className="title-wrapper">
                  {partnerBalanceData?.data && (
                    <BalanceSummaryCard showDetails={false} balance={partnerBalanceData?.data} currency={currency} />
                  )}
                </div>
              )}

              <div className="controls">
                <button type="button" className="btn btn-primary" onClick={handleBalanceRefetch}>
                  <i className="os-icon os-icon-refresh-ccw" />
                  Refetch Balance
                </button>
                {isAllowed(userAccess, ['partner_funding.create']) ? (
                  <button type="button" className="btn btn-primary" onClick={() => setActiveModal('add-funds')}>
                    <i className="os-icon os-icon-plus" />
                    Add Funds
                  </button>
                ) : null}
              </div>
            </div>

            <div className="table-section">
              <section className="os-tabs-w">
                <div className="os-tabs-controls os-tabs-complex">
                  <ul className="nav nav-tabs" role="tablist">
                    {Object.entries(TabInfo).map(([tab, value]) => {
                      const tabValue = tab as keyof typeof TabInfo;
                      return (
                        <li
                          className="nav-item"
                          key={tab}
                          role="tab"
                          onClick={() => {
                            searchQuery.setQuery({ tab: tabValue });
                          }}
                          onKeyDown={() => {
                            searchQuery.setQuery({ tab: tabValue });
                          }}
                        >
                          <div className={`nav-link ${tab === activeTab && 'active'}`}>{capitalizeRemovedash(value)}</div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
              <Table
                annotation="Transactions"
                className={partnerBalanceHistory.className}
                data={partnerBalanceHistory.data || []}
                filterExportAction={exportFile}
                tableHeadings={['Status', 'Transaction ID', 'Session ID', 'Date and Time', 'Amount(NGN)']}
                loading={isFetching}
                renderFields
                hasPagination
                pageSize={Number(limit)}
                totalItems={data?.data?.paging?.total_items}
                current={parseInt(page, 10)}
                hideTable={partnerBalanceHistory.data?.length === 0}
                tableWrapperClassName="table-responsive table-wrapper"
                emptyStateHeading={partnerBalanceHistory?.emptyStateHeading || ''}
                emptyStateMessage={partnerBalanceHistory.emptyStateMessage || ''}
                actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
                limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
                filterHasAdvancedFilter={false}
                type="partners_balance"
                rowKey={partnerBalanceHistory.rowKey}
                rowURL={partnerBalanceHistory.rowURL}
                isRowClickable={Boolean(isAllowed(userAccess, ['partner_funding_details.view']))}
                filterShowExport={Boolean(isAllowed(userAccess, ['partner_funding.export']))}
              >
                {partnerBalanceHistory.fields}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {activeModal && <AddFundsModal type={activeModal} setter={value => setActiveModal(value)} accountId={accountId} />}
    </>
  );
};

export default PartnersBalanceHistory;
