import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import createAuthSlice, { AuthSlice } from './setAuth';

const useStore = create<AuthSlice>()(
  devtools(
    (set, get, api) => ({
      ...createAuthSlice(set, get, api)
    }),
    { enabled: process.env.NODE_ENV !== 'production' }
  )
);

export default useStore;
