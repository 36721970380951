import React, { useLayoutEffect, useState } from 'react';
import { formatAmount } from '+utils';
import pendingCircles from '+assets/img/dashboard/pending-circles.gif';
import ReversalStates from '../ReversalStates';
import '../index.scss';

const Refunds = ({ data, currency, isLoading, isError, refetch }) => {
  const items = [];
  const [item, setItem] = useState(items);
  const [activeReference, setActiveReference] = useState(null);

  const count = data?.length;

  const handleClick = (item) => {
    setItem([item]);
    setActiveReference(item.reference);
  };

  useLayoutEffect(() => {
    if (data?.length > 0) {
      setItem([data[0]]);
      setActiveReference(data[0].reference);
    }
  }, [data]);

  const switchRefundStatus = {
    success: { name: 'Successful', color: '#24B314', icon: '✓' },
    manual: { name: 'Manual', color: '#24B314', icon: '✓' },
    pending: {
      name: 'Processing...',
      color: 'rgba(16,38,73,.4)',
      icon: <img src={pendingCircles} alt="" id="pending-circles" />
    },
    processing: {
      name: 'Processing...',
      color: 'rgba(16,38,73,.4)',
      icon: <img src={pendingCircles} alt="" id="pending-circles" />
    },
    failed: { name: 'Failed', color: '#F32345', icon: '✗' }
  };

  return (
    <div>
      {isLoading && <ReversalStates reversalState="loading" reversalType="refunds" />}
      {isError && <ReversalStates reversalState="error" reversalType="refunds" refreshFn={() => refetch()} />}
      {data?.length > 0 && (
        <ul>
          <li id="payment-detail">
            <div className="reversal-count-container" style={{ width: '40%' }}>
              <p className="reversal-count">{`${count} ${count > 1 ? 'Refunds' : 'Refund'} found`}</p>
              {data.map((item) => {
                if (item) {
                  items.push(item);
                }
                return (
                  <div
                    className={`--container ${activeReference === item.reference ? 'active-dispute' : ''}`}
                    key={item.reference}
                    onClick={() => handleClick(item)}
                  >
                    <p className="reversal-amount">
                      <label className="">Refund of</label>
                      {`${formatAmount(item.amount || 0)} ${currency}`}
                    </p>
                    <span className={`tab-icon ${activeReference === item.reference && 'active'}`} />
                  </div>
                );
              })}
            </div>

            <div className="customer-reversal-info" style={{ width: '60%' }}>
              <ul className="">
                <li>
                  <p>Refund ID</p>
                  <p>{item[0]?.id}</p>
                </li>
                <li>
                  <p>Refund Destination</p>
                  <p>{item.destination === 'disbursement_wallet' ? `Balance` : 'Customer'}</p>
                </li>
                <li>
                  <p>Refund Status</p>
                  <p
                    style={{
                      color: switchRefundStatus[item[0]?.status]?.color || ''
                    }}
                  >
                    {switchRefundStatus[item[0]?.status]?.name}
                  </p>
                </li>
                <li>
                  <p>Date Created</p>
                  <p>{item[0]?.created_at}</p>
                </li>
                <li>
                  <p>Date Completed</p>
                  <p>{item[0]?.completed_at}</p>
                </li>
                <li>
                  <p>Reference</p>
                  <p className="reversal_ref">{item[0]?.reference}</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      )}
      {data?.length === 0 && !isLoading && !isError && <ReversalStates reversalState="empty" reversalType="refunds" />}
    </div>
  );
};
export default Refunds;
