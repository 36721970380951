/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import { TTransactionDetails, useTransactionStatusStateType } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  daysfromToday,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  switchChannel,
  switchCurrency,
  switchStatus,
  truncateString
} from '+utils';

import { payInDetails } from './payins';
import { payOutDetails } from './payouts';

export const moreDetails = ({
  data,
  transactionType,
  swapStatus,
  exchange_rate,
  source_currency,
  updateTransactionStatusModalState,
  state,
  userAccess,
  swapChannel,
  swapReference,
  destination_currency
}: {
  data: TTransactionDetails;
  transactionType: string;
  swapStatus: string;
  exchange_rate: number;
  source_currency: string;
  updateTransactionStatusModalState: (a: boolean, b: any) => void;
  state: useTransactionStatusStateType;
  userAccess: { [key: string]: boolean };
  swapChannel: string;
  swapReference: string;
  destination_currency: string;
}) => {
  const {
    trace_id: traceId,
    reference,
    payment_reversals_type: refundOrChargeback,
    channel,
    transaction_date: createdAt,
    completed_at: completedAt,
    currency,
    source,
    payment,
    narration,
    merchant_bears_cost: merchantBearsCost,
    processor,
    processor_reference: processorReference,
    meta,
    payment_source,
    cross_currency,
    description,
    type: cardTransactionType = '',
    card,
    external_reference: providerReference,
    unique_reference: uniqueReference,
    auth_data
  } = data || {};

  const [ngnCurrencyIndex, usdCurrencyIndex] = Object.keys(switchCurrency);
  const koraId = transactionType === 'payouts' ? uniqueReference : payment?.reference;

  const authExpiryText = () => {
    const diff = daysfromToday(auth_data?.pre_auth_expiry);

    if (diff.includes('in')) return `${diff.split(' ').slice(1).join(' ').split(',').join(' ')} remaining`;
    if (diff.includes('ago')) return 'Expired';
    return diff;
  };

  const targetRef = meta?.void?.target_transaction_reference;

  return {
    shared: {
      status:
        transactionType === 'pay-ins'
          ? payInDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).status
          : transactionType === 'payouts'
          ? payOutDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).status
          : '',
      ...(targetRef && {
        target: (
          <Link to={`/dashboard/pay-ins/${targetRef}`} className="target-ref">
            {targetRef} <i className="os-icon os-icon-arrow-up-right" />
          </Link>
        )
      }),
      currency_charged:
        switchCurrency[currency as keyof typeof switchChannel] || switchCurrency[source_currency as keyof typeof switchCurrency],
      'Korapay ID':
        (
          <Copyable
            text={transactionType === 'currency-exchange' ? swapReference : koraId}
            textModifier={text => truncateString(text, 8)}
          />
        ) || 'Not available',
      channel: switchChannel(transactionType === 'currency-exchange' ? swapChannel : channel)
    },
    common: {
      transaction_type: (
        <span>
          {transactionType === 'pay-ins'
            ? channel === 'web' && source?.type !== 'wallet'
              ? 'Deposit'
              : 'Pay-in'
            : channel === 'web'
            ? 'Withdrawal'
            : 'Payout'}
        </span>
      ),
      processor: `${processor || 'Not available'}`,
      'Processor ID': `${processorReference || 'Not available'}`,
      fee_bearer: `${merchantBearsCost ? 'Merchant' : 'Customer'}`,
      ...(!['refunds'].includes(transactionType) && {
        channel: switchChannel(channel)
      }),
      ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
      ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
      ...(traceId ? { trace_id: traceId } : {}),
      ...(meta?.stan && { stan: <Copyable text={meta?.stan} /> }),
      approval_code: meta?.authorization_code ? <Copyable text={meta?.authorization_code} /> : 'Not Available',
      date_created: `${getDate(createdAt)}, ${getTime(createdAt)}`,
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
      ...(auth_data?.pre_auth_expiry && {
        authorization_expiry: (
          <>
            {getDateAndTime(auth_data?.pre_auth_expiry)} <i style={{ color: '#A9AFBC', fontStyle: 'italic' }}>({authExpiryText()})</i>
          </>
        )
      }),
      payment_reversals_type: refundOrChargeback ? 'Yes' : 'None',
      ...(transactionType === 'payouts' ? { transaction_ID: <Copyable text={reference} /> } : {})
    },
    'virtual-card': {
      transaction_type: <span className="semibold">{capitalizeRemovedash(cardTransactionType)}</span>,
      processor: `${capitalize(card?.provider) || 'Not available'}`,
      "Processor's Transaction ID": providerReference ? <Copyable text={providerReference} /> : 'Not available',
      ...(cardTransactionType !== 'card_creation' && { description }),
      'cross-currency': cross_currency ? 'Yes' : 'No'
    },
    refunds: {
      ...(completedAt ? { date_completed: `${getDate(completedAt)}, ${getTime(completedAt)}` } : {}),
      processor: `${capitalizeRemovedash(payment_source?.processor || 'Not available')}`,
      processor_reference: `${payment_source?.processor_reference || 'Not available'}`,
      channel: `${switchChannel(payment_source?.channel) || 'Not available'}`,
      settlement_reference: `${payment_source?.settlement?.reference || 'Not available'}`
    },
    'currency-exchange': {
      status: (
        <>
          <span
            className={`status-pill smaller ${swapStatus === 'success' ? 'green' : switchStatus(swapStatus)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(swapStatus === 'success' ? 'Successful' : swapStatus || 'processing')}
        </>
      ),
      exchange_rate: (
        <>
        {`1 USD → ${exchange_rate} ${destination_currency !== 'USD' ? destination_currency : source_currency}`}
        </>
      ),
      description: <span className="__no-transaction-text">{capitalizeRemovedash(narration || 'No description provided')}</span>
    }
  };
};
