import React, { FC } from 'react';
import useStore from '+store/zustand';
import ProcessSpinnerIcon from '+assets/img/dashboard/process-spinner.svg';
import CompletedMakerIcon from '+assets/img/dashboard/completed-tranparent-marker.svg';
import CloseDarkIcon from '+assets/img/dashboard/close-dark.svg';
import CloseWhiteIcon from '+assets/img/dashboard/close-white.svg';
import { Link } from 'react-router-dom';
import { BulkActionType } from 'src/types/tables';
import './index.scss';

const BulkTableNotify: FC<{ action: string; isOpen: boolean; toggle: () => void }> = ({ action, isOpen, toggle }) => {
  
  const { bulkInfo } = useStore();
  const bulkState = bulkInfo?.data?.filter((item: BulkActionType) => item.type === action);

  const isProcessing = bulkState?.some((item: BulkActionType) => item.status === 'in_progress');
  
  return (
    <>
      {isOpen && (
        <div className={`bulktable-box ${!isProcessing ? 'is-completed' : ''}`}>
          {!isProcessing ? (
            <p className="mb-0 bulktable-p-completed">
              <span>
                <img alt="CompletedMakerIcon" src={CompletedMakerIcon} aria-hidden="true" />
              </span>
              Batch process complete
            </p>
          ) : (
            <p className="mb-0">
              <span>
                <img alt="ProcessSpinnerIcon" src={ProcessSpinnerIcon} aria-hidden="true" className="rotate" />
              </span>
              A batch process is in progress...
              <Link to="/dashboard/utilities">See more</Link>
            </p>
          )}
          <button type="button" className="btn btn-link p-0" onClick={toggle}>
            <img alt="CloseIcon" src={!isProcessing ? CloseWhiteIcon : CloseDarkIcon} aria-hidden="true" />{' '}
          </button>
        </div>
      )}
    </>

  );
};

export default BulkTableNotify;
