import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ReportPayLoadType, UseGenerateReportType } from '+types';
import { logError } from '+utils';
import APIRequest from '+services/api-services';

const api = new APIRequest();

interface IReportData {
  status: string;
  id: number;
  currency: string;
  processor: string;
  start_date: string;
  end_date: string;
  email_to: string;
  updatedAt: string;
  createdAt: string;
}

interface IReportResponse {
  status: boolean;
  message: string;
  data: IReportData;
}

const useGenerateReport = (): UseGenerateReportType => {
  const queryClient = useQueryClient();
  const {
    mutate: generateReportMutation,
    isLoading,
    isError,
    isSuccess
  } = useMutation((payload: ReportPayLoadType) => api.generateSpoolingTransactionReportSerivce(payload), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['GET_ALL_SPOOLING_REPORTS']);
    },
    onError: (error) => {
      logError(error);
    }
  });
  const generateReport = async (data: ReportPayLoadType) => {
    await generateReportMutation(data);
  };

  return {
    isLoading,
    generateReportMutation: generateReport,
    isError,
    isSuccess
  };
};

export default useGenerateReport;
