import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '+shared/Modal';


interface ISettlementsConfigInfoModalProps {
  closeModal: () => void;
  visible: boolean;
  type: string;
  source: string;
}
function SettlementsConfigInfoModal({ type, visible, closeModal, source } : ISettlementsConfigInfoModalProps) {
  const headings = {
    destination: 'Settlement Destination',
    timing: 'Settlement Cycle/Timing'
  };

  const settlementDestinationContent = () => {
    return (
      <section className="settlements-settings__modal-content">
        <article>
          <p>
            Whenever a merchant receives payments from their customer, Korapay initiates a settlement process to make the funds available
            for use by the merchant. The merchant receives these funds in their Settlement Destination. Funds can either be settled in their
            Korapay Wallet or their Saved Bank Account.
          </p>
        </article>
        <article className="my-4">
          <h6>Settlements to Korapay Balance</h6>
          <p>If the Settlement Destination is set to Korapay Balance, the merchant will receive settled funds in their Balance.</p>
        </article>
        <article className="my-4">
          <h6>Settlements to Saved Bank Account</h6>
          <p>
            If the Settlement Destination is set to Saved Bank Account, the merchant will receive settled funds directly in their approved
            bank account specified for receiving settlements. Note that this type of settlement may attract a fee similar to Payout fees.
          </p>
        </article>

        {source === 'default' && (
          <p>Go to the ‘Settlements’ tab under a merchant’s detail page to modify the Settlement Destination for that merchant.</p>
        )}
        {source === 'merchant' && (
          <p>
            Go to the ‘Settings’ page to{' '}
            <Link to="/dashboard/settings/settlements">
              <strong>see the Default Settlement Destination for all merchants.</strong>
            </Link>
          </p>
        )}
      </section>
    );
  };

  const settlementTimingContent = () => {
    return (
      <section className="settlements-settings__modal-content">
        <article className="mb-4">
          <p>
            The time it takes for a merchant to get settled is dependent on the Settlement Cycle/Timing configuration for the merchant.
            Usually, this timing is based on the risk rating of the merchant.
          </p>
          <p>
            Settlement timing is usually in the form of T + X, where T is the time the transaction was completed and X is the amount of time
            (in days) before the transaction is ready to be settled.
          </p>
        </article>

        {source === 'default' && (
          <p>Go to the ‘Settlements’ tab under a merchant’s detail page to modify the Settlement Cycle/Timing for that merchant.</p>
        )}
        {source === 'merchant' && (
          <p>
            Go to the ‘Settings’ page to{' '}
            <Link to="/dashboard/settings/settlements">
              <strong>see the Settlement Cycle/Timing for all merchants.</strong>
            </Link>
          </p>
        )}
      </section>
    );
  };

  const modalContent = () => {
    return (
      <>
        {type === 'destination' && settlementDestinationContent()}
        {type === 'timing' && settlementTimingContent()}
      </>
    );
  };

  return <Modal visible={visible} size="md" close={closeModal} showButtons={false} heading={headings[type]} content={modalContent()} />;
}

export default SettlementsConfigInfoModal;
