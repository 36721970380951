import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { chargebackStatusTagConfig, getDateAndTime, history, switchStatusConfig, switchTrxnMessage } from '+utils';
import { CurrencyType, StatusConfigType } from '+types';
import ToolTip from '+shared/Tooltip';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import ClockIcon from '+assets/img/dashboard/clock-icon-5.svg';
import HistoryIcon from '+assets/img/dashboard/history.svg';
import './layoutElements.scss';

export type ActionButtonType = {
  label: string;
  onClick: () => void;
  variant?: 'primary' | 'light-blue' | 'text-button';
  iconAfter?: string | ReactNode;
  iconBefore?: string | ReactNode;
  disabled?: boolean;
};

type TagType = keyof typeof switchTrxnMessage & keyof typeof chargebackStatusTagConfig;

type SectionHeaderProps = {
  actionButtons?: Array<ActionButtonType>;
  loading?: boolean;
  heading?: string;
  currency?: CurrencyType;
  tag?: TagType;
};

export type TxPageHeaderProps = {
  actionButtons?: Array<ActionButtonType>;
  loading?: boolean;
  heading?: string;
  currency?: CurrencyType;
  tag?: TagType;
  tagType?: StatusConfigType;
};

export type SummaryInfoDataType = Array<{
  label: string;
  value: ReactNode;
  linkOnClick?: () => void;
  labelTooltipContent?: string;
  contentAfter?: string;
  contentAfterLinkTo?: string;
}>;

type SummaryInfoProps = {
  data: SummaryInfoDataType;
};

const matchStatusInfo = (configType: StatusConfigType = 'default', status: TagType) => {
  const statusConfig = switchStatusConfig(configType);
  return statusConfig[status];
};

const renderActionButtons = (actionButtons: Array<ActionButtonType>) => {
  return (
    <div className="action-buttons">
      {actionButtons.map(({ label, onClick, variant = 'primary', disabled, iconAfter, iconBefore }) => (
        <button key={label} className={`btn btn-${variant} action-btn`} type="button" onClick={onClick} disabled={disabled}>
          {iconBefore}
          {label}
          {iconAfter}
        </button>
      ))}
    </div>
  );
};

export function TxPageHeader({ heading, currency, tag, tagType, actionButtons = [], loading }: TxPageHeaderProps): JSX.Element {
  const { backgroundColor: tagBgColor, name: tagName, color: tagColor } = matchStatusInfo(tagType, tag);

  return (
    <div className="tx-layout-heading">
      <div className="main-row">
        <div className="text-row">
          <p className="heading">
            {heading}
            <span className="subscript">{currency}</span>
          </p>
          <span className="tag" style={{ backgroundColor: tagBgColor, color: tagColor }}>
            {tagName}
          </span>
        </div>

        <div className="actions-row">{renderActionButtons(actionButtons)}</div>
      </div>
    </div>
  );
}

export function SectionHeader({ heading, actionButtons = [], loading }: SectionHeaderProps) {
  return (
    <div className="tx-section-header">
      <div>
        <p className="heading">{heading}</p>
      </div>

      <div>{renderActionButtons(actionButtons)}</div>
    </div>
  );
}

export function SummaryInfo({ data = [] }: SummaryInfoProps): JSX.Element {
  return (
    <div className="tx-summary-info">
      {data.map(({ label, value, labelTooltipContent, linkTo, contentAfter, contentAfterLinkTo }) => (
        <div className="info-item" key={label}>
          <div className="label">
            <p>{label}</p>
            {labelTooltipContent && (
              <div>
                <ToolTip type="net_amount" image={InfoIcon} message={labelTooltipContent} />
              </div>
            )}
          </div>

          <div className="">
            <div className="value">{linkTo ? <Link to={linkTo}>{value}</Link> : <>{value}</>}</div>

            {contentAfter && !contentAfterLinkTo && <>{contentAfter}</>}
            {contentAfter && contentAfterLinkTo && (
              <Link to={contentAfterLinkTo} className="content-after">
                <>{contentAfter}</>
                <i className="os-icon os-icon-arrow-up-right icon-after" />
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export function Timeline({ data = [] }) {
  const MIN_LIST_LENGTH = 2;
  const [listState, setListState] = useState({ isTruncated: true, length: MIN_LIST_LENGTH });
  const [timelineList, setTimelineList] = useState([]);

  const handleListTruncation = () => {
    setListState((prevState) => ({
      ...prevState,
      isTruncated: !prevState.isTruncated,
      length: prevState.isTruncated ? data.length : MIN_LIST_LENGTH
    }));
  };

  useEffect(() => {
    const slicedList = data?.slice(0, listState.length);
    setTimelineList(slicedList);
  }, [data?.length, listState?.length]);

  return timelineList?.length ? (
    <>
      <div className="timeline-comp">
        {timelineList?.map(({ date, status }) => (
          <div className="timeline-item" key={status}>
            <div className="left-section">
              <img src={ClockIcon} className="time-icon" />
              <span className="time">{getDateAndTime(date)}</span>
            </div>
            <span className="label">{chargebackStatusTagConfig[status]?.name}</span>
          </div>
        ))}
      </div>
      {data?.length > MIN_LIST_LENGTH && (
        <>
          <button type="button" onClick={handleListTruncation} className="expand-btn btn btn-link">
            <img src={HistoryIcon} className="history-icon" />
            <span>{listState.length !== MIN_LIST_LENGTH ? `Show less` : `View history`}</span>
          </button>
        </>
      )}
    </>
  ) : (
    <p className="empty-state-text">Merchant chargeback history is unavailable</p>
  );
}

export function InfoList({ data = [] }) {
  return (
    <div className="tx-info-list">
      {data.map(({ label, value, labelTooltipContent, linkTo, statusPill }) => (
        <div className="info-item" key={label}>
          <div className="label">
            <p>{label}</p>
            {labelTooltipContent && (
              <div>
                <ToolTip type="net_amount" image={InfoIcon} message={labelTooltipContent} />
              </div>
            )}
          </div>

          <div className="value">
            {linkTo ? (
              <Link to={linkTo}>{value}</Link>
            ) : (
              <>
                {statusPill && statusPill}
                {value}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export function BackButton() {
  return (
    <button type="button" className="btn btn-link tx-back-button" onClick={() => history.goBack()}>
      <i className="os-icon os-icon-arrow-left7" />
      <span>Go Back</span>
    </button>
  );
}
