/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {Chart, registerables} from 'chart.js'
import { Bar, Line, } from 'react-chartjs-2';
import { formatAmount, capitalize } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import retry from '+assets/img/dashboard/retry.svg';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import AnalyticsChart from './AnalyticsChart';

const api = new APIRequest();
Chart.register(...registerables);
const AnalyticsGraph = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState({
    tabs: { Collected: 'collection', Disbursed: 'disbursement', Revenue: 'revenue' },
    durationTabs: ['week', 'month', 'year', 'all time'],
    activeTab: 'Collected',
    activeDuration: 'year'
  });

  const buffer = [];
  const anyLoading = {};
  const getTabDetails = tab =>
    buffer.find(data => {
      if (Object.keys(data).length === 1) {
        return {};
      }
      return data[0]?.type === tab;
    });
  ['collection', 'disbursement', 'revenue'].forEach(tab => {
    const { data, isFetching, refetch } = useQuery(`${tab.toUpperCase()}_ANALYTICS`, () => api.getTransactionAnalytics({ type: [tab] }), {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the ${tab} analytics.`,
          type: 'danger'
        });
      }
    });
    anyLoading[tab] = isFetching;
    buffer.push({ ...data, refetch });
  });
  const initialData = getTabDetails(state.tabs[state.activeTab])[0]
    ? getTabDetails(state.tabs[state.activeTab])[0][`${state.activeDuration}Analytics`] || {}
    : {};
  const tickOptions = {
    fontColor: '#4a4a4a80',
    fontFamily: 'Averta PE',
    fontSize: 11
  };
  const toolTipOptions = {
    backgroundColor: '#07002C',
    titleFontColor: 'white',
    bodyFontColor: 'white',
    borderColor: '#07002C',
    titleFontFamily: 'Averta PE',
    bodyFontFamily: 'Averta PE',
    borderWidth: 0,
    caretSize: 2,
    displayColors: false,
    callbacks: {
      label: tooltipItem => formatAmount(tooltipItem.yLabel)
    }
  };

  const BarChart = () => {
    const options = {
      legend: { display: false },
      tooltips: toolTipOptions,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            color: 'rgba(0,0,0,0.05)',
            zeroLineColor: 'rgba(0,0,0,0.09)'
          },
          ticks: {
            display: false
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: tickOptions
        }
      }
    };
    const data = {
      labels: Object.keys(initialData).map(key => key.substring(0, 3)),
      datasets: [
        {
          data: Object.keys(initialData).map(key => initialData[key]),
          backgroundColor: '#047bf8',
          borderColor: '#047bf8',
          maxBarThickness: 20,
          minBarLength: 0
        }
      ]
    };

    return state.activeDuration !== 'all time' ? (
      <Bar data={data} options={options} />
    ) : (
      <div className="no-analytics">
        <p>Charts are currently not available for this time period</p>
      </div>
    );
  };

  const LineChart = () => {
    const options = {
      legend: { display: false },
      plugins: {
        legend: {
          display: false
        }
      },
      tooltips: toolTipOptions,
      scales: {
        y: {
          display: false
        },
        x: {
          ticks: tickOptions,
          grid: {
            color: 'rgba(0,0,0,0.07)',
            zeroLineColor: 'rgba(0,0,0,0.0)'
          }
        }
      }
    };
    const data = canvas => {
      return {
        labels: Object.keys(initialData).map(key => key.substring(0, 3)),
        datasets: [
          {
            data: Object.keys(initialData).map(key => initialData[key]),
            fill: 'start',
            lineTension: 0.45,
            pointBorderColor: 'white',
            pointRadius: 4,
            borderWidth: 2,
            pointBackgroundColor: 'black',
            backgroundColor: context => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 300);
              gradient.addColorStop(0, 'rgba(35, 118, 243, 0.4)');
              gradient.addColorStop(1, 'rgba(35, 118, 243, 0)');
              return gradient;
            },
            borderColor: '#047bf8'
          }
        ]
      };
    };

    return state.activeDuration !== 'all time' ? (
      <Line data={data()} options={options} />
    ) : (
      <div className="no-analytics">
        <p>Charts are currently not available for this time period</p>
      </div>
    );
  };

  const totalCalculator = (activeDuration, activeTabData) => {
    if (!activeTabData[0]) return '--.--';
    if (activeDuration === 'all time') return formatAmount(activeTabData[0].total);
    const durationData = Object.values(activeTabData[0][`${state.activeDuration}Analytics`]);
    if (durationData.length === 0) return '--.--';
    return formatAmount(durationData.reduce((acc, val) => acc + val));
  };

  return (
    <section className="row analytics-graph__comp">
      <div className="col-sm-8 container">
        <div className="element-box">
          <div className="os-tabs-w mb-3">
            <div className="os-tabs-controls">
              <ul className="nav nav-tabs">
                {Object.keys(state.tabs).map(tab => {
                  return (
                    <li className="nav-item" key={tab}>
                      <span 
                        className={`nav-link ${state.activeTab === tab ? 'active' : ''} pb-2 pl-4`}
                        data-toggle="tab"
                        onClick={() => setState({ ...state, activeTab: tab })}
                        onKeyUp={() => setState({ ...state, activeTab: tab })}
                        role="button"
                        tabIndex={0}
                      >
                        {tab}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <ul className="nav nav-pills smaller d-none d-md-flex">
                {state.durationTabs.map(tab => {
                  return (
                    <li className="nav-item" key={tab}>
                      <button
                        data-toggle="tab"
                        type="button"
                        onClick={() => {
                          setState({ ...state, activeDuration: tab });
                        }}
                        className={`nav-link  ${state.activeDuration === tab && 'active'}`}
                      >
                        {capitalize(tab)}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="tab_collected">
                <section className="el-tablo --disbursment">
                  <div>
                    <div className="value">{totalCalculator(state.activeDuration, getTabDetails(state.tabs[state.activeTab]))}</div>
                    <div className="label">Total {state.activeTab} (NGN)</div>
                  </div>
                  <button aria-label="Retry Button" type="button" onClick={() => getTabDetails(state.tabs[state.activeTab]).refetch()}>
                    <img src={retry} alt="" />
                  </button>
                </section>
                <div className="el-chart-w" style={{ margin: '30px 0 10px' }}>
                  {anyLoading[state.tabs[state.activeTab]] ? (
                    <LoadingPlaceholder type="table" content={1} background="#f5f6f6" />
                  ) : state.activeTab === 'Revenue' ? (
                    LineChart()
                  ) : (
                    BarChart()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnalyticsChart />
    </section>
  );
};

export default AnalyticsGraph;
