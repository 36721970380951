/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { capitalize, formatAmount, history, logError, defaultScrollToTop } from '+utils';
import { AxiosError } from 'axios';
import useChargebackStore, { chargebackContentT } from '+store/chargebackStore';
import Table from '+shared/Table';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { chargebackErrorT } from '+types';
import warning from '+assets/img/dashboard/warning-triangle.svg';
import warningTriangle from '+assets/img/dashboard/warning-triangle-outline.svg';
import warningTriangleRed from '+assets/img/dashboard/warning-triangle-red.svg';
import ChargebackUploadModal from '../components/ChargebackUploadModal';
import './index.scss';

const apiRequest = new APIRequest();

type openChargebackStateT = {
  open: boolean;
  mode: 'add' | 'edit' | 'add-to-csv' | 'delete' | 'error' | 'success';
  action?: (value?: chargebackContentT) => void;
  data?: Partial<chargebackContentT>;
  chargebackErrors?: chargebackErrorT[];
  chargebackSuccessful?: string[];
};
const ChargebackSummary = () => {
  const queryClient = useQueryClient();
  const [openAddChargeback, setOpenAddChargeback] = useState<openChargebackStateT>({
    open: false,
    mode: 'add',
    action: () => undefined,
    data: {}
  });
  const [chargebackErrors, setChargebackErrors] = useState<chargebackErrorT[]>([]);
  const [chargebackSuccessful, setChargebackSuccessful] = useState<string[]>([]);
  const [errorReferences, setErrorReferences] = useState<string[]>([]);

  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const chargebackData = useChargebackStore((state) => state.chargebackData);
  const paginatedData = useChargebackStore((state) => state.paginatedData);
  const setPaginatedData = useChargebackStore((state) => state.setPaginatedData);
  const clearChargebackData = useChargebackStore((state) => state.clearChargebackData);
  const deleteChargeback = useChargebackStore((state) => state.deleteChargeback);
  const addChargeback = useChargebackStore((state) => state.addChargeback);
  const editChargeback = useChargebackStore((state) => state.editChargeback);
  const setChargebackData = useChargebackStore((state) => state.setChargebackData);

  const handleCloseChargeback = () => {
    setOpenAddChargeback({ open: false, mode: 'add', action: () => undefined, data: {} });
  };
  const handleDelete = (data: string) => setOpenAddChargeback({ open: true, mode: 'delete', action: () => deleteChargeback(data) });
  const handleAddChargeback = () => setOpenAddChargeback({ open: true, mode: 'add-to-csv', action: (data) => addChargeback(data!) });
  const handleEditChargeback = (data: chargebackContentT) =>
    setOpenAddChargeback({ open: true, mode: 'edit', action: (value) => editChargeback(value!), data });
  const handleShowChargebackErrors = () =>
    setOpenAddChargeback({ open: true, mode: 'error', action: () => undefined, data: undefined, chargebackErrors });
  const handleShowChargebackSuccess = () =>
    setOpenAddChargeback({ open: true, mode: 'success', action: () => undefined, data: undefined, chargebackSuccessful });

  useEffect(() => {
    if (chargebackData.chargebacks.length > 50) {
       defaultScrollToTop();
      feedbackInit({
        message: 'Chargeback file must contain maximum of 50 entries only',
        type: 'danger',
        isClosable: true
      });
    } else {
      closeFeedback();
    }
  }, [chargebackData.chargebacks.length]);

  const { mutate, isLoading } = useMutation(apiRequest.uploadChargebacks, {
    onSuccess: (data) => {
      if (data.errorMessages.length) {
        let container: string[] = [];
        data.errorMessages.map((item: chargebackErrorT) => {
          container = [...container, ...item.references];
          return item;
        });

        const successfulReference = chargebackData.chargebacks
          .filter((item) => !container.includes(item.processor_reference?.trim() as string))
          .map((item) => item.processor_reference?.trim() || '');
        const errorReference = chargebackData.chargebacks.filter((item) => container.includes(item.processor_reference?.trim() as string));
        setChargebackErrors(data.errorMessages);
        setChargebackSuccessful(successfulReference);
        setErrorReferences(container);
        setChargebackData({ ...chargebackData, chargebacks: errorReference });
      } else {
        queryClient.invalidateQueries(`CHARGEBACK`);
        history.push('/dashboard/disputes?tab=chargebacks');
      }
    },
    onError: (error: AxiosError) => {
      logError(error);
      feedbackInit({
        message: `${capitalize(
          error.response?.data?.data?.chargebacks?.message || error.response?.data?.message || 'There has been error adding chargebacks'
        )}`,
        type: 'danger'
      });
    }
  });

  const checkForNull = (value: chargebackContentT) =>
    Object.values(value).filter((item) => [null, undefined, NaN, ''].includes(item as number)).length > 0;
  const checkGlobalError = (value: chargebackContentT[]) => value.filter((item) => checkForNull(item)).length > 0;
  const checkReferenceInError = (value: chargebackContentT) => {
    if (!chargebackErrors.length) return false;
    return errorReferences.includes(value.processor_reference?.trim() as string);
  };

  const handleSubmitChargebacks = () => {
    const chargebackswithoutUUID = chargebackData.chargebacks.map((chargeback: chargebackContentT) => ({
      amount: chargeback.amount,
      processor_reference: chargeback.processor_reference,
      reason: chargeback.reason
    }));
    mutate({ ...chargebackData, chargebacks: chargebackswithoutUUID });
  };

  const selectError = (each: chargebackContentT) => {
    switch (true) {
      case checkForNull(each):
        return {
          bgColor: '#FFF8E1',
          errorImage: warning
        };
      case checkReferenceInError(each):
        return {
          bgColor: '#FFD2DA',
          errorImage: warningTriangleRed
        };
      default:
        return {
          bgColor: '',
          errorImage: ''
        };
    }
  };
  const renderMerchants = () => {
    return (
      <>
        {paginatedData.data.map((each) => {
          return (
            <div
              key={each.id}
              className="div-table --chargeback-summary-table --row"
              style={{ backgroundColor: selectError(each).bgColor }}
            >
              <div>
                <span className="body-row-header" />
                {selectError(each).errorImage ? (
                  <img src={selectError(each).errorImage} alt="warning" style={{ width: '15px', height: '15px' }} />
                ) : (
                  <i className="os-icon os-icon-check" style={{ color: '#29B473' }} />
                )}
              </div>
              <div className="user-with-avatar edit-merchant" style={{ color: '#2376F3' }}>
                <span className="body-row-header">Provider reference:</span>
                <span style={{ fontWeight: 600 }}>{each.processor_reference?.toUpperCase()}</span>
              </div>
              <div className="user-with-avatar edit-merchant">
                <span className="body-row-header">Chargeback reason:</span>
                <span id="merchant-name" style={{ color: '#354253' }}>
                  {each.reason}
                </span>
              </div>
              <div>
                <span className="body-row-header">Chargeback Amount:</span>
                <span className="lighter" style={{ fontWeight: 500 }}>
                  {formatAmount(Number(each.amount))}
                </span>
              </div>
              <div>
                <span className="body-row-header" />
                <span
                  role="button"
                  tabIndex={0}
                  className="lighter "
                  onClick={() => handleEditChargeback(each)}
                  onKeyDown={() => handleEditChargeback(each)}
                >
                  <i className="os-icon os-icon-edit-32" />
                </span>
                <span
                  className="lighter"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleDelete(each?.id as string)}
                  onKeyDown={() => handleDelete(each?.id as string)}
                >
                  <i className="os-icon os-icon-trash-2" />
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="element-box">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                history.goBack();
                clearChargebackData();
              }}
            >
              <i className="os-icon os-icon-arrow-left7" />
              <span style={{ fontWeight: 500 }}>Go Back</span>
            </button>
          </div>
          <div className="upload-summary-wrapper">
            <div className="upload-summary-head">
              <span>
                <p>Chargeback upload Summary</p>
                <p>Modify or delete chargeback entries, or export as csv. </p>
              </span>
              <span>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmitChargebacks}
                  disabled={
                    isLoading ||
                    !chargebackData.chargebacks.length ||
                    checkGlobalError(chargebackData.chargebacks) ||
                    chargebackData.chargebacks.length > 50
                  }
                >
                  {isLoading ? (
                    <span>Processing...</span>
                  ) : (
                    <>
                      <span className="os-icon os-icon-upload" />
                      <span>Submit Entries</span>
                    </>
                  )}
                </button>
              </span>
            </div>
            {chargebackErrors.length > 0 && (
              <div className="chargeback-error_wrapper">
                <p>
                  You are unable to proceed because you need to make some modifications on the csv file, please make the edits and re-upload
                  the file to continue
                </p>
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleShowChargebackErrors()}
                  onClick={() => handleShowChargebackErrors()}
                >
                  <i className="os-icon os-icon-arrow-up-right" />
                  <p>View errors</p>
                </span>
              </div>
            )}
            {checkGlobalError(chargebackData.chargebacks) && (
              <div className="chargeback-warning_wrapper">
                <span>
                  <img src={warningTriangle} alt="warning" style={{ width: '15px', height: '15px' }} />
                  <p>Some errors were detected in your uploaded file and have been highlighted. Update them to upload your entries</p>
                </span>
              </div>
            )}
            {chargebackSuccessful.length > 0 && (
              <div className="chargeback-success_wrapper">
                <p>These chargebacks were created successfully.</p>
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleShowChargebackSuccess()}
                  onClick={() => handleShowChargebackSuccess()}
                >
                  <i className="os-icon os-icon-arrow-up-right" />
                  <p>View</p>
                </span>
              </div>
            )}
            <div className="upload-summary-mid">
              <p>{`${paginatedData.paging.total_items} Chargeback(s)`}</p>
              <span role="button" tabIndex={0} onClick={() => handleAddChargeback()} onKeyDown={() => handleAddChargeback()}>
                <i className="os-icon os-icon-plus" />
                <span>Add chargeback</span>
              </span>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="element-wrapper pt-0">
                  <div className="element-box-tp">
                    <Table
                      className="--chargeback-summary-table"
                      tableHeadings={['', 'Provider reference', 'Chargeback reason', 'Chargeback Amount', '']}
                      hasPagination
                      loading={false}
                      current={paginatedData.paging.current}
                      totalItems={paginatedData.paging.total_items}
                      pageSize={paginatedData.paging.page_size}
                      actionFn={(page) => setPaginatedData({ page_size: paginatedData.paging.page_size, current: page })}
                      limitAction={(limit) => setPaginatedData({ page_size: limit, current: paginatedData.paging.current })}
                      emptyStateHeading="No Chargebacks"
                      emptyStateMessage="There are no chargebacks records"
                      tableWrapperClassName="table-responsive table-wrapper"
                      hasFilter={false}
                    >
                      {renderMerchants()}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openAddChargeback.open && (
        <ChargebackUploadModal
          close={handleCloseChargeback}
          action={openAddChargeback.action}
          mode={openAddChargeback.mode}
          data={openAddChargeback.data}
          errors={openAddChargeback.chargebackErrors}
          successContent={openAddChargeback.chargebackSuccessful}
        />
      )}
    </>
  );
};

export default ChargebackSummary;