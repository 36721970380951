import {
  ActionType,
  FeeCategoryType,
  IFeeProps,
  IPlan,
  PlansFormikBagType,
  PlansInitialValuesType,
  StepType,
  TPVCollectionType
} from '+types';

export const actionHeadings = {
  edit: 'Edit plan',
  add: 'Add new plan'
};

export const actionDescriptions = {
  edit: 'Provide the details below to make change to a new subscription plan.',
  add: 'Provide the details below to create a new subscription plan.'
};

export const confirmationHeadings = {
  edit: 'Confirm change(s) to plan',
  add: 'Confirm new plan'
};

export const confirmationDescriptions = {
  edit: 'Once you confirm, subscription plan will be updated and implemented for issuing merchants assigned under it.',
  add: 'Once you confirm, a new subscription plan will be created and issuing merchants can be assigned under it.'
};

export const feeTypes = [
  { label: '-- select fee type --', value: '' },
  { label: 'Flat fee', value: 'flat' },
  { label: 'Percentage fee', value: 'percentage' }
];

export const feeCategories: Array<{ fee: FeeCategoryType; prefix: string; subtitle: string }> = [
  { fee: 'subscription', prefix: 'Subscription', subtitle: 'This is the fee charged to the merchant to access this service.  ' },
  { fee: 'issuance', prefix: 'Card Issuing', subtitle: 'This is the fee charged to the merchant to create a new card.  ' },
  { fee: 'funding', prefix: 'Card Funding', subtitle: 'This is the fee charged to the merchant to fund a card.  ' },
  { fee: 'withdrawal', prefix: 'Withdrawal', subtitle: 'This is the fee charged to the merchant to withdraw from a card. ' },
  {
    fee: 'cross_currency',
    prefix: 'Cross-currency',
    subtitle: 'This is the fee charged to the merchant each time a card is used to transact in a foreign currency  '
  },
  {
    fee: 'chargeback',
    prefix: 'Chargeback',
    subtitle: 'This is the fee charged to the merchant when there’s a dispute to a charge on an issued card.'
  }
];

export const formSectionIsComplete = (sectionErrors: IFeeProps) => {
  if (sectionErrors?.max) return false;
  if (sectionErrors?.type) return false;
  if (sectionErrors?.amount) return false;
  if (sectionErrors?.vat_inclusive) return false;
  if (sectionErrors?.charge_interval) return false;
  return true;
};

// Initialize form values
export const initializeValues = (data: IPlan, action: ActionType) => {
  const {
    fee,
    max_payment_value,
    min_payment_value,
    reserved_card_max_payment_value,
    reserved_card_min_payment_value,
    monthly_card_limit,
    reserved_card_limit,
    ...otherProps
  } = (data || {}) as IPlan;

  switch (action) {
    case 'edit':
      return {
        reserved: {
          max_tpv: reserved_card_max_payment_value,
          min_tpv: reserved_card_min_payment_value,
          card_limit: reserved_card_limit,
          fee: { ...fee?.reserved, security_reserve: { type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' } }
        },
        customer: {
          max_tpv: max_payment_value,
          min_tpv: min_payment_value,
          card_limit: monthly_card_limit,
          fee: {
            ...fee?.customer
          }
        },
        ...otherProps
      };
    case 'add':
    default:
      return {
        card_type: 'virtual',
        currency: 'USD',
        name: '',
        reference: '',
        reserved: {
          fee: {
            funding: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            issuance: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            chargeback: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            withdrawal: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            subscription: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            cross_currency: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' }
          },
          max_tpv: '0',
          min_tpv: '0',
          card_limit: '0'
        },
        customer: {
          fee: {
            funding: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            issuance: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            chargeback: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            withdrawal: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            subscription: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' },
            cross_currency: { active: false, type: 'flat', amount: '0', vat_inclusive: false, charge_interval: 'once' }
          },
          max_tpv: '0',
          min_tpv: '0',
          card_limit: '0'
        }
      } as PlansInitialValuesType;
  }
};

// eslint-disable-next-line consistent-return
export const validate = ({
  values,
  TPVCollection,
  step,
  planName
}: {
  values: PlansInitialValuesType;
  TPVCollection: TPVCollectionType;
  step: StepType;
  planName: string;
}) => {
  const errors: PlansFormikBagType['errors'] = {};
  const { customer, reserved } = TPVCollection;

  if (step === 'setIssuedCardFees') {
    if (!values.name) errors.name = 'Name is required';
    if (values.customer.card_limit === '') errors.customer = { card_limit: 'Monthly card limit is required' };

    // TPV validation
    if (!values.customer.max_tpv || values.customer.max_tpv === '0') errors.customer = { max_tpv: 'Max TPV is required' };
    if (!values.customer.min_tpv || values.customer.min_tpv === '0') errors.customer = { min_tpv: 'Min TPV is required' };

    if (Number(values.customer.max_tpv) < Number(values.customer.min_tpv))
      errors.customer = { max_tpv: 'Max TPV cannot be less than min TPV' };

    // check for plan tpv overlap
    for (let i = 0; i < customer.length; i++) {
      if (planName !== customer[i].plan) {
        if (
          (Number(values.customer.min_tpv) >= Number(customer[i].tpv[0]) &&
            Number(values.customer.max_tpv) <= Number(customer[i].tpv[1])) ||
          (Number(values.customer.max_tpv) >= Number(customer[i].tpv[0]) &&
            Number(values.customer.min_tpv) <= Number(customer[i].tpv[0])) ||
          (Number(values.customer.max_tpv) >= Number(customer[i].tpv[1]) && Number(values.customer.min_tpv) <= Number(customer[i].tpv[1]))
        ) {
          errors.customer = { max_tpv: `TPV conflicts with '${customer[i].plan}' plan` };
          break;
        }
      }
    }

    if (values.customer.fee.subscription.amount === '') errors.customer = { fee: { subscription: { amount: 'Fee is required' } } };
    if (!values.customer.fee.subscription.type) errors.customer = { fee: { subscription: { type: 'Fee type is required' } } };
    if (values.customer.fee.subscription.max === '') errors.customer = { fee: { subscription: { max: 'Max capped fee is required' } } };

    if (values.customer.fee.withdrawal.amount === '') errors.customer = { fee: { withdrawal: { amount: 'Fee is required' } } };
    if (!values.customer.fee.withdrawal.type) errors.customer = { fee: { withdrawal: { type: 'Fee type is required' } } };
    if (values.customer.fee.withdrawal.max === '') errors.customer = { fee: { withdrawal: { max: 'Max capped fee is required' } } };

    if (values.customer.fee.funding.amount === '') errors.customer = { fee: { funding: { amount: 'Fee is required' } } };
    if (!values.customer.fee.funding.type) errors.customer = { fee: { funding: { type: 'Fee type is required' } } };
    if (values.customer.fee.funding.max === '') errors.customer = { fee: { funding: { max: 'Max capped fee is required' } } };

    if (values.customer.fee.issuance.amount === '') errors.customer = { fee: { issuance: { amount: 'Fee is required' } } };
    if (!values.customer.fee.issuance.type) errors.customer = { fee: { issuance: { type: 'Fee type is required' } } };
    if (values.customer.fee.issuance.max === '') errors.customer = { fee: { issuance: { max: 'Max capped fee is required' } } };

    if (values.customer.fee.cross_currency.amount === '') errors.customer = { fee: { cross_currency: { amount: 'Fee is required' } } };
    if (!values.customer.fee.cross_currency.type) errors.customer = { fee: { cross_currency: { type: 'Fee type is required' } } };
    if (values.customer.fee.cross_currency.max === '') errors.customer = { fee: { cross_currency: { max: 'Max capped fee is required' } } };

    if (values.customer.fee.chargeback.amount === '') errors.customer = { fee: { chargeback: { amount: 'Fee is required' } } };
    if (!values.customer.fee.chargeback.type) errors.customer = { fee: { chargeback: { type: 'fee type is required' } } };
    if (values.customer.fee.chargeback.max === '') errors.customer = { fee: { chargeback: { max: 'Max capped fee is required' } } };

    return errors;
  }

  if (step === 'setReservedCardFees') {
    // Reserved card fee validation
    if (values.reserved.card_limit === '') errors.reserved = { card_limit: 'Monthly card limit is required' };
    if (!values.reserved.max_tpv || values.reserved.max_tpv === '0') errors.reserved = { max_tpv: 'Max TPV is required' };
    if (!values.reserved.min_tpv || values.reserved.min_tpv === '0') errors.reserved = { min_tpv: 'Min TPV is required' };

    // check for plan tpv overlap
    for (let i = 0; i < reserved.length; i++) {
      if (planName !== reserved[i].plan) {
        if (
          (Number(values.reserved.min_tpv) >= Number(reserved[i].tpv[0]) &&
            Number(values.reserved.max_tpv) <= Number(reserved[i].tpv[1])) ||
          (Number(values.reserved.max_tpv) >= Number(reserved[i].tpv[0]) &&
            Number(values.reserved.min_tpv) <= Number(reserved[i].tpv[0])) ||
          (Number(values.reserved.max_tpv) >= Number(reserved[i].tpv[1]) && Number(values.reserved.min_tpv) <= Number(reserved[i].tpv[1]))
        ) {
          errors.reserved = { max_tpv: `TPV conflicts with '${reserved[i].plan}' plan` };
          break;
        }
      }
    }

    if (Number(values.reserved.max_tpv) < Number(values.reserved.min_tpv))
      errors.reserved = { max_tpv: 'Max TPV cannot be less than min TPV' };
    if (values.reserved.fee.subscription.amount === '') errors.reserved = { fee: { subscription: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.subscription.type) errors.reserved = { fee: { subscription: { type: 'Fee type is required' } } };
    if (values.reserved.fee.subscription.max === '') errors.reserved = { fee: { subscription: { max: 'Max capped fee is required' } } };

    if (values.reserved.fee.withdrawal.amount === '') errors.reserved = { fee: { withdrawal: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.withdrawal.type) errors.reserved = { fee: { withdrawal: { type: 'Fee type is required' } } };
    if (values.reserved.fee.withdrawal.max === '') errors.reserved = { fee: { withdrawal: { max: 'Max capped fee is required' } } };

    if (values.reserved.fee.funding.amount === '') errors.reserved = { fee: { funding: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.funding.type) errors.reserved = { fee: { funding: { type: 'Fee type is required' } } };
    if (values.reserved.fee.funding.max === '') errors.reserved = { fee: { funding: { max: 'Max capped fee is required' } } };

    if (values.reserved.fee.issuance.amount === '') errors.reserved = { fee: { issuance: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.issuance.type) errors.reserved = { fee: { issuance: { type: 'Fee type is required' } } };
    if (values.reserved.fee.issuance.max === '') errors.reserved = { fee: { issuance: { max: 'Max capped fee is required' } } };

    if (values.reserved.fee.cross_currency.amount === '') errors.reserved = { fee: { cross_currency: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.cross_currency.type) errors.reserved = { fee: { cross_currency: { type: 'Fee type is required' } } };
    if (values.reserved.fee.cross_currency.max === '') errors.reserved = { fee: { cross_currency: { max: 'Max capped fee is required' } } };

    if (values.reserved.fee.chargeback.amount === '') errors.reserved = { fee: { chargeback: { amount: 'Fee is required' } } };
    if (!values.reserved.fee.chargeback.type) errors.reserved = { fee: { chargeback: { type: 'fee type is required' } } };
    if (values.reserved.fee.chargeback.max === '') errors.reserved = { fee: { chargeback: { max: 'Max capped fee is required' } } };

    return errors;
  }

  // Plan name validation and issuable cards validation
  if (!values.name) errors.name = 'Name is required';
  return errors;
};

export const getTPVCollection = (plans: IPlan[]) =>
  plans.reduce<TPVCollectionType>(
    (acc, next) => ({
      customer: [...acc.customer, { plan: next.name, tpv: [next.min_payment_value, next.max_payment_value] }],
      reserved: [...acc.reserved, { plan: next.name, tpv: [next.reserved_card_min_payment_value, next.reserved_card_max_payment_value] }]
    }),
    { customer: [], reserved: [] }
  );
