import { useMemo } from 'react';
import { Storage } from '+services/storage-services';

const useSetUserAccess = () => {
  const userToken = Storage.checkAuthentication();

  const userAccess = useMemo(() => {
    if (userToken) {
      return Storage.getItem('access');
    }
    return {};
  }, [userToken]);

  return userAccess;
};

export default useSetUserAccess;
