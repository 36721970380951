import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import RiskLevel from '+shared/RiskLevel';
import { getDate, getTime, history, isAllowed, logError, truncateString } from '+utils';

import ActionModal from './components/ActionModal';
import BusinessProfile from './components/BusinessProfile';
import ComplianceFeedbacks from './components/ComplianceFeedbacks';
import MerchantDocuments from './components/MerchantDocuments';
import MerchantModals from './components/MerchantModals';
import MerchantRepresentatives from './components/MerchantRepresentatives';
import MerchantSettlementAccount from './components/MerchantSettlementAccount';

import CheckedIcon from '+assets/img/dashboard/check-green.svg';
import InfoIcon from '+assets/img/dashboard/info-i.svg';
import PlusIcon from '+assets/img/dashboard/plus-dark.svg';
import SendIcon from '+assets/img/dashboard/send.svg';
import Warning from '+assets/img/dashboard/warning-orange.svg';
import BusinessAvatar from '+assets/theme/img/biz-avatar-two.svg';

import './components/index.scss';

const api = new APIRequest();

function MerchantKycComponent() {
  const location = useLocation();
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();

  const userAccess = useSetUserAccess();

  const [state, setState] = useState({
    activeTab: 'business_profile',
    tabs: [
      ['Business Profile', 'business_profile'],
      ['Documents', 'documents'],
      ['Settlement Accounts', 'settlement_accounts']
    ]
  });

  const switchRiskLevel = {
    low_risk: 'Low-Risk',
    medium_risk: 'Medium-Risk',
    above_average_risk: 'Semi-High-Risk',
    high_risk: 'High-Risk'
  };

  const { data: merchantDetails, isLoading } = useQuery(`MERCHANT_DETAILS_${id}`, () => api.getMerchantKyc(id), {
    onSuccess: data => {
      if (data.info.business_type !== 'individual') {
        setState(prev => ({
          ...prev,
          tabs: [
            ['Business Profile', 'business_profile'],
            ['Documents', 'documents'],
            ['Representatives', 'representatives'],
            ['Settlement Accounts', 'settlement_accounts']
          ]
        }));
      }
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching merchant's kyc details`,
        type: 'danger'
      });
    }
  });
  const { info: merchantInfo = {}, merchant: merchantProfile = {} } = merchantDetails ?? {};

  const verifyMerchant = useMutation(variable => api.verifyMerchant(id, variable), {
    onSuccess: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
      queryClient.invalidateQueries('MERCHANT_STATISTICS');
      const message = modal.modalType === 'confirm-reject-merchant' ? 'Merchant Rejected' : 'Merchant Verified';
      feedbackInit({
        message,
        type: 'success'
      });
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error verifying merchant. Please try again.`,
        type: 'danger'
      });
    }
  });

  const updateRiskStatus = useMutation(data => api.updateMerchantRisk(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
      feedbackInit({
        message: 'Merchant risk level updated',
        type: 'success'
      });
    },
    onError: () => {
      feedbackInit({
        message: "There has been an error updating this merchant's risk level. Please try again.",
        type: 'danger'
      });
      setModal({ modalType: null });
    }
  });

  const { data: sentinalStatus } = useQuery([`MERCHANT_SENTINEL_STATUS_${id}`], () => api.manageSentinal(id), {
    onError: err => {
      if (err?.response?.status === 404) return;
      logError(err);

      feedbackInit({
        message: "There has been an error getting  this merchant's sentinal status. Please try again.",
        type: 'danger'
      });

      setModal({ modalType: null });
    }
  });

  const { mutateAsync: manageSentinal } = useMutation(data => api.manageSentinal(id, data), {
    onSuccess: data => {
      queryClient.setQueryData(`MERCHANT_SENTINEL_STATUS_${id}`, data);
      feedbackInit({
        message: `Merchant sentinal has been ${sentinalStatus?.enabled ? 'disabled' : 'enabled'}`,
        type: 'success'
      });
    },
    onError: err => {
      logError(err);
      feedbackInit({
        message:
          err.response?.data?.message ||
          `There has been an error ${sentinalStatus?.enabled ? 'disabling' : 'enabling'} merchant for sentinal. Please try again.`,
        type: 'danger'
      });

      setModal({ modalType: null });
    }
  });

  const updateMerchantBvn = useMutation(variable => api.rejectBvn(id, variable), {
    onSuccess: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
      queryClient.invalidateQueries('MERCHANT_STATISTICS');
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error updating merchant bvn status. Please try again.`,
        type: 'danger'
      });
    }
  });

  const [modal, setModal] = useState({
    modalType: null,
    modalInfo: null
  });

  const [allFeedbacks, setAllFeedbacks] = useState(
    sessionStorage.getItem(`KYC_FEEDBACK_${id}`) ? JSON.parse(sessionStorage.getItem(`KYC_FEEDBACK_${id}`)) : []
  );

  const activeComplianceTab = location?.state?.tab;
  const activeKycTab = state.activeTab;
  const childTabs = { activeComplianceTab, activeKycTab };

  const hasAddedFeedback = type => {
    const unsentAdded = Boolean(allFeedbacks.find(feedback => feedback.kycType === activeKycTab));
    if (type === 'text') return unsentAdded;
    if (!merchantInfo?.compliance?.feedback) return unsentAdded;
    return unsentAdded || merchantInfo?.compliance?.feedback[activeKycTab]?.length > 0;
  };

  const setFeedbacks = (feedbacks, rewrite = false) => {
    const computedFeedback = rewrite ? feedbacks : [...allFeedbacks, ...feedbacks];
    sessionStorage.setItem(`KYC_FEEDBACK_${id}`, JSON.stringify(computedFeedback));
    return setAllFeedbacks(() => computedFeedback);
  };

  const switchTabs = () => {
    switch (state.activeTab) {
      case 'documents':
        return (
          <MerchantDocuments
            documents={merchantInfo?.documents}
            businessType={merchantInfo?.business_type}
            profile={merchantProfile}
            validation={merchantInfo?.validation_result}
          />
        );
      case 'representatives':
        return <MerchantRepresentatives representatives={merchantInfo?.representatives} profile={merchantProfile} />;
      case 'settlement_accounts':
        return (
          <MerchantSettlementAccount
            settlementAccounts={merchantInfo?.settlement_accounts}
            setModal={setModal}
            kycStatus={merchantProfile?.kyc_status}
            validation={merchantInfo?.validation_result}
            bvn={merchantInfo?.bvn}
          />
        );
      default:
        return <BusinessProfile details={merchantDetails} profile={merchantProfile} validation={merchantInfo?.validation_result} />;
    }
  };

  const switchComplianceTabsHeading = {
    business_profile:
      'This is where you’ll find the profile information of the merchant’s business. A good business profile is always complete and correct, so ensure to always verify all the details submitted.',
    documents:
      'Based on the merchant’s business type and category, these are the documents required for verification. Ensure that all documents are valid before verifying the merchant.',
    representatives:
      'Here are key stakeholders and representatives of this merchant’s business. Kindly ensure that thorough checks are carried out for each representative.',
    settlement_accounts:
      'Ensure that the settlement account provided is valid and belongs to the business. In the case where no account is provided, funds will be settled to the merchant’s Korapay Balance.'
  };
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2 px-0" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Merchants</span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="element-box main-container">
              <div className="kyc-heading">
                <div className="kyc-heading__left">
                  <div>
                    <div className="user-avatar">
                      <img alt="business avatar" src={BusinessAvatar} />
                    </div>
                  </div>
                  <div className="kyc-heading__title">
                    {merchantProfile?.name && (
                      <h3 id="merchant-name">
                        {merchantProfile?.name}{' '}
                        {sentinalStatus && !sentinalStatus?.enabled && <span className="sen-disabled">Sen Disabled</span>}
                      </h3>
                    )}
                    <div className="merchant-id" data-testid="merchant-id">
                      {merchantProfile?.id && `ID: ${merchantProfile?.id}`}{' '}
                      {sentinalStatus && (
                        <span className="sen-id">
                          | Sen ID: <Copyable text={sentinalStatus.identifier} textModifier={text => truncateString(text, 5)} />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="kyc-heading__right">
                  <div className="kyc-heading__risk">
                    <RiskLevel riskLevel={merchantProfile?.risk_level || ''} hideTitle hideDivider />
                    <span>{switchRiskLevel[merchantProfile?.risk_level]}</span>
                  </div>
                  <span className="divider-sm" />
                  <ActionModal details={{ ...merchantProfile, sentinalEnabled: sentinalStatus?.enabled }} setModal={setModal} />
                </div>
              </div>

              <section className="status-banner-wrapper">
                {merchantProfile.status === 'suspended' && (
                  <section className="status-banner __suspended">
                    <img alt="warning sign" src={Warning} /> This business account has been suspended!
                  </section>
                )}
                {merchantProfile.status === 'deactivated' && (
                  <section className="status-banner __deleted">
                    <img alt="warning sign" src={Warning} /> This business account has been deactivated!
                  </section>
                )}
                {merchantProfile?.country?.is_active === false && (
                  <section className="status-banner __deleted">
                    <img alt="warning sign" src={Warning} /> This business signed up from an unsupported country!
                  </section>
                )}

                {merchantProfile.kyc_status === 'rejected' && (
                  <section className="status-banner __deleted">
                    <img alt="warning sign" src={Warning} /> This merchants documentation has been rejected
                  </section>
                )}
              </section>

              <div className="os-tabs-w" style={{ marginBottom: '30px' }}>
                <div className="os-tabs-controls os-tabs-complex mx-0">
                  <ul className="nav nav-tabs px-0 mb-2">
                    {state.tabs.map(tab => {
                      return (
                        <li className="nav-item" key={tab}>
                          <button
                            type="button"
                            onClick={() => setState({ ...state, activeTab: tab[1] })}
                            disabled={merchantProfile.status === 'unsupported'}
                            className={classnames('nav-link details--custom--button', { active: state.activeTab === tab[1] })}
                          >
                            <span className="tab-label">{tab[0]}</span>
                            {merchantInfo?.compliance?.last_submitted_categories?.includes?.(tab[1]) &&
                              merchantProfile.kyc_status !== 'rejected' && (
                                <span className="status-pill smaller yellow compliance-ticker" />
                              )}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {isLoading ? (
                  <LoadingPlaceholder type="text" background="#f5f6f6" content={3} />
                ) : (
                  <section>
                    <div className="kyc-tab-header">
                      <div className="leading-text">{switchComplianceTabsHeading[state.activeTab]}</div>

                      {activeComplianceTab !== 'Verified' && isAllowed(userAccess, ['merchant_kyc_status.update']) && (
                        <div className="feedback-container">
                          {merchantProfile?.kyc_status !== 'verified' && (
                            <button
                              type="button"
                              onClick={() => {
                                setModal({ modalType: 'feedback-modal' });
                              }}
                              disabled={
                                merchantProfile?.kyc_status === 'verified' ||
                                merchantProfile?.kyc_status === 'rejected' ||
                                hasAddedFeedback('text')
                              }
                            >
                              <img alt="" src={PlusIcon} />
                              <span>Add Feedback</span>
                            </button>
                          )}

                          {hasAddedFeedback('text') && (
                            <p>
                              <img alt="" src={CheckedIcon} /> Feedback added
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    {switchTabs()}
                  </section>
                )}
              </div>

              <ComplianceFeedbacks details={merchantInfo?.compliance} tabs={childTabs} />

              <div className="main-container__footer">
                <div className="date">
                  Joined: {getDate(merchantProfile?.createdAt)}, {getTime(merchantProfile?.createdAt)}
                </div>

                <div className="footer-actions">
                  {isAllowed(userAccess, ['merchant_kyc_status.update']) && (
                    <>
                      {allFeedbacks.length > 0 && (
                        <button
                          type="button"
                          className="footer-actions__unsent"
                          onClick={() => {
                            setModal({ modalType: 'unsent-feedback' });
                          }}
                        >
                          <img alt="" src={InfoIcon} />
                          <span>Unsent Feedback</span>
                          <span className="count">{allFeedbacks.length || ''}</span>
                        </button>
                      )}

                      {merchantProfile?.kyc_status !== 'verified' && (
                        <button
                          type="button"
                          className="footer-actions__send"
                          onClick={() => {
                            setModal({ modalType: 'send-feedback' });
                          }}
                          disabled={allFeedbacks.length < 1}
                        >
                          <img alt="" src={SendIcon} />
                          <span>Send All Feedback</span>
                        </button>
                      )}
                    </>
                  )}
                  {merchantProfile?.kyc_status === 'verified' ? (
                    <button type="button" className="footer-actions__verified" id="c-out">
                      <img alt="" src={CheckedIcon} />
                      <span>This account is verified</span>
                    </button>
                  ) : (
                    isAllowed(userAccess, ['merchant_kyc_status.update']) && (
                      <button
                        type="button"
                        className="footer-actions__verify"
                        onClick={() => setModal({ modalType: 'verify-merchant' })}
                        disabled={merchantProfile.kyc_status === 'rejected'}
                      >
                        <span>Verify Account</span>
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal.modalType && (
        <MerchantModals
          close={() => setModal({ modalType: null })}
          type={modal.modalType}
          info={modal.modalInfo}
          details={merchantProfile}
          id={id}
          tabs={childTabs}
          setFeedbacks={setFeedbacks}
          unsentFeedbacks={allFeedbacks}
          verifyMerchant={verifyMerchant}
          updateRiskStatus={updateRiskStatus}
          updateMerchantBvn={updateMerchantBvn}
          setModal={setModal}
          sentinal={{
            enabled: sentinalStatus?.enabled,
            manageSentinal
          }}
        />
      )}
    </div>
  );
}

export default MerchantKycComponent;
