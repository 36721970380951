import React from 'react';
import { IEmptyReversalStateProps, IErrorReversalStateProps, ILoadingReversalStateProps, IReversalStatesProps } from '+types';
import ImgEmptyState from '+assets/img/dashboard/empty-state.png';
import WarningSvg from '+assets/img/dashboard/warning.svg';
import RetrySvg from '+assets/img/dashboard/refresh.svg';
import './index.scss';

const EmptyReversalState: React.FC<IEmptyReversalStateProps> = ({ text }) => {
  return (
    <div className="reversal-state__container">
      <img alt="EmptyState" src={ImgEmptyState} loading="lazy" />
      <div className="reversal-state__subtitle">{text}</div>
    </div>
  );
};

const ErrorReversalState: React.FC<IErrorReversalStateProps> = ({ text, refreshFn }) => {
  return (
    <div className="reversal-state__container">
      <img alt="EmptyState" src={WarningSvg} />
      <h3 className="reversal-state__title">Something went wrong!</h3>
      <p className="reversal-state__subtitle">{text}</p>
      <button type="button" onClick={refreshFn}>
        <img src={RetrySvg} alt="refresh" />
        <span>Refresh</span>
      </button>
    </div>
  );
};

const LoadingReversalState: React.FC<ILoadingReversalStateProps> = ({ text }) => {
  return (
    <div className="reversal-state__container">
      <span className="spinner-border spinner-border-xs text-primary" role="status" aria-hidden="true" />
      <h3 className="reversal-state__title">Please wait...</h3>
      <p className="reversal-state__subtitle">{text}</p>
    </div>
  );
};

const ReversalStates: React.FC<IReversalStatesProps> = ({ reversalType, reversalState, refreshFn }) => {
  return (
    <div className="reversal-state">
      {reversalState === 'loading' && <LoadingReversalState text={`Loading the ${reversalType} for this transaction...`} />}
      {reversalState === 'error' && <ErrorReversalState text={`Unable to load ${reversalType}. Please refresh.`} refreshFn={refreshFn} />}
      {reversalState === 'empty' && <EmptyReversalState text={`No ${reversalType} found for this transaction`} />}
    </div>
  );
};

export default ReversalStates;
