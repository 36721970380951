import React from 'react';
import CustomCheckbox from './CustomCheckbox';

interface IToggleFormProps {
  label: string;
  defaultOpen?: any;
  children: React.ReactNode;
  reset: () => void;
  hidden?: boolean;
}

const ToggleFormGroup = ({ children, label, defaultOpen = false, reset, hidden = false }: IToggleFormProps): JSX.Element => {
  const [open, setOpen] = React.useState(!!defaultOpen);
  return (
    <div className="form-group border-bottom accordion__comp--checkbox" hidden={hidden}>
      <div className="pb-2 accordion__comp--checkbox-title">
        <CustomCheckbox
          checked={open}
          onChange={() => {
            if (!open === false && reset) reset();
            setOpen(!open);
          }}
          text={label}
        />
      </div>
      <section className={open ? 'content-visible' : ''}>{children}</section>
    </div>
  );
};

export default ToggleFormGroup;
