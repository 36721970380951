/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CreateNewRole from './Roles/CreateNewRole';
import RoleDetails from './Roles/RoleDetails';
import RolesList from './Roles/RolesList';
import UserDetails from './UserDetails';
import UserNewInvite from './UserNewInvite';
import UsersList from './UsersList';

export default function Users() {
  return (
    <div className="content-i checkouts-container">
      <Switch>
        <Route path="/dashboard/users" exact component={UsersList} />
        <Route exact path="/dashboard/users/roles">
          <RolesList />
        </Route>
        <Route exact path="/dashboard/users/roles/new">
          <CreateNewRole />
        </Route>
        <Route exact path="/dashboard/users/invite">
          <UserNewInvite />
        </Route>
        <Route exact path="/dashboard/users/roles/:id" component={RoleDetails} />
        <Route path="/dashboard/users/:id">
          <UserDetails />
        </Route>
      </Switch>
    </div>
  );
}
