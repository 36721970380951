import React, { MutableRefObject } from 'react';
import { Formik } from 'formik';
import { ManualDebitFormType } from '../data';
import './index.scss';

const cardCategories = [
  { label: '-- select card category --', value: '' },
  { label: 'Issued cards', value: 'customer' },
  { label: 'Reserved cards', value: 'reserved' }
];

interface IManualDebitFormProps {
  validateForm: (state: boolean) => void;
  handleFormData: (values: ManualDebitFormType) => void;
  formData: MutableRefObject<{ debitType: string; cardCategory: string; feeType: string }>;
}

const ManualDebitForm = ({ validateForm, handleFormData, formData }: IManualDebitFormProps) => {
  return (
    <Formik
      initialValues={{
        cardCategory: formData.current.cardCategory || '',
        feeType: 'Cross-Currency Fee',
        debitType: formData.current.debitType || ''
      }}
      onSubmit={() => undefined}
      validate={(values) => {
        const errors: ManualDebitFormType = {
          cardCategory: '',
          feeType: '',
          debitType: ''
        };
        if (values.cardCategory && values.feeType && values.debitType) {
          handleFormData(values);
          validateForm(true);
        } else {
          validateForm(false);
        }
        return errors;
      }}
    >
      {({ values, handleChange }) => {
        return (
          <>
            <div className="form-group">
              <label htmlFor="cardCategory">Card Category</label>
              <select className="form-control" id="cardCategory" name="cardCategory" value={values.cardCategory} onChange={handleChange}>
                {cardCategories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="feeType">Fee Type</label>
              <input className="form-control" id="feeType" name="feeType" value={values.feeType} onChange={handleChange} disabled />
            </div>
            <div className="radio-form-group">
              <label htmlFor="debitType">Debit Type</label>
              <div className="radio-container">
                <div className="radio-input">
                  <input
                    type="radio"
                    id="single-debit"
                    name="debitType"
                    value="single"
                    onChange={handleChange}
                    checked={values.debitType === 'single'}
                  />
                  <label htmlFor="single-debit">Single debit</label>
                </div>
                <div className="radio-input">
                  <input
                    type="radio"
                    id="multiple-debit"
                    name="debitType"
                    value="multiple"
                    onChange={handleChange}
                    checked={values.debitType === 'multiple'}
                  />
                  <label htmlFor="multiple-debit">Multiple debit</label>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ManualDebitForm;
