import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { formatAmount, isAllowed, logError, switchTrxnMessage } from '+utils';

import TransactionDetails from '../TransactionDetailsNew';
import { generateAdminDetailsSummary, generateHeaderSummary, generateMoreDetailsSummary } from './BalanceHistoryDetailsData';

const api = new APIRequest();
const PartnerBalanceHistoryDetails = () => {
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();
  const { reference } = useParams<{ reference: string }>();
  const {
    data: partnerDetailsData,
    isLoading,
    refetch
  } = useQuery(`PARTNER_BALANCE_HISTORY_${reference}`, () => api.getPartnerBalanceDetails(reference), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${reference.toUpperCase()}.`,
        type: 'danger',
        action: { name: 'Retry', action: () => refetch() }
      });
    }
  });

  const partnerBalanceDetails = partnerDetailsData?.data ?? {};
  const handleFundingRequery = useMutation(() => api.requeryPartnerBalanceFunding(reference), {
    onSuccess: () => {
      queryClient.invalidateQueries(`PARTNER_BALANCE_HISTORY_${reference}`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error requerying the transaction: ${reference.toUpperCase()}.`,
        type: 'danger',
        action: { name: 'Retry', action: () => handleFundingRequery.mutate() }
      });
    }
  });

  return (
    <div className="content-i">
      <div className="content-box">
        <TransactionDetails>
          <TransactionDetails.Header
            heading={formatAmount(partnerBalanceDetails?.amount)}
            currency={partnerBalanceDetails?.currency}
            status={partnerBalanceDetails?.status}
            statusBg={String(switchTrxnMessage[partnerBalanceDetails?.status as keyof typeof switchTrxnMessage]?.backgroundColor)}
            statusColor={String(switchTrxnMessage[partnerBalanceDetails?.status as keyof typeof switchTrxnMessage]?.color)}
            isLoading={isLoading}
            summaries={generateHeaderSummary(partnerBalanceDetails)}
            actionButtons={
              partnerBalanceDetails?.status === 'processing' && isAllowed(userAccess, ['partner_funding.create'])
                ? [
                    {
                      children: !handleFundingRequery.isLoading ? (
                        <>
                          <span>Requery</span>
                          <i className="os-icon os-icon-refresh-ccw" />
                        </>
                      ) : (
                        'Requerying...'
                      ),
                      variant: 'light-blue',
                      onClick: () => handleFundingRequery.mutate(),
                      disabled: handleFundingRequery.isLoading
                    }
                  ]
                : []
            }
          />
          <TransactionDetails.Section
            isLoading={isLoading}
            heading="Admin’s Information"
            summaries={generateAdminDetailsSummary(partnerBalanceDetails)}
          />
          <TransactionDetails.Section
            isLoading={isLoading}
            heading="More Transaction Details"
            summaries={generateMoreDetailsSummary(partnerBalanceDetails)}
          />
        </TransactionDetails>
      </div>
    </div>
  );
};

export default PartnerBalanceHistoryDetails;
