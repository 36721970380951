import React, { useMemo, useState } from 'react';
import { chargebackStatusTagConfig, getDateAndTime } from '+utils';
import ClockIcon from '+assets/img/dashboard/clock-icon-5.svg';
import HistoryIcon from '+assets/img/dashboard/history.svg';
import { ChargebackStatusType, ISODateStringType } from '+types';
import './index.scss';

interface ITimeline {
  status: ChargebackStatusType;
  date: ISODateStringType;
}

const minLength = 2;

export default function Timeline<T extends ITimeline>({ data = [] }: { data: Array<T> }) {
  if (!data) return <p className="empty-state-text">Merchant chargeback history is unavailable</p>;

  const [state, setState] = useState({ isExpanded: true, length: minLength });

  const toggleExpand = () => {
    setState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
      length: prevState.isExpanded ? data.length : minLength
    }));
  };

  const timeline = useMemo(() => data?.slice(0, state.length), [data?.length, state?.length]);

  return (
    <>
      <div className="position-relative timeline-comp">
        {timeline?.map(({ date, status }) => (
          <div className="d-flex timeline-item" key={status}>
            <div className="flex-grow-1 d-flex align-items-center position-relative left-section">
              <img src={ClockIcon} alt="" className="time-icon" />
              <span className="time">{getDateAndTime(date)}</span>
            </div>
            <span className="label">{chargebackStatusTagConfig[status]?.name}</span>
          </div>
        ))}
      </div>

      {data?.length > minLength ? (
        <button type="button" onClick={toggleExpand} className="expand-btn btn btn-link">
          <img alt="" src={HistoryIcon} className="history-icon" />
          <span>{state.length !== minLength ? `Show less` : `View history`}</span>
        </button>
      ) : null}
    </>
  );
}
