/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IChargebackSummaryCard } from '+types';
import { useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';
import ChargebackUploadModal from './ChargebackUploadModal';
import './index.scss';

type ChargebackT = {
  title: string;
  amount: number;
  message: string;
};

const data: ChargebackT[] = [
  {
    title: 'Action Required',
    amount: 0,
    message: 'Chargebacks yet to be resolved'
  },
  {
    title: 'Chargebacks Resolved',
    amount: 0,
    message: 'Chargebacks resolved today'
  }
];
const ChargebackSummaryCard = ({ summaryData }: IChargebackSummaryCard) => {
  const history = useHistory();
  const [displayCharge, setDisplayChargeback] = useState(false);
  const [newData, setNewData] = useState(data);
  const handleChargebackModal = () => setDisplayChargeback(!displayCharge);

  const userAccess = useSetUserAccess();
  useEffect(() => {
    if (!summaryData) return;
    const result = data.map(item => {
      if (item.title === 'Action Required') {
        return { ...item, amount: summaryData.pending_chargebacks };
      }
      if (item.title === 'Chargebacks Resolved') {
        return { ...item, amount: summaryData.chargebacks_resolved_today };
      }
      return item;
    });
    setNewData(result);
  }, [summaryData]);

  const navigateToMultipleChargebackUpload = () => history.push('/dashboard/disputes/chargeback/add');
  return (
    <>
      <section className="history_summary_details settlement-summary" style={{ marginTop: '80px' }}>
        <section className="--dispute-invoice">
          <section className="history_summary_types">
            {newData.map(({ title, amount, message }) => {
              return (
                <div className="pending-details" key={title}>
                  <p>{title}</p>
                  <p className={`${title.includes('Action') ? '--text-orange' : ''}`}>{amount}</p>
                  <p>{message}</p>
                </div>
              );
            })}
          </section>
          <div className="chargeback_summary_heading col-sm-3">
            {isAllowed(userAccess, ['chargebacks.update']) && (
              <div className="__buttons">
                <button className="btn btn-primary" type="button" onClick={handleChargebackModal}>
                  <span className="os-icon os-icon-plus" />
                  <span>Add Chargeback</span>
                </button>
                <button className="btn btn-primary" type="button" onClick={navigateToMultipleChargebackUpload}>
                  <span>Upload File (CSV)</span>
                </button>
              </div>
            )}
          </div>
        </section>
      </section>
      {displayCharge && <ChargebackUploadModal close={handleChargebackModal} mode="add" />}
    </>
  );
};

export default ChargebackSummaryCard;
