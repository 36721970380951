/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import { IError, IRole } from '+types';
import { capitalize, EmailValidation, history, isAllowed, logError } from '+utils';

import ConfirmInvitation from '../components/ConfirmInvitation';
import UserPermissionTable from '../components/UsersPermissionsTable';

import exclamationMark from '+assets/img/dashboard/exclamationMark.svg';
import infoMark from '+assets/img/dashboard/info-i.svg';
import successful from '+assets/img/dashboard/successful.svg';

import '../index.scss';

const apiRequest = new APIRequest();

type UserInviteModalType = 'default' | 'confirm' | 'learn-more' | 'danger-zone';

const UserNewInvite = () => {
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [selectRole, setSelectRole] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<UserInviteModalType>('default');
  const [modifyPermission, setModifyPermission] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [inputError, setInputError] = useState('');
  const [emailState, setEmailState] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<(string | number)[]>([]);

  const { data: allRoles, refetch: refetchRoles } = useQuery(['ROLES'], () => apiRequest.getAllRoles(1, 1000));

  const { data: detailsData, refetch: refetchDetails } = useQuery(`${selectRole}_ROLE_DETAILS`, () => apiRequest.getRole(selectRole), {
    onError: () => {
      feedbackInit({
        message: "There has been an error getting this role's information",
        type: 'danger',
        action: {
          action: () => refetchDetails(),
          name: 'Try again'
        }
      });
    },
    enabled: !!selectRole
  });

  const rolesOptions = (allRoles?.data as IRole[])?.map(({ name, id, category }) => ({
    label: `${category !== 'custom' ? 'System-' : ''}${capitalize(name)}`,
    value: id
  }));

  const onCloseModal = () => {
    setModalVisible(false);
    setSaveState(false);
    setRoleName('');
  };
  const inviteNewUser = useMutation(apiRequest.inviteNewUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(['USERS_INVITED_USERS', 1, 10, {}]);
    },
    onError: (error: IError) => {
      onCloseModal();
      logError(error);
      feedbackInit({
        message:
          error?.response?.data?.message === `Invitation already accepted`
            ? `This user is already on the platform`
            : `${error?.response?.data?.message}`,
        type: 'danger'
      });
    }
  });
  const createUserRole = useMutation(apiRequest.createUserRole, {
    onSuccess: () => {
      refetchRoles();
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error creating this user's role`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  useEffect(() => {
    if (detailsData && detailsData?.permissions?.length === selectedPermissions.length) {
      setSelectedPermissions([]);
    }
  }, [selectedPermissions, detailsData]);

  useEffect(() => {
    if (modifyPermission && !!selectedPermissions.length) {
      setSaveState(true);
    }
  }, [modifyPermission, selectedPermissions.length]);

  const inviteToggle = () => {
    setModalType('confirm');
    setModalVisible(true);
  };

  const saveStateToggle = () => setSaveState(!saveState);

  const onInviteNewUser = async () => {
    closeFeedback();
    if (selectedPermissions.length > 0 && roleName) {
      const newUserRole = await createUserRole.mutateAsync({
        name: `Custom-${roleName}`,
        permissions: [...new Set(selectedPermissions)]
      });
      return inviteNewUser.mutateAsync({
        email: emailState,
        role_id: newUserRole?.id
      });
    }
    return inviteNewUser.mutateAsync({
      email: emailState,
      role_id: selectRole
    });
  };

  const validateInput = () => {
    const validationError = EmailValidation(emailState, true);
    setInputError(validationError as string);
  };

  const disableSendInvite = () => {
    return !!EmailValidation(emailState, true) || (!selectRole && !selectedPermissions.length);
  };

  const onDisplayPermissionTable = () => {
    setModifyPermission(!modifyPermission);
    setSelectedPermissions([]);
  };

  const switchModal = () => {
    switch (modalType) {
      case 'confirm':
        return {
          secondaryCompletedModal: true,
          heading: `Confirm invitation?`,
          description: (
            <p className="confirmInput-p">
              Please confirm that you want to invite this user to the internal dashboard. This action cannot be undone.
            </p>
          ),
          size: 'mdBase',
          formCenter: false,
          content: (
            <ConfirmInvitation saveState={saveState} roleName={roleName} getRoleName={setRoleName} saveStateToggle={saveStateToggle} />
          ),
          showButtons: true,
          firstButtonText: 'Cancel',
          secondButtonText: saveState ? 'Save & Confirm' : 'Yes, Confirm',
          secondButtonDisable: saveState ? !roleName : false,
          secondButtonAction: () => onInviteNewUser(),
          firstButtonAction: () => onCloseModal(),
          secondButtonActionIsTerminal: true,
          headerBottomBorder: false,
          equalFooterBtn: true,
          completedHeading: 'Invitation Sent!',
          completedDescription: 'Your invitation has been sent successfully.',
          completedImage: successful,
          completedActionText: 'Dismiss',
          completedAction: () => {
            onCloseModal();
            history.push('/dashboard/users', null);
          },
          showImage: true,
          closeAction: () => {
            onCloseModal();
          }
        };

      case 'learn-more':
        return {
          heading: `You have the permission to create system roles`,
          content: (
            <p className="info-modal-cont">
              <b>You are about to create a:</b> <br /> <span className="info-modal-span"> Custom role</span> <br />
              <br /> We have two kinds of roles;
              <b>
                <i> System roles </i>
              </b>
              and
              <b>
                <i> custom roles</i>
              </b>
              <br />
              <br />
              System roles can be used by anyone that has the permission to send user invitations while custom roles can only be used by you
              for future invites.
            </p>
          ),
          size: 'md',
          showButtons: false
        };
      case 'danger-zone':
        return {
          heading: `Before you modify this user's permissions`,
          content: (
            <p className="info-modal-cont danger-zone-decs">
              We have two kinds of roles;
              <b>
                <i> System roles </i>
              </b>
              and
              <b>
                <i> custom roles</i>
              </b>
              <br /> <br />
              System roles can be used by anyone that has the permission to send user invitations while custom roles can only be used by you
              for future invites.
              <br /> <br />
              It is recommended that you use one of the already defined roles except you are looking to create a new role.
            </p>
          ),
          size: 'md',
          showButtons: false
        };

      default:
        return null;
    }
  };

  return (
    <div className="content-i user-new-invite">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Users</span>
            </button>
          </div>
        </div>
        <div className="element-box">
          <div className="element-box-heading">
            <div className="heading-box-mmd users-heading-box-mmd">
              <h5 className="form-header">Invite a new user</h5>
              <div className="form-desc no-underline">
                Invite team members to collaborate on this dashboard. An email invitation would be sent to them.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="element-input-box">
                <div className="input-info">
                  <label htmlFor="emailAddress">Email</label>
                  <div className="basic-filter">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter the user's email"
                      value={emailState}
                      aria-label="emailAddress"
                      name="emailAddress"
                      id="emailAddress"
                      onChange={({ target: { value } }) => setEmailState(value)}
                      onBlur={validateInput}
                    />
                  </div>
                  {inputError && <p className="input-error">{inputError}</p>}
                </div>
                <div className="input-info">
                  <label htmlFor="role">Role</label>
                  <div className="basic-filter">
                    <div className="form-group filter-object filter-object-ssm">
                      <select className="form-control" onChange={e => setSelectRole(e.target.value)} value={selectRole} id="role">
                        <option value="" className="disabled">
                          Select a role
                        </option>
                        {rolesOptions?.map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {isAllowed(userAccess, [
                'admin_user_permissions.view',
                'system_roles.update',
                'custom_roles.create',
                'custom_roles.update',
                'custom_roles.view',
                'my_custom_roles.update',
                'my_custom_roles.view'
              ]) && (
                <div className="element-link-exclament">
                  {!modifyPermission ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-user-permission"
                        onClick={onDisplayPermissionTable}
                        disabled={!!EmailValidation(emailState, true)}
                      >
                        View or modify this user’s permissions
                      </button>
                      <div
                        role="button"
                        className="element-exclament"
                        onClick={() => {
                          setModalType('danger-zone');
                          setModalVisible(true);
                        }}
                        onKeyDown={() => {
                          setModalType('danger-zone');
                          setModalVisible(true);
                        }}
                        tabIndex={0}
                      >
                        <div className="img-exclament-mark">
                          <img src={exclamationMark} alt="close" aria-hidden />
                        </div>
                        <p className="danger-txt">Danger Zone </p>
                      </div>
                    </>
                  ) : (
                    <div className="element-exclament btn element-exclamen-cont" style={{ display: 'flex' }}>
                      <div
                        role="button"
                        className="element-exclament btn"
                        onClick={onDisplayPermissionTable}
                        onKeyDown={onDisplayPermissionTable}
                        tabIndex={0}
                      >
                        <div className="img-exclament-mark">
                          <img src={infoMark} alt="close" aria-hidden />
                        </div>
                        <p className="danger-txt danger-txt-more">
                          Consider saving a new role after {selectRole ? 'modifying' : 'creating'} the permissions.{' '}
                        </p>
                      </div>
                      <b
                        role="button"
                        className="danger-txt danger-txt-more"
                        onClick={() => {
                          setModalType('learn-more');
                          setModalVisible(true);
                        }}
                        onKeyDown={() => {
                          setModalType('learn-more');
                          setModalVisible(true);
                        }}
                        tabIndex={0}
                      >
                        Learn More{' '}
                      </b>
                    </div>
                  )}
                </div>
              )}

              <div className="legend-dash" />
              {modifyPermission && (
                <UserPermissionTable
                  acceptEmptyPermission
                  userDetails={detailsData}
                  prevSelectedPermissions={selectedPermissions}
                  getSelectedPermission={setSelectedPermissions}
                />
              )}
              {isAllowed(userAccess, ['admin_user_invitations.create']) && (
                <button
                  type="button"
                  className="btn btn-invite-users"
                  onClick={inviteToggle}
                  disabled={(inputError || disableSendInvite()) as boolean}
                >
                  Send Invitation
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal visible={modalVisible} {...(switchModal() as Omit<IModalProps, 'close'>)} close={onCloseModal} />
    </div>
  );
};

export default UserNewInvite;
