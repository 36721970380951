import React from 'react';

import { cleanInput } from '+utils';

import './index.scss';

interface IConfirmInvitationProps {
  getRoleName: (value: string) => void;
  saveStateToggle?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  saveState?: boolean;
  checkInvite?: boolean;
  roleName: string;
}

const ConfirmInvitation = ({ saveState, checkInvite, getRoleName, saveStateToggle, roleName }: IConfirmInvitationProps) => {
  return (
    <div className="invite-new-roles">
      {checkInvite && (
        <div className="form-check-cont">
          <label className="form-text-format form-check-label checkbox-text">
            <input
              data-testid="save-state-input"
              className="form-check-input"
              type="checkbox"
              onChange={saveStateToggle}
              checked={saveState}
            />
            Save modified permissions in a new role
          </label>
        </div>
      )}

      {saveState && (
        <div className="input-info element-input-info">
          <p className="form-text-format mb-0">New Role Name</p>
          <div className="basic-filter">
            <input
              type="text"
              aria-label="New role name"
              name="newrolename"
              id="newrole"
              placeholder=""
              maxLength={40}
              className="form-control"
              data-testid="role-name-input"
              onChange={({ target: { value } }) => getRoleName(cleanInput(value))}
            />

            <p className="rolename-length" data-testid="role-name-length">
              {roleName?.length}/40
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmInvitation;
