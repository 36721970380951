import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import AdvanceExportModal from '+shared/AdvanceExportModal';
import EmptyState from '+shared/EmptyState';
import ListDropdown from '+shared/ListDropdown';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { APIDownload, getDate, history, isAllowed, logError } from '+utils';

import AdvancedFilterModal from './components/AdvancedWebhooksFilterModal';
import WebhooksTableComponent from './components/WebhooksTable';

import caret from '+assets/img/dashboard/caret.svg';
import check from '+assets/img/dashboard/check-white.svg';
import failed from '+assets/img/dashboard/failed.svg';
import filter from '+assets/img/dashboard/filter.svg';
import pause from '+assets/img/dashboard/pause.svg';
import searchClose from '+assets/img/dashboard/search-close.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const Webhooks = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState({
    activePage: false,
    merchantList: [],
    merchantDropdownVisible: false,
    isAdvancedFiltering: false,
    paginationPage: 1,
    limit: 25,
    sorterType: null,
    sortingParams: {}
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const overflowSectionRef = useRef(null);
  const summarySectionRef = useRef(null);
  const overflowEndRef = useRef(null);
  const overflowTopRef = useRef(null);
  const location = useLocation();
  const userAccess = useSetUserAccess(location);

  const filterMerchantsList = list => {
    if (!list?.length) return [];
    const merchants = {
      list: [],
      cache: {}
    };
    for (let i = 0; i < list.length; i += 1) {
      if (!merchants.cache[list[i].id]) {
        merchants.cache[list[i].id] = true;
        merchants.list.push(list[i]);
      }
    }
    return merchants.list;
  };

  const setOverflowHeight = useCallback(() => {
    // Set minimum height of window for initial empty state
    if (!state.activePage) {
      const emptyState = document.querySelector('.empty-state-box');
      const offsetHeight = summarySectionRef.current?.clientHeight + window.pageYOffset + emptyState?.getBoundingClientRect().top;
      if (emptyState) {
        emptyState.style.height = `calc(100vh - ${offsetHeight}px`;
      }
    }
    // Set minimum height of window for mobile devices
    if (state.activePage && document.body.clientWidth < 768) {
      document.querySelector('.content-w').style.minHeight = `calc(100vh - ${document.querySelector('.menu-mobile')?.clientHeight}px)`;
    }
    if (overflowSectionRef.current && summarySectionRef.current && state.activePage) {
      const offsetHeight =
        summarySectionRef.current?.clientHeight + window.pageYOffset + overflowSectionRef.current?.getBoundingClientRect().top;
      overflowSectionRef.current.style.height = `calc(100vh - ${offsetHeight}px`;

      if (summaryVisible) {
        overflowEndRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }
    }
  }, [summaryVisible, state.activePage]);

  const {
    isFetching: isFetchingMerchants,
    refetch: refetchMerchants,
    data: merchantsData
  } = useQuery('MERCHANTS', () => api.getAllMerchants(), {
    select: data => filterMerchantsList(data?.data),
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting all merchants',
        type: 'danger',
        action: {
          action: () => refetchMerchants(),
          name: 'Try again'
        },
        callback: setOverflowHeight
      });
    }
  });

  useEffect(() => {
    if (!merchantsData?.length) return;
    setState(prevState => ({
      ...prevState,
      merchantList: merchantsData
    }));
  }, [merchantsData]);

  const {
    data,
    isFetching: isFetchingWebhooks,
    refetch: refetchWebhooks
  } = useQuery(
    ['WEBHOOK_NOTIFICATIONS', state.paginationPage, state.limit, state.sortingParams],
    () => api.getWebhookNotifications(state.paginationPage, state.limit, state.sortingParams),
    {
      onError: () => {
        let message = 'There has been an error getting your webhook notifications.';
        if (state.sorterType) message = `There has been an error so we could not find any results for your ${state.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetchWebhooks(),
            name: 'Try again'
          },
          callback: setOverflowHeight
        });
      }
    }
  );

  const scrollToTop = () => overflowTopRef.current.scrollIntoView({ behavior: 'smooth' });

  const setSummaryTitle = () => {
    if (!state?.sortingParams?.['filter[merchant]']) {
      if (document.body.clientWidth > 600) {
        return ' (All Merchants)';
      }
      return ' (All)';
    }
    return ' for this merchant';
  };

  const exportWebhookNotifications = async format => {
    try {
      const res = await api.exportWebhookNotifications(state.sortingParams, format);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Webhook notifications at ${getDate(Date.now())}`, type);
      setExportModalVisible(false);
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your webhook notifications`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const searchWebhooksReference = useDebouncedCallback(query => {
    const cleanedQuery = query?.trim();
    setState(prevState => ({
      ...prevState,
      paginationPage: 1,
      sorterType: 'filter',
      sortingParams: {
        ...prevState.sortingParams,
        'filter[keyword]': cleanedQuery || undefined
      }
    }));
  }, 800);

  useLayoutEffect(() => {
    const storedState = history.getPathInfo('history');
    if (storedState) {
      setState(prevState => ({
        ...prevState,
        paginationPage: storedState?.paginationPage || 1,
        merchantDropdownVisible: storedState?.merchantDropdownVisible || false,
        limit: storedState?.limit || 25,
        sorterType: storedState?.sorterType,
        sortingParams: storedState?.sortingParams || {},
        activePage: storedState?.activePage || false,
        merchantList: storedState?.merchantList || [],
        isAdvancedFiltering: storedState?.isAdvancedFiltering || false
      }));
    }
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => setOverflowHeight());
  }, [summaryVisible, state.activePage]);

  useEffect(() => {
    window.addEventListener('resize', setOverflowHeight);

    return () => window.removeEventListener('resize', setOverflowHeight);
  }, [state.activePage]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      searchWebhooksReference(searchQuery);
    }
  }, [searchQuery]);

  const webhookNotifications = data?.data;
  const paging = data?.paging;
  const summary = data?.summary;
  const clearSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setSearchQuery('');
    setState(prevState => ({
      ...prevState,
      paginationPage: 1,
      sortingParams: {
        ...prevState.sortingParams,
        'filter[keyword]': undefined
      }
    }));
  };
  const WebhooksSummary = () => {
    const summaryList =
      summary?.reduce((totalList, currentItem) => {
        return {
          ...totalList,
          [currentItem.status]: currentItem.count + (totalList?.[currentItem.status] || 0)
        };
      }, {}) || {};
    const isSummaryEmpty = () => !Object.keys(summaryList)?.length || (Object.keys(summaryList)?.length === 1 && summaryList?.processing);

    return (
      <div className="webhooks__summary" ref={summarySectionRef}>
        <div className="webhooks__summary--header">
          <h5>Summary {setSummaryTitle()}</h5>
          <span className="divider-sm" />
          <button
            type="button"
            className={`${!summaryVisible ? 'not-visible' : ''} ${
              isFetchingWebhooks || !webhookNotifications?.length || isSummaryEmpty() ? 'disabled' : ''
            }`}
            onClick={() => {
              if (isFetchingWebhooks || !webhookNotifications?.length || isSummaryEmpty()) return;
              setSummaryVisible(!summaryVisible);
            }}
          >
            {summaryVisible ? (
              <>
                Hide{' '}
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 5.5L5 15.5" stroke="#AABDCE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 5.5L15 15.5" stroke="#AABDCE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </>
            ) : (
              'Show Summary'
            )}
          </button>
        </div>

        {summaryVisible && (
          <ul className="webhooks__summary--list">
            {summaryList?.pending && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      paginationPage: 1,
                      isAdvancedFiltering: true,
                      activePage: true,
                      sorterType: 'filter',
                      sortingParams: {
                        'filter[status][]': 'pending'
                      }
                    }));
                    setSummaryVisible(false);
                  }}
                >
                  <p className="summary--title">
                    <span>
                      {!state?.sortingParams?.['filter[merchant]'] && 'Total '}
                      Delayed <img src={pause} alt="delayed" aria-hidden />
                    </span>
                  </p>
                  <p className="summary--count">{summaryList?.pending || 'N/A'}</p>
                </button>
                <p className="summary--description">Pending / delayed for over 15 mins</p>
              </li>
            )}
            {summaryList?.failed && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      paginationPage: 1,
                      activePage: true,
                      isAdvancedFiltering: true,
                      sorterType: 'filter',
                      sortingParams: {
                        'filter[status][]': 'failed'
                      }
                    }));
                    setSummaryVisible(false);
                  }}
                >
                  <p className="summary--title">
                    <span>
                      {!state?.sortingParams?.['filter[merchant]'] && 'Total '}
                      Failed <img src={failed} alt="failed" aria-hidden />
                    </span>
                  </p>
                  <p className="summary--count">{summaryList?.failed || 'N/A'}</p>
                </button>
                <p className="summary--description">Failed after all attempts</p>
              </li>
            )}
            {summaryList?.delivered && (
              <li>
                <p className="summary--title">
                  <span>
                    {!state?.sortingParams?.['filter[merchant]'] && 'Total '}
                    Delivered <img src={check} alt="delivered" aria-hidden />
                  </span>
                </p>
                <p className="summary--count">{summaryList?.delivered || 'N/A'}</p>
                <p className="summary--description">Webhooks sent successfully</p>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  };
  return (
    <section className="webhooks__page">
      <div className="content-i">
        <div className="content-box webhooks__page--content-box">
          <div className="col-sm-12">
            <div className="webhooks__main-section">
              <div className="webhooks__top-menu">
                <div className="webhooks__merchant--select">
                  <h5>Show webhook notifications for</h5>
                  <div className="webhooks__merchant--select-w">
                    <ListDropdown
                      type="Merchant"
                      list={state.merchantList}
                      isFetching={isFetchingMerchants}
                      value={state.sortingParams?.['filter[merchant]'] || ''}
                      defaultValue="All Merchants"
                      active={state.merchantDropdownVisible}
                      setActive={value => {
                        setState(prevState => ({
                          ...prevState,
                          merchantDropdownVisible: value
                        }));
                      }}
                      setValue={value => {
                        if (!state.activePage) {
                          setState(prevState => ({
                            ...prevState,
                            activePage: true
                          }));
                        }
                        setState(prevState => ({
                          ...prevState,
                          paginationPage: 1,
                          sortingParams: {
                            'filter[merchant_id]': value === 'all' ? undefined : value
                          }
                        }));
                      }}
                      useId
                    />
                  </div>
                </div>
              </div>

              {state.activePage ? (
                <div className="webhooks__overflow-section" ref={overflowSectionRef}>
                  <div ref={overflowTopRef} className="webhooks__overflow-top-anchor" />
                  <div className="webhooks__table--controls">
                    <div className={`webhooks__table--controls-l ${!state.isAdvancedFiltering ? '--simple-filter' : ''}`}>
                      {state.isAdvancedFiltering ? (
                        <>
                          <span className="webhooks__table--results">
                            Filtered results {webhookNotifications?.length ? `(${webhookNotifications?.length})` : ''}
                          </span>
                          <span className="divider-sm" />
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary webhooks__filter-button"
                            style={{
                              background: 'none',
                              border: 'none',
                              color: '#2376F3',
                              fontWeight: 500
                            }}
                            onClick={() => {
                              setState(prevState => ({
                                ...prevState,
                                isAdvancedFiltering: false,
                                sorterType: null,
                                sortingParams: {}
                              }));
                            }}
                          >
                            <img src={searchClose} alt="close" aria-hidden />
                            <span>Clear</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="dropdown__container">
                            <label htmlFor="status">Status: </label>
                            <select
                              name="status"
                              onChange={e => {
                                const statusValue = e.target.value;
                                setState(prevState => ({
                                  ...prevState,
                                  paginationPage: 1,
                                  sorterType: 'filter',
                                  sortingParams: {
                                    ...prevState.sortingParams,
                                    'filter[status][]': statusValue || undefined
                                  }
                                }));
                              }}
                              value={state.sortingParams?.['filter[status][]'] || ''}
                              style={{ backgroundImage: `url(${caret})` }}
                            >
                              <option value="">All</option>
                              <option value="delivered">Delivered</option>
                              <option value="pending">Pending</option>
                              <option value="processing">Processing</option>
                              <option value="failed">Failed</option>
                            </select>
                          </div>

                          <div className="search__container search__container--webhooks">
                            <svg
                              className={`search__icon ${searchFocused ? 'focused' : ''}`}
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.5 18.5C14.6421 18.5 18 15.1421 18 11C18 6.85786 14.6421 3.5 10.5 3.5C6.35786 3.5 3 6.85786 3 11C3 15.1421 6.35786 18.5 10.5 18.5Z"
                                stroke="#AABDCE"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.9998 21.4998L15.7998 16.2998"
                                stroke="#AABDCE"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            <input
                              aria-label="Search webhooks transaction IDs"
                              name="searchHistory"
                              id="searchtransactions"
                              placeholder="Search"
                              onFocus={() => setSearchFocused(true)}
                              onBlur={() => setSearchFocused(false)}
                              value={searchQuery}
                              onChange={e => setSearchQuery(e.target.value)}
                            />

                            <button
                              type="button"
                              className={`search__icon-close ${searchFocused ? 'focused' : ''}`}
                              onClick={e => clearSearch(e)}
                            >
                              <img src={searchClose} alt="close" aria-hidden />
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="webhooks__table--controls-r">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary webhooks__filter-button"
                        style={{
                          background: 'none',
                          border: 'none',
                          color: '#2376F3',
                          fontWeight: 500
                        }}
                        hidden={!webhookNotifications?.length}
                        onClick={() => setFilterModalVisible(true)}
                      >
                        <img src={filter} alt="filter" />
                        <span>Advanced Filter</span>
                      </button>
                      <span className="divider-sm" />

                      {isAllowed(userAccess, ['webhooks.export']) ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary webhooks__export-button"
                          style={{
                            background: 'none',
                            border: 'none',
                            color: '#2376F3',
                            fontWeight: 500
                          }}
                          hidden={!webhookNotifications?.length}
                          onClick={() => setExportModalVisible(true)}
                        >
                          <i className="os-icon os-icon-arrow-up-right" />
                          <span>Export</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {isFetchingWebhooks ? (
                    <LoadingPlaceholder type="table" background="#f5f6f6" />
                  ) : (
                    <>
                      {webhookNotifications?.length ? (
                        <WebhooksTableComponent
                          data={webhookNotifications}
                          paging={paging}
                          pagingAction={c =>
                            setState(prevState => ({
                              ...prevState,
                              paginationPage: c
                            }))
                          }
                          limitAction={c =>
                            setState(prevState => ({
                              ...prevState,
                              limit: c
                            }))
                          }
                          savedState={state}
                          scrollToTop={scrollToTop}
                        />
                      ) : (
                        <EmptyState heading="There are no webhook notifications" />
                      )}
                    </>
                  )}

                  <div ref={overflowEndRef} className="webhooks__overflow-end-anchor" />
                </div>
              ) : (
                <EmptyState message="The merchant's webhook notifications will show up here" searching screenHeight />
              )}
            </div>
          </div>
        </div>
      </div>
      {WebhooksSummary()}
      <AdvancedFilterModal
        handleFilterQuery={param => {
          setState(prevState => ({
            ...prevState,
            paginationPage: 1,
            sorterType: 'filter',
            sortingParams: param,
            isAdvancedFiltering: true
          }));
        }}
        isFiltering={state.isAdvancedFiltering}
        closeModal={() => setFilterModalVisible(false)}
        visible={filterModalVisible}
      />
      {exportModalVisible && (
        <AdvanceExportModal
          openExport={exportModalVisible}
          setOpenExport={setExportModalVisible}
          exportAction={exportWebhookNotifications}
          type="webhooks"
          heading="Export Webhook Notifications"
          description="Choose how you would like to export these webhook notifications"
        />
      )}
    </section>
  );
};

export default Webhooks;
