import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import usePartnerBalanceStore from '+store/partnerBalanceStore';
import { CurrencyType, IPartnerAccount } from '+types';
import { capitalizeRemovedash, formatAmount, getDate, getTime, isAllowed, logError } from '+utils';

const api = new APIRequest();
const BalanceSummaryCard = ({
  showDetails = true,
  balance,
  currency
}: {
  balance: IPartnerAccount;
  showDetails?: boolean;
  currency: CurrencyType;
}) => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const setPartnerBalance = usePartnerBalanceStore(state => state.setPartnerBalances);

  const { data, refetch, isFetching } = useQuery(`PARTNERS_BALANCE_${balance?.id}`, () => api.getPartnerBalance(String(balance?.id)), {
    onSuccess: result => {
      setPartnerBalance({ [`${balance?.id}`]: result?.data });
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error in getting this partners balance.`,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        }
      });
    },
    enabled: !!balance.id
  });

  useEffect(() => {
    refetch();
  }, [balance]);

  const currentBalance = data?.data ?? balance;
  const isBalanceLow = currentBalance?.available_balance < currentBalance?.threshold?.min_balance;

  return (
    <div className="balance-summary-card">
      <p className="balance-summary-card__bank" data-testid="bank-name">{`${capitalizeRemovedash(
        balance.institution_name
      )} - ${capitalizeRemovedash(balance.destination_name)}  (${balance.destination_number})`}</p>
      {isFetching ? (
        <LoadingPlaceholder type="text" content={2} section={1} rows={1} />
      ) : (
        <p className={`balance-summary-card__amount ${isBalanceLow ? 'low' : ''}`}>{formatAmount(currentBalance.available_balance ?? 0)}</p>
      )}
      <p className="balance-summary-card__time" data-testid="balance-time">{`Balance as at ${getTime(String(new Date()))}, ${getDate(String(new Date()))}`}</p>
      {showDetails && isAllowed(userAccess, ['partner_funding.view']) ? (
        <Link className="balance-summary-card__details" to={`/dashboard/partners-balance/${balance.id}?currency=${currency}`}>
          <p>See Details</p>
          <i className="os-icon os-icon-arrow-up-right" />
        </Link>
      ) : null}
    </div>
  );
};

export default BalanceSummaryCard;
