import APIRequest from '+services/api-services';

const utilityBaseURL = process.env.REACT_APP_UTILITY_API_BASE  || 'http://localhost:3000';
const utilityAPIRequest = new APIRequest(utilityBaseURL);

export default function useFileUpload() {
  const uploadFiles = async (file: FormData) => {
    try {
      const response = await utilityAPIRequest.uploadFiles(file);
      return { data: response.data };
    } catch (error) {
      return { error };
    }
  };

  return { uploadFiles };
}
