import React from 'react';

import FieldErrorMsg from '+shared/FormError';
import Icon from '+shared/Icons';
import { IFeeProps, IPlanEditor, VirtualCardCategory } from '+types';
import { advanceCleanInput, backwardAmountInput, stripNonNumeric } from '+utils';

import { feeCategories, formSectionIsComplete } from '../constants/plan-editor';
import ExistingTPVList from './ExistingTPVList';
import FeeFormField from './FeeFormField';
import * as FormAccordion from './FormAccordion';
import OverlayInput from './OverlayInput';
import Stepper from './Stepper';

const categoryTitle = {
  customer: 'Issued',
  reserved: 'Reserved'
};

export default function PlanEditor({
  values,
  setField,
  errors,
  touched,
  getField,
  onBlur,
  onChange,
  action = 'add',
  currentStep,
  tpvList
}: IPlanEditor) {
  const category: VirtualCardCategory = currentStep === 'setReservedCardFees' ? 'reserved' : 'customer';
  const disableFeeControl = () => {
    if (!errors?.[category]?.card_limit && !errors?.[category]?.min_tpv && !errors?.[category]?.max_tpv) return false;
    return true;
  };

  return (
    <div className="plan-editor">
      <div className="stack-md pt-2">
        <p className="font-weight-bold">Setup the configuration for {categoryTitle[category]} cards</p>
        <Stepper steps={['setIssuedCardFees', 'setReservedCardFees']} step={currentStep} />

        <div className="issuable-cards-and-tpv">
          {action === 'edit' && currentStep === 'setIssuedCardFees' ? (
            <div className="stack-sm pb-3">
              <FormSectionHeading title="Plan name" isComplete={!errors?.name} />
              <label htmlFor="plan_name" className="sr-only font-weight-bold">
                Plan name
              </label>
              <input
                name="name"
                value={values.name}
                onChange={e => setField?.('name', advanceCleanInput(e.target.value))}
                onBlur={onBlur}
                type="text"
                id="plan_name"
                className="form-control"
                placeholder="e.g. Growth"
              />
              <FieldErrorMsg error={errors?.name as string} id="plan_name" touched />
            </div>
          ) : null}

          <div className="stack-sm py-3">
            <FormSectionHeading title="Number of cards issuable" isComplete={!errors?.[category]?.card_limit} />
            <label htmlFor="cards_issuable" className="sr-only font-weight-bold">
              Number of cards issuable
            </label>
            <input
              name={`${category}.card_limit`}
              value={values[category]?.card_limit}
              onChange={e => setField?.(`${category}.card_limit`, stripNonNumeric(e.target.value))}
              id="cards_issuable"
              className="form-control"
              placeholder="e.g. 2000"
            />
            <FieldErrorMsg id="cards_issuable" error={errors?.[category]?.card_limit as string} touched />
          </div>

          {/* TOTAL PAYMENT VALUE OF THE MERCHANT */}
          <fieldset className="stack-sm pt-3" aria-labelledby="tpv_of_merchant">
            <FormSectionHeading
              title="Total Payment Value (TPV) of the merchant"
              subtitle="This is the total amount processed by a merchant over a specific period."
              isComplete={!errors?.[category]?.min_tpv && !errors?.[category]?.max_tpv}
            />
            <p id="tpv_of_merchant" data-role="label" className="sr-only">
              Total Payment Value (TPV) of the merchant
            </p>

            <div className="p-2 stack-sm">
              <div data-group="radio-label-text">
                <div data-group="radio-label">
                  <label htmlFor="min_tpv" id="label_tpv_between">
                    Between
                  </label>
                  <input name="tpv_range" value="between" type="radio" checked id="tpv_between" />
                </div>

                <div className="stack-xs">
                  <div className="d-flex align-items-baseline tpv-range" style={{ gap: '0.5rem' }}>
                    <OverlayInput
                      id="min_tpv"
                      error={errors?.[category]?.min_tpv as string}
                      touched={touched?.[category]?.min_tpv as boolean}
                      showError={false}
                      name={`${category}.min_tpv`}
                      value={values[category]?.min_tpv}
                      onChange={e => setField?.(`${category}.min_tpv`, backwardAmountInput(stripNonNumeric(e.target.value)))}
                      onBlur={onBlur}
                      overlayText={values.currency}
                      placeholder="2000"
                      aria-labelledby="label_tpv_between"
                    />
                    <span>and</span>
                    <OverlayInput
                      id="max_tpv"
                      error={errors?.[category]?.max_tpv as string}
                      touched={touched?.[category]?.max_tpv as boolean}
                      showError={false}
                      name={`${category}.max_tpv`}
                      value={values[category]?.max_tpv}
                      onChange={e => setField?.(`${category}.max_tpv`, backwardAmountInput(stripNonNumeric(e.target.value)))}
                      onBlur={onBlur}
                      overlayText={values.currency}
                      placeholder="2000"
                      aria-labelledby="label_tpv_between"
                    />
                  </div>
                  <FieldErrorMsg
                    id="tpv-range"
                    error={errors?.[category]?.max_tpv || (errors?.[category]?.min_tpv as string)}
                    touched={(touched?.[category]?.max_tpv || touched?.[category]?.min_tpv) as boolean}
                  />
                </div>
              </div>
              <ExistingTPVList tpvList={tpvList} />
            </div>
          </fieldset>
        </div>
      </div>

      <div>
        <FormAccordion.Root>
          {feeCategories.map((feeCategory, index) => (
            <>
              <FormAccordion.Summary
                className="form-accordion-summary"
                isOpen={!formSectionIsComplete(errors?.[category]?.fee?.[feeCategory.fee] as unknown as IFeeProps)}
                index={index}
              >
                <FormSectionHeading
                  title={`${feeCategory.prefix} Fee`}
                  subtitle={feeCategory.subtitle}
                  isComplete={formSectionIsComplete(errors?.[category]?.fee?.[feeCategory.fee] as unknown as IFeeProps)}
                />

                <Icon width={8} height={8} className="disclosure-chevron" name="caretRight" />
              </FormAccordion.Summary>

              <FormAccordion.Description
                className="form-accordion-description"
                isOpen={!formSectionIsComplete(errors?.[category]?.fee?.[feeCategory.fee] as unknown as IFeeProps)}
                index={index}
              >
                <FeeFormField
                  disableControls={disableFeeControl()}
                  feeCategory={feeCategory.fee}
                  cardCategory={category}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setField={setField}
                  getField={getField}
                  onBlur={onBlur}
                  onChange={onChange}
                  prefix={feeCategory.prefix}
                />
              </FormAccordion.Description>
            </>
          ))}
        </FormAccordion.Root>
      </div>
    </div>
  );
}

const FormSectionHeading = ({ title, subtitle, isComplete = false }: { title: string; subtitle?: string; isComplete: boolean }) => {
  return (
    <div className="billing-form-section-heading">
      <div className="indicator-with-title">
        {isComplete ? <Icon name="validForm" width={14} /> : <Icon name="invalidForm" width={14} />}
        <p className="title">{title}</p>
      </div>
      <p className="mt-1 subtitle">{subtitle}</p>
    </div>
  );
};
