import { useEffect } from 'react';
import { CustomFormType, PlansType } from '../data';

const useFormData = (defaultPlan: PlansType, handleFormData: (data: CustomFormType) => void) => {
  useEffect(() => {
    handleFormData({
      customerNumberOfCardsIssuable: defaultPlan?.monthly_card_limit || 0,
      customerFundingFee: defaultPlan?.fee?.customer?.funding?.amount || 0,
      customerIssuanceFee: defaultPlan?.fee?.customer?.issuance?.amount || 0,
      customerWithdrawalFee: defaultPlan?.fee?.customer?.withdrawal?.amount || 0,
      customerSecurityReserveFee: defaultPlan?.fee?.customer?.security_reserve?.amount || 0,
      customerSubscriptionFee: defaultPlan?.fee?.customer?.subscription?.amount || 0,
      customerCrossCurrencyFee: defaultPlan?.fee?.customer?.cross_currency?.amount || 0,
      customerChargebackFee: defaultPlan?.fee?.customer?.chargeback?.amount || 0,

      reservedNumberOfCardsIssuable: defaultPlan?.reserved_card_limit || 0,
      reservedFundingFee: defaultPlan?.fee?.reserved?.funding?.amount || 0,
      reservedIssuanceFee: defaultPlan?.fee?.reserved?.issuance?.amount || 0,
      reservedWithdrawalFee: defaultPlan?.fee?.reserved?.withdrawal?.amount || 0,
      reservedSecurityReserveFee: defaultPlan?.fee?.reserved?.security_reserve?.amount || 0,
      reservedSubscriptionFee: defaultPlan?.fee?.reserved?.subscription?.amount || 0,
      reservedCrossCurrencyFee: defaultPlan?.fee?.reserved?.cross_currency?.amount || 0,
      reservedChargebackFee: defaultPlan?.fee?.reserved?.chargeback?.amount || 0
    });
  }, [defaultPlan, handleFormData]);
};

export default useFormData;
