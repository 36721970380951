import React, { memo, useState, FC } from 'react';
import Icon from '+containers/Dashboard/Shared/Icons';
import { hourData, minuteData, timeMode } from '+utils';
import { useReducerState } from '+hooks';
import './CustomTimeRange.scss';

interface ICustomTimeRangeProps {
  setSelectedTimeRange: (timeFormat: { startTime: string; endTime: string; meridiemStart: string; meridiemEnd: string }) => void;
}

const CustomTimeRange: FC<ICustomTimeRangeProps> = memo(({ setSelectedTimeRange }) => {
  const [state, setState] = useReducerState({
    startAmPmLabel: 'AM',
    endAmPmLabel: 'AM',
    startHourIndex: 0,
    endHourIndex: 0,
    startMinIndex: 0,
    endMinIndex: 0
  });
  const { startAmPmLabel, endAmPmLabel, startHourIndex, endHourIndex, startMinIndex, endMinIndex } = state;
  const updateSelectedTimeRange = (
    newStartHourIndex = startHourIndex,
    newStartMinIndex = startMinIndex,
    newEndHourIndex = endHourIndex,
    newEndMinIndex = endMinIndex,
    newStartAmPmLabel = startAmPmLabel,
    newEndAmPmLabel = endAmPmLabel
  ) => {
    setSelectedTimeRange({
      startTime: `${hourData[newStartHourIndex]}:${minuteData[newStartMinIndex]}`,
      endTime: `${hourData[newEndHourIndex]}:${minuteData[newEndMinIndex]}`,
      meridiemStart: newStartAmPmLabel,
      meridiemEnd: newEndAmPmLabel
    });
  };

  const handleAmPmToggle = (mode: string) => {
    if (mode === timeMode.startTime) {
      const newLabel = startAmPmLabel === 'AM' ? 'PM' : 'AM';
      setState({ startAmPmLabel: newLabel });
      updateSelectedTimeRange(startHourIndex, startMinIndex, endHourIndex, endMinIndex, newLabel, endAmPmLabel);
    } else {
      const newLabel = endAmPmLabel === 'AM' ? 'PM' : 'AM';
      setState({ endAmPmLabel: newLabel });
      updateSelectedTimeRange(startHourIndex, startMinIndex, endHourIndex, endMinIndex, startAmPmLabel, newLabel);
    }
  };

  const handleHourChange = (mode: string, increment: boolean) => {
    if (mode === timeMode.startTime) {
      const newIndex = (startHourIndex + (increment ? 1 : -1) + hourData.length) % hourData.length;
      setState({ startHourIndex: newIndex });
      updateSelectedTimeRange(newIndex, startMinIndex, endHourIndex, endMinIndex);
    } else {
      const newIndex = (endHourIndex + (increment ? 1 : -1) + hourData.length) % hourData.length;
      setState({ endHourIndex: newIndex });
      updateSelectedTimeRange(startHourIndex, startMinIndex, newIndex, endMinIndex);
    }
  };

  const handleMinuteChange = (mode: string, increment: boolean) => {
    if (mode === timeMode.startTime) {
      const newIndex = (startMinIndex + (increment ? 1 : -1) + minuteData.length) % minuteData.length;
      setState({ startMinIndex: newIndex });
      updateSelectedTimeRange(startHourIndex, newIndex, endHourIndex, endMinIndex);
    } else {
      const newIndex = (endMinIndex + (increment ? 1 : -1) + minuteData.length) % minuteData.length;
      setState({ endMinIndex: newIndex });
      updateSelectedTimeRange(startHourIndex, startMinIndex, endHourIndex, newIndex);
    }
  };

  const renderTimeControl = (mode: string, hourIndex: number, minIndex: number, amPmLabel: string) => (
    <div className="inner-flex">
      <div className="mr-32 pt-32 start-label">{mode === timeMode.startTime ? 'Start time:' : 'Stop time:'}</div>
      <div>
        <div
          className="pointer"
          onClick={() => handleHourChange(mode, true)}
          onKeyDown={() => handleHourChange(mode, true)}
          role="button"
          tabIndex={0}
          aria-label={`Increase ${mode === timeMode.startTime ? 'start' : 'end'} hour`}
          data-testid={`${mode === timeMode.startTime ? 'start' : 'end'}-hour-up-arrow`}
        >
          <Icon name="caretUp" />
        </div>
        <div className="mt-10 mb-10">
          <span className="time-text" data-testid={`${mode === timeMode.startTime ? 'start' : 'end'}-hour-result`}>
            {hourData[hourIndex]}
          </span>
        </div>
        <div
          className="neg-ml-5 pointer"
          onClick={() => handleHourChange(mode, false)}
          onKeyDown={() => handleHourChange(mode, false)}
          role="button"
          tabIndex={0}
          aria-label={`Decrease ${mode === timeMode.startTime ? 'start' : 'end'} hour`}
          data-testid={`${mode === timeMode.startTime ? 'start' : 'end'}-hour-down-arrow`}
        >
          <Icon name="caretDown" height="auto" />
        </div>
      </div>
      <div className="pt-32 ml-24 mr-24">
        <span className="time-text">:</span>
      </div>
      <div>
        <div
          className="pointer"
          onClick={() => handleMinuteChange(mode, true)}
          onKeyDown={() => handleMinuteChange(mode, true)}
          role="button"
          tabIndex={0}
          aria-label={`Increase ${mode === timeMode.startTime ? 'start' : 'end'} minute`}
          data-testid={`${mode === timeMode.startTime ? 'start' : 'end'}-minutes-up-arrow`}
        >
          <Icon name="caretUp" />
        </div>
        <div className="mt-10 mb-10">
          <span className="time-text" data-testid="minutes-result">
            {minuteData[minIndex]}
          </span>
        </div>
        <div
          className="neg-ml-5 pointer"
          onClick={() => handleMinuteChange(mode, false)}
          onKeyDown={() => handleMinuteChange(mode, false)}
          role="button"
          tabIndex={0}
          aria-label={`Decrease ${mode === timeMode.startTime ? 'start' : 'end'} minute`}
          data-testid={`${mode === timeMode.startTime ? 'start' : 'end'}-minutes-down-arrow`}
        >
          <Icon name="caretDown" height="auto" />
        </div>
      </div>
      <div className="ml-48">
        <div
          className="ml-8 pointer"
          onClick={() => handleAmPmToggle(mode)}
          onKeyDown={() => handleAmPmToggle(mode)}
          role="button"
          tabIndex={0}
          aria-label={`Toggle ${mode === timeMode.startTime ? 'start' : 'end'} AM/PM`}
        >
          <Icon name="caretUp" />
        </div>
        <div className="mt-10 mb-10 pl-3x">
          <span className="time-text">{amPmLabel}</span>
        </div>
        <div
          className="ml-1 pointer"
          onClick={() => handleAmPmToggle(mode)}
          onKeyDown={() => handleAmPmToggle(mode)}
          role="button"
          tabIndex={0}
          aria-label={`Toggle ${mode === timeMode.startTime ? 'start' : 'end'} AM/PM`}
        >
          <Icon name="caretDown" height="auto" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="custom-time-wrapper">
      <div className="top-case">
        {renderTimeControl(timeMode.startTime, startHourIndex, startMinIndex, startAmPmLabel)}
        {renderTimeControl(timeMode.endTime, endHourIndex, endMinIndex, endAmPmLabel)}
      </div>
    </div>
  );
});
export default CustomTimeRange;
