import React, { useState } from 'react';
import VerificationEventResultModal from './VerificationEventResultModal';
import Icon from '+containers/Dashboard/Shared/Icons';
import { VerificationDataT } from '+types/identity';
import FileViewer from '+containers/Dashboard/Shared/FileViewer';
import './index.scss';

export default function VerificationEventResult({ idName, data, status }: { idName: string; data?: VerificationDataT; status: string }) {
  const [showModal, setShowModal] = useState(false);
  const [fileToView, setFileToView] = useState<string | null>(null);
  const closeFileViewer = () => setFileToView(null);
  const openFileViewer = (file: string) => setFileToView(file);
  return (
    <div className="ivr">
      <div className="ivr__heading">
        <h2>Results</h2>
      </div>
      <div className="ivr__content">
        <div className="ivr__content__row">
          <div>
            <div className="ivr__content__row__key">{idName}</div>
            <div className={`ivr__content__row__status ${status === 'failed' && 'incomplete'}`}>
              {' '}
              {status && status !== 'failed' ? (
                'Verification complete'
              ) : (
                <>
                  <Icon name="caution" width={14} height={14} /> Unable to complete verification{' '}
                </>
              )}
            </div>
          </div>
          <div className="ivr__content__row__btn">
            {status && status !== 'failed' ? (
              <button onClick={() => setShowModal(true)} type="button" className="btn btn-link">
                See result details
              </button>
            ) : (
              <span className="incomplete-msg">Results are only available when verification is complete</span>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <VerificationEventResultModal close={() => setShowModal(false)} idName={idName} data={data as VerificationDataT} setFileToView={openFileViewer} />
      )}
      <FileViewer url={fileToView as string} setShowView={closeFileViewer} showView={!!fileToView} size='md' />
    </div>
  );
}
