import React from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import { datadogRum } from '@datadog/browser-rum';

import AppWrapper from './App';
import ErrorPage from './containers/Dashboard/Shared/ErrorPage';

import 'bootstrap/dist/css/bootstrap.css';
import '+styles/main.scss';

datadogRum.init({
  applicationId: process.env.REACT_APP_DARKHORSE_BASE ?? '',
  clientToken: process.env.REACT_APP_DARKHORSE_CLIENT ?? '',
  site: process.env.REACT_APP_DARKHORSE_URL ?? 'us5.datadoghq.com',
  service: 'internal-dashboard',
  env: process.env.REACT_APP_DARKHORSE_AREA ?? 'staging',
  version: '1.0.0',
  sessionSampleRate: Number(process.env.REACT_APP_DARKHORSE_SESSION ?? 100),
  sessionReplaySampleRate: Number(process.env.REACT_APP_DARKHORSE_REPLAY ?? 100),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [url => url.startsWith(`${process.env.REACT_APP_DARKHORSE_TRACE_URL}`)]
});

datadogRum.startSessionReplayRecording();
Bugsnag.start({
  apiKey: process.env.REACT_APP_HERMES_WINGS ?? '',
  plugins: [new BugsnagPluginReact()],
  onError(event) {
    event.addMetadata('application', {
      name: 'Korapay Admin Dashboard'
    });
  }
});
// this is to prevent console.log from working on production
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)!;
const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <AppWrapper />
  </ErrorBoundary>
);
