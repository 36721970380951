import React, { useState } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import { TCurrentPaymnentPreferenceSettings } from '+types';
import { capitalizeRemovedash } from '+utils';

import { modalContent } from './currentPreferenceData';

import './current-preference.scss';

export default function CurrentPreference({ pref }: { pref: TCurrentPaymnentPreferenceSettings }) {
  const [modalState, setModalState] = useState('');

  const modal = modalContent(modalState);

  return (
    <div className="current-preference" data-testid="current-preference">
      <div className="grey-section__subsection-item">
        <div>
          <h6 data-testid="overpayment" className="grey-section__title">
            Overpayments
            <button
              data-testid="overpayment-info-btn"
              aria-label="info about overpayment"
              onClick={() => setModalState('overpayment')}
              type="button"
              className="ml-2 infobutton"
            >
              <Icon name="circledQuestion" height={16} width={16} fill="#414F5F" />
            </button>
          </h6>

          <p data-testid="overpayment-subtext">Merchant’s preference for when a customer makes an overpayment.</p>
        </div>
        <div className="form-group mb-0">
          <p data-testid="overpayment-setting" className="form-control  current-preference__pref">
            {capitalizeRemovedash(pref?.overpayment?.action)}
          </p>
        </div>
      </div>
      <div className="grey-section__subsection-item">
        <div>
          <h6 data-testid="underpayment" className="grey-section__title">
            Underpayments
            <button
              data-testid="underpayment-info-btn"
              aria-label="info about underpayment"
              onClick={() => setModalState('underpayment')}
              type="button"
              className="ml-2"
            >
              <Icon name="circledQuestion" height={16} width={16} fill="#414F5F" />
            </button>
          </h6>
          <p data-testid="underpayment-subtext">Merchant’s preference for when a customer makes an underpayment.</p>
        </div>
        <div className="form-group mb-0">
          <p data-testid="underpayment-setting" className="form-control current-preference__pref">
            {capitalizeRemovedash(pref?.underpayment?.action)}
          </p>
        </div>
      </div>
      <Modal
        visible={!!modal}
        size="md"
        close={() => setModalState('')}
        heading={modal?.heading || ''}
        description={modal?.description}
        content={modal?.content}
        firstButtonText="Close"
        hideSecondButton
        secondButtonText=""
      />
    </div>
  );
}
