import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Params } from '+types';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { filteredOutObjectProperty, history, formatAmount, capitalize, queriesParams } from '+utils';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import SecondaryDetails from '+shared/SecondaryDetails';
import BusinessAvatar from '+assets/img/dashboard/customer-avatar.svg';
import BulkPayoutDraftTable from './BulkPayoutDraftTable';
import BulkPayoutTable from './BulkPayoutTable';
import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const BulkTransactionList = () => {
  const { id } = useParams<Params>();
  const searchQuery = useSearchQuery();
  const feedbackInit = useFeedbackHandler();
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const status = searchQuery.value.status || [];
  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status
    ])
  };

  const {
    data: bulkTransactions,
    isFetching,
    refetch
  } = useQuery(['BULK_PAYOUTS_DETAILS', searchQuery.value, id], () => api.getBulkPayoutTransactionList(id, page, limit, sortingParams), {
    keepPreviousData: true,
    onError: () => {
      let message = 'There has been an error getting bulk transaction lists.';
      if (searchQuery.value?.sorterType)
        message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        },
        componentlevel: false
      });
    }
  });

  const {
    data: summaryData,
    refetch: refetchDetails,
    isLoading: isFetchingDetails
  } = useQuery(['BULK_PAYOUT_SUMMARY', id], () => api.getBulkPayoutSummary(id), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting the bulk payout information',
        type: 'danger'
      });
    },
    enabled: !!id
  });

  const data = bulkTransactions?.data || [];
  const paging = bulkTransactions?.paging;

  const {
    status: bulkStatus,
    reference,
    description,
    payout_count: payCount,
    failed_transactions: failed,
    date_completed: date,
    total_bulk_amount: totalBulkAmount,
    successful_transactions: successful,
    account,
    amount: amountPaid,
    currency
  } = summaryData || {};

  const summaryDetails = [
    {
      'Total Bulk Amount (incl. fees)':
        (
          <span>
            <strong>{formatAmount(totalBulkAmount || 0)}</strong> {currency}
          </span>
        ) || 'N/A',
      ...(bulkStatus === 'complete'
        ? {
            'Amount Paid Successfully':
              (
                <span>
                  <strong>{formatAmount(amountPaid || 0)}</strong> {currency}
                </span>
              ) || 'N/A',
            'Date Completed': date || 'N/A'
          }
        : {}),
      'Merchant Name': capitalize(account?.name) || 'N/A',
      'Bulk Status': <span className={bulkStatus === 'complete' ? 'complete-class' : ''}>{capitalize(bulkStatus) || 'N/A'}</span>
    },
    {
      'Payouts Count': payCount || 'N/A',
      'Successful Payouts': (
        <>
          <span className={`status-pill smaller align-baseline ${successful ? 'green' : ''}`} /> {successful}
        </>
      ),
      'Failed Payouts': (
        <>
          <span className={`status-pill smaller align-baseline ${failed ? 'red' : ''}`} /> {failed}
        </>
      )
    }
  ];

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            {!isFetchingDetails && (
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <i className="os-icon os-icon-arrow-left7" />
                <span>Back to Bulk Payouts</span>
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="element-wrapper">
              <div className="element-box">
                <div className="content-details-box">
                  <div className="content-details-head --stl-details-head">
                    <div className="content-details-title">
                      <div className="user-avatar-w">
                        <div className="user-avatar">
                          <img alt="" src={BusinessAvatar} />
                        </div>
                      </div>
                      <div className="cd-title ml-3">
                        <h3 className="cd-maintitle" style={{ textTransform: 'uppercase' }}>
                          {reference || '- -'}
                        </h3>
                        <div className="cd-subtitle">{capitalize(description || '- -')}</div>
                      </div>
                    </div>
                  </div>
                  {isFetchingDetails ? (
                    <LoadingPlaceholder type="text" content={4} />
                  ) : (
                    <SecondaryDetails title="Bulk Payout Summary" data={summaryDetails} />
                  )}
                  <div className="content-details-body mt-4 --stl-details-body">
                    {['draft', 'cancelled'].includes(bulkStatus as string) ? (
                      <BulkPayoutDraftTable data={summaryData} />
                    ) : (
                      <BulkPayoutTable
                        data={data}
                        paging={paging}
                        loading={isFetching}
                        tableWrapperClassName="table-responsive"
                        page={page}
                        limit={limit}
                        status={bulkStatus}
                        sortingParams={sortingParams}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkTransactionList;
