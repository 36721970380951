import React from 'react';

import Accordion from '+containers/Dashboard/Shared/Accordion';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import { DocumentDetailsT, ValidationT, ValidationValueT, VerificationEventResultModalT } from '+types/identity';
import { capitalize, capitalizeRemovedash, findMismatchKeys } from '+utils';

import './index.scss';

const ProgressBar = ({ percentage, matched }: { percentage: number, matched?: boolean }) => {
  const totalDots = 35;
  const activeDots = Math.round((percentage / 100) * totalDots);
  const acitveColor = matched ? '#48CEB0' : '#F32345';

  return (
    <div className="position-absolute progress-circle-wrapper">
      <div className="progress-circle">
        {Array.from({ length: totalDots }).map((_, index) => (
          <span
            key={index}
            className={`dot ${index < activeDots ? 'active' : ''}`}
            style={{
              backgroundColor: index < activeDots ? acitveColor : '#EAF2FE',
              transform: `rotate(${(index / totalDots) * 360}deg) translate(55px) rotate(90deg)`
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default function VerificationEventResultModal({ close, idName, data, setFileToView }: VerificationEventResultModalT) {
  const metaData = data?.metadata;
  const mismatched = findMismatchKeys(data?.validation || {});

  const getValue = (key: string | number | ValidationValueT, type: keyof typeof metaData, validationValue?: ValidationValueT) => {
    if (!data) return '';
    const value = data[key as keyof typeof data];
    if (!value && type !== 'validation') return <span className="not-found">Not available</span>;
    if (type === 'document_details') {
      if (typeof value === 'string') {
        return capitalize(value as string);
      }
      return <>{value}</>;
    }
    if (type === 'validation') {
      return (
        <div className="d-flex justify-content-between">
          {!validationValue?.match && <span className="mr-2">{capitalize(value as string)}</span>}
          <span className={`mr-2 ${!validationValue?.match && 'wrong'}`}>{capitalize(validationValue?.value as string)}</span>
          <Icon name={validationValue?.match ? 'checkRounded' : 'circledClose'} width={18} height={19} />
        </div>
      );
    }
    if (type === 'files') {
      const fileType = (value as string).split('.').pop();
      if (fileType === 'pdf' || fileType === 'docx' || fileType === 'doc') {
        return (
          <a href={value as string} target="_blank" rel="noopener noreferrer" download>
            View
          </a>
        );
      }
      return (
        <button type="button" onClick={() => setFileToView?.(value as string)}>
          View
        </button>
      );
    }
  };

  const getMismatchMessage = (keys: string[]) => {
    const formattedKeys = keys?.map(key => capitalizeRemovedash(key));
    let mismatchMessage = 'Possible mismatch in ';
    if (formattedKeys.length > 1) {
      mismatchMessage += formattedKeys.slice(0, -1).join(', ') + ' and ' + formattedKeys.slice(-1);
    } else if (formattedKeys.length === 1) {
      mismatchMessage += formattedKeys[0];
    }
    return mismatchMessage;
  };

  const facialImg = (img: string, title: string, score?: number, matched?: boolean) => {
    return (
      <div className={`ivr__facial-match__img-wrapper ${score ? 'dashed' : ''}`}>
        <div className={`img-wrapper ${score ? 'dashed' : ''}`}>
          {(score !== null && score !== undefined) && <ProgressBar percentage={score || 0} matched={matched} />}
          <img src={img} alt="facial" />
          <div className='back-drop'></div>
          <button className="hover-button" onClick={() => setFileToView?.(img)} type='button'>View</button>
        </div>

        <div className="rounded ivr__facial-match__title">{title}</div>
      </div>
    );
  };
  const facialMatchingContent = (selfie: ValidationValueT) => {
    const score = selfie?.confidence_rating || 0;
    const isScoreHigh = selfie.match;
    const documentImage = data?.['image'];
    return (
      <div className=" ivr__facial-match">
        {!isScoreHigh && (
          <div className="d-flex align-items-center rounded ivr__facial-match-message">
            <Icon name="caution" width={12} height={12} /> <span className="ml-2">Mismatch in image captured</span>
          </div>
        )}
        <div className="d-inline-flex mt-4 position-relative ivr__facial-match-img-container">
          <span className={`ivr__facial-match__percentage ${isScoreHigh ? 'high' : 'low'}`}>{score}%</span>
          {facialImg(documentImage, 'Document Image')}
          {facialImg(selfie.value, 'Selfie Image', score, isScoreHigh)}
        </div>
      </div>
    );
  };
  const renderContent = () => {
    const personalDetails = data.validation as ValidationT;
    const personalDetailsKey = Object.keys(personalDetails || {});

    return (
      <div className="ivr-modal">
        {personalDetails && (
          <Accordion
            open={true}
            title={'Personal Details'}
            className="identity__accordion"
            content={
              <>
                {mismatched.length > 0 && <div className="mismatched-error-msg"><Icon name="caution" width={15} height={15} /><span>{getMismatchMessage(mismatched)}</span></div>}
                <div className="ivr-modal__content">
                  {personalDetailsKey
                    ?.filter(d => d !== 'selfie')
                    .map((m, index: number) => {
                      return (
                        <div className="ivr-modal__content__row" key={index}>
                          <div className={`ivr-modal__content__row__label`}>{capitalizeRemovedash(m)}</div>
                          <div className={`ivr-modal__content__row__value`}>
                            {getValue(m, 'validation', personalDetails[m as keyof typeof personalDetails])}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            }
          />
        )}
        {Object.keys(metaData)
          .filter(meta => meta !== 'validation')
          .map(d => {
            return (
              <Accordion
                open={true}
                title={capitalizeRemovedash(d)}
                className="identity__accordion"
                content={
                  <div className="ivr-modal__content">
                    {(metaData[d as keyof typeof metaData] as DocumentDetailsT[])
                      .filter(d => !personalDetailsKey.includes(d.key))
                      .map((m, index: number) => {
                        return (
                          <div className="ivr-modal__content__row" key={index}>
                            <div className={`ivr-modal__content__row__label ${d === 'files' && 'file'}`}>{m.description}</div>
                            <div className={`ivr-modal__content__row__value ${d === 'files' && 'file'}`}>
                              {getValue(m.key, d as keyof typeof metaData)}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                }
              />
            );
          })}
        {personalDetails?.['selfie'] && (
          <Accordion
            open={true}
            title={capitalizeRemovedash('facial matching')}
            className="identity__accordion"
            content={facialMatchingContent(personalDetails?.['selfie'])}
          />
        )}
      </div>
    );
  };
  return (
    <Modal
      size="md"
      heading={`Result Details - ${idName}`}
      description="These are the results of the verification check and some details extracted from the data and document(s) submitted."
      content={renderContent()}
      secondaryCompletedModal
      completedActionText=""
      close={close}
      secondButtonText="Done"
      secondButtonAction={close}
      hideSecondButton
      firstButtonStyles={{
        width: 'auto',
        backgroundColor: '#2376F3',
        color: '#fff'
      }}
      firstButtonText="Done"
      isScrollable
    />
  );
}
