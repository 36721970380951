import React, { useLayoutEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useSearchQuery, useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';

import Merchants from './Merchants';
import MerchantDetails from './Merchants/MerchantDetails';
import Overview from './Overview';
import Verification from './Verification';
import VerificationEventDetails from './Verification/VerificationEventDetails';
import { TabsT } from '+types';
import './index.scss';

export default function Identity() {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const [tabs, setTabs] = useState([{ name: 'Overview', key: 'overview' }]);
  const addTab = (tab: TabsT) => {
    if (tabs.some(t => t.key === tab.key)) return;
    setTabs(prev => [...prev, tab]);
  };
  const activeTab = searchQuery.value.tab || 'overview';
  const canViewMerchants = isAllowed(userAccess, ['identity_merchants.view']);
  const canViewVerification = isAllowed(userAccess, ['identity_verifications.view']);
  useLayoutEffect(() => {
    if (canViewMerchants) {
      addTab({ name: 'Merchants', key: 'merchants' });
    }
    if (canViewVerification) {
      addTab({ name: 'Verifications', key: 'verifications' });
    }
  }, []);
  return (
    <Switch>
      <Route
        path="/dashboard/identity"
        exact
        render={() => (
          <div className="content-i">
            <div className="content-box">
              <div className="os-tabs-w">
                <div className="os-tabs-controls os-tabs-complex">
                  <ul className="nav nav-tabs">
                    {tabs.map(tab => (
                      <li className="nav-item">
                        <div
                          aria-expanded="false"
                          className={activeTab === tab.key ? 'nav-link active' : 'nav-link'}
                          data-toggle="tab"
                          onClick={() => searchQuery.setQuery({ tab: tab.key }, true)}
                          onKeyDown={() => searchQuery.setQuery({ tab: tab.key }, true)}
                        >
                          <span className="tab-label">{tab.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  {activeTab === 'overview' && <Overview />}
                  {activeTab === 'merchants' && canViewMerchants && <Merchants />}
                  {activeTab === 'verifications' && canViewMerchants && <Verification />}
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <Route path="/dashboard/identity/merchants/:id" component={MerchantDetails} />
      <Route path="/dashboard/identity/verifications/:id" component={VerificationEventDetails} />
    </Switch>
  );
}
