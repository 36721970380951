import React from 'react';

import { TTransactionDetails } from '+types';
import { capitalize, capitalizeRemovedash, getDate, getTime, switchCurrency, switchStatus } from '+utils';

// eslint-disable-next-line import/prefer-default-export
export const refundDetails = ({ data }: { data: TTransactionDetails }) => {
  const {
    amount,
    channel,
    completed_at: completedAt,
    currency,
    destination,
    status,
    payment_source,
    created_at,
    reversal_reason
  } = data || {};

  return {
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 2px 0' }}
        />
        {capitalize(status === 'requires_auth' ? 'Processing (Requires Authorization)' : status || 'Not Available')}
      </>
    ),
    breakdown: {
      refund_amount: amount,
      currency_charged: switchCurrency[currency as keyof typeof switchCurrency],
      channel: `${capitalizeRemovedash(channel as unknown as string) || 'Not Available'}`,
      refund_destination: `${capitalize(destination) || 'Not Available'}`,
      date_created: created_at ? `${getDate(created_at)}, ${getTime(created_at)}` : 'Not available',
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not available',
      processor: `${capitalizeRemovedash(payment_source?.processor) || 'Not available'}`,
      processor_reference: `${payment_source?.processor_reference || 'Not available'}`,
      reason_for_refund: `${capitalizeRemovedash(reversal_reason) || 'Not Available'}`
    }
  };
};
