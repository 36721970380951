import React from 'react';

import Copyable from '+shared/Copyable';
import { IReversalsTransactionDetails } from '+types/reversal';
import { SummaryItemType } from '+types/transactionDetails';
import {
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getTime,
  switchBank,
  switchCurrency,
  switchStatus,
  truncateString,
  history
} from '+utils';
export function generateSummaryFrom(data: IReversalsTransactionDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Net Amount',
      value: data?.payment_source?.amount ? `${formatAmount(data?.payment_source?.amount)} ${data?.currency}` : 'Not Available'
    },
    {
      label: 'Fee',
      value: `${formatAmount((+data?.payment_source?.fee || 0) + (+data?.payment_source?.vat || 0))} ${data?.currency}`
    },
    {
      label: 'Date / Time',
      value: `${getDate(data?.createdAt as string)}, ${getTime(data?.createdAt as string)}`
    },
    {
      label: 'Customer Name',
      value: `${data?.account?.name ?? 'Not Available'}`
    },
    {
      label: 'Description',
      value: (
        <div>
          <Copyable
            text={data?.payment_source?.narration?.toUpperCase?.() || ''}
            textModifier={text => truncateString(text, 18)}
            copyText={data?.payment_source?.narration?.toUpperCase?.() || ''}
            spanClassName=""
            buttonClassName=""
            textToCopy={data?.payment_source?.narration?.toUpperCase?.() || ''}
          />
        </div>
      )
    },
    ...(data?.payment_source?.reference ? [{
      label: 'Initial Pay In Reference',
      value : (
         <button type="button" className="initial-payment-reference" onClick={() => history.push(`/dashboard/pay-ins/${data?.payment_source?.reference}`)}>
          {data?.payment_source?.reference}
        </button>
      )
    }]: [])
  ];
}

export function generateMoreDetailFrom(data: IReversalsTransactionDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Status',
      value: data?.status ? (
        <>
          <span
            className={`status-pill smaller ${data?.status === 'requires_auth' ? 'yellow' : switchStatus(data?.status)}`}
            style={{ margin: '0 7px 2px 0' }}
          />
          <span style={{ margin: '0' }}>{capitalizeRemovedash(data?.status) || 'Not Available'}</span>
        </>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Transaction ID',
      value: (
        <Copyable
          text={data?.reference}
          textModifier={text => truncateString(text, 15)}
          spanClassName="copyable-blue"
          copyText=""
          buttonClassName=""
          textToCopy=""
        />
      )
    },
    {
      label: 'Reversal Type',
      value: data?.type ? <strong>{capitalizeRemovedash(data?.type)}</strong> : 'Not Available'
    },
    {
      label: 'Currency Charged',
      value: switchCurrency[data?.currency as keyof typeof switchCurrency] ?? 'Not Available'
    },
    {
      label: 'Amount Originally Paid',
      value: data?.payment_source?.amount_collected
        ? `${formatAmount(data?.payment_source?.amount_collected)} ${data?.currency}`
        : 'Not Available'
    },
    {
      label: 'Payment Method',
      value: capitalizeRemovedash(data?.payment_source?.payment_source_type) || 'Not Available'
    }
  ];
}

export function generateReversalFrom(data: IReversalsTransactionDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Payment Method',
      value: capitalizeRemovedash(data?.payment_source?.payment_source_type) || 'Not Available'
    },
    {
      label: 'Bank',
      value: data?.payment_reversal_payouts?.[data?.payment_reversal_payouts.length - 1]?.bank_name ? (
        <>
          <img id="bank-icon" src={switchBank.default} alt="bank icon" />
          <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
            {capitalize(
              data?.payment_reversal_payouts?.[data?.payment_reversal_payouts.length - 1]?.bank_name || 'No Bank Information Available'
            )}
          </span>
        </>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Account Number',
      value: data?.payment_reversal_payouts?.[data?.payment_reversal_payouts.length - 1]?.account_number ? (
        <span>{data?.payment_reversal_payouts?.[data?.payment_reversal_payouts.length - 1]?.account_number}</span>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Account Name',
      value: data?.account?.name || 'Not Available'
    },
    {
      label: 'Email',
      value: data?.account?.email || 'Not Available'
    }
  ];
}
