import React, { useEffect } from 'react';
import Modal from '+shared/Modal';
import { useFormik } from 'formik';
import { advanceCleanInput, history } from '+utils';
import { useMutation, useQueryClient } from 'react-query';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import { queryKeys } from '../data';

interface IBalanceFundingActionModal {
  action: 'reject' | 'approve';
  close: () => void;
  reference: string;
}

const reasonOptions = [
  { label: '- Select a reason -', value: '' },
  { label: 'Insufficient USD to credit merchant', value: 'insufficient_usd' },
  { label: 'other', value: 'other' }
];

const initialValues = {
  reason: undefined, // This is set as undefined because this is not required when sending approval API call.
  description: ''
};
const api = new APIRequest();

const BalanceFundingActionModal = ({ action, close, reference }: IBalanceFundingActionModal) => {
  const MAX_LENGTH = 100;
  const { closeFeedback, feedbackInit } = useFeedbackHandler();
  const { isValid, dirty, touched, values, errors, validateForm, validateField, handleBlur, setFieldValue, submitForm, resetForm } =
    useFormik({
      initialValues,
      validateOnBlur: true,
      validateOnMount: true,
      validate: (values) => {
        const errors: Record<string, string> = {};
        const { reason, description } = values;
        if (!reason) errors.reason = 'Please select a reason';
        if (reason === 'other' && !description) errors.description = 'Please provide a description';
        return errors;
      },
      onSubmit: async () => {
        await decideFundingRequestMutation.mutateAsync();
      }
    });
  const queryClient = useQueryClient();
  useEffect(() => {
    validateField('reason');
  }, []);

  const decideFundingRequestMutation = useMutation((data) => api.decideFundingRequest(reference, { action, ...values }), {
    onSuccess: () => {
      resetForm({ values: initialValues });
    },
    onError: (error: any) => {
      feedbackInit({
        message: error.response?.data?.message || 'We are sorry, we could not process the decision right now. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 2500);
    }
  });

  const goToPrevStage = (stage?: string) => {
    close();
    if (stage === 'complete') {
      queryClient.invalidateQueries(queryKeys.APPROVALS);
      history.goBack();
    }
  };

  const requiredInputsAreValid = dirty && isValid;

  const renderDeclineFundingReq = () => (
    <div>
      <div className="mb-3">
        <label htmlFor="reason" className="semibold">
          Why do you want to decline this request?
        </label>
        <select
          className="form-control"
          id="reason"
          name="reason"
          value={values.reason}
          onChange={(e) => {
            setFieldValue('reason', e.target.value);
          }}
          onBlur={handleBlur}
        >
          {reasonOptions?.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>

        {touched.reason && errors.reason && (
          <div className="input__errors">
            <span>{errors.reason}</span>
          </div>
        )}
      </div>

      <div>
        <label htmlFor="description" className="semibold">
          Tell us more about your reason to decline this request? (Optional)
        </label>
        <textarea
          rows={4}
          maxLength={MAX_LENGTH}
          id="description"
          name="description"
          className="form-control"
          value={values.description}
          onChange={(e) => setFieldValue('description', advanceCleanInput(e.target.value))}
          onBlur={handleBlur}
        />
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span className="input__errors m-0">{touched.description && errors.description}</span>
          <div className="small">{`${values?.description?.length}/${MAX_LENGTH}`}</div>
        </div>
      </div>
    </div>
  );

  const modalOptions = {
    shared: {
      close: (stage: string) => {
        resetForm({ values: initialValues });
        goToPrevStage(stage);
      }
    },
    reject: {
      size: 'md',
      heading: 'Decline funding request',
      description: 'Provide the details below to decline merchant’s request to fund issuing balance.',
      content: renderDeclineFundingReq(),
      secondaryCompletedModal: true,
      completedDescription: 'You have successfully declined this funding request.',
      completedActionText: 'Dismiss',
      secondButtonStyles: { backgroundColor: 'hsla(350, 90%, 55%, 1)' },
      secondButtonDisable: !requiredInputsAreValid,
      secondButtonAction: submitForm
    },
    approve: {
      size: 'sm',
      heading: 'Approve issuing balance funding',
      description: 'Kindly confirm that you want to approve this funding request. This action cannot be undone.',
      firstButtonText: 'Back',
      secondButtonText: 'Yes, confirm',
      secondaryCompletedModal: true,
      completedDescription: 'You have successfully approved this funding request.',
      completedActionText: 'Dismiss',
      secondButtonAction: async () => decideFundingRequestMutation.mutateAsync()
    }
  };
  const modalProps = {
    ...modalOptions.shared,
    ...modalOptions[action]
  };

  return <Modal {...modalProps} />;
};
export default BalanceFundingActionModal;
