import React from 'react';

import './index.scss';

const ExplicitPermissionLabel = ({ number }: { number: number }) => {
  return (
    <div className="explicit-label">
      <p className="text">Explicit Permission</p>
      <span className="count-wrapper">
        <p>{number}</p>
      </span>
    </div>
  );
};

export default ExplicitPermissionLabel;
