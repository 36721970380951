import React, { useState } from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';
import { FeeType, IFeeFormField } from '+types';
import { backwardAmountInput, stripNonNumeric } from '+utils';

import { feeTypes } from '../constants/plan-editor';
import OverlayInput from './OverlayInput';

export default function FeeFormField({
  disableControls,
  feeCategory,
  cardCategory,
  onChange,
  onBlur,
  setField,
  getField,
  errors,
  values,
  touched,
  prefix
}: IFeeFormField) {
  const [isCapped, setIsCapped] = useState(Boolean(values[cardCategory].fee[feeCategory].max));

  const handleChangeFeeIsCapped = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCapped(e.target.checked);
  };

  const handleChangeFeeIsNotCapped = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCapped(!e.target.checked);
    setField?.(`${cardCategory}.fee.${feeCategory}.max`, undefined);
  };

  const getOverlayText = (value: FeeType) => (value === 'percentage' ? '%' : values.currency);

  return (
    <fieldset className="stack-sm" aria-labelledby={`${feeCategory}_fee`}>
      <p id={`${feeCategory}_fee`} className="sr-only" data-role="label">
        {prefix} Fee
      </p>

      <div data-group="radio-label">
        <input
          name={`${cardCategory}.fee.${feeCategory}.active`}
          onChange={onChange}
          checked={values[cardCategory]?.fee?.[feeCategory].active === true}
          id={`${feeCategory}_fee_is_active`}
          type="checkbox"
          disabled={disableControls}
        />
        <label htmlFor={`${feeCategory}_fee_is_active`}>
          This fee is {values[cardCategory]?.fee?.[feeCategory].active === true ? 'active' : 'inactive'}
        </label>
      </div>

      <div data-group="select-and-input" className="mt-2">
        <div>
          <select
            {...getField?.(`${cardCategory}.fee.${feeCategory}.type`)}
            aria-labelledby={`${feeCategory}_fee`}
            className="form-control"
            data-state={errors?.[cardCategory]?.fee?.[feeCategory]?.type ? 'invalid' : ''}
            disabled={disableControls}
            onBlur={undefined}
          >
            {feeTypes.map(feetype => (
              <option
                value={feetype.value}
                key={feetype.value}
                hidden={['chargeback', 'issuance', 'subscription'].includes(feeCategory) && feetype.value === 'percentage'}
              >
                {feetype.label}
              </option>
            ))}
          </select>
          <FieldErrorMsg error={errors?.[cardCategory]?.fee?.[feeCategory]?.type as string} id={`${feeCategory}_fsa`} touched />
        </div>
        <OverlayInput
          id={`${feeCategory}_fsa`}
          touched
          error={errors?.[cardCategory]?.fee?.[feeCategory]?.amount as string}
          name={`${cardCategory}.fee.${feeCategory}.amount`}
          value={values[cardCategory]?.fee?.[feeCategory].amount}
          onChange={e => setField?.(`${cardCategory}.fee.${feeCategory}.amount`, backwardAmountInput(stripNonNumeric(e.target.value)))}
          overlayText={getOverlayText(values[cardCategory]?.fee?.[feeCategory].type)}
          placeholder="Value"
          aria-labelledby={`${feeCategory}_fee`}
          disabled={disableControls}
        />
      </div>

      <div data-group="radio-label" className="mt-2">
        <label htmlFor={`${feeCategory}_vat`}>Tax (VAT) has been considered and included in this fee</label>
        <input
          name={`${cardCategory}.fee.${feeCategory}.vat_inclusive`}
          onChange={onChange}
          id={`${feeCategory}_vat`}
          type="checkbox"
          checked={values[cardCategory]?.fee?.[feeCategory].vat_inclusive === true}
          disabled={disableControls}
        />
      </div>

      <div className="mt-2">
        <p data-role="label" id={`${feeCategory}_point_of_debit`}>
          Point of Debit
        </p>
        <div className="d-flex align-items-center" style={{ gap: '3rem' }} aria-labelledby={`${feeCategory}_point_of_debit`}>
          <div data-group="radio-label">
            <label htmlFor={`${feeCategory}_include_tax_immediately`}>Immediately</label>
            <input
              type="radio"
              onChange={onChange}
              id={`${feeCategory}_include_tax_immediately`}
              name={`${cardCategory}.fee.${feeCategory}.charge_interval`}
              value="once"
              checked={values[cardCategory]?.fee?.[feeCategory].charge_interval === 'once'}
              disabled={disableControls}
            />
          </div>
          <div data-group="radio-label">
            <label htmlFor={`${feeCategory}_include_tax_monthly`}>Monthly</label>
            <input
              name={`${cardCategory}.fee.${feeCategory}.charge_interval`}
              onChange={onChange}
              value="monthly"
              checked={values[cardCategory]?.fee?.[feeCategory].charge_interval === 'monthly'}
              type="radio"
              id={`${feeCategory}_include_tax_monthly`}
              disabled={disableControls}
            />
          </div>
        </div>
      </div>

      {values[cardCategory].fee?.[feeCategory].type === 'percentage' ? (
        <div className="fade-in stack-xs">
          <p data-role="label" id={`${feeCategory}_is_fee_capped`}>
            Is this fee capped?
          </p>
          <div className="d-flex align-items-center" style={{ gap: '3rem' }} aria-labelledby={`${feeCategory}_is_fee_capped`}>
            <div data-group="radio-label">
              <label htmlFor={`${feeCategory}_fee_is_capped`}>Yes, it is.</label>
              <input
                checked={isCapped === true}
                onChange={handleChangeFeeIsCapped}
                type="radio"
                id={`${feeCategory}_fee_is_capped`}
                disabled={disableControls}
              />
            </div>
            <div data-group="radio-label">
              <label htmlFor={`${feeCategory}_fee_is_not_capped`}>No, it&apos;s not.</label>
              <input
                checked={isCapped === false}
                onChange={handleChangeFeeIsNotCapped}
                type="radio"
                id={`${feeCategory}_fee_is_not_capped`}
                disabled={disableControls}
              />
            </div>
          </div>

          {isCapped ? (
            <div>
              <label className="font-weight-bold d-block" htmlFor={`${feeCategory}_capped_at`}>
                Capped at
              </label>
              <input
                name={`${cardCategory}.fee.${feeCategory}.max`}
                onChange={e => setField?.(`${cardCategory}.fee.${feeCategory}.max`, backwardAmountInput(stripNonNumeric(e.target.value)))}
                value={values[cardCategory]?.fee?.[feeCategory]?.max}
                onBlur={onBlur}
                id={`${feeCategory}_capped_at`}
                className="form-control"
                disabled={disableControls}
              />
              <FieldErrorMsg
                id={`${feeCategory}_capped_at`}
                error={errors?.[cardCategory]?.fee?.[feeCategory]?.max as string}
                touched={touched?.[cardCategory]?.fee?.[feeCategory]?.max as boolean}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </fieldset>
  );
}
