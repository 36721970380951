import { createBrowserHistory } from 'history';

const _ = createBrowserHistory();

const history = {
  ..._,
  previousHistory: null,

  setPrevious(path, info) {
    history.previousHistory = { path, type: info.type, data: info.data };
  },

  getPathInfo(type) {
    if (type !== history.previousHistory?.type) return null;
    return history.previousHistory?.data;
  },

  push(path, state, info = null) {
    history.previousHistory = { path: window.location.pathname, type: info?.type, data: info?.data };
    return _.push(path, state);
  }
};

export default history;
