import React from 'react';

import FieldErrorMsg from '+containers/Dashboard/Shared/FormError';

import '../index.scss';

interface IOverlayInput extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id: string;
  error?: string;
  showError?: boolean;
  touched: boolean;
  overlayText?: string;
}

export default function OverlayInput({ className, overlayText, id, error, touched, showError, ...props }: IOverlayInput) {
  return (
    <div className="stack-xxs">
      <div data-input-wrapper="">
        <input {...props} className={`form-control ${className}`} data-state={touched && error ? 'invalid' : ''} />
        <span>{overlayText}</span>
      </div>
      {showError ? <FieldErrorMsg id={id} error={error || ''} touched={touched} /> : null}
    </div>
  );
}
