import React, { useState } from 'react';
import { useQuery } from 'react-query';
import SettlementsConfig from '+shared/SettlementsConfig';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import { logError } from '+utils';
import CurrencyPicker from '+shared/CurrencyPicker';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import './index.scss';

const api = new APIRequest();

function SettlementsSettings() {
  const { feedbackInit } = useFeedbackHandler();
  const [activeCurrency, setActiveCurrency] = useState('NGN');

  const errorHandler = error => {
    logError(error);
    feedbackInit({
      message: `There's been an error getting default settlement configuration`,
      type: 'danger'
    });
  };

  const { refetch, isLoading, data } = useQuery('defaultSettlementConfigData', async () => api.getDefaultSettlementsConfig(), {
    onError: errorHandler
  });

  return (
    <div className="element-box">
      <div className="element-box-heading settlements-settings__heading">
        <div className="heading-box-mmd">
          <h5 className="form-header">Default Settlement Configuration</h5>
          <div className="form-desc no-underline mb-2">
            You can adjust the default settlement configuration for all merchants here. To change the configuration for a specific merchant,
            simply go to the ‘Settlements’ tab in the details page of that merchant.
          </div>
        </div>
      </div>

      <CurrencyPicker
        onChange={value => {
          setActiveCurrency(value);
        }}
        className="mt-2 mb-3"
        activeCurrency={activeCurrency}
        label={<strong>Set configuration for:</strong>}
        id="settlements-settings__currency-switch"
      />

      {isLoading ? (
        <LoadingPlaceholder type="table" background="#f5f6f6" />
      ) : (
        <SettlementsConfig data={data?.data} activeCurrency={activeCurrency} source="default" getConfig={refetch} />
      )}
    </div>
  );
}

export default SettlementsSettings;
