import React from 'react';

import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  label: string;
  sublabel: string;
  isSwitchValue: boolean;
  onChange: (value: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, sublabel, isSwitchValue, onChange }) => {
  return (
    <div className="switch-input">
      <div className="labels">
        <div className="switch-label"> {label} </div>
        <span className="sublabel">{sublabel}</span>
      </div>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={label}
          id={label}
          checked={isSwitchValue}
          onChange={e => onChange(e.target.checked)}
        />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
