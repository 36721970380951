import Bugsnag from '@bugsnag/js';
import { BulkActionType } from 'src/types';

declare global {
  interface Window {
    Pusher: any;
  }
}

type PusherData = {
  message: {
    paylaod: BulkActionType;
    message: string;
  };
};

let pusherClient: any = null;

const setupPusher = (
  unique_reference: string,
  eventName1: string,
  eventName2: string,
  eventName3: string,
  update: (status: 'processing' | 'completed' | 'delayed_processes', info: any) => void
): void => {
  if (!window.Pusher) {
    console.log('Pusher is not loaded properly. Please reload the page');
    return Bugsnag.notify('Pusher not loaded properly on client');
  }

  if (!pusherClient) {
    window.Pusher.logToConsole = false;
    pusherClient = new window.Pusher(process.env.REACT_APP_HERA_CROWN, {
      cluster: process.env.REACT_APP_ZEUS_THUNDER,
      forceTLS: true
    });
  }

  const channel = pusherClient.subscribe(unique_reference);

  const handlePusherError = (err: any) => {
    if (err?.error?.data?.code === 4004) {
      Bugsnag.notify('Pusher connection limit error');
    }
  };

  pusherClient.connection.bind('error', handlePusherError);

  channel.bind(eventName1, (data: PusherData) => {
    update('processing', data?.message);
  });

  channel.bind(eventName2, (data: PusherData) => {
    update('completed', data?.message);
  });

  channel.bind(eventName3, (data: PusherData) => {
    update('delayed_processes', data);
  });
};

export default setupPusher;
