import React from 'react';
import EmptyState from '+shared/EmptyState';
import ViewAndDownload from '+shared/ViewAndDownload';
import UserIcon from '+assets/img/dashboard/user.svg';
import { capitalizeRemovedash } from '+utils';
import Icon from '+containers/Dashboard/Shared/Icons';

interface IMerchantRepresentative {
  [key: string]: string;
}

interface IMerchantRepresentativesProps {
  representatives?: IMerchantRepresentative[] | null;
  profile?: Record<string, any>;
}
export default function MerchantRepresentatives({ representatives, profile }: IMerchantRepresentativesProps) {
  const validationError = (values) => {
    if (values?.is_valid === false) return true;
    if (!values?.name?.match) return true;
  };

  const renderValidationMessage = (documentValidation) => {
    const nameMatch = documentValidation?.name?.match;

    if (documentValidation.is_valid === false) {
      return (
        <div className="mt-2">
          <Icon name="close" stroke="#FC4343" />
          <span className="red-text ml-1 text-sm">Invalid</span>
        </div>
      );
    }
    if (!nameMatch) {
      return (
        <div className="mt-2">
          <Icon name="close" stroke="#FC4343" />
          <span className="red-text ml-1 text-sm">Representative name does not match</span>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {representatives ? (
        <div className={`details-wrapper ${profile?.kyc_status?.toLowerCase() === 'rejected' ? 'is-disabled' : ''}`}>
          {representatives.map((representative) => (
            <div className="mb-3">
              <div className="entry entry-doc mb-0" key={representative.name}>
                <div className="entry-doc__left">
                  <img alt="" src={UserIcon} />
                  <span className="bk-text">{representative.name}</span>

                  <span className="entry-pill">
                    {representative.role?.label} <br />
                    {representative?.shareholding_company_name}
                  </span>
                </div>
                <div className="entry-doc__right">
                  {representative.documents[0].id ? (
                    <>
                      <span className="bk-text">{capitalizeRemovedash(representative.documents[0].type)}</span>

                      <span
                        className={`${
                          (representative?.validation_result &&
                            representative?.validation_result?.[representative.documents[0].type]?.is_valid === false &&
                            'red-text') ||
                          'gn-text'
                        }`}
                      >
                        {representative.documents[0].id}
                      </span>

                      {(representative?.validation_result &&
                        (validationError(representative?.validation_result?.[representative.documents[0].type]) ? (
                          <Icon name="close" stroke="#FC4343" />
                        ) : (
                          <Icon name="check" stroke="#24B314" />
                        ))) ||
                        null}
                    </>
                  ) : (
                    <span className="gy-text">{representative.documents[0].file.description}</span>
                  )}

                  <span className="divider-sm" />
                  <ViewAndDownload
                    url={representative?.documents[0]?.file?.url}
                    info={representative.name}
                    type={representative?.documents[0]?.file?.description?.split('.').pop()}
                    filename={representative?.documents[0]?.file?.description?.split('.')[0]}
                    disableView={profile?.kyc_status?.toLowerCase() === 'rejected'}
                    disableDownload={profile?.kyc_status?.toLowerCase() === 'rejected'}
                  />
                </div>
              </div>
              {representative?.validation_result &&
                representative?.validation_result?.[representative.documents[0].type] &&
                validationError(representative?.validation_result?.[representative.documents[0].type]) &&
                renderValidationMessage(representative?.validation_result?.[representative.documents[0].type])}

              {representative?.validation_result &&
                !validationError(representative?.validation_result?.[representative.documents[0].type]) && (
                  <div className="mt-2">
                    <Icon name="check" stroke="#24B314" />
                    <span className="gn-text ml-1 text-sm">Valid</span>
                  </div>
                )}
            </div>
          ))}
        </div>
      ) : (
        <EmptyState message="No representatives information available." />
      )}

      {representatives && Object.keys(representatives || {}).length === 0 && (
        <EmptyState message="No representatives information available." />
      )}
    </>
  );
}