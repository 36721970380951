import React, { FC } from 'react';
import { capitalizeRemovedash } from '+utils';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+shared/Modal';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import './index.scss';

type TTrxBreakdownModalProp = {
  trx: {
    currency: string;
    amount_charged: string;
    amount_paid: string;
    fees: string;
    tax?: string;
    net_amount: string;
  };
  close: () => void;
};

const toolTips = {
  amount_charged: 'This is the total amount to be settled excluding fees and any reserves',
  amount_paid: 'This is the total amount paid including fees',
  fees: 'This is the sum of the transaction fees',
  tax: 'This is the tax deducted'
};

const TrxBreakdownModal: FC<TTrxBreakdownModalProp> = ({ trx, close }) => {
  return (
    <Modal
      close={close}
      size="md"
      heading="Amount Breakdown"
      description="This is a detailed breakdown of the amount on this transaction, including the individual components that contribute to the final value of the transaction."
      firstButtonText="Close"
      hideSecondButton
      content={
        <ul className="breakdown-modal">
          {Object.entries(trx).map(([key, value]) => (
            <li key={key}>
              <span>
                {capitalizeRemovedash(key)}
                {toolTips[key as keyof typeof toolTips] && (
                  <ToolTip type={capitalizeRemovedash(key)} message={toolTips[key as keyof typeof toolTips]} />
                )}
              </span>
              <span className={key === 'net_amount' ? 'total' : ''}>
                {key !== 'currency' && value} {trx?.currency}
              </span>
            </li>
          ))}
        </ul>
      }
    />
  );
};

export default TrxBreakdownModal;
