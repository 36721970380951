/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useReducer, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { capitalizeRemovedash, formatAmount } from '+utils';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import pen from '+assets/img/dashboard/pen.svg';
import { EditLimitModal, ConfirmEditModal, EditCompletedModal } from './FvaModal';
import { TAccountLimits, TLimitsPayload, TLimitsApiError, tiers, TAccountType, TModalType, TLimits } from '../data';
import './fixed-virtual-accounts.scss';

const api = new APIRequest();

const renderLimit = (duration: string, amount: string | number, currency: string): React.JSX.Element => (
  <div className="limit">
    <span>Max. Funding Limit ({duration})</span>
    <span className="text-gray">
      {formatAmount(amount)} {currency.toUpperCase()}
    </span>
  </div>
);

function FixedVirtualAccounts({ currentLimit, currency }: { currentLimit: TAccountLimits; currency: string }) {
  const [modalType, setModalType] = useState<TModalType>(null);
  const [newLimits, setNewLimits] = useState({} as { currency: string; data: TLimitsPayload });
  const [newLimitCap, setNewLimitCap] = useState({} as TLimits);
  const { feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(() => api.updateVirtualBankAccountLimits(newLimits), {
    onSuccess: () => {
      queryClient.invalidateQueries('TRANSACTION_LIMITS');
      setTimeout(() => setModalType('completed'), 0);
    },
    onError: (error: TLimitsApiError) => {
      feedbackInit({
        type: 'danger',
        message: error?.response?.data?.message || 'Unable to update Transaction Limit'
      });
    }
  });

  const openEditModal = (limit: typeof newLimits.data.limits, tier: 'tier_one' | 'tier_two', type: 'individual' | 'corporate') => {
    setNewLimits((prevState) => ({ ...prevState, data: { type, tier, limits: limit } }));
    setNewLimitCap(currentLimit[type]?.[tier === 'tier_one' ? 'tier_two' : 'tier_one'] || ({} as TLimits));

    setModalType('edit');
  };

  // eslint-disable-next-line no-shadow
  const formSubmit = (limits: TLimits) => {
    setNewLimits((prevState) => ({ currency, data: { ...prevState.data, limits } }));
    setModalType('confirm');
  };

  return (
    <>
      <div className="os-tabs-controls os-tabs-complex mx-0 mt-5">
        <ul className="nav nav-tabs px-0 mb-2">
          {['Fixed Virtual Account'].map((paymentMethod) => (
            <li className="nav-item" key={paymentMethod}>
              <button type="button" className="nav-link active" data-toggle="tab" onClick={() => null}>
                <span className="tab-label">{capitalizeRemovedash(paymentMethod)}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="fva">
        <h6>Default Limits on Dashboard</h6>

        {Object.entries(currentLimit).map(([type, limitObj], i) => {
          return Object.entries(limitObj).map(([tier, limitsObject]) => {
            return (
              <div className="fva__section" data-testid="limit-ui" key={tier}>
                <div className="heading">
                  <h6>
                    Limits for Tier {tiers(tier)} {type} Account
                  </h6>

                  <button
                    type="button"
                    disabled={!!modalType}
                    data-testid="open-edit-modal"
                    aria-label={`open modal to edit ${type} Tier ${tiers(tier)} limit`}
                    onClick={() =>
                      openEditModal(limitsObject as typeof newLimits.data.limits, tier as 'tier_one' | 'tier_two', type as TAccountType)
                    }
                  >
                    <img src={pen} alt="edit limit icon" /> <span>Edit Limit</span>
                  </button>
                </div>
                <p className="text-gray">This is the tier {tiers(tier)} transaction limit configuration for this account type</p>
                <div className="content">
                  {renderLimit('Per Transaction', limitsObject.single, currency)}
                  {renderLimit('Daily', limitsObject.daily, currency)}
                  {renderLimit('Monthly', limitsObject.monthly, currency)}
                </div>
              </div>
            );
          });
        })}
      </div>

      {modalType &&
        {
          edit: <EditLimitModal newLimit={newLimits.data} newLimitCap={newLimitCap} formSubmit={formSubmit} setModalType={setModalType} />,
          confirm: <ConfirmEditModal updateLimits={mutateAsync} setModalType={setModalType} />,
          completed: <EditCompletedModal setModalType={setModalType} />
        }[modalType]}
    </>
  );
}

export default FixedVirtualAccounts;
