import React from 'react';
import { usePDF } from '@react-pdf/renderer';
import Filesaver from 'file-saver';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import { capitalizeRemovedash, getDate, getTime, switchStatus } from '+utils';
import { ReceiptPropsType } from '../../data';
import ReceiptTemplate from './ReceiptTemplate';

type ReceiptModalProps = {
  isOpen: boolean;
  onClose: () => void;
  receiptData: ReceiptPropsType;
};

const ReceiptModal = ({ isOpen, onClose, receiptData }: ReceiptModalProps) => {
  const { currency, amount, reference, merchant_name, status, type, created_at } = receiptData;

  const receipt = <ReceiptTemplate receiptData={receiptData} />;
  const [docInstance] = usePDF({ document: receipt });
  const blob = docInstance.blob || '';

  const fileName = `receipt-${reference}`;
  return (
    <Modal
      visible={isOpen}
      close={onClose}
      heading="View Receipt"
      size="md"
      secondButtonText="Download"
      secondButtonAction={() => Filesaver.saveAs(blob, fileName)}
      secondButtonActionIsTerminal={false}
      showSecondButton
      description="View receipt details below."
      content={
        <div className="receipt-container">
          <Icon name="receipt" />
          <h1>
            {currency}&nbsp;{amount}
          </h1>
          <p>
            Payment has been received for <strong>{reference}.</strong>
            <br />
            View additional details below.
          </p>
          <div className="receipt-details-box">
            <div className="receipt-details__row">
              <span>Merchant</span>
              <p>{merchant_name}</p>
            </div>
            <div className="receipt-details__row">
              <span>Amount</span>
              <p>{amount}</p>
            </div>
            <div className="receipt-details__row">
              <span>Invoice Reference</span>
              <Copyable text={reference} copyText="" spanClassName="" buttonClassName="" textToCopy={reference} />
            </div>
            <div className="receipt-details__row" style={{ margin: '10px 0' }}>
              <span>Status</span>
              <div>
                <span className={`status-pill smaller align-baseline ${switchStatus(status)}`} />
                <span className={status === 'complete' ? 'complete-class' : ''}>{capitalizeRemovedash(status) || 'N/A'}</span>
              </div>
            </div>
            <div className="receipt-details__row">
              <span>Fee type</span>
              <p>{capitalizeRemovedash(type)}</p>
            </div>
            <div className="receipt-details__row">
              <span>Date,Time</span>
              <span>
                <strong>{getDate(created_at)}</strong>, {getTime(created_at)}
              </span>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ReceiptModal;
