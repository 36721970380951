/* eslint-disable react/no-children-prop */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SettlementDetail from './SettlementDetail';
import SettlementHistory from './SettlementHistory';

import './index.scss';

export default function Settlements() {
  return (
    <div className="content-i">
      <div className="content-box">
        <Switch>
          <Route path="/dashboard/settlements/home" component={SettlementHistory} />
          <Route path="/dashboard/settlements/:id">
            <SettlementDetail />
          </Route>
          <Redirect path="/dashboard/settlements" to="/dashboard/settlements/home" />
        </Switch>
      </div>
    </div>
  );
}
