import ViewAndDownload from '+containers/Dashboard/Shared/ViewAndDownload';
import Icon from '+shared/Icons';
import React, { FC } from 'react';
import { TAccountHolderDocument } from '../TypesAccountHolders';

const isPdf = ['Proof of Address', 'Account Statement'];

const KycDocuments: FC<{ doc: TAccountHolderDocument }> = ({ doc }) => {
  const obj = {
    ...(!doc?.identification_back ? { 'ID Document': doc?.identification?.url } : {}),
    'Proof of Address': {
      link: doc?.proof_of_address?.document.url,
      type: doc?.proof_of_address?.type
    },
    'Account Statement': doc?.source_of_inflow?.url,
    ...(doc?.selfie?.url && { Selfie: doc?.selfie?.url }),
    ...(doc?.identification_back && { 'ID Image (front)': doc?.identification?.url, 'ID Image (back)': doc?.identification_back.url })
  };

  return (
    <div data-testid="kyc-doc-group" className="kyc-doc-group">
      {Object.entries(obj).map(([key, value]) => {
        const isObj = typeof value === 'object';
        const url = isObj ? value.link : value;

        return value ? (
          <div className="doc" key={key}>
            <div className="doc-type">
              <Icon style={{ marginRight: 5 }} name={key === 'Selfie' ? 'picture' : 'file'} />
              <span className="grey-text">
                {key}
                {isObj && `_(${value.type})`}
              </span>
            </div>

            <span className="view-doc">
              <Icon name="eye" />
              <ViewAndDownload
                disableView={undefined}
                url={url}
                info={key}
                type={isPdf.includes(key) ? 'pdf' : 'jpg'}
                filename={key}
                disableDownload
                showDownloadBtn={false}
              />
            </span>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default KycDocuments;
