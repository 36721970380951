import React from 'react';
import './index.scss';

interface ICustomRadioProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  text: React.ReactNode | string;
  onChange: () => void;
  name: string;
}

const CustomRadio = ({ className, checked, text, onChange, disabled, name } : ICustomRadioProps ) => {
  return (
    <label className={`radio__custom ${className} ${disabled ? 'disabled' : ''}`} htmlFor={name}>
      <input
        type="radio"
        className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        defaultChecked={checked}
        name={name}
        onClick={() => {
          if (disabled) return;
          onChange(name);
        }}
      />
      <button
        aria-label="button"
        onClick={() => {
          if (disabled) return;
          onChange(name);
        }}
        type="button"
      >
        {text}
      </button>
    </label>
  );
};

export default CustomRadio;
