import React from 'react';

import { capitalize, capitalizeRemovedash, getDate, getTime, payinMethodMapping, switchStatus } from '+utils';

// eslint-disable-next-line import/prefer-default-export
export const chargebackDetails = ({ data }: { data: any }) => {
  const {
    currency,
    status,
    processor,
    source,
    payment_source,

    created_at,
    reason,
    deadline
  } = data || {};

  return {
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 2px 0' }}
        />
        <span style={{ margin: '0' }}>{capitalizeRemovedash(status) || 'Not Available'}</span>
      </>
    ),
    breakdown: {
      currency_charged: currency,
      channel: payinMethodMapping[source?.type as keyof typeof payinMethodMapping] || 'Not Available',
      date_contested: `${getDate(created_at)}, ${getTime(created_at)}`,
      date_completed: `${capitalize(payment_source?.channel || 'Not available')}`,
      processor: `${capitalizeRemovedash(processor) || 'Not available'}`,
      reason_for_chargeback: capitalize(reason) || 'Not Available',
      deadline: `${getDate(deadline)}, ${getTime(deadline)}`
    }
  };
};
