import { useLocation } from 'react-router-dom';

const usePageHeader = () => {
  const location = useLocation();

  const getPageHeader = () => {
    if (location.pathname === '/dashboard/home') {
      return 'Home';
    }
    if (location.pathname === '/dashboard/analytics') {
      return 'Dashboard';
    }
    if (location.pathname === '/dashboard/pay-ins') {
      return 'Pay-ins';
    }
    if (location.pathname === '/dashboard/payouts') {
      return 'Payouts';
    }
    if (location.pathname === '/dashboard/disputes') {
      return 'Disputes';
    }
    if (location.pathname.includes('/dashboard/reversals')) {
      return 'Reversals';
    }
    if (location.pathname === '/dashboard/virtual-accounts') {
      return 'Virtual Accounts';
    }
    if (location.pathname.includes('/dashboard/payouts/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/pay-ins/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/reversals')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/disputes/chargeback/add')) {
      return 'Disputes';
    }
    if (location.pathname.includes('/dashboard/disputes/chargeback/summary')) {
      return 'Upload Summary';
    }
    if (location.pathname.includes('/dashboard/disputes/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/virtual-accounts/')) {
      return 'Virtual Account Details';
    }
    if (location.pathname.includes('/dashboard/settlement-payouts')) {
      return 'Settlement Payouts';
    }
    if (location.pathname.includes('/dashboard/settlements/merchant')) {
      return 'Merchant Settlements';
    }
    if (location.pathname.includes('/dashboard/settlements')) {
      return 'Settlements';
    }
    if (location.pathname.includes('/dashboard/settlement_payouts')) {
      return 'Settlement Payouts';
    }
    if (location.pathname === '/dashboard/audit-logs') {
      return 'Audit Logs';
    }
    if (location.pathname === '/dashboard/webhooks') {
      return 'Webhooks';
    }
    if (location.pathname === '/dashboard/settings') {
      return 'Settings';
    }
    if (location.pathname === '/dashboard/merchants') {
      return 'Merchants';
    }
    if (location.pathname === '/dashboard/card-issuance') {
      return 'Card Issuance';
    }
    if (location.pathname === '/dashboard/audit-logs') {
      return 'Audit Logs';
    }
    if (location.pathname.includes('/dashboard/payouts/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/pay-ins/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/disputes/chargeback/add')) {
      return 'Disputes';
    }

    if (location.pathname.includes('/dashboard/disputes/')) {
      return 'Dispute Details';
    }
    if (location.pathname.includes('/dashboard/payment-reversals')) {
      return 'Reversals';
    }
    if (location.pathname.includes('/dashboard/virtual-accounts/')) {
      return 'Virtual Account Details';
    }
    if (location.pathname.includes('/dashboard/settlement-payouts')) {
      return 'Settlement Payouts';
    }
    if (location.pathname.includes('/dashboard/settlements')) {
      return 'Settlements';
    }
    if (location.pathname.includes('/dashboard/merchant/')) {
      return 'Merchant Details';
    }
    if (location.pathname.includes('/dashboard/kyc-approval/')) {
      return 'Merchant KYC';
    }
    if (location.pathname.includes('/dashboard/settings/fees')) {
      return 'Default Fees';
    }
    if (location.pathname.includes('/dashboard/settings')) {
      return 'Settings';
    }
    if (location.pathname.includes('/dashboard/card-issuance/merchants/')) {
      return 'Issuing Merchant';
    }
    if (location.pathname.includes('/dashboard/card-issuance/cards/')) {
      return 'Virtual Card Details';
    }
    if (location.pathname.includes('/dashboard/card-issuance/billing/')) {
      return 'Card Issuance';
    }
    if (location.pathname.includes('/dashboard/card-issuance/chargebacks/')) {
      return 'Chargeback Details';
    }
    if (location.pathname.includes('/dashboard/user')) {
      return 'Users';
    }
    if (location.pathname.includes('/dashboard/utilities')) {
      return 'Utilities';
    }
    if (location.pathname.includes('/dashboard/currency-exchange')) {
      return 'Currency Exchange';
    }
    if (location.pathname.includes('/dashboard/product-config')) {
      return 'Product Configuration';
    }
    if (location.pathname.includes('/dashboard/bulk-payouts')) {
      return 'Bulk Payouts';
    }
    if (location.pathname.includes('/dashboard/bulk-payouts/')) {
      return 'Bulk Payout Details';
    }
    if (location.pathname.includes('/dashboard/partners-balance/transaction/')) {
      return 'Transaction Details';
    }
    if (location.pathname.includes('/dashboard/partners-balance')) {
      return 'Partner Balance';
    }
    if (location.pathname.includes('/dashboard/paused/payouts')) {
      return 'Paused Payments';
    }
    if (location.pathname.includes('/dashboard/reports')) {
      return 'Reports';
    }
    if (location.pathname.includes('/dashboard/identity')) {
      return 'Identity';
    }
    if (location.pathname.includes('/dashboard/paused/payouts')) {
      return 'Paused Payments';
    }
    return null;
  };

  return getPageHeader();
};

export default usePageHeader;
