/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import ToolTip from '+shared/Tooltip';
import {
  ExplicitPermissionType,
  IPermission,
  IRoleDetails,
  IUserDetails,
  PermissionColumnType,
  PermissionModalType,
  SelectedPermissionType
} from '+types';
import { capitalize, capitalizeRemovedash, isAllowed, logError } from '+utils';

import ConfirmInvitation from './ConfirmInvitation';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const apiRequest = new APIRequest();

interface IManagePermissionModalProps {
  close: () => void;
  modalType: PermissionModalType;
  selectedPermission?: SelectedPermissionType | null;
  userDetails?: IUserDetails | IRoleDetails | { id: number; adminPermissions: IPermission[] } | { permissions: (string | number)[] } | null;
  action?: (value?: (string | number)[]) => void;
  usePermissionId?: boolean;
  updatedPermissions?: (number | string)[];
  readOnly?: boolean;
  from?: string;
  currentUsersExplicitPermissions?: ExplicitPermissionType[];
  type?: string;
}

const ManagePermissionModal = ({
  selectedPermission,
  close,
  modalType,
  action = () => null,
  userDetails = null,
  usePermissionId = false,
  updatedPermissions = [],
  readOnly = false,
  from = '',
  currentUsersExplicitPermissions = [],
  type = 'user'
}: IManagePermissionModalProps) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [role, setRole] = useState<string | number>('');
  const [savePermission, setSavePermission] = useState(false);
  const [confirmDeleteExplicitPermission, setConfirmDeleteExplicitPermission] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<(string | number)[]>([]);
  const [defaultPermissions, setDefaultPermissions] = useState<(string | number)[]>([]);
  const animatedComponents = makeAnimated();
  const userAccess = useSetUserAccess();
  const allPermissions = queryClient.getQueryData('PERMISSIONS') as IPermission[];
  const userId = (userDetails as IUserDetails)?.id as number;

  const { data: resolvedData, refetch } = useQuery(['ROLES'], () => apiRequest.getAllRoles(1, 1000), {
    onError: () => {
      feedbackInit({
        title: 'Users',
        message: 'There has been an error getting roles',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    },
    enabled: ['changeRole', 'confirmPermission', 'confirmInvitation'].includes(modalType)
  });

  const addOrUpdateUserRoleMutation = useMutation(
    (data: { user_id: number; roles: (number | string)[] }) => apiRequest.addOrUpdateUserRole(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${userId}_USER_DETAILS_${from?.toUpperCase()}`);
        queryClient.invalidateQueries(`${userId}_USER_PERMISSIONS_${from.toUpperCase()}`);
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this user's role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const addAndUpdatePermissionToUserMutation = useMutation(
    (data: {
      user_id: number;
      permissions: {
        slug: string | number;
        allowed: boolean;
      }[];
    }) => apiRequest.addOrUpdatePermissionToUser(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${userId}_USER_DETAILS_${from?.toUpperCase()}`);
        queryClient.invalidateQueries(`${userId}_USER_PERMISSIONS_${from.toUpperCase()}`);
        action();
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this user's permissions.`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const createUserRoleMutation = useMutation((data: { name: string; permissions: string[] }) => apiRequest.createUserRole(data), {
    onSuccess: data => {
      if (data) {
        addOrUpdateUserRoleMutation.mutateAsync({ user_id: userId as number, roles: [data?.id] });
        action();
      }
      queryClient.invalidateQueries(`${userId}_USER_DETAILS_${from?.toUpperCase()}`);
      queryClient.invalidateQueries(`${userId}_USER_PERMISSIONS_${from.toUpperCase()}`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error updating this user's role`,
        type: 'danger',
        componentLevel: true
      });
    }
  });
  useEffect(() => {
    if (userDetails) {
      if ((userDetails as IUserDetails)?.adminPermissions?.length) {
        const defaultUserPermission = (userDetails as IUserDetails)?.adminPermissions?.map(value =>
          usePermissionId ? value?.id : value?.slug
        ) as number[];
        if (defaultUserPermission?.length) {
          setSelectedPermissions(defaultUserPermission);
          setDefaultPermissions(defaultUserPermission);
        }
      } else if ((userDetails as IRoleDetails)['user_role.permissions']?.length ?? (userDetails as IRoleDetails)?.permissions?.length) {
        const defaultUserPermission = (userDetails as IRoleDetails)['user_role.permissions'] ?? (userDetails as IRoleDetails)?.permissions;
        if (defaultUserPermission?.length) {
          if (usePermissionId && typeof defaultUserPermission[0] !== 'number') {
            const extractIdForStringPermission = allPermissions
              .filter(allPermission => defaultUserPermission.includes(allPermission.slug))
              .map(item => item.id);
            setSelectedPermissions(extractIdForStringPermission);
            setDefaultPermissions(extractIdForStringPermission);
          } else {
            setSelectedPermissions(defaultUserPermission);
            setDefaultPermissions(defaultUserPermission);
          }
        }
      }
    }
  }, [userDetails]);
  const handlePermissionSelection = useCallback(
    (currentPermission: string | number) => {
      if (selectedPermissions.includes(currentPermission)) {
        setSelectedPermissions(selectedPermissions.filter(permission => permission !== currentPermission));
      } else {
        setSelectedPermissions([...selectedPermissions, currentPermission]);
      }
    },
    [selectedPermissions]
  );

  const renderCheckbox = (availableColumnFromPermission: PermissionColumnType[]) => {
    const columns = ['view', 'export', 'update', 'create', 'approve', 'process'];
    const modifiedAvailableColumnFromPermission = columns.map(item => {
      const availablePermission = availableColumnFromPermission.find(column => column.slug.split('.')[1] === item);
      if (availablePermission && userAccess) {
        return {
          ...availablePermission,
          // the check after the "OR" is to prevent currently signed in user to assign permissions they don't have.
          disabled: !!readOnly || !userAccess[availablePermission?.slug]
        };
      }
      return { ...availablePermission, disabled: true };
    });
    return (
      <>
        {modifiedAvailableColumnFromPermission.map(column => (
          <td className="table-data-td" key={`${column?.id || column?.slug}-${Math.floor(1000 + Math.random() * 8000)}`}>
            <div className="form-check mb-1 table-data-check">
              <input
                className={`form-check-input ${
                  column.isExplicit || (!defaultPermissions.includes(column.id as number) && type === 'user') ? 'explicit-input' : ''
                }`}
                type="checkbox"
                checked={
                  ((column?.id || column?.slug) &&
                    selectedPermissions.includes((usePermissionId ? column?.id : column?.slug) as string | number)) as boolean
                }
                disabled={column.disabled}
                onChange={() => {
                  handlePermissionSelection((usePermissionId ? column.id : column.slug) as string | number);
                }}
              />
            </div>
          </td>
        ))}
      </>
    );
  };

  const roleOption = (resolvedData?.data || [])?.map((item: IRoleDetails) => ({
    value: item.id,
    label: `${item.category !== 'custom' ? 'System-' : ''}${capitalize(item.name)}`
  }));

  // eslint-disable-next-line react/no-unstable-nested-components
  const ConfirmDeleteExplicitPermission = () => {
    return (
      <div className="form-check-cont --explicit">
        <label className="form-text-format form-check-label checkbox-text">
          <input
            data-testid="save-state-input"
            className="form-check-input"
            type="checkbox"
            onChange={() => setConfirmDeleteExplicitPermission(!confirmDeleteExplicitPermission)}
            checked={confirmDeleteExplicitPermission}
          />
          Yes I understand the implication of this action
        </label>
      </div>
    );
  };

  const modalDetails = {
    changeRole: {
      heading: 'Change the role of this user?',
      isScrollable: false,
      description:
        'If you change the role of a user, the permissions tied to that role would be assigned to that user. Please make sure that you are assigning the appropriate role to the user.',
      content: (
        <div className="form-group filter-object filter-object-lg" style={{ zIndex: 1000, fontWeight: 600 }}>
          <label htmlFor="role"> Role</label>
          <Select
            id="role"
            aria-label="role"
            placeholder="Select a role"
            components={animatedComponents}
            options={roleOption}
            styles={{
              control: (styles, { isFocused }) => ({
                ...styles,
                fontSize: '13px',
                fontWeight: 300,
                border: isFocused ? '' : '2px solid #dde2ec'
              }),
              option: (styles, { isSelected }) => {
                return {
                  ...styles,
                  fontSize: '13px',
                  fontWeight: 400,
                  zIndex: 1000,
                  backgroundColor: isSelected ? '#2376F3' : 'inherit',
                  '&:hover': { backgroundColor: isSelected ? '#2376F3' : 'rgb(222, 235, 255)' }
                };
              }
            }}
            onChange={value => setRole((value as { value: number; label: string }).value)}
          />
        </div>
      ),
      completedHeading: 'Done!',
      completedDescription: 'You have successfully changed the role of this user.',
      secondButtonText: 'Update',
      size: 'md',
      secondButtonAction: () => {
        return addOrUpdateUserRoleMutation.mutateAsync({
          user_id: userId,
          roles: [role]
        });
      },
      secondaryCompletedModal: true
    },
    confirmPermission: {
      heading: 'Confirm changes to permissions?',
      description:
        'Please confirm that you want the changes you have made to this user’s permissions to take effect. This action cannot be undone.',
      content: (
        <ConfirmInvitation
          checkInvite
          saveState={savePermission}
          saveStateToggle={e => setSavePermission(e.target.checked)}
          roleName={roleName}
          getRoleName={setRoleName}
        />
      ),
      secondButtonText: 'Save & Confirm',
      completedHeading: 'Confirmed!',
      completedDescription: 'The changes you made to this user’s permission have been confirmed.',
      size: 'md',
      secondButtonAction: () => {
        const updatedPermissionsName = allPermissions?.filter(item => updatedPermissions.includes(item.id))?.map(item => item.slug);
        if (savePermission) {
          return createUserRoleMutation.mutateAsync({ name: `Custom-${roleName}`, permissions: updatedPermissionsName });
        }

        const modifiedRemovedPermission = defaultPermissions
          .filter(permissionName => !updatedPermissionsName.includes(permissionName as string))
          .map(item => ({
            slug: item,
            allowed: false
          }));

        const modifiedSelectedPermission = updatedPermissionsName
          .filter(permissionName => !defaultPermissions.includes(permissionName))
          .map(item => ({
            slug: item,
            allowed: true
          }));

        const mergedModifiedPermission = [...modifiedRemovedPermission, ...modifiedSelectedPermission];

        return addAndUpdatePermissionToUserMutation.mutateAsync({
          user_id: userId,
          permissions: [...new Set(mergedModifiedPermission)]
        });
      },
      secondButtonDisable: savePermission ? !roleName : false,
      secondaryCompletedModal: true,
      equalFooterBtn: true,
      headerBottomBorder: false,
      formCenter: true
    },
    deleteExplicitPermission: {
      heading: 'Delete all explicit permissions?',
      description: 'Please confirm that you want the clear all explicit permissions extended to users. This action cannot be undone.',
      content: <ConfirmDeleteExplicitPermission />,
      secondButtonText: 'Yes, Delete',
      completedHeading: 'Deleted!',
      completedDescription: 'The changes you made to this user’s permission have been confirmed.',
      size: 'mdBase',
      secondButtonDisable: !confirmDeleteExplicitPermission,
      secondaryCompletedModal: true,
      equalFooterBtn: true,
      headerBottomBorder: false,
      formCenter: true,
      secondButtonColor: 'red',
      secondButtonAction: () => {
        const explicitPermissions = currentUsersExplicitPermissions?.map(item => ({ slug: item.slug, allowed: false }));

        const mergedModifiedPermission = [...explicitPermissions];

        return addAndUpdatePermissionToUserMutation.mutateAsync({
          user_id: userId,
          permissions: [...new Set(mergedModifiedPermission)]
        });
      }
    }
  };
  const modalActions = () => {
    let content;
    switch (modalType) {
      case 'updatePermission':
        content = {
          heading: `Permissions for ‘${capitalizeRemovedash(selectedPermission?.name || '')}’`,
          description: `These are the possible permissions for the ‘${capitalizeRemovedash(
            selectedPermission?.name || ''
          )}’ entity on the dashboard.`,
          size: 'xl',
          content: (
            <div className="feedback-modal">
              <p>
                Each permission has multiple actions represented as checkboxes. A filled checkbox means the permission is enabled, an empty
                checkbox means the action does not apply and a disabled checkbox means you cannot interact with that permission.
              </p>

              <div className="feedback-modal-user-check">
                <table className="table table-lightborder">
                  <thead className="">
                    <tr>
                      <th className="lighter">&apos;&apos;</th>
                      <th className="table-header">Can View</th>
                      <th className="table-header">Can Export</th>
                      <th className="table-header">Can Modify</th>
                      <th className="table-header">Can Create</th>
                      <th className="table-header">Can Approve</th>
                      <th className="table-header">Can Process</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPermission?.children?.map(child => (
                      <tr key={`${Math.floor(1000 + Math.random() * 9000)}-${Object.keys(child)[0]}`}>
                        <td className="table-data">
                          <span>{capitalizeRemovedash(Object.keys(child)[0])}</span>
                          <ToolTip type="permissions" image={InfoIcon} message={Object.values(child)[0][0]?.description} />
                        </td>
                        {renderCheckbox(Object.values(child)[0])}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Save',
          firstButtonAction: close,
          secondButtonAction: () => action(selectedPermissions),
          secondButtonActionIsTerminal: false,
          secondButtonDisable:
            readOnly ||
            !isAllowed(userAccess, [
              'admin_users.update',
              'custom_roles.create',
              'system_roles.update',
              'admin_user_permissions.update',
              'my_custom_roles.update',
              'custom_roles.update'
            ])
        };

        return {
          close,
          themeColor: '',
          ...content
        };
      case 'changeRole':
      case 'confirmPermission':
      case 'confirmInvitation':
      case 'deleteExplicitPermission':
        content = { ...modalDetails[modalType as keyof typeof modalDetails] };
        return {
          close,
          ...content
        };
      default:
        return {};
    }
  };
  return <Modal isScrollable {...(modalActions() as IModalProps)} />;
};

export default ManagePermissionModal;
