import React from 'react';
import './index.scss';

interface IHeroSectionProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  button?: {
    name: string;
    action: () => void;
    color: 'primary' | 'secondary' | 'success';
    icon?: JSX.Element;
  };
}

export default function HeroSection({ title, description, button }: IHeroSectionProps) {
  return (
    <div className="ihs">
      <div className="ihs__content">
        <h1 className="ihs__title">{title}</h1>
        <p className="ihs__description">{description}</p>
      </div>
      {button && (
        <div className="ihs__btn-wrapper">
          <button className={`ihs__btn ${button?.color}`} onClick={button?.action}>
            {button?.icon}
            {button?.name}
          </button>
        </div>
      )}
    </div>
  );
}
