/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import useStore from '+store/zustand';
import { getDate, getTime, history } from '+utils';
import Table from '+shared/Table';
import dayjs from 'dayjs';
import { BulkActionType, BulkInfoType, NotificationType } from '+types';
import CheckedIcon from '+assets/img/dashboard/check-green.svg';
import ProcessSpinnerIcon from '+assets/img/dashboard/process-spinner.svg';
import './index.scss';

export default function Utilities(): JSX.Element {
  const bulkInfo = useStore((state: { bulkInfo: BulkInfoType }) => state.bulkInfo);
  const processingInfo = bulkInfo?.data?.filter((item: BulkActionType) => item.status === 'in_progress');
  const delayedProcesses = sessionStorage.getItem('delayedProcesses');
  const parsedDelayedProcesses = delayedProcesses ? JSON.parse(delayedProcesses) : null;

  const mapActionType: Record<string, { type: string; link: string; action: string; activeTab?: string }> = {
    settlement_approval: {
      type: 'Settlement',
      link: '/dashboard/settlements/home',
      action: 'Approve Settlement',
      activeTab: 'ready'
    },
    settlement_processing: {
      type: 'Settlement',
      link: '/dashboard/settlements/home',
      action: 'Settle Settlement',
      activeTab: 'approved'
    },
    payin: {
      type: 'Payins',
      link: '/dashboard/pay-ins/',
      action: '',
      activeTab: ''
    },
    payout: {
      type: 'Payouts',
      link: '/dashboard/payouts/',
      action: '',
      activeTab: ''
    }
  };

  return (
    <div className="content-i element-box">
      <div className="content-box utility-heading-box">
        <div className="utility-heading-content">
          <div className="form-desc" style={{ border: 'none', marginBottom: '0' }}>
            This page serves as the central hub for some essential applications and temporary utilities used within this dashboard.
          </div>
        </div>
        {processingInfo?.length > 0 && (
          <>
            <h6 className="form-header">Batch Processes</h6>
            <Table
              hideTableHeadings
              header={null}
              hasFilter={false}
              className="utility-table-content"
              data={processingInfo}
              renderFields
              tableWrapperClassName="table-responsive"
              emptyStateMessage="There are no ongoing batch processes."
              type="utilities"
              hasPagination={0}
            >
              {(iter: BulkActionType) => {
                return {
                  data: {
                    message: (
                      <span className="utility-p mb-0">
                        <img
                          alt={iter?.status === 'in_progress' ? 'ProcessSpinnerIcon' : 'CheckedIcon'}
                          src={iter?.status === 'in_progress' ? ProcessSpinnerIcon : CheckedIcon}
                          className={iter?.status === 'in_progress' ? 'rotate' : ''}
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Bulk Action for {mapActionType[iter?.type].action}
                      </span>
                    ),
                    name: <span className="mb-0">{iter?.user?.name}</span>,
                    items: (
                      <span className="mb-0">
                        <span>{iter?.references?.length}</span> Items
                      </span>
                    ),
                    created_at: (
                      <span className="utility-p mb-0">
                        {getDate(iter?.createdAt)}, <span>{getTime(iter.createdAt)}</span>{' '}
                      </span>
                    ),
                    type: (
                      <span className="utility-p mb-0">
                        <span> {mapActionType[iter?.type]?.type} </span>
                      </span>
                    ),
                    source: (
                      <button
                        type="button"
                        className="btn btn-link p-0 font-weight-bold"
                        onClick={() =>
                          history.push(mapActionType[iter?.type]?.link, {
                            type: mapActionType[iter?.type]?.type,
                            data: { activeTab: mapActionType[iter?.type]?.activeTab }
                          })
                        }
                      >
                        <span>Go to source page</span>
                      </button>
                    )
                  }
                };
              }}
            </Table>
          </>
        )}

        {parsedDelayedProcesses?.length > 0 && (
          <>
            <h6 className="form-header">Delayed Processes</h6>
            <Table
              hideTableHeadings
              header={null}
              hasFilter={false}
              className="utility-table-content"
              data={parsedDelayedProcesses}
              renderFields
              tableWrapperClassName="table-responsive"
              emptyStateMessage="There are no ongoing delayed processes."
              type="utilities"
              hasPagination={0}
            >
              {(iter: NotificationType) => {
                return {
                  data: {
                    message: (
                      <span
                        className="utility-p mb-0"
                        style={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <img alt="ProcessSpinnerIcon" src={ProcessSpinnerIcon} className="rotate" role="status" aria-hidden="true" />
                        {`${iter?.message.slice(0, 38)}...`}
                      </span>
                    ),
                    created_at: (
                      <span className="utility-p mb-0">
                        {getDate(iter?.timestamp)}, <span>{getTime(iter?.timestamp)}</span>{' '}
                      </span>
                    ),
                    type: (
                      <span className="utility-p mb-0">
                        <span> {mapActionType[iter?.type]?.type} </span>
                      </span>
                    ),
                    source: (
                      <button
                        type="button"
                        className="btn btn-link p-0 font-weight-bold"
                        onClick={() =>
                          history.push(
                            `${mapActionType[iter?.type]?.link}?status=processing&dateFrom=${dayjs().format(
                              'YYYY-MM-DD'
                            )}&dateTo=${dayjs().format('YYYY-MM-DD')}`
                          )
                        }
                      >
                        <span>Go to source page</span>
                      </button>
                    )
                  }
                };
              }}
            </Table>
          </>
        )}
      </div>
    </div>
  );
}
