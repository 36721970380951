import React, { useEffect, useState } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { TCurrentPaymnentPreferenceSettings, TPaymentPreferenceRequest } from '+types';

import CurrentPreference from './CurrentPreference';
import PreferenceRequest from './PreferenceRequest';
import PreferenceRequestHistory from './PreferenceRequestHistory';

import './payment-preference-tabs.scss';

const paymentPreferenceTabList = ['Current Preference', 'Preference Request', 'Preference Request History'];

const PaymentPreferenceTabs = ({
  currentPref,
  loading,
  data = []
}: {
  currentPref: TCurrentPaymnentPreferenceSettings;
  loading: boolean;
  data: TPaymentPreferenceRequest[];
}) => {
  // NOTE: If there is a pending request, the first item in the array is always the pending request.
  const pendingRequest = data[0]?.status === 'pending' ? data[0] : ({} as TPaymentPreferenceRequest);

  const [tab, setTab] = useState('Current Preference');

  useEffect(() => {
    if (pendingRequest.status) setTab('Preference Request');
  }, [pendingRequest.status]);

  return (
    <div className="row payment-preference-tabs">
      {loading ? (
        <LoadingPlaceholder content={2} type="table" />
      ) : (
        <>
          {pendingRequest?.status && (
            <div data-testid="payment-preference-alert" className="payment-preference-alert">
              <Icon name="warningTriangle" height={20} width={20} style={{ marginTop: 2 }} />
              <div className="action-info">
                <span>Pending:</span>
                <p>
                  Kindly review this merchant’s request to change their payment preferences for <b>overpayments and underpayments.</b>
                </p>
              </div>
            </div>
          )}
          <div className="tabs-wrapper">
            <ul data-testid="preference-nav-list" className="vertical-menu">
              {paymentPreferenceTabList.map(menu => {
                return (
                  <li className={tab === menu ? 'active' : ''} key={menu}>
                    <button data-testid={menu} type="button" tabIndex={0} onClick={() => setTab(menu)}>
                      {menu} {pendingRequest.status && menu === 'Preference Request' && <span className="highlight">1</span>}
                    </button>
                  </li>
                );
              })}
            </ul>

            <div>
              {tab === 'Current Preference' && <CurrentPreference pref={currentPref} />}
              {tab === 'Preference Request' && <PreferenceRequest pendingRequest={pendingRequest} currentPref={currentPref} />}
              {tab === 'Preference Request History' && <PreferenceRequestHistory history={data} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentPreferenceTabs;
