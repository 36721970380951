import React from 'react';
import { CardTypes, getCardColorProp, schemeLogoOptions } from './cardThumbnailData';
import './index.scss';

interface IThumbnailProps {
  status: string;
  scheme: string;
  holderName: string;
  expiresAt: string;
  firstSixDigits: string;
  lastFourDigits: string;
  color: string;
}

export default function CardThumbnail({ cardDetails }: { cardDetails: IThumbnailProps }) {
  const targetColorProps = getCardColorProp(cardDetails.color);
  return (
    <div className="card-thumbnail__comp">
      <div className="card-wrapper" style={{ background: `linear-gradient(${targetColorProps.value})` }}>
        <div className="top">
          <div className={`status-indicator lg ${cardDetails?.status || ''} capitalize status`}>{cardDetails?.status}</div>
          <div className="scheme-logo">
            <img alt={cardDetails?.scheme || ''} src={schemeLogoOptions[(cardDetails?.scheme as CardTypes) || '']} />
          </div>
        </div>
        <div className="middle">
          <span className="digits">{cardDetails?.firstSixDigits?.slice(0, 4) || ''}</span>
          <span className="digits">{cardDetails?.firstSixDigits?.slice(-2) || ''}••</span>
          <span className="hidden-char">••••</span>
          <span className="digits">{cardDetails?.lastFourDigits || ''}</span>
        </div>
        <div className="bottom">
          <div className="card-info-unit">
            <p className="label">expires</p>
            <p className="value">{cardDetails?.expiresAt || ''}</p>
          </div>
          <div className="card-info-unit">
            <p className="label">Cardholder</p>
            <p className="value">{cardDetails?.holderName || ''}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
