import React, { useEffect, useState } from 'react';
import useQueryStorage from '+hooks/useQueryStorage';
import CurrencyPicker from '+shared/CurrencyPicker';
import EmptyStateComponent from '+shared/EmptyState';
import { getDate, storageDataKey } from '+utils';

export default function RollingReserve() {
  const getReserveConfigCurrencies = () => {
    if (!Object.keys(settlements || {}).length) return [];
    const reserveConfigCurrencies = Object.keys(settlements).reduce((allCurrencies, currency) => {
      const currencyConfig = settlements[currency]?.rolling_reserve;
      if (!Object.keys(currencyConfig || {}).length) return allCurrencies;
      return [...allCurrencies, currency];
    }, []);
    return reserveConfigCurrencies;
  };
  const { getQueryData } = useQueryStorage();
  const queryData = getQueryData(storageDataKey.SINGLE_MERCHANT);
  const settlements = queryData?.settlements;
  const availableCurrencies = getReserveConfigCurrencies();
  const [activeCurrency, setActiveCurrency] = useState(availableCurrencies[0]);

  const switchMethod = {
    bank_transfer: 'Bank Transfer',
    card: 'Card Payments',
    reserved_bank_account: 'Reserved Bank Account',
    virtual_bank_account: 'Virtual Bank Account',
    pay_with_bank: 'Pay with Bank (Direct debit)',
    mobile_money: 'Mobile Money'
  };

  useEffect(() => {
    setActiveCurrency(availableCurrencies[0]);
  }, [settlements]);

  const renderConfig = () => {
    const paymentMethodsConfig = settlements[activeCurrency]?.rolling_reserve;
    return (
      <div className="rule-group">
        {Object.keys(paymentMethodsConfig || {})?.map((paymentMethod) => {
          return (
            <div className="rule-box" key={paymentMethod}>
              <div className="rule-details">
                <p>
                  {'For '}
                  <span className="--detail-grey ml-1">{switchMethod[paymentMethod]}</span>,
                  <span className="--detail-green ml-2">{paymentMethodsConfig[paymentMethod]?.rate}%</span>
                  {' of the settlement amount is reserved for '}
                  <span className="--detail-green">{paymentMethodsConfig[paymentMethod]?.period} days</span>
                </p>

                {settlements[activeCurrency]?.updated_at && (
                  <p className="rolling-reserve__updated">Last updated: {getDate(settlements[activeCurrency]?.updated_at)}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="nav-content active">
      <div className="element-box rolling-reserve">
        <div className="element-box-heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="heading-box-mmd">
            <h4 className="form-header">Rolling Reserve Settings</h4>
            <div className="form-desc no-underline mb-2 rolling-reserve__desc">
              A portion of the settlement amount is held as Rolling Reserve for a specified period of time, after which funds are released
              into this merchant’s available balance.
            </div>
          </div>
        </div>

        {availableCurrencies?.length === 0 ? (
          <EmptyStateComponent heading="No configuration" message="There is no rolling reserve configuration for this merchant yet." />
        ) : (
          <>
            <CurrencyPicker
              options={availableCurrencies}
              onChange={(value) => {
                setActiveCurrency(value);
              }}
              className="mt-2 mb-3"
              activeCurrency={activeCurrency}
              label={<strong>Showing Rolling Reserve for:</strong>}
              id="merchant-reserve-settings__currency-switch"
            />

            <legend>
              <span />
            </legend>

            <section className="grey-section rolling-reserve__settings">
              <div className="grey-section__subsection">
                <div className="rule-container grey-section__subsection-group">
                  <div className="fee-item grey-section__subsection-item">
                    <div>
                      <p className="mb-0" />
                      <p>
                        These are the Rolling Reserve settings for this merchant’s {activeCurrency} payments on different payment methods.
                      </p>
                    </div>
                  </div>
                  {renderConfig()}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}
