import { Storage } from '+services/storage-services';
import { storageDataKey } from '+utils';

export default function getStoreBulkCurrency(): string[] {
    const currencies = Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES);
    if (Array.isArray(currencies)) {
        return currencies.filter((currency: string) => ['NGN', 'GHS', 'KES', 'ZAR'].includes(currency));
    }
    return [];
}
