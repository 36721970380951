import React from 'react';
import { ITableData } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime } from '+utils';
import Icon from '+shared/Icons';

export type TPausedPayment = {
  [key in
    | 'reference'
    | 'status'
    | 'currency'
    | 'amount'
    | 'fee'
    | 'vat'
    | 'amount_charged'
    | 'amount_paid'
    | 'channel'
    | 'created_at'
    | 'narration'
    | 'message'
    | 'trace_id'
    | 'batch_reference'
    | 'product_type'
    | 'transaction_date'
    | 'processor'
    | 'processor_reference'
    | 'unique_reference'
    | 'merchant']: string;
} & {
  completed_at: string | null;
  account: {
    name: 'LANJ';
  };
};

export type TStage = 'process_single' | 'process_multiple' | 'cancel_single' | 'cancel_multiple';

export const switchTabData = (activeCurrency: string, btnAction: (arg: TStage, ref: string) => void): ITableData<TPausedPayment> => {
  return {
    type: 'table',
    className: '--paused-payments-table',
    rowURL: '/dashboard/payouts',
    rowKey: 'unique_reference',
    emptyStateHeading: 'No paused payouts transactions',
    emptyStateMessage: 'It looks like you do not have any paused payouts transactions at the moment.',
    annotations: 'transaction(s)',
    fields: (iter: TPausedPayment) => {
      return {
        data: {
          product_name: <span className="merch-name">{capitalizeRemovedash(iter?.product_type
            )}</span>,
          transaction_id: <span className="trxn-id">{iter?.unique_reference}</span>,
          merchant_name: <span className="merch-name">{capitalize(iter?.account?.name)}</span>,
          'Date & Time': (
            <>
              <span>{getDate(iter?.created_at)}</span>
              <span className="annotation">{getTime(iter.transaction_date)}</span>
            </>
          ),
          amount: (
            <>
              <span>
                <strong>{formatAmount(iter?.amount)}</strong>
              </span>
              <span className="annotation">{activeCurrency}</span>
            </>
          ),
          '': (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <span
              className="click-event" 
              onClick={(e: React.MouseEvent<Element, globalThis.MouseEvent>) => {
                e.stopPropagation();
              }}
            >
              <button type="button" className="btn" onClick={() => btnAction('cancel_single', iter?.unique_reference)}>
                <Icon name="cancel" stroke="red" height={15} width={15} />
              </button>
              <button type="button" className="btn" onClick={() => btnAction('process_single', iter?.unique_reference)}>
                <Icon name="check" stroke="green" height={14} width={14} />
              </button>
            </span>
          )
        }
      };
    }
  };
};
