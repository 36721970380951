import React, { useEffect } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import SignInComponent from './Signin';
import UserInviteVerification from './UserInviteVerification';
import AccessDenial from '../Dashboard/AccessDenial';
import InviteLogo from '+assets/img/dashboard/clock-red.svg';

import './index.scss';

function AuthComponent() {
  const location = useLocation();

  const getExportDownload = () => {
    const isExportDownload = location.pathname.includes('/dashboard/files');
    if (isExportDownload) {
      const downloadUrl = location.search;
      sessionStorage.setItem('EXPORT_LINK', downloadUrl);
    }
  };

  useEffect(() => {
    getExportDownload();
  }, []);

  return (
    <div className="auth__content">
      <Switch>
        <Route path="/invite-denial">
          <AccessDenial
            imgSrc={InviteLogo}
            title="Invalid invitation!"
            description="Sorry, this invitation link can no longer be used because it has expired or was revoked. Please contact your administrator."
            hasContactSupport
          />
        </Route>
        <Route path="/access-denied">
          <AccessDenial
            title="Access Denied!"
            description="Sorry, you do not have the permission to perform this action or view this page. Please contact your administrator."
          />
        </Route>
        <Route path="/suspended">
          <AccessDenial
            title="Account suspended"
            description="Sorry, you do not have the permission to access the internal dashboard because your account has been suspended. Please contact your administrator."
          />
        </Route>
        <Route path="/deactivated">
          <AccessDenial
            imgSrc={InviteLogo}
            title="Account Deactivated!"
            description="Sorry, this account has been deactivated. Please contact your administrator."
            hasContactSupport
          />
        </Route>
        <Route path="/invite/:code" component={UserInviteVerification} exact />
        <Route path="/" component={SignInComponent} />
        <Redirect path="/" to="/" />
      </Switch>
    </div>
  );
}

export default AuthComponent;
