import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import { CurrencyType, IPartnerAccount } from '+types';
import { isAllowed, logError, storageDataKey } from '+utils';

import EmptyStateComponent from '../Shared/EmptyState';
import LoadingPlaceholder from '../Shared/LoadingPlaceHolder';
import BalanceSummaryCard from './components/BalanceSummaryCard';

import './index.scss';

const api = new APIRequest();

const Balances = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery<{ currency: CurrencyType }>();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';

  const availableCurrencies = Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES) ?? ['NGN'];

  const { data, refetch, isFetching } = useQuery('PARTNERS_ACCOUNTS', () => api.getPartnersAccounts(), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error in getting this partners accounts.`,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        }
      });
    }
  });

  return (
    <div className="content-i">
      <div className="content-box partners-balance">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex mb-4 partners-balance__tabs">
            <ul className="nav nav-tabs ">
              <li className="nav-item">
                <button type="button" className="nav-link  active">
                  Partner Balances
                </button>
              </li>
            </ul>
            <CurrencyPicker
              options={availableCurrencies?.filter((currency: CurrencyType) => currency === 'NGN')}
              onChange={value => {
                searchQuery.setQuery({ currency: value as CurrencyType });
              }}
              className="partners-balance__currency-switch"
              activeCurrency={activeCurrency?.toUpperCase()}
              label={null}
            />
          </div>
        </section>
        {isAllowed(userAccess, ['processor_accounts.view']) ? (
          <section className="row px-2">
            <section className="partners-balance__intro mt-4">
              <div>
                <h5>{`Partner Balances `}</h5>
                <p>These are the balances for the different processing partners that service our products.</p>
              </div>
              <button type="button" className="btn btn-primary" onClick={() => refetch()}>
                <i className="os-icon os-icon-refresh-ccw" />
                Refetch Balances
              </button>
            </section>
            {isFetching || data?.data.length > 0 ? (
              <section className="partners-balance__summary">
                {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                {isFetching
                  ? Array.from({ length: 3 }).map(_ => <LoadingPlaceholder type="text" content={3} />)
                  : data?.data?.map((item: IPartnerAccount) => (
                      <BalanceSummaryCard balance={item} key={item.id} currency={activeCurrency} />
                    ))}
              </section>
            ) : (
              <div className="empty-state">
                <EmptyStateComponent message="Account Balance Not Available" />
              </div>
            )}
          </section>
        ) : (
          <div className="empty-state">
            <EmptyStateComponent message="You don't have permission to see Partners account" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Balances;
