import React, { ReactNode, useState } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

type TPreferenceDropdown = {
  header: ReactNode;
  children: JSX.Element;
};

export default function PreferenceDropdown({ header, children }: TPreferenceDropdown) {
  const [toggle, setToggle] = useState(false);

  const toggleDropdown = () => {
    setToggle(!toggle);
  };

  return (
    <div className="preference-section">
      <button
        data-testid="preference-dropdown-btn"
        className="preference-dropdown__btn"
        onClick={toggleDropdown}
        type="button"
        aria-label="view preference"
      >
        <h6 data-testid="preference-dropdown-header">{header}</h6>
        <i className={`${toggle ? 'rotate-up' : ''}`}>
          <Icon name="caretDownSolid" width={14} height={14} />
        </i>
      </button>

      {toggle && (
        <div data-testid="preference-dropdown-content-wrapper" className="animate-dropdown">
          {children}
        </div>
      )}
    </div>
  );
}
