/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DisputesHistory from './DisputesHistory';
import TransactionDetails from '../TransactionDetails';
import MultipleChargeback from './Chargeback/MultipleChargeback';
import ChargebackSummary from './Chargeback/ChargebackSummary/ChargebackSummary';
import './index.scss';

export default function Disputes() {
  return (
    <div className="checkouts-container dispute-container">
      <Switch>
        <Route exact path="/dashboard/disputes" component={DisputesHistory} />
        <Route path="/dashboard/disputes/chargeback/add" component={MultipleChargeback} />
        <Route path="/dashboard/disputes/chargeback/summary" component={ChargebackSummary} />
        <Route path="/dashboard/disputes/refunds/:id">
          <TransactionDetails />
        </Route>
        <Route path="/dashboard/disputes/chargebacks/:id">
          <TransactionDetails />
        </Route>
      </Switch>
    </div>
  );
}
