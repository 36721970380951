import React from 'react';

import { IFeeProps, IPlansTable } from '+types';
import { formatAmount } from '+utils';

import './index.scss';

const placeholderColumns = [0, 1, 2];
const placeholderRows = Array(10).fill('--');
const aThousand = 1_000;
const aMillion = 1_000_000;
const fiveMillion = 5_000_000;

const showIfVat = (arg: boolean) => (arg ? '(Incl. VAT)' : '');
const showFeeType = (type: IFeeProps['type']) => (type === 'percentage' ? '%' : '$');

const formatTpvAmount = (amount: number) => {
  if (amount >= aMillion) return `${Math.floor(amount / aMillion)}M`;
  if (amount >= aThousand) return `${Math.floor(amount / aThousand)}k`;
};

const showTpvRangeFrom = (min: string, max: string) => {
  const minAsNumber = Number(min);
  const maxAsNumber = Number(max);

  if (maxAsNumber < aMillion) return 'Less than $1M';
  if (maxAsNumber > fiveMillion) return 'Above $5M';
  return `$${formatTpvAmount(minAsNumber)} - $${formatTpvAmount(maxAsNumber)}`;
};

export default function PlansTable({ onPlanEdit, data, category, isDisabled }: IPlansTable) {
  return (
    <div role="region" aria-labelledby="headersCardPlans">
      <table className="card-plans-table d-flex align-items-center w-100 bg-transparent">
        <caption className="sr-only" id="headersCardPlans">
          Subscription plans for issuing merchants
        </caption>
        <thead>
          <tr>
            <th aria-hidden />
            <th>Total Payment Value (TPV)</th>
            <th>Card Issuing Fee</th>
            <th>Max. Number of Issuable Cards (per cycle)</th>
            <th>Card Transaction Fee</th>
            <th>Monthly Subscription Fee</th>
            <th>Security Reserve</th>
            <th>Chargeback Fee</th>
            <th>Cross-currency Fee (per cycle)</th>
            <th>Funding Fee</th>
            <th>Non-Sufficient Funds Fee</th>
            <th aria-hidden />
          </tr>
        </thead>
        <tbody>
          {!isDisabled && data
            ? data?.map(datum => {
                const cardFee = datum.fee[category];
                const minTpv = category === 'customer' ? datum.min_payment_value : datum.reserved_card_min_payment_value;
                const maxTpv = category === 'customer' ? datum.max_payment_value : datum.reserved_card_max_payment_value;
                const monthlyCardLimit = category === 'customer' ? datum.monthly_card_limit : datum.reserved_card_limit;

                return (
                  <tr key={datum.reference}>
                    <th scope="row" className="text-capitalize">
                      {datum.name}
                    </th>
                    <td>{showTpvRangeFrom(minTpv, maxTpv)}</td>
                    <td>
                      {`${cardFee.issuance.type === 'flat' ? showFeeType(cardFee.issuance.type) : ''}${formatAmount(
                        cardFee.issuance.amount
                      )} ${cardFee.issuance.type === 'percentage' ? showFeeType(cardFee.issuance.type) : ''}`}
                    </td>
                    <td>{monthlyCardLimit}</td>
                    <td>$0.00</td>
                    {/* hardcoded */}
                    <td>
                      {`${cardFee.subscription.type === 'flat' ? showFeeType(cardFee.subscription.type) : ''}${formatAmount(
                        cardFee.subscription.amount
                      )}${cardFee.subscription.type === 'percentage' ? showFeeType(cardFee.subscription.type) : ''} ${showIfVat(
                        cardFee.subscription.vat_inclusive
                      )}`}
                    </td>
                    <td>$0.00</td> {/* hardcoded */}
                    <td>
                      {`${cardFee.chargeback.type === 'flat' ? showFeeType(cardFee.chargeback.type) : ''}${formatAmount(
                        cardFee.chargeback.amount
                      )}${cardFee.chargeback.type === 'percentage' ? showFeeType(cardFee.chargeback.type) : ''}`}
                    </td>
                    <td>{`${cardFee.cross_currency.type === 'flat' ? showFeeType(cardFee.cross_currency.type) : ''}${
                      cardFee.cross_currency.amount
                    }${cardFee.cross_currency.type === 'percentage' ? showFeeType(cardFee.cross_currency.type) : ''} of CC TPV`}</td>
                    <td>
                      {`${cardFee.funding.type === 'flat' ? showFeeType(cardFee.funding.type) : ''}${formatAmount(cardFee.funding.amount)}${
                        cardFee.funding.type === 'percentage' ? showFeeType(cardFee.funding.type) : ''
                      }`}
                    </td>
                    <td>$0.00</td> {/* hardcoded; same as withdrawal fee? */}
                    <td>
                      <button
                        disabled={isDisabled}
                        onClick={() => onPlanEdit(datum.reference)}
                        data-feat="plans"
                        className="btn"
                        type="button"
                      >
                        Edit plan
                      </button>
                    </td>
                  </tr>
                );
              })
            : placeholderColumns.map(column => (
                <tr key={column}>
                  <th scope="row" className="text-capitalize">
                    --
                  </th>
                  {placeholderRows.map((row, index) => (
                    <td key={index}>{row}</td>
                  ))}
                  <td>
                    <button disabled={isDisabled} data-feat="plans" type="button" className="btn">
                      Edit plan
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
