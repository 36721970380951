/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import Select, { components, GroupBase, OptionProps, SingleValueProps } from 'react-select';
import { useFormik } from 'formik';

import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { advanceCleanInput, capitalize, capitalizeFirst, logError } from '+utils';

import Icon from './Icons';

import './TransactionStatus.scss';

const api = new APIRequest();

type TUpgradeRequestModal = Omit<IModalProps, 'close'> & { buttonState?: boolean };

const { Option, SingleValue } = components;

const statuses = [
  { label: 'Failed', value: 'failed', icon: <Icon name="failed" /> },
  { label: 'Success', value: 'success', icon: <Icon name="success" /> }
];

const iconOption: React.FC<OptionProps<any>> = props => {
  const { data } = props;

  return (
    <Option {...props}>
      {data.icon}
      {data.label}
    </Option>
  );
};

const statusValue: React.FC<SingleValueProps<any, boolean, GroupBase<any>>> = props => {
  const { data } = props;

  return (
    <SingleValue {...props}>
      {data.icon}
      {data.label}
    </SingleValue>
  );
};

type TREnderForm = {
  setStatus: Dispatch<SetStateAction<string>>;
  setReasonText: Dispatch<SetStateAction<string>>;
  handleCheckboxChange: (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => void;
  currentStatus: 'expired' | 'processing';
};

function renderForm({ setStatus, setReasonText, handleCheckboxChange, currentStatus }: TREnderForm) {
  const form = useFormik({
    initialValues: {
      reasonText: ''
    },
    onSubmit: () => undefined
  });
  return (
    <div className="form-group modal-content">
      <label htmlFor="current-status">
        <span className="dark">Current Status</span>
      </label>
      <input type="text" id="current-status" className="form-control" value={capitalize(currentStatus)} disabled />
      <label htmlFor="reason">
        <span className="dark">New Status</span>
      </label>
      <div className="select-action">
        <Select
          isSearchable={false}
          defaultValue={statuses[0]}
          options={statuses}
          components={{ Option: iconOption, SingleValue: statusValue }}
          onChange={e => {
            setStatus(e.value);
          }}
        />
      </div>
      <label htmlFor="update-reason">
        <span className="dark">Reason for update</span>
      </label>
      <input
        type="text"
        id="update-reason"
        className="form-control"
        onChange={e => {
          const inputValue = advanceCleanInput(e.target.value);
          form.setFieldValue('reasonText', inputValue);
          setReasonText(inputValue);
        }}
        value={form.values.reasonText}
      />
      <span className="error">Reason for update must be at least 5 characters</span>
      <div className="checkbox-container">
        <input type="checkbox" id="approve" onChange={handleCheckboxChange} />
        <label htmlFor="approve">Yes, I understand the implications of this action</label>
      </div>
    </div>
  );
}

const RenderForm = memo(renderForm);

const TransactionStatusModal = ({
  activeTransaction,
  updateModalState,
  triggerProcessingLoader,
  transactionType
}: {
  activeTransaction: {
    processor: string;
    payment_reference?: string;
    processor_reference: string;
    unique_reference?: string;
    reference?: string;
    status: 'expired' | 'processing';
  };
  updateModalState: () => void;
  triggerProcessingLoader: (param: string) => void;
  transactionType: string;
}) => {
  const { feedbackInit } = useFeedbackHandler();
  const [reasonText, setReasonText] = useState<string>('');
  const [status, setStatus] = useState<string>('failed');
  const [isChecked, setChecked] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [modalStage, setModalStage] = useState('');

  const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setChecked(e.target.checked);
  };

  const [modal, setModal] = useState({
    modalType: null as string | null,
    modalInfo: {} as TUpgradeRequestModal
  });

  useEffect(() => {
    setButtonState(isChecked);
  }, [isChecked]);

  const { mutateAsync: updateTransactionStatus } = useMutation(
    () =>
      api.updateTransactionStatus(
        transactionType,
        status,
        reasonText,
        transactionType === 'payins' ? activeTransaction?.reference : activeTransaction?.unique_reference
      ),
    {
      onSuccess: () => {
        updateModalState();
        feedbackInit({
          type: 'success',
          message: 'Transaction status updated successfully'
        });
        triggerProcessingLoader(activeTransaction.processor_reference);
      },
      onError: (error: any) => {
        updateModalState();
        logError(error);
        feedbackInit({
          type: 'danger',
          message: error?.response?.data?.message || error?.response?.message || 'There has been an error updating the status'
        });
      }
    }
  );

  const modalDetail = useCallback(
    () => ({
      updateStatus: {
        heading: 'Update Transaction Status',
        description: 'Updating this transaction status will reflect on all places where this transaction is stored.',
        firstButtonText: 'Cancel',
        secondButtonText: 'Update Status',
        content: (
          <RenderForm
            setStatus={setStatus}
            setReasonText={setReasonText}
            handleCheckboxChange={handleCheckboxChange}
            currentStatus={activeTransaction.status}
          />
        ),
        size: 'md',
        secondButtonActionIsTerminal: false,
        buttonState: !isChecked,
        secondButtonAction: () => {
          setModalVisible('confirmUpdate');
        }
      },
      confirmUpdate: {
        heading: 'Update Status',
        secondButtonText: 'Yes, Update',
        size: 'md',
        secondButtonActionIsTerminal: true,
        secondButtonAction: updateTransactionStatus,
        completedHeading: 'Status Updated',
        completedDescription: 'You have successfully updated this transaction status',
        firstButtonAction: () => setModalVisible('updateStatus')
      }
    }),
    [reasonText]
  ) as unknown as () => Record<string, Omit<IModalProps & { buttonState?: boolean }, 'close'>>;

  const setModalVisible = (type: string) => {
    setModalStage(type);
    const modalDetails = modalDetail();
    setModal({
      modalType: type,
      modalInfo: {
        heading: modalDetails[type].heading,
        description: modalDetails[type].description,
        completedHeading: modalDetails[type]?.completedHeading,
        completedDescription: modalDetails[type]?.completedDescription,
        content: modalDetails[type].content,
        firstButtonText: modalDetails[type].firstButtonText,
        secondButtonText: modalDetails[type].secondButtonText,
        size: modalDetails[type]?.size,
        secondButtonAction: modalDetails[type].secondButtonAction,
        firstButtonAction: modalDetails[type].firstButtonAction,
        secondButtonActionIsTerminal: modalDetails[type].secondButtonActionIsTerminal,
        buttonState: modalDetails[type].buttonState
      }
    });
  };

  useEffect(() => {
    setModalVisible('updateStatus');
  }, []);

  const renderAdditionalContent = () => {
    return (
      <div className="modal-content">
        <div className="additional-content">
          <Icon name="infoSolid" width={26} height={26} fill="#FA9500" />
          <p>
            {status === 'success'
              ? 'Important: Updating this status will make this transaction successful and merchant will be credited. Are you sure you want to continue?'
              : 'Important: Updating this status will fail this transaction and merchant will not be credited. Are you sure you want to continue?'}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        visible={modal.modalType !== null}
        close={() => {
          updateModalState();
          setModal({ modalType: null, modalInfo: {} as IModalProps });
        }}
        heading={modal.modalInfo?.heading}
        description={
          modalStage === 'updateStatus' ? (
            modal.modalInfo?.description
          ) : (
            <>
              You are about to update the status of this transaction from <strong>{capitalize(activeTransaction?.status)}</strong> to{' '}
              <strong>{capitalizeFirst(status)}</strong>.
            </>
          )
        }
        content={modalStage === 'updateStatus' ? modal.modalInfo?.content : renderAdditionalContent()}
        size={modal.modalInfo?.size}
        firstButtonText={modal.modalInfo?.firstButtonText}
        secondButtonText={modal.modalInfo?.secondButtonText}
        firstButtonAction={
          modal.modalInfo?.firstButtonAction
            ? modal.modalInfo?.firstButtonAction
            : () => {
                updateModalState();
                setModal({ modalType: null, modalInfo: {} as IModalProps });
              }
        }
        secondButtonAction={() => modal.modalInfo?.secondButtonAction?.()}
        secondButtonDisable={!buttonState || reasonText.length < 5}
        secondButtonActionIsTerminal={modal.modalInfo?.secondButtonActionIsTerminal}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        showButtons={modal.modalInfo?.showButtons}
        secondaryCompletedModal
      />
    </div>
  );
};

export default TransactionStatusModal;
