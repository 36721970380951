import { persist } from 'zustand/middleware';
import { encryptContent, decryptContent } from '+utils';
import { BulkInfoType } from '+types';

const createBulkActionSlice = persist(
  set => ({
    bulkInfo: {} as BulkInfoType,
    completedAction: '',
    setBulkInfo: (bulkInfo: BulkInfoType) =>
      set(() => ({
        bulkInfo
      })),
    setCompletedAction: (completedAction: string) =>
      set(() => ({
        completedAction
      }))
  }),
  {
    name: 'bulk-actions',
    getStorage: () => sessionStorage,
    serialize: state => encryptContent(state),
    deserialize: state => decryptContent(state)
  }
);

export default createBulkActionSlice;
