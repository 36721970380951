import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { cleanInput, filterOutEmptyValues } from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import Modal from '+shared/Modal';
import CheckboxAccordion from '+shared/CheckboxAccordion';
import CustomCheckbox from '+shared/CustomCheckbox';
import 'react-datepicker/dist/react-datepicker.css';


interface IAdvancedWebhooksFilterProps {
  handleFilterQuery: () => void;
  closeModal: () => void;
  isFiltering: boolean;
  visible: boolean;
}

const AdvancedWebhooksFilter = ({ handleFilterQuery, closeModal, isFiltering, visible } : IAdvancedWebhooksFilterProps ) => {
  const { feedbackInit } = useFeedbackHandler();
  const [visibleState, setVisibleState] = useState({
    statusFilter: false,
    merchantFilter: false,
    uniqueIDFilter: false,
    retriesFilter: false,
    triggeredAtFilter: false,
    createdAtFilter: false,
    lastAttemptFilter: false,
    responseCodeFilter: false,
    typeFilter: false,
    keywordsFilter: false
  });
  const [status, setStatus] = useState({
    kind: {},
    selected: []
  });
  const [queryMerchant, setQueryMerchant] = useState('');
  const [createdDate, setCreatedDate] = useState({
    to: null,
    from: null
  });
  const [webhookDate, setWebhookDate] = useState({
    to: null,
    from: null
  });
  const [lastAttemptDate, setLastAttemptDate] = useState({
    to: null,
    from: null
  });
  const [responseCode, setResponseCode] = useState('');
  const [retries, setRetries] = useState('');
  const [queryID, setQueryID] = useState('');
  const [queryKeyword, setQueryKeyword] = useState('');
  const [uniqueIDRange, setUniqueIDRange] = useState('exactly');
  const [webhookType, setWebhookType] = useState('');
  const selectedStatus = () => {
    return Object.keys(status.kind).filter(kind => status.kind[kind]);
  };

  const getQueryParams = () => {
    const {
      statusFilter,
      merchantFilter,
      uniqueIDFilter,
      retriesFilter,
      triggeredAtFilter,
      createdAtFilter,
      lastAttemptFilter,
      responseCodeFilter,
      typeFilter,
      keywordsFilter
    } = visibleState;
    const createdDateValue = () => {
      if (!createdAtFilter || !createdDate.to || !createdDate.from) return {};
      return {
        'filter[created_at][to]': dayjs(createdDate.to).format('YYYY-MM-DD'),
        'filter[created_at][from]': dayjs(createdDate.from).format('YYYY-MM-DD')
      };
    };
    const webhookDateValue = () => {
      if (!triggeredAtFilter || !webhookDate.to || !webhookDate.from) return {};
      return {
        'filter[webhook_date][to]': dayjs(webhookDate.to).format('YYYY-MM-DD'),
        'filter[webhook_date][from]': dayjs(webhookDate.from).format('YYYY-MM-DD')
      };
    };
    const lastAttemptDateValue = () => {
      if (!lastAttemptFilter || !lastAttemptDate.to || !lastAttemptDate.from) return {};
      return {
        'filter[lastAttempt_at][to]': dayjs(lastAttemptDate.to).format('YYYY-MM-DD'),
        'filter[lastAttempt_at][from]': dayjs(lastAttemptDate.from).format('YYYY-MM-DD')
      };
    };
    const statusValue = () => {
      if (!statusFilter) return {};
      let statusText = '';
      if (status.selected.length > 1) {
        for (let i = 0; i < status.selected.length; i += 1) {
          statusText += status.selected[i];
          if (i < status.selected.length - 1) {
            statusText += '&filter[status][]=';
          }
        }
      } else {
        statusText = status.selected.join('');
      }
      return {
        'filter[status][]': statusText
      };
    };
    const merchantValue = () => {
      if (!merchantFilter) return {};
      if (Number.isNaN(parseInt(queryMerchant, 10))) {
        return {
          'filter[merchant]': queryMerchant
        };
      }
      return {
        'filter[merchant_id]': queryMerchant
      };
    };

    return {
      'filter[transaction_reference]': uniqueIDFilter ? queryID : undefined,
      'filter[response_code]': responseCodeFilter ? responseCode : undefined,
      'filter[type]': typeFilter ? webhookType : undefined,
      'filter[attempts]': retriesFilter ? retries : undefined,
      'filter[keyword]': keywordsFilter ? queryKeyword : undefined,
      ...merchantValue(),
      ...statusValue(),
      ...createdDateValue(),
      ...webhookDateValue(),
      ...lastAttemptDateValue()
    };
  };

  const handleClearFilter = () => {
    setVisibleState(prevState => ({
      ...prevState,
      statusFilter: false,
      merchantFilter: false,
      uniqueIDFilter: false,
      retriesFilter: false,
      triggeredAtFilter: false,
      createdAtFilter: false,
      lastAttemptFilter: false,
      responseCodeFilter: false,
      typeFilter: false,
      keywordsFilter: false
    }));
  };

  useEffect(() => {
    if (!isFiltering) handleClearFilter();
  }, [isFiltering]);

  useEffect(() => {
    if (Object.keys(status.kind).length) {
      setStatus(prevState => ({
        ...prevState,
        selected: selectedStatus()
      }));
    }
  }, [status.kind]);

  useEffect(() => {
    if (!visibleState.statusFilter) {
      setStatus({
        kind: {},
        selected: []
      });
    }
    if (!visibleState.lastAttemptFilter) {
      setLastAttemptDate({
        to: null,
        from: null
      });
    }
    if (!visibleState.merchantFilter) setQueryMerchant('');
    if (!visibleState.createdAtFilter) {
      setCreatedDate({
        to: null,
        from: null
      });
    }
    if (!visibleState.triggeredAtFilter) {
      setWebhookDate({
        to: null,
        from: null
      });
    }
    if (!visibleState.uniqueIDFilter) setQueryID('');
    if (!visibleState.retriesFilter) setRetries('');
    if (!visibleState.typeFilter) setWebhookType('');
    if (!visibleState.responseCodeFilter) setResponseCode('');
    if (!visibleState.keywordsFilter) setQueryKeyword('');
  }, [visibleState]);

  const filterWebhookNotifications = async () => {
    const queryParams = filterOutEmptyValues(getQueryParams());
    if (!Object.keys(queryParams)?.length) {
      feedbackInit({
        message: `Please fill out the selected field(s)`,
        type: 'danger',
        componentLevel: true
      });
      return;
    }
    await handleFilterQuery(queryParams);
    closeModal();
  };

  const WebhooksFilter = () => (
    <div className="row webhooks__advanced-modal">
      <CheckboxAccordion
        title="Status"
        visible={visibleState.statusFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            statusFilter: !prevVisibleState.statusFilter
          }))
        }
      >
        <div className="col-12">
          <div className="d-flex flex-wrap">
            <div className="mr-4">
              <div className="mb-3">
                <CustomCheckbox
                  text="All"
                  checked={status?.kind?.delivered && status?.kind?.failed && status?.kind?.processing && status?.kind?.pending}
                  onChange={value => {
                    if (value) {
                      setStatus(prevState => ({
                        ...prevState,
                        kind: {
                          delivered: true,
                          failed: true,
                          pending: true,
                          processing: true
                        }
                      }));
                      return;
                    }
                    setStatus(prevState => ({
                      ...prevState,
                      kind: {},
                      selected: []
                    }));
                  }}
                />
              </div>
            </div>

            <div className="mr-4">
              <div className="mb-3">
                <CustomCheckbox
                  text="Delivered"
                  checked={status?.kind?.delivered}
                  onChange={value => {
                    setStatus(prevState => ({
                      ...prevState,
                      kind: {
                        ...prevState.kind,
                        delivered: value
                      }
                    }));
                  }}
                />
              </div>
            </div>

            <div className="mr-4">
              <div className="mb-3">
                <CustomCheckbox
                  text="Failed"
                  checked={status?.kind?.failed}
                  onChange={value => {
                    setStatus(prevState => ({
                      ...prevState,
                      kind: {
                        ...prevState.kind,
                        failed: value
                      }
                    }));
                  }}
                />
              </div>
            </div>
            <div className="mr-4">
              <div className="mb-3">
                <CustomCheckbox
                  text="Pending"
                  checked={status?.kind?.pending}
                  onChange={value => {
                    setStatus(prevState => ({
                      ...prevState,
                      kind: {
                        ...prevState.kind,
                        pending: value
                      }
                    }));
                  }}
                />
              </div>
            </div>
            <div className="mr-4">
              <div className="mb-3">
                <CustomCheckbox
                  text="Processing"
                  checked={status?.kind?.processing}
                  onChange={value => {
                    setStatus(prevState => ({
                      ...prevState,
                      kind: {
                        ...prevState.kind,
                        processing: value
                      }
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Merchant"
        visible={visibleState.merchantFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            merchantFilter: !prevVisibleState.merchantFilter
          }))
        }
      >
        <div className="col-12  pb-4">
          <input
            className="form-control"
            type="text"
            placeholder="Merchant name or ID"
            value={queryMerchant}
            onChange={e => setQueryMerchant(e.target.value)}
          />
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Unique Reference (Transaction ID)"
        visible={visibleState.uniqueIDFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            uniqueIDFilter: !prevVisibleState.uniqueIDFilter
          }))
        }
      >
        <div className="col-12  pb-2">
          <div className="row">
            <div className="form-group col-sm-4">
              <select className="form-control" name="column" onChange={e => setUniqueIDRange(e.target.value)} value={uniqueIDRange}>
                {/* <option value="contains">Contains</option> */}
                <option value="exactly">Exactly</option>
              </select>
            </div>

            <div className="form-group col-sm-8">
              <input
                name="transaction-id"
                className="form-control"
                type="search"
                placeholder="Eg. KPY-C-8052042.."
                value={queryID}
                onChange={e => setQueryID(cleanInput(e.target.value))}
              />
            </div>
          </div>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Triggered At"
        visible={visibleState.triggeredAtFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            triggeredAtFilter: !prevVisibleState.triggeredAtFilter
          }))
        }
      >
        <div className="col-12  pb-2">
          <div className="row">
            <div className="form-group col-sm-6">
              <p className="mb-1">From</p>
              <DatePicker
                selected={webhookDate.from}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setWebhookDate(prevState => ({
                    ...prevState,
                    from: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>

            <div className="form-group col-sm-6 filter-object filter-object-sm">
              <p className="mb-1">To</p>
              <DatePicker
                selected={webhookDate.to}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setWebhookDate(prevState => ({
                    ...prevState,
                    to: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>
          </div>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Created At"
        visible={visibleState.createdAtFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            createdAtFilter: !prevVisibleState.createdAtFilter
          }))
        }
      >
        <div className="col-12  pb-2">
          <div className="row">
            <div className="form-group col-sm-6">
              <p className="mb-1">From</p>
              <DatePicker
                selected={createdDate.from}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setCreatedDate(prevState => ({
                    ...prevState,
                    from: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>

            <div className="form-group col-sm-6 filter-object filter-object-sm">
              <p className="mb-1">To</p>
              <DatePicker
                selected={createdDate.to}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setCreatedDate(prevState => ({
                    ...prevState,
                    to: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>
          </div>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Last Attempt At"
        visible={visibleState.lastAttemptFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            lastAttemptFilter: !prevVisibleState.lastAttemptFilter
          }))
        }
      >
        <div className="col-12  pb-2">
          <div className="row">
            <div className="form-group col-sm-6">
              <p className="mb-1">From</p>
              <DatePicker
                selected={lastAttemptDate.from}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setLastAttemptDate(prevState => ({
                    ...prevState,
                    from: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>

            <div className="form-group col-sm-6 filter-object filter-object-sm">
              <p className="mb-1">To</p>
              <DatePicker
                selected={lastAttemptDate.to}
                dateFormat="dd/MM/yyyy"
                onChange={date => {
                  setLastAttemptDate(prevState => ({
                    ...prevState,
                    to: date
                  }));
                }}
                placeholderText="Select a date"
                calendarClassName="custom-datepicker"
              />
            </div>
          </div>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Response Code"
        visible={visibleState.responseCodeFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            responseCodeFilter: !prevVisibleState.responseCodeFilter
          }))
        }
      >
        <div className="col-12  pb-4">
          <input
            className="form-control"
            type="text"
            name="response_code"
            maxLength={3}
            inputMode="numeric"
            placeholder="E.g 400"
            value={responseCode}
            onChange={e => setResponseCode(cleanInput(e.target.value))}
          />
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Attempts"
        visible={visibleState.retriesFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            retriesFilter: !prevVisibleState.retriesFilter
          }))
        }
      >
        <div className="col-12  pb-4">
          <input
            className="form-control"
            type="text"
            name="retries"
            maxLength={3}
            inputMode="numeric"
            placeholder="E.g 1"
            value={retries}
            onChange={e => setRetries(cleanInput(e.target.value))}
          />
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Type"
        visible={visibleState.typeFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            typeFilter: !prevVisibleState.typeFilter
          }))
        }
      >
        <div className="col-12  pb-4">
          <select className="form-control" onChange={e => setWebhookType(e.target.value)} value={webhookType}>
            <option value="all">All</option>
            <option value="payout">Payout</option>
            <option value="payin">Payin</option>
          </select>
        </div>
      </CheckboxAccordion>

      <CheckboxAccordion
        title="Keyword(s)"
        visible={visibleState.keywordsFilter}
        showContent={() =>
          setVisibleState(prevVisibleState => ({
            ...prevVisibleState,
            keywordsFilter: !prevVisibleState.keywordsFilter
          }))
        }
      >
        <div className="col-12 mb-0">
          <input
            type="search"
            className="form-control"
            placeholder="Search anything..."
            value={queryKeyword}
            onChange={e => setQueryKeyword(cleanInput(e.target.value))}
          />
          <div className="info-detail-box bg-transparent px-0 mb-0">
            Keywords can include Webhook ID, Merchant Email, Response Code, etc.
          </div>
        </div>
      </CheckboxAccordion>
    </div>
  );

  return (
    <Modal
      visible={visible}
      size="md"
      close={closeModal}
      heading="Filter Webhook Notifications"
      description="Use any of the following advanced filter controls to find more webhook notifications"
      firstButtonText="Close"
      secondButtonText="Apply Filter"
      secondButtonAction={() => filterWebhookNotifications()}
      content={WebhooksFilter()}
      secondButtonActionIsTerminal={false}
    />
  );
};


export default AdvancedWebhooksFilter;
