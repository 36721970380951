/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import FieldErrorMsg from '+shared/FormError';
import Modal, { IModalProps } from '+shared/Modal';
import { IEditedPlan, IError, INewPlan, IPlan, IPlanCreationModal, PlansInitialValuesType, StepType } from '+types';
import { advanceCleanInput, logError } from '+utils';

import {
  actionDescriptions,
  actionHeadings,
  confirmationDescriptions,
  confirmationHeadings,
  initializeValues,
  validate
} from '../constants/plan-editor';
import PlanEditor from './PlanEditor';

const api = new APIRequest();

export default function PlanEditorModal({
  onClose,
  action,
  currentPlanData,
  cardCategory,
  refetchPlans,
  TPVCollection
}: IPlanCreationModal) {
  const isReservedCards = cardCategory === 'reserved';
  const isEditingReservedCardPlan = action === 'edit' && isReservedCards;
  const isEditingIssuedCardPlan = action === 'edit';
  const initialValues = initializeValues(currentPlanData as IPlan, action);

  const initialStep: StepType = isEditingReservedCardPlan ? 'setReservedCardFees' : isEditingIssuedCardPlan ? 'setIssuedCardFees' : 'init';
  const [step, setStep] = useState<StepType>(initialStep);
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [actionIsConfirmed, setActionIsConfirmed] = useState(false);

  const formik = useFormik({
    initialValues,
    validate: values => validate({ values, TPVCollection, step, planName: currentPlanData?.name || '' }),
    onSubmit: async () => {
      switch (step) {
        case 'init':
          handleStepChange('setIssuedCardFees');
          formik.setTouched({});
          break;
        case 'setIssuedCardFees':
          handleStepChange('setReservedCardFees');
          formik.setTouched({});
          break;
        case 'setReservedCardFees':
          handleStepChange('confirmPlanUpdate');
          formik.validateForm();
          break;
        case 'confirmPlanUpdate':
          await handlePlanEdit();
          setFormIsSubmitted(true);
          break;
        default:
          break;
      }
    }
  });

  const gotoPrev = () => {
    switch (step) {
      case 'confirmPlanUpdate':
        setStep('setReservedCardFees');
        break;
      case 'setReservedCardFees':
        setStep('setIssuedCardFees');
        break;
      case 'setIssuedCardFees':
        setStep('init');
        break;
      case 'init':
      default:
        onClose();
        break;
    }
  };

  const addPlan = useMutation((data: INewPlan) => api.addPlan(data), {
    onSuccess: () => {
      formik.resetForm();
      refetchPlans();
    },
    onError: (error: IError) => {
      logError(error);
      feedbackInit({
        message: error.response?.data?.message || 'Unable to add plan. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const updatePlan = useMutation((data: IEditedPlan) => api.updatePlan(String(currentPlanData?.reference), data), {
    onSuccess: () => {
      formik.resetForm();
      refetchPlans();
    },
    onError: (error: IError) => {
      feedbackInit({
        message: error.response?.data?.message || 'Unable to update plan. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const handleStepChange = (val: StepType) => formik.isValid && setStep(val);

  const errorKeys = Object.keys(formik.errors.customer || formik.errors.reserved || {});

  useEffect(() => {
    if (step !== 'init') {
      if (errorKeys.length === 0) closeFeedback();
      if (errorKeys.includes('max_tpv' || 'min_tpv'))
        feedbackInit({
          message: (
            <div className="font-weight-bold">
              The entered Total Payment Value (TPV) conflicts with an existing plan. Please set a different value.
            </div>
          ),
          type: 'danger',
          componentLevel: true
        });
    }
  }, [errorKeys.length]);

  const handlePlanEdit = async () => {
    let plan;
    switch (action) {
      case 'edit':
        plan = updatePlanTo(formik.values);
        await updatePlan.mutateAsync(plan as IEditedPlan);
        break;
      default:
        plan = addPlanFrom(formik.values);
        await addPlan.mutateAsync(plan as INewPlan);
        break;
    }
  };

  const InitForm = (
    <div className="stack-s">
      <div className="stack-xs">
        <label className="font-weight-bold" htmlFor="plan-name">
          Plan name
        </label>
        <input
          className="form-control"
          id="plan-name"
          placeholder="Enter plan name"
          aria-describedby="plan-name-error"
          name="name"
          value={formik.values.name}
          onChange={e => formik.setFieldValue('name', advanceCleanInput(e.target.value))}
          onBlur={formik.handleBlur}
        />
        <FieldErrorMsg id="plan-name-error" error={formik.errors?.name as string} touched={formik.touched?.name as boolean} />
      </div>

      <div className="stack-xs" aria-labelledby="describe-card-type">
        <span id="describe-card-type" className="sr-only">
          The default card type is virtual cards
        </span>
        <label htmlFor="card-type" className="font-weight-bold">
          Card type
        </label>
        <select {...formik.getFieldProps('card_type')} className="form-control" id="card-type" value="virtual" disabled>
          <option value="virtual">Virtual cards</option>
          <option value="physical">Physical cards</option>
        </select>
      </div>
    </div>
  );

  const Confirmation = (
    <div data-group="confirm-action">
      <label htmlFor="confirm_action">Yes I understand the implications of this action</label>
      <input
        id="confirm_action"
        type="checkbox"
        onChange={e => setActionIsConfirmed(e.target.checked)}
        checked={actionIsConfirmed}
        name="confirm_action"
      />
    </div>
  );

  const stepProps: Record<'shared' | StepType, Partial<IModalProps>> = {
    shared: {
      close: () => {
        onClose();
        if (formIsSubmitted) queryClient.invalidateQueries(['BILLING_PLANS']);
      },
      size: 'md',
      secondButtonAction: formik.submitForm,
      firstButtonAction: gotoPrev,
      firstButtonText: 'Back',
      firstButtonDisable: !formik.isValid,
      secondButtonActionIsTerminal: false,
      secondaryCompletedModal: true,
      maxHeight: '700px',
      isScrollable: true
    },
    init: {
      heading: actionHeadings[action],
      description: actionDescriptions[action],
      content: InitForm,
      firstButtonText: 'Cancel',
      isScrollable: false
    },
    setIssuedCardFees: {
      heading: actionHeadings[action],
      description: actionDescriptions[action],
      content: (
        <PlanEditor
          tpvList={TPVCollection.customer}
          action={action}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          currentStep={step}
          values={formik.values}
          setField={formik.setFieldValue}
          getField={formik.getFieldProps}
          errors={formik.errors}
          touched={formik.touched}
        />
      ),
      ...(action === 'edit' ? { firstButtonText: 'Cancel', firstButtonAction: onClose } : {})
    },
    setReservedCardFees: {
      heading: actionHeadings[action],
      description: actionDescriptions[action],
      content: (
        <PlanEditor
          tpvList={TPVCollection.reserved}
          action={action}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          currentStep={step}
          values={formik.values}
          setField={formik.setFieldValue}
          getField={formik.getFieldProps}
          errors={formik.errors}
          touched={formik.touched}
        />
      )
    },
    confirmPlanUpdate: {
      size: 'sm',
      heading: confirmationHeadings[action],
      description: confirmationDescriptions[action],
      content: Confirmation,
      secondButtonDisable: !actionIsConfirmed,
      secondButtonActionIsTerminal: true,
      completedDescription: 'You have successfully added a subscription plan'
    }
  };

  const mergedProps = {
    ...stepProps.shared,
    ...stepProps[step]
  };

  return (
    <div className="m-0">
      <Modal {...(mergedProps as IModalProps)} />
    </div>
  );
}

const addPlanFrom = (data: PlansInitialValuesType) => {
  const plan = {
    card_type: data.card_type,
    currency: data.currency,
    min_payment_value: data.customer.min_tpv,
    max_payment_value: data.customer.max_tpv,
    monthly_card_limit: data.customer.card_limit,
    reserved_card_limit: data.reserved.card_limit,
    name: data.name,
    reserved_card_min_payment_value: data.reserved.min_tpv,
    reserved_card_max_payment_value: data.reserved.max_tpv,
    fee: {
      reserved: data.reserved.fee,
      customer: data.customer.fee
    }
  };

  return plan;
};

const updatePlanTo = (data: PlansInitialValuesType) => {
  const plan: IEditedPlan | INewPlan = {
    min_payment_value: data.customer.min_tpv,
    max_payment_value: data.customer.max_tpv,
    monthly_card_limit: data.customer.card_limit,
    reserved_card_limit: data.reserved.card_limit,
    name: data.name,
    reserved_card_min_payment_value: data.reserved.min_tpv,
    reserved_card_max_payment_value: data.reserved.max_tpv,
    fee: {
      reserved: data.reserved.fee,
      customer: data.customer.fee
    }
  };
  return plan;
};
