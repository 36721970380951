/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Storage } from '+/services/storage-services';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import ToolTip from '+shared/Tooltip';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatAllTime,
  formatAmount,
  getDate,
  getPresentDate,
  getTime,
  isAllowed,
  queriesParams,
  storageDataKey,
  switchStatus
} from '+utils';
import useStore from '+zustandStore';

import TransactionStatusModal from '../Shared/TransactionStatus';
import TransactionDetails from '../TransactionDetails';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function PayoutComponent() {
  const { feedbackInit } = useFeedbackHandler();

  const availableCurrencies = Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const searchQuery = useSearchQuery();
  const activeCurrencyTab = searchQuery.value.tab || 'NGN';
  const limit = searchQuery.value.limit || '10';
  const dateFrom = searchQuery.value.dateFrom || getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo || getPresentDate().dateTo;
  const status = searchQuery.value.status || [];
  const sortingParams = {
    dateFrom: formatAllTime(dateFrom),
    dateTo: formatAllTime(dateTo),
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.totalItems
    ])
  };
  const userAccess = useSetUserAccess();
  const { profile } = useStore(state => state);

  const { getDownload } = useLargeExportDownloader('Payouts');

  useEffect(() => {
    getDownload();
  }, []);

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetch();
    }
  }, [state.clickedTransactionIds]);

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(
    [
      'PAYOUT_TRANSACTIONS',
      filteredOutObjectProperty(searchQuery.value, [queriesParams.totalItems, queriesParams.page]),
      activeCurrencyTab
    ],
    () => api.getTransactions('payouts', limit, sortingParams, activeCurrencyTab),
    {
      keepPreviousData: true,
      onError: () => {
        let message = 'There has been an error getting your payouts.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error so we could not find any results for your ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetch();
    }
  }, [state.clickedTransactionIds]);

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging;
  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportTransactions('payouts', sortingParams, format, activeCurrencyTab, fieldsToExport);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'excel';
        APIDownload(res, `Payouts at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>payouts transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your payouts`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const payouts = {
    className: ' --history-table',
    rowURL: '/dashboard/payouts',
    rowKey: 'unique_reference',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no payouts yet.',
    annotations: 'transaction(s)',
    fields: iter => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus(iter.status === 'requires_auth' ? 'pending' : iter.status)}`} />
            <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
            {iter.status === 'processing' &&
              ['mobile_money', 'bank_account'].includes(iter.payment_destination_type) &&
              !state.clickedTransactionIds?.includes(iter.processor_reference) &&
              isAllowed(userAccess, ['payouts_status.update']) && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    updateTransactionStatusModalState(true, iter);
                  }}
                  type="button"
                  className="status-update-icon"
                  aria-label="Update transaction status"
                >
                  <Icon name="settings" />
                </button>
              )}
            {state.clickedTransactionIds?.includes(iter.processor_reference) && iter.status === 'processing' && (
              <ToolTip
                type="processing"
                message={
                  <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
                }
                centered
              >
                <span className="rotate">
                  <Icon name="loading" />
                </span>
              </ToolTip>
            )}
          </>
        ),
        transaction_id: <span className="trxn-id">{iter.reference}</span>,
        merchant: <span className="merch-name">{capitalize(iter?.account?.name || 'Not Available')}</span>,
        type: <span>{iter.channel === 'web' ? 'Withdrawal' : 'Payout'}</span>,
        'Date / Time': (
          <>
            <span>{getDate(iter.transaction_date)}</span>
            <span className="annotation">{getTime(iter.transaction_date)}</span>
          </>
        ),
        amount: (
          <>
            <span>
              <strong>{formatAmount(iter.amount)}</strong>
            </span>
            <span className="annotation">{iter.currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(payouts.fields({} as PayOutType).data);
  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />
      <div className="content-i">
        <div className="content-box">
          <section className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex mb-4">
              <ul className="nav nav-tabs">
                {availableCurrencies?.map(currency => (
                  <li className="nav-item" key={currency}>
                    <button
                      type="button"
                      className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                      onClick={() => searchQuery.setQuery({ tab: currency }, true)}
                    >
                      {currency}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <div className="row">
            <div className="col-sm-12">
              <Table
                className={payouts.className || ''}
                loading={isFetching}
                data={data}
                renderFields
                hasPagination
                tableHeadings={tableDataKeys}
                annotation={payouts.annotations}
                rowKey={payouts.rowKey}
                rowURL={payouts.rowURL}
                pageSize={paging?.page_size || 0}
                totalItems={paging?.total_items || 0}
                limitAction={currentLimit => searchQuery.setQuery({ limit: currentLimit })}
                actionFn={current => searchQuery.setQuery({ page: current })}
                emptyStateHeading={payouts.emptyStateHeading || ''}
                tableWrapperClassName="table-responsive"
                emptyStateMessage={payouts.emptyStateMessage || ''}
                filterType="pay-outs"
                filterExportAction={exportFile}
                filterActiveCurrency={activeCurrencyTab}
                filterShowExport={isAllowed(userAccess, ['payouts.export']) as boolean}
                cursors={paging}
              >
                {payouts.fields}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {state.openTransactionStatusModal && (
        <TransactionStatusModal
          activeTransaction={state.activeTransaction}
          updateModalState={updateTransactionStatusModalState}
          triggerProcessingLoader={handleProcessingLoader}
          transactionType="payout"
        />
      )}
    </>
  );
}

export default function PayOutPage() {
  return (
    <div className="checkouts-container">
      <Switch>
        <Route exact path="/dashboard/payouts" component={PayoutComponent} />
        <Route exact path="/dashboard/payouts/:id">
          <TransactionDetails />
        </Route>
        <Redirect to="/dashboard/access-denied" />
      </Switch>
    </div>
  );
}
