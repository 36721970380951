import React from 'react';
import HeroSection from '../Components/HeroSection';
import EventsSummary from '../Components/EventsSummary';
import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function Overview() {
  const userAccess = useSetUserAccess();
  const canViewMerchants = isAllowed(userAccess, ['identity_merchants.view']);
  const canViewVerificationEvents = isAllowed(userAccess, ['identity_verifications.view']);

  const { feedbackInit } = useFeedbackHandler();
  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(['MERCHANTS_STATISTICS'], () => api.getIdentityStatistics(), {
    keepPreviousData: true,
    onError: () => {
      let message = 'There has been an error getting merchants statistic.';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        },
        componentLevel: false
      });
    }
  });

  const { total_merchants, complete_verifications, available_countries } = resolvedData?.data || {
    total_merchants: 0,
    complete_verifications: 0,
    available_countries: 0
  };

  const events = [
    {
      title: 'Identity Merchants',
      value: total_merchants,
      description: 'Merchants with Identity service access',
      cta: { label: 'See Merchants', link: '/dashboard/identity?tab=merchants' },
      haveAccess: canViewMerchants as boolean
    },
    {
      title: 'Checks Done',
      value: complete_verifications,
      description: 'Completed Verification Checks',
      cta: { label: 'See Verification Events', link: '/dashboard/identity?tab=verifications' },
      haveAccess: canViewVerificationEvents as boolean
    },
    {
      title: 'Region Covered',
      value: available_countries,
      description: 'Countries where Identity is available ',
      cta: { label: 'See Configuration', link: '/dashboard/identity' },
    }
  ];
  return (
    <div>
      <HeroSection
        title="Overview"
        description={
          <>
            Identity enables merchants to perform verification checks on their customers.
            <br /> Here’s an overview of the usage of this service.
          </>
        }
      />
      <EventsSummary events={events} isFetching={isFetching} />
    </div>
  );
}
