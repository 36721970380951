import React from 'react';
import Icon from '+containers/Dashboard/Shared/Icons';
import { ISODateStringType } from '+types';
import { getDate, getTime } from '+utils';

type FileItemType = {
  date: ISODateStringType;
  url: string;
};

interface IFileDownloadList<T extends FileItemType> {
  title?: React.ReactNode;
  data: Array<T>;
}

export default function DownloadDocuments<T extends FileItemType>({
  title = 'Supporting documents uploaded by merchant to validate this chargeback',
  data = []
}: IFileDownloadList<T>) {
  if (!data || data.length <= 0) return <p className="empty-state-text">No documents uploaded for this chargeback</p>;

  return (
    <div className="download-documents-comp">
      <div className="content">
        <p>{title}</p>

        <ul className="documents">
          {data.map(({ date, url }, index) => (
            <li className="document" key={index}>
              <div className="d-flex justify-content-center align-items-center flex-wrap document-info">
                <Icon name="file" width={25} height={24} />
                <span className="document-name">{`Evidence ${index + 1}`}</span>
                <div className="document-date d-flex justify-content-center">
                  <span className="no-wrap">{getDate(date)} </span> · <span className="no-wrap">{getTime(date)}</span>
                </div>
              </div>

              <a className="btn btn-link p-0 d-flex align-items-center download-btn" href={url} target="_blank" rel="noopener noreferrer">
                <span>Download</span>
                <Icon name="download" className="download-icon" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
