import React from 'react';
import EmptyState from '+shared/EmptyState';
import { getTime, switchStatus, history, formatAmount, getDate, capitalizeRemovedash, isAllowed } from '+utils';
import useSetUserAccess from '+hooks/useSetUserAccess';

interface IRefundsTableProps {
  activeTab?: string;
  activeCurrency?: string;
  activeList: Record<string, any>;
}

const RefundsTable = ({
  activeTab = '',
  activeCurrency = '',
  activeList,
}: IRefundsTableProps) => {
  const userAccess = useSetUserAccess();
  const getDetailsLink = (reference) =>
    isAllowed(userAccess, ['refund_details.view']) ? `/dashboard/disputes/refunds/${reference}` : '/dashboard/access-denied';
  const getStatus = (status) => (status?.toLowerCase() === 'processing' ? 'Processing' : capitalizeRemovedash(status));

  return (
    <div className="nav-content active">
      <div className="element-box-tp">
        {activeList?.data && activeList?.data.length !== 0 ? (
          <div>
            {activeList?.data?.map((each) => {
              return (
                <div
                  key={`reversal_${each.reference}`}
                  className="div-table --disputes-table --row --refund-table"
                  role="button"
                  tabIndex={0}
                  onClick={() => history.push(getDetailsLink(each.reference))}
                  onKeyDown={() => history.push(getDetailsLink(each.reference))}
                >
                  <div>
                    <span className="body-row-header">Status:</span>
                    <span className={`status-pill smaller ${switchStatus(each.status)}`} />
                    <span role="button" tabIndex="0">
                      {getStatus(each?.status || 'Not available')}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">{`${activeTab} ID`}:</span>
                    <span className="trxn-id" role="button" tabIndex="0">
                      {each?.reference?.toUpperCase() || 'Not available'}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Merchant:</span>
                    <span className="merch-name">{capitalizeRemovedash(each?.merchant || 'Not available')}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Channel:</span>
                    <span>{capitalizeRemovedash(each?.channel || 'Not available')}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date / Time:</span>
                    <span>{getDate(each?.reversal_date)}</span>
                    <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                      {getTime(each?.reversal_date)}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">
                      {`${activeTab} Amount`}
                      {activeCurrency}
                    </span>
                    <span style={{ fontWeight: '500' }}>{formatAmount(each?.refund_amount)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default RefundsTable;
