/* eslint-disable camelcase */
import React, { LegacyRef, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import useClickOutside from '+hooks/useClickOutside';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { IUserModalProps, UserModalType } from '+types';
import { cleanInput, history, logError } from '+utils';

import WarningIcon from '+assets/img/dashboard/warning-red.svg';

const apiRequest = new APIRequest();
const UserModal = ({ details, setModal, from, setButtonState }: IUserModalProps) => {
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [manageModal, setManageModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const wrapperRef = useClickOutside(() => {
    setManageModal(false);
  });

  const userName = `${details?.first_name || 'N/A'} ${details?.last_name || 'N/A'}`;

  const updateUserStatusMutation = useMutation(
    ({ userId, data }: { userId: number; data: { status: 'suspended' | 'active' } }) =>
      apiRequest.updateUserStatus(userId, details?.adminInvitation?.user_status === 'active' ? { ...data, reason: feedback } : data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`${details?.id}_USER_DETAILS_${from?.toUpperCase()}`);
        queryClient.invalidateQueries([`USERS_${from.toUpperCase()}`, 1, 10, {}]);
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this user's status`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const deactivateUserMutation = useMutation(() => apiRequest.deactivateUser(details?.id), {
    onSuccess: () => {
      history.push('/dashboard/users', null);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error deactivating this user.`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const modalDetails = {
    delete: {
      heading: 'Delete user?',
      completedHeading: 'Deleted',
      completedDescription: (
        <span>
          You have successfully deleted <p style={{ fontWeight: 700 }}>{userName}</p> from accessing the internal dashboard
        </span>
      ) as unknown as Element,
      secondButtonText: 'Delete User'
    },
    suspend: {
      heading: 'Confirm Suspension?',
      completedHeading: 'Suspended',
      completedDescription: 'This user has been suspended from accessing the internal dashboard',
      secondButtonText: 'Suspend User'
    },
    reactivate: {
      heading: 'Reactivate User?',
      completedHeading: 'Reactivated!',
      completedDescription: `You have successfully reactivated ${userName} and they can now access the internal dashboard`,
      secondButtonText: 'Yes, Reactivate'
    }
  } as { [k in Partial<UserModalType>]: Partial<IModalProps> };

  useEffect(() => {
    if (!feedback || feedback.length < 3) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [feedback]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedback(cleanInput(e?.target?.value));

  const setModalVisible = (type: UserModalType) => {
    setModal({
      modalType: type,
      modalInfo: {
        heading: modalDetails[type].heading,
        content: (
          <>
            <span>
              {type === 'reactivate' ? (
                <p>
                  You are about to reactivate <span style={{ fontWeight: 700 }}> {userName}</span>, they will be able to access the internal
                  dashboard.
                </p>
              ) : (
                <p>
                  You are about to {type}
                  <span style={{ fontWeight: 700 }}> {userName}</span>, they will no longer have access the internal dashboard.
                </p>
              )}
            </span>{' '}
            {type !== 'reactivate' && (
              <div className="feedback-modal">
                <div className="form-group">
                  {type === 'delete' && (
                    <div className="my-4" style={{ display: 'flex', alignItems: 'center' }}>
                      <img alt="" src={WarningIcon} style={{ width: '10px', height: '10px' }} />
                      <div
                        className="ml-2"
                        style={{
                          color: '#F32345',
                          fontWeight: 400,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 12,
                          fontStyle: 'italic'
                        }}
                      >
                        <span style={{ fontWeight: 700 }}>Warning: </span>
                        <p className="mb-0 ml-1"> deleting a user cannot be undone</p>
                      </div>
                    </div>
                  )}
                  <label htmlFor="reason" style={{ fontWeight: 600 }}>
                    {`Reason for ${type === 'delete' ? 'deleting' : ' Suspension'}`} <span style={{ color: 'red' }}>*</span>
                  </label>

                  <textarea
                    name="reason"
                    className="form-control modal-input"
                    rows={1}
                    maxLength={type === 'delete' ? 40 : 100}
                    placeholder="Enter your reason here"
                    onChange={e => handleChange(e)}
                  />
                </div>
              </div>
            )}
          </>
        ),
        completedHeading: modalDetails[type].completedHeading,
        completedDescription: modalDetails[type].completedDescription as unknown as Element,
        action: () => {
          if (type === 'delete') {
            closeFeedback();
            return deactivateUserMutation.mutateAsync();
          }
          if (['suspend', 'reactivate'].includes(type)) {
            closeFeedback();
            return updateUserStatusMutation.mutateAsync({
              userId: details?.id,
              data: { status: details?.adminInvitation?.user_status === 'active' ? 'suspended' : 'active' }
            });
          }
          return null;
        },
        secondButtonColor: (type !== 'reactivate' && 'red') as string,
        secondButtonText: modalDetails[type].secondButtonText,
        completedModalSize: 'base',
        equalFooterBtn: true
      }
    });
  };

  return (
    <div ref={wrapperRef as LegacyRef<HTMLDivElement>}>
      <button
        type="button"
        className="btn btn-secondary"
        style={{ color: '#4E555B' }}
        onClick={() => {
          setManageModal(value => !value);
        }}
      >
        <div className="header-actions">
          <span>Manage User</span>
          <i className="os-icon os-icon-arrow-down2" />
        </div>
      </button>

      {manageModal && (
        <ul className="element-box ellipsis__nav user-ellipsis">
          <li
            role="presentation"
            className="ellipsis__item"
            onClick={() => {
              setModalVisible(details?.adminInvitation?.user_status === 'active' ? 'suspend' : 'reactivate');
              setManageModal(false);
            }}
          >
            <span>{details?.adminInvitation?.user_status === 'active' ? 'Suspend this user' : 'Reactivate this user'}</span>
          </li>

          {details?.adminInvitation?.user_status === 'active' && (
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setModalVisible('delete');
                setManageModal(false);
              }}
            >
              <span style={{ color: '#F32345', fontWeight: 600 }}>Delete account</span>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default UserModal;
