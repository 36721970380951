import warningIcon from '+assets/img/dashboard/warning-triangle-outline.svg';
import SecondaryDetails from '+containers/Dashboard/Shared/SecondaryDetails';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';
import React, { FC, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { kycFullDetails, kycNavHeaders } from '../data';
import { TKycInformation, TKycModalType } from '../TypesAccountHolders';
import KycDocuments from './KycDocuments';
import KycHistory from './KycHistory';
import { ApproveKycModal, DeclineKycModal, KycActionFeedbackModal } from './KycModals';
import './kyc-information.scss';

const api = new APIRequest();

const KycInformation: FC<TKycInformation> = ({ data }) => {
  const [tab, setTab] = useState('KYC Details' as keyof typeof kycNavHeaders);
  const [modalType, setModalType] = useState<TKycModalType | null>(null);
  const modalTypeOpened = useRef<'approve' | 'decline'>('approve');
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const queryClient = useQueryClient();
  const [kycHistoryLen, setKycHistoryLen] = useState(0);

  const { mutateAsync } = useMutation(
    (payload: string) =>
      api.updateAccountHolderKyc({
        reference: data.reference,
        action: modalTypeOpened.current,
        ...(modalTypeOpened.current === 'decline' ? { reason: payload } : {})
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ACCOUNT_HOLDERS']);
        queryClient.invalidateQueries(['ACCOUNT_HOLDER_KYC']);
        queryClient.invalidateQueries(['KYC_HISTORY']);

        setModalType('success');
      },

      onError: (error: { response: { data: { message: string } } }) => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || `We Could not update ${data.first_name} ${data.last_name} at this time. Try Again`,
          type: 'danger',
          componentLevel: true
        });
        setTimeout(() => {
          closeFeedback();
        }, 2500);
      }
    }
  );

  const openModalAction = (modal: TKycModalType) => {
    setModalType(modal);
    modalTypeOpened.current = modal as 'approve' | 'decline';
  };

  return (
    <div className="row kyc-info">
      {data.account_summary.account_status === 'pending' && (
        <div data-testid="kyc-action" className="kyc-action">
          <img src={warningIcon} alt="kyc approval action" />

          <div className="action-info">
            {data.documents_upload_completed ? (
              <>
                <h6>Action Required: Verify KYC Details</h6>
                <p className="--description">
                  The KYC information of this account holder needs to be verified. Please check that the information and documents submitted
                  are valid, accurate and corresponds.
                </p>
              </>
            ) : (
              <h6>Account Holder documents upload are not yet completed</h6>
            )}
          </div>
          <div className="btn-group">
            {data.documents_upload_completed && (
              <button className="approve" data-testid="approve" onClick={() => openModalAction('approve')} type="button">
                Approve
              </button>
            )}
            <button data-testid="decline" onClick={() => openModalAction('decline')} type="button">
              Decline
            </button>
          </div>
        </div>
      )}
      <div className="col-md-3">
        <div>
          <ul data-testid="kyc-nav" className="vertical-menu">
            {(Object.keys(kycNavHeaders) as [keyof typeof kycNavHeaders])?.map((menu) => {
              return menu === 'KYC Related Documents' && !data.documents_upload_completed ? null : (
                <li className={tab === menu ? 'active' : ''} key={menu}>
                  <button data-testid="kyc-nav-item" type="button" tabIndex={0} onClick={() => setTab(menu)}>
                    {menu}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div data-testid="kyc-content" className="col-md-9">
        {tab === 'Previous Approval/Rejections' && !kycHistoryLen ? null : (
          <>
            <h5 className="kyc-info__title">{kycNavHeaders[tab].header}</h5>
            <p className="kyc-info__description">{kycNavHeaders[tab].description}</p>
          </>
        )}
        <div className="nav-content-wrapper">
          {tab === 'KYC Details' && <SecondaryDetails title="" data={kycFullDetails(data)} hideLine={false} leftSpacing="" />}
          {tab === 'KYC Related Documents' && <KycDocuments doc={data.documents} />}
          {tab === 'Previous Approval/Rejections' && <KycHistory id={data?.reference} setKycHistoryLen={setKycHistoryLen} />}
        </div>
      </div>
      {modalType === 'decline' && <DeclineKycModal setModalType={setModalType} formSubmit={(reason: string) => mutateAsync(reason)} />}
      {modalType === 'approve' && <ApproveKycModal setModalType={setModalType} dispatch={() => mutateAsync('')} />}
      {modalType === 'success' && <KycActionFeedbackModal setModalType={setModalType} action={modalTypeOpened.current} />}
    </div>
  );
};

export default KycInformation;
