import React, { useEffect, useLayoutEffect } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import FeedbackHandler from '+shared/FeedBackHandler';
import { history, logError } from '+utils';
import useStore from '+zustandStore';

import bloomLogin from '+assets/theme/img/kpy-bloom-login.png';
import logo from '+assets/theme/img/kpy-logo-kora.svg';

interface ISignInComponentProps {
  location: {
    search: string;
  };
}

const authAPIRequest = new APIRequest();
export default function SignInComponent(props: ISignInComponentProps) {
  const { feedbackInit } = useFeedbackHandler();
  const {
    location: { search }
  } = props;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const location = useLocation();
  const redirectTo = sessionStorage.getItem('redirectExtLink');

  useEffect(() => {
    if (!location.hostname) {
      sessionStorage.setItem('redirectExtLink', location.pathname);
    }
  }, []);

  const authorizeAuthMutation = useMutation(() => authAPIRequest.authorizeOauth(), {
    onError: error => {
      feedbackInit({ message: error.response.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: data => {
      if (!code) window.location.href = data.data.authorization_url;
    }
  });

  const completeAuthMutation = useMutation(code => authAPIRequest.completeOauth(code), {
    onError: error => {
      logError(error);
    },
    onSuccess: data => {
      const user = {
        id: data?.data?.account?.id,
        name: data?.data?.account?.name,
        email: data?.data?.account?.email
      };
      datadogRum.setUser(user);
      useStore.setState(state => ({
        ...state,
        isAuthorized: true,
        userToken: data.data.access_token,
        refreshToken: data.data.refresh_token,
        userTokenExpiration: data.data.expires_in,
        isAuthenticated: true,
        isLoading: false,
        error: false,
        profile: {
          email: data.data.account.email,
          avatar: data.data.account.avatar
        },
        errors: {}
      }));
      if (redirectTo && redirectTo !== '/') {
        history.push(redirectTo);
        sessionStorage.removeItem('redirectExtLink');
      } else {
        history.push('/dashboard/home');
      }
    }
  });

  useLayoutEffect(() => {
    if (code) {
      completeAuthMutation.mutateAsync(code);
    }
  }, [code]);

  const handleSubmit = () => {
    try {
      authorizeAuthMutation.mutateAsync();
    } catch (err) {
      return logError(err);
    }
  };

  return (
    <div className="auth-wrapper px-3">
      <div className="solid-bg-all">
        <div className="logo-w logo-login-w">
          <a href="/">
            <img className="auth-logo" alt="Korapay" src={logo} />
          </a>
        </div>
        <div className="auth-box-w auth-login-w">
          <div className="login-w login-style-w">
            <img className="auth-logo login-bloom" alt="Bloom login" src={bloomLogin} />
          </div>
          <div>
            <h4 style={{ textAlign: 'center' }}>Welcome back</h4>
            <p className="login-txt-desc" style={{ textAlign: 'center' }}>
              Welcome to Kora’s internal dashboard - the secure back office for managing transactions, merchants and all users.
            </p>
          </div>

          <hr />

          <section className="auth-section p-0">
            <div>
              <FeedbackHandler />
            </div>
            <button
              disabled={authorizeAuthMutation.isLoading || completeAuthMutation.isLoading}
              type="button"
              className="btn btn-primary btn-block btn-lg auth-btn "
              onClick={handleSubmit}
            >
              {authorizeAuthMutation.isLoading || completeAuthMutation.isLoading ? (
                <div className="dual-ring-loader kb-auth-dual-ring-loader" />
              ) : (
                'Login'
              )}
            </button>
          </section>
        </div>
      </div>
    </div>
  );
}
