/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import useLargeExportDownloader from '+hooks/useLargeExportDownloader';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import Table from '+shared/Table';
import { CurrencyType, FileFormatType, moduleType } from '+types';
import { APIDownload, capitalize, filteredOutObjectProperty, getDate, isAllowed, logError, queriesParams } from '+utils';

import ChargebackTableHistory from '../Chargeback/components/ChargebackHistoryTable';
import ChargebackSummaryCard from '../Chargeback/components/ChargebackSummaryCard';
import RefundsTable from '../components/RefundsTable';

import './index.scss';

const api = new APIRequest();

const disputeQueryDetails = {
  refunds: ['PAYMENT_REFUNDS', api.getRefunds],
  chargebacks: ['CHARGEBACK', api.getChargebacks]
};

const exportAPI = {
  refunds: api.exportRefunds,
  chargebacks: api.exportChargebacks
} as const;

function DisputesHistory() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const [tabs, setTabs] = useState<string[]>([]);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const searchQuery = useSearchQuery();
  const activeTab = (searchQuery?.value?.tab || tabs[0]) as moduleType;
  const currentPage = searchQuery?.value?.page || '1';
  const currentLimit = searchQuery?.value?.limit || '10';
  const activeCurrency = (searchQuery?.value?.currency || 'NGN') as CurrencyType;
  const status = searchQuery.value.status || [];

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit, queriesParams.status];
  const sortingParams = {
    keyword: searchQuery?.value.reference || searchQuery?.value?.keyword,
    paymentMethod: searchQuery?.value?.payment_method,
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const buffer = [];
  const anyLoading = {};
  const getTabDetails = (tab: string) => buffer.find(data => data.type === tab);

  useLayoutEffect(() => {
    if (isAllowed(userAccess, ['refunds.view', 'refunds.export']) && !tabs.includes('refunds')) {
      setTabs(prev => ['refunds', ...prev]);
    }
    if (isAllowed(userAccess, ['chargebacks.view', 'chargebacks.export']) && !tabs.includes('chargebacks')) {
      setTabs(prev => [...prev, 'chargebacks']);
    }
  }, [userAccess]);

  useLayoutEffect(() => {
    if (tabs.length > 0) {
      searchQuery.setQuery({ tab: searchQuery?.value?.tab ?? tabs[0] });
    }
  }, [tabs]);

  const { data, refetch, isFetching } = useQuery(
    [disputeQueryDetails.refunds[0], currentPage, currentLimit, sortingParams, activeCurrency],
    () => disputeQueryDetails.refunds[1](currentPage, currentLimit, sortingParams, activeCurrency),
    {
      keepPreviousData: true,
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the ${activeTab}`,
          type: 'danger',
          action: {
            action: () => {
              refetch();
            },
            name: 'Try again'
          }
        });
      },
      enabled: (activeTab === 'refunds' && isAllowed(userAccess, ['refunds.view'])) as boolean
    }
  );

  const {
    data: chargebackData,
    refetch: refetchChargebackData,
    isFetching: isFetchingChargebackData
  } = useQuery(
    [disputeQueryDetails.chargebacks[0], currentPage, currentLimit, sortingParams, activeCurrency],
    () => disputeQueryDetails.chargebacks[1](currentPage, currentLimit, sortingParams, activeCurrency),
    {
      keepPreviousData: true,
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the ${activeTab}`,
          type: 'danger',
          action: {
            action: () => {
              refetchChargebackData();
            },
            name: 'Try again'
          }
        });
      },
      enabled: (activeTab === 'chargebacks' && isAllowed(userAccess, ['chargebacks.view'])) as boolean
    }
  );

  anyLoading[activeTab] = activeTab === 'chargebacks' ? isFetchingChargebackData : isFetching;
  const activeData = activeTab === 'chargebacks' ? chargebackData : data;
  buffer.push({ type: activeTab, ...activeData });

  const activeList = getTabDetails(activeTab);

  const { getDownload } = useLargeExportDownloader();

  const switchDisputes = {
    refunds: <RefundsTable activeTab={activeTab} activeCurrency={activeCurrency} activeList={activeList} />,
    chargebacks: <ChargebackTableHistory activeList={activeList} activeTab={activeTab} activeCurrency={activeCurrency} />
  };

  useEffect(() => {
    getDownload();
  }, []);

  const { data: summaryData, refetch: refetchSummary } = useQuery('CHARGEBACKSUMMARY', () => api.getChargebackSummary(), {
    keepPreviousData: true,
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the chargeback summary`,
        type: 'danger',
        action: {
          action: () => {
            refetchSummary();
          },
          name: 'Try again'
        }
      });
    },
    enabled: activeTab === 'chargebacks'
  });

  const tableHeader = [
    'Status',
    `${capitalize(activeTab)} ID`,
    'Merchant',
    'Channel',
    'Date / Time',
    `${activeTab === 'chargebacks' ? 'dispute' : capitalize(activeTab)} Amount (${[activeCurrency]})`
  ];

  const chargebacksTableHeader = [...tableHeader];
  chargebacksTableHeader.splice(2, 0, 'Transaction ID');

  const exportRefunds = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    const parameterizeArray = (key: string, arr: string | string[]) => {
      if (arr.length === 0) return '';
      if (typeof arr === 'string') return arr;
      const urlArr = arr.map(encodeURIComponent);
      return urlArr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldsToExport);
    try {
      const res = await exportAPI[activeTab as keyof typeof exportAPI](sortingParams, format, activeCurrency, fields);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Report for ${getTabDetails(activeTab).type} at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>{getTabDetails(activeTab)?.paging?.total_items} transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your ${activeTab}`,
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  return (
    <>
      <section className="os-tabs-w disputes-history__page">
        <div className="os-tabs-controls os-tabs-complex settlement-history__tabs">
          <ul className="nav nav-tabs">
            {tabs.map(tab => (
              <li
                className="nav-item"
                key={tab}
                onClick={() => {
                  searchQuery.setQuery({ tab, page: '1' }, true);
                }}
                onKeyUp={() => {
                  searchQuery.setQuery({ tab, page: '1' }, true);
                }}
                role="tab"
                tabIndex={0}
              >
                <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                  {capitalize(tab)}
                </button>
              </li>
            ))}
          </ul>
          <CurrencyPicker
            onChange={value => {
              searchQuery.setQuery({ currency: value, page: '1' });
            }}
            className="settlement-history__currency-switch"
            activeCurrency={activeCurrency}
            id="settlement-history__currency-switch"
          />
        </div>
      </section>
      {activeTab === 'chargebacks' && <ChargebackSummaryCard summaryData={summaryData} />}

      <section className="element-box-tp mt-5">
        <Table
          className={activeTab === 'chargebacks' ? '--chargeback-table' : '--disputes-table --refund-table'}
          tableHeadings={activeTab === 'chargebacks' ? chargebacksTableHeader : tableHeader}
          hasPagination={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab).paging.total_items : 0}
          loading={anyLoading[activeTab as keyof typeof anyLoading]}
          current={parseInt(currentPage, 10)}
          totalItems={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab).paging.total_items : 0}
          pageSize={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab).paging.page_size : 0}
          actionFn={current => searchQuery.setQuery({ page: String(current) })}
          limitAction={limit => searchQuery.setQuery({ limit: String(limit) })}
          annotation={activeTab}
          emptyStateHeading={`There are no ${activeTab} yet.`}
          filterName={(activeTab === 'chargebacks' && `Showing all ${activeCurrency} Chargebacks`) as string}
          filterExportAction={exportRefunds}
          type={activeTab}
          filterActiveCurrency={activeCurrency}
          filterKeywordPlaceholder={
            activeTab === 'chargebacks' ? 'Search by Chargeback ID' : 'Search by Refund ID, Transaction ID, or Customer Name  ...'
          }
          filterAmountPlaceholder="Search by Amount"
          filterShowExport={
            (activeTab === 'chargebacks'
              ? isAllowed(userAccess, ['chargebacks.export'])
              : isAllowed(userAccess, ['refunds.export'])) as boolean
          }
          filterHasAdvancedFilter={activeTab !== 'chargebacks'}
        >
          {switchDisputes[activeTab as keyof typeof switchDisputes]}
        </Table>
      </section>
    </>
  );
}

export default DisputesHistory;
