/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import Modal, { IModalProps } from '+shared/Modal';
import Table from '+shared/Table';
import { IAssignees, IRoleDetails } from '+types';
import { capitalizeFirst, getDate, getTime, history, logError } from '+utils';

import '../index.scss';

const apiRequest = new APIRequest();

const Assignees = ({ roleDetails }: { roleDetails: IRoleDetails }) => {
  const { id: roleId } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? '10';

  const [modal, setModal] = useState<{
    modalType: string | boolean | undefined;
    modalInfo: (Partial<IModalProps> & { action: () => Promise<unknown> }) | null;
  }>({
    modalType: undefined,
    modalInfo: null
  });

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery([`ASSIGNEES_${roleId}`, page, limit], () => apiRequest.getAllRoleAssignees(roleId, page, limit), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Assignees',
        message: 'There has been an error getting assignees',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const removeUserFromRoleUserMutation = useMutation(
    (value: { user_id: number; roles: string[] }) => apiRequest.removeUserFromRole(value),
    {
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error removing this user.`,
          type: 'danger'
        });
      }
    }
  );

  const navigateToUserDetails = (userId: number) => history.push(`/dashboard/users/${userId}`, null);

  const setUnassignModal = (id: number, userName: string) => {
    setModal({
      modalType: 'unassign',
      modalInfo: {
        heading: 'Remove assignee from role?',
        content: (
          <p>
            Please confirm that you want to unassign <span style={{ fontWeight: 700 }}> {userName}</span>. Their role would become
            undefined.
          </p>
        ),
        completedHeading: 'Removed!',
        completedDescription: `You have successfully unassigned ${userName} from the ${capitalizeFirst(roleDetails.name || '')}`,
        action: () => {
          return removeUserFromRoleUserMutation.mutateAsync({
            user_id: id,
            roles: [roleId]
          });
        },
        secondButtonText: 'Yes, Remove',
        completedModalSize: 'base',
        equalFooterBtn: true,
        headerBottomBorder: false
      }
    });
  };

  const assignees = {
    className: '  --white assignee-table div-table-assignee',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no assignees yet.',
    annotations: 'assignee(s)',
    fields: (iter: IAssignees) => {
      const name = `${iter?.firstName} ${iter?.lastName}`;
      return {
        data: {
          full_name: <span>{name}</span>,
          email: (
            <span className="smaller no-ellipsis" role="button">
              {iter?.email}
            </span>
          ),
          date_assigned: (
            <>
              <span>{getDate(iter?.dateAssigned)}</span>
              <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                {getTime(iter?.dateAssigned)}
              </span>
            </>
          ),
          option: (
            <span className="more-option">
              <span
                role="button"
                tabIndex={0}
                onClick={() => navigateToUserDetails(iter?.userId)}
                onKeyDown={() => navigateToUserDetails(iter?.userId)}
              >
                See More
              </span>
              <span className="option-divider" />
              <span
                role="button"
                onClick={() => setUnassignModal(iter?.userId, name)}
                tabIndex={0}
                onKeyDown={() => setUnassignModal(iter?.userId, name)}
              >
                Remove
              </span>
            </span>
          )
        }
      };
    }
  };

  const tableDataKeys = Object.keys(assignees.fields({} as IAssignees).data);
  return (
    <div>
      <Table
        className={assignees.className || ''}
        loading={isFetching}
        data={data}
        renderFields
        hasPagination
        tableHeadings={tableDataKeys}
        annotation={assignees.annotations}
        current={Number.parseInt(page, 10)}
        pageSize={paging?.page_size || 0}
        totalItems={paging?.total_items || 0}
        limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
        actionFn={current => searchQuery.setQuery({ page: String(current) })}
        emptyStateHeading={assignees.emptyStateHeading || ''}
        tableWrapperClassName="table-responsive"
        emptyStateMessage={assignees.emptyStateMessage || ''}
        hasFilter={false}
      >
        {assignees.fields}
      </Table>
      <Modal
        visible={!!modal.modalType}
        secondButtonColor="red"
        close={() => setModal({ modalType: undefined, modalInfo: {} as IModalProps & { action: () => Promise<unknown> } })}
        heading={modal.modalInfo?.heading as string}
        description={modal.modalInfo?.description}
        content={modal.modalInfo?.content}
        size={'mdBase' as IModalProps['size']}
        secondButtonText={modal.modalInfo?.secondButtonText as string}
        secondButtonAction={() => modal.modalInfo?.action()}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        secondaryCompletedModal
        completedModalSize={modal.modalInfo?.completedModalSize}
        equalFooterBtn={modal.modalInfo?.equalFooterBtn}
        headerBottomBorder={modal.modalInfo?.headerBottomBorder}
      />
    </div>
  );
};

export default Assignees;
