import { StoreApi, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import createBulkActionSlice from './bulkAction';
import { BulkActionStoreType } from '+types';

const useStore = create(
  devtools((set, get, api) => ({
    ...(createBulkActionSlice(
      set,
      get as StoreApi<BulkActionStoreType>['getState'],
      api as StoreApi<BulkActionStoreType>
    ) as BulkActionStoreType)
  }))
);

export default useStore;
