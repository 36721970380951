import React, { FC, useEffect } from 'react';
import { Field, Formik } from 'formik';

import { CustomFormType, PlansType } from '../data';
import useFormData from '../hooks/useFormData';

import './index.scss';

interface IChangeSubscriptionCustomFormProps {
  defaultPlan: PlansType;
  validateForm: (state: boolean) => void;
  handleFormData: (data: CustomFormType) => void;
  activeTab: string;
}

const ChangeSubscriptionCustomForm: FC<IChangeSubscriptionCustomFormProps> = ({ defaultPlan, validateForm, handleFormData, activeTab }) => {
  function isFormInvalid(obj: { [key: string]: any }): boolean {
    return Object.values(obj).some(value => typeof value === 'string' && value.trim() === '');
  }

  useFormData(defaultPlan, handleFormData);

  const validate = (values: CustomFormType) => {
    if (isFormInvalid(values)) {
      validateForm(false);
    } else {
      handleFormData(values);
      validateForm(true);
    }
  };

  const initialValues: CustomFormType = {
    customerNumberOfCardsIssuable: defaultPlan?.monthly_card_limit || 0,
    customerFundingFee: defaultPlan?.fee?.customer?.funding?.amount || 0,
    customerIssuanceFee: defaultPlan?.fee?.customer?.issuance?.amount || 0,
    customerWithdrawalFee: defaultPlan?.fee?.customer?.withdrawal?.amount || 0,
    customerSecurityReserveFee: defaultPlan?.fee?.customer?.security_reserve?.amount || 0,
    customerSubscriptionFee: defaultPlan?.fee?.customer?.subscription?.amount || 0,
    customerCrossCurrencyFee: defaultPlan?.fee?.customer?.cross_currency?.amount || 0,
    customerChargebackFee: defaultPlan?.fee?.customer?.chargeback?.amount || 0,

    reservedNumberOfCardsIssuable: defaultPlan?.reserved_card_limit || 0,
    reservedFundingFee: defaultPlan?.fee?.reserved?.funding?.amount || 0,
    reservedIssuanceFee: defaultPlan?.fee?.reserved?.issuance?.amount || 0,
    reservedWithdrawalFee: defaultPlan?.fee?.reserved?.withdrawal?.amount || 0,
    reservedSecurityReserveFee: defaultPlan?.fee?.reserved?.security_reserve?.amount || 0,
    reservedSubscriptionFee: defaultPlan?.fee?.reserved?.subscription?.amount || 0,
    reservedCrossCurrencyFee: defaultPlan?.fee?.reserved?.cross_currency?.amount || 0,
    reservedChargebackFee: defaultPlan?.fee?.reserved?.chargeback?.amount || 0
  };

  return (
    <div className="form-container">
      <div className="fee-type">
        <button type="button" className={`${activeTab === 'issued_cards' ? 'active' : ''}`}>
          <div className={`indicator ${activeTab === 'issued_cards' ? 'active-indicator' : ''}`} />
          <span>Issued cards</span>
        </button>
        <div className={`${activeTab === 'reserved_cards' ? 'active-dotted-line' : 'dotted-line'}`} />
        <button type="button" className={`${activeTab === 'reserved_cards' ? 'active' : ''}`}>
          <div className={`indicator ${activeTab === 'reserved_cards' ? 'active-indicator' : ''}`} />
          <span>Reserved cards</span>
        </button>
      </div>
      <Formik
        validateOnMount
        validateOnChange
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        onSubmit={() => undefined}
        validate={validate}
      >
        {() => {
          return activeTab === 'issued_cards' ? (
            <div>
              <div className="form-group">
                <label htmlFor="customerNumberOfCardsIssuable">Number of Cards Issuable</label>
                <Field type="text" id="customerNumberOfCardsIssuable" name="customerNumberOfCardsIssuable" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="customerFundingFee">Funding Fee</label>
                <div className="input-wrapper">
                  <Field id="customerFundingFee" type="text" name="customerFundingFee" className="form-control" />
                  <div className="suffix">%</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerSubscriptionFee">Subscription Fee</label>
                <div className="input-wrapper">
                  <Field id="customerSubscriptionFee" type="text" name="customerSubscriptionFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerIssuanceFee">Issuance Fee</label>
                <div className="input-wrapper">
                  <Field id="customerIssuanceFee" type="text" name="customerIssuanceFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerCrossCurrencyFee">Cross Currency Fee</label>
                <div className="input-wrapper">
                  <Field id="customerCrossCurrencyFee" type="text" name="customerCrossCurrencyFee" className="form-control" />
                  <div className="suffix">%</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerChargebackFee">Chargeback Fee</label>
                <div className="input-wrapper">
                  <Field id="customerChargebackFee" type="text" name="customerChargebackFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerWithdrawalFee">Withdrawal Fee</label>
                <div className="input-wrapper">
                  <Field id="customerWithdrawalFee" type="text" name="customerWithdrawalFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customerSecurityReserveFee">Security Reserve Fee</label>
                <div className="input-wrapper">
                  <Field id="customerSecurityReserveFee" type="text" name="customerSecurityReserveFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group">
                <label htmlFor="reservedNumberOfCardsIssuable">Number of Cards Issuable</label>
                <Field type="text" id="reservedNumberOfCardsIssuable" name="reservedNumberOfCardsIssuable" className="form-control" />
              </div>
              <div className="form-group">
                <label htmlFor="reservedFundingFee">Funding Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedFundingFee" type="text" name="reservedFundingFee" className="form-control" />
                  <div className="suffix">%</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedSubscriptionFee">Subscription Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedSubscriptionFee" type="text" name="reservedSubscriptionFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedIssuanceFee">Issuance Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedIssuanceFee" type="text" name="reservedIssuanceFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedCrossCurrencyFee">Cross Currency Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedCrossCurrencyFee" type="text" name="reservedCrossCurrencyFee" className="form-control" />
                  <div className="suffix">%</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedChargebackFee">Chargeback Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedChargebackFee" type="text" name="reservedChargebackFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedWithdrawalFee">Withdrawal Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedWithdrawalFee" type="text" name="reservedWithdrawalFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="reservedSecurityReserveFee">Security Reserve Fee</label>
                <div className="input-wrapper">
                  <Field id="reservedSecurityReserveFee" type="text" name="reservedSecurityReserveFee" className="form-control" />
                  <div className="suffix">USD</div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangeSubscriptionCustomForm;
